import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import pageUrls from '@/constants/pageUrls';
import { DetailsItem, Section, TagRequired } from '@/components';

import RequiredValue from '../RequiredValue';

import classes from './SectionContacts.module.scss';

const SectionContacts = props => {
  const { t } = useTranslation();

  const { allowEdit, children, data: { contacts } = {}, NoData = <TagRequired />, onEdit } = props;
  const { ceo, contact } = contacts || {};

  return (
    <Section
      title={t('pages.company.contact-information')}
      to={allowEdit && pageUrls.COMPANY.EDIT.CONTACTS}
      onEdit={onEdit}
      className={classes.detailsList}
    >
      <DetailsItem
        label={t('common.ceo')}
        value={
          <RequiredValue fields={['lastName', 'firstName', 'middleName']} target={ceo} joinBy=" " NoData={NoData} />
        }
      />
      <DetailsItem label={t('common.ceo.email')} value={<RequiredValue target={ceo?.email} NoData={NoData} />} />
      <DetailsItem label={t('common.ceo.phone')} value={<RequiredValue target={ceo?.phone} NoData={NoData} />} />
      {ceo?.contactPerson === true ? (
        <DetailsItem label={t('pages.company.contact-person')} value={t('pages.company-fill.ceo-is-contact-person')} />
      ) : (
        <>
          <DetailsItem
            label={t('pages.company.contact-person')}
            value={
              <RequiredValue
                fields={['lastName', 'firstName', 'middleName']}
                target={contact}
                joinBy={' '}
                NoData={NoData}
              />
            }
          />
          <DetailsItem
            label={t('pages.company.contact-person.position')}
            value={<RequiredValue target={contact?.position} NoData={NoData} />}
          />
          <DetailsItem
            label={t('pages.company.contact-person.email')}
            value={<RequiredValue target={contact?.email} NoData={NoData} />}
          />
          <DetailsItem
            label={t('pages.company.contact-person.phone')}
            value={<RequiredValue target={contact?.phone} NoData={NoData} />}
          />
        </>
      )}
      {children}
    </Section>
  );
};

export default memo(SectionContacts);
