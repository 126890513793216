import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import { parsePagination } from '@/utils/common';
import {
  FIND_SPECIFIC_TENDERS_ERROR,
  FIND_SPECIFIC_TENDERS_LOADING,
  FIND_SPECIFIC_TENDERS_SUCCESS,
  FIND_TENDERS_ERROR,
  FIND_TENDERS_LOADING,
  FIND_TENDERS_SUCCESS,
  GET_TENDERS_ERROR,
  GET_TENDERS_LOADING,
  GET_TENDERS_SUCCESS,
} from '../constants/actionTypes';

import { normalizeTender } from './tenderActions';

const getTenders = () => async dispatch => {
  dispatch({
    type: GET_TENDERS_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TENDER.LIST);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TENDERS_ERROR,
    });
  } else {
    dispatch({
      payload: response.data?.map(normalizeTender),
      type: GET_TENDERS_SUCCESS,
    });
  }
};

const findTenders = payload => async dispatch => {
  dispatch({
    type: FIND_TENDERS_LOADING,
  });

  const { status, error, response } = await apiService.post(apiUrls.TENDER.FIND, payload.data, {
    params: { ...payload?.sort, ...payload?.pagination },
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: FIND_TENDERS_ERROR,
    });
  } else {
    const data = response?.data?.content?.map(normalizeTender);
    const pagination = parsePagination(response?.data);
    dispatch({
      payload: { data, pagination },
      type: FIND_TENDERS_SUCCESS,
    });
  }
};

const findTendersByStatus = payload => async dispatch => {
  const { data, pagination, sort, status: field } = payload;
  dispatch({
    payload: { field },
    type: FIND_SPECIFIC_TENDERS_LOADING,
  });

  const { status, error, response } = await apiService.post(apiUrls.TENDER.FIND, data, {
    params: { ...pagination, ...sort },
  });

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, field },
      type: FIND_SPECIFIC_TENDERS_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data?.content?.map(normalizeTender), field },
      type: FIND_SPECIFIC_TENDERS_SUCCESS,
    });
  }
};

export { findTenders, findTendersByStatus, getTenders };
