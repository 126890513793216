import React, { memo, useCallback } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table as AntTable, Button, Col, Row, Typography } from 'antd';

import { Badge, Link, TableEmpty } from '@/components';

import classes from './Table.module.scss';

const defaultPaginationProps = {
  defaultPageSize: 10,
  pageSizeOptions: [5, 10, 20, 50, 100],
  showSizeChanger: true,
};

const Table = props => {
  const {
    BadgeProps,
    className,
    columns,
    data,
    loading,
    onRowClick,
    onChange,
    pagination = false,
    rowKey = 'id',
    tableLayout = 'auto',
    title,
    viewAll = false,
    viewAllProps,
    ...rest
  } = props;
  const { t } = useTranslation();

  const renderTitle = useCallback(
    () =>
      title && (
        <Row justify="space-between">
          <Col>
            <Row align="middle">
              <Typography.Title level={4} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
              <Badge square {...BadgeProps} />
            </Row>
          </Col>
          {viewAll && (
            <Col>
              <Link {...viewAllProps}>
                <Button shape="round">{t('common.view-all')}</Button>
              </Link>
            </Col>
          )}
        </Row>
      ),
    [BadgeProps, title]
  );

  const onRow = useCallback(
    (row, index) => ({
      onClick: () => {
        if (onRowClick) {
          onRowClick(row, index);
        }
      },
    }),
    [onRowClick]
  );

  const handleChange = useCallback(
    ({ current, pageSize }) => {
      if (onChange) {
        onChange({
          page: current,
          pageSize,
        });
      }
    },
    [onChange]
  );

  return (
    <AntTable
      className={clsx(classes.table, className)}
      columns={columns}
      dataSource={data}
      loading={loading}
      tableLayout={tableLayout}
      onRow={onRow}
      onChange={handleChange}
      pagination={pagination && { ...defaultPaginationProps, total: data?.length, ...pagination }}
      rowKey={rowKey}
      title={title && renderTitle}
      locale={{ emptyText: <TableEmpty /> }}
      {...rest}
    />
  );
};

Table.propTypes = {
  BadgeProps: PropTypes.shape({}),
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  tableLayout: PropTypes.string,
  title: PropTypes.string,
  viewAll: PropTypes.bool,
  viewAllProps: PropTypes.shape({}),
};

export default memo(Table);
