import i18n from 'i18next';

import { ACCOUNT_ROLE } from './roles';

export const EMPLOYEE_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};

export const ROLES_OPTIONS = [
  { label: i18n.t(`common.role.${ACCOUNT_ROLE.AU.toLocaleLowerCase()}`), value: ACCOUNT_ROLE.AU },
  { label: i18n.t(`common.role.${ACCOUNT_ROLE.LG.toLocaleLowerCase()}`), value: ACCOUNT_ROLE.LG },
  { label: i18n.t(`common.role.${ACCOUNT_ROLE.LG_CHIEF.toLocaleLowerCase()}`), value: ACCOUNT_ROLE.LG_CHIEF },
  { label: i18n.t(`common.role.${ACCOUNT_ROLE.SB.toLocaleLowerCase()}`), value: ACCOUNT_ROLE.SB },
];
