import statusType from '../constants/statusType';
import {
  PLACE_AUTOCOMPLETE_ERROR,
  PLACE_AUTOCOMPLETE_LOADING,
  PLACE_AUTOCOMPLETE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {};

const uploadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PLACE_AUTOCOMPLETE_ERROR: {
      const { place, error } = payload;
      return { ...state, [place]: { error, status: statusType.FAILED } };
    }

    case PLACE_AUTOCOMPLETE_LOADING: {
      const { place } = payload;
      return { ...state, [place]: { status: statusType.LOADING } };
    }

    case PLACE_AUTOCOMPLETE_SUCCESS: {
      const { place, data } = payload;
      return { ...state, [place]: { data, status: statusType.SUCCESSED } };
    }

    default:
      return state;
  }
};

export default uploadReducer;
