import React, { memo, useCallback } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Select } from 'antd';

import * as fieldRules from '@/constants/fieldRules';

import FormItem from '../FormItem';
import TransportSelector from './components/TransportSelector';
import UploadImage from './components/UploadImage';

import classes from './GeneralFields.module.scss';

const { Option } = Select;

const rules = {
  vat: [...fieldRules.numbers, ...fieldRules.length(12)],
};

const normalizeImage = image => image;

const GeneralFields = props => {
  const { excludeFields } = props;

  const { t } = useTranslation();
  return (
    <Row gutter={[0, 22]}>
      <Col span={24}>
        <FormItem
          label={t('pages.company-fill.company-logo')}
          name={['general', 'logo']}
          valuePropName="src"
          getValueFromEvent={normalizeImage}
        >
          <UploadImage name="logo" />
        </FormItem>
        <FormItem label={t('common.company-name')} name={['general', 'name']} rules={fieldRules.required}>
          <Input placeholder={t('placeholder.name')} />
        </FormItem>
        <FormItem label={t('pages.company-fill.company-bin')} name={['general', 'bin']} rules={fieldRules.bin}>
          <Input placeholder={t('placeholder.numbers')} />
        </FormItem>
        <FormItem
          label={t('pages.company.company-type')}
          name={['general', 'propertyType']}
          tooltip={t('common.choose-one-of-options')}
        >
          <Select bordered={false} placeholder={t('placeholder.select-from-list')}>
            <Option value="too">{t('pages.company.ownership-type-too')}</Option>
            <Option value="ao">{t('pages.company.ownership-type-ao')}</Option>
          </Select>
        </FormItem>
        <FormItem
          label={t('pages.company.vat-certificate-number')}
          name={['general', 'vatCertificateNumber']}
          tooltip={t('common.fill-x-length-number', { length: 12 })}
          rules={rules.vat}
        >
          <Input placeholder={t('placeholder.numbers')} />
        </FormItem>
        {excludeFields?.indexOf('transportsAvailable') >= 0 || (
          <FormItem
            label={t('pages.company.transport-park')}
            name={['general', 'transportsAvailable']}
            valuePropName="checked"
          >
            <Checkbox>{t('common.available')}</Checkbox>
          </FormItem>
        )}
        {excludeFields?.indexOf('transports') >= 0 || (
          <FormItem shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const transportsAvailable = getFieldValue(['general', 'transportsAvailable']);
              const transports = getFieldValue('transports');
              const unfilledTransport = transports?.some(item => !item || !item.capacity);
              const hasUnfilled = unfilledTransport || false;

              return (
                transportsAvailable && (
                  <Col offset={10}>
                    <Form.List name={['transports']}>
                      {(fields, { add, remove }) => (
                        <Row gutter={[0, 12]}>
                          {fields.map(({ key, ...restField }) => (
                            <Col span={24} key={key}>
                              <TransportSelector onRemove={remove} FormItemProps={{ ...restField }} />
                            </Col>
                          ))}
                          <Col span={24}>
                            <Form.Item>
                              <Divider orientation="center">
                                <Button
                                  type="link"
                                  icon={<PlusCircleOutlined />}
                                  disabled={hasUnfilled}
                                  onClick={() => add()}
                                >
                                  {t('pages.company-fill.add-transport')}
                                </Button>
                              </Divider>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </Form.List>
                  </Col>
                )
              );
            }}
          </FormItem>
        )}
        {excludeFields?.indexOf('transportationType') >= 0 || (
          <FormItem
            label={t('pages.company.transportation-type')}
            style={{ marginBottom: 0 }}
            name={['general', 'transportationType']}
          >
            <Radio.Group size="large" className={classes['radio-group']} buttonStyle="solid">
              <Radio value="inner">{t('pages.company.transportation-type.inner')}</Radio>
              <Radio value="outer">{t('pages.company.transportation-type.outer')}</Radio>
              <Radio value="both">{t('pages.company.transportation-type.both')}</Radio>
            </Radio.Group>
          </FormItem>
        )}
      </Col>
      <Col span={24}>
        <Divider orientation="left">{t('pages.company.registration-address')}</Divider>
        <FormItem label={t('common.zip-code')} name={['addresses', 'registration', 'zipCode']}>
          <Input placeholder={t('placeholder.zip-code')} />
        </FormItem>
        <FormItem label={t('common.address')} name={['addresses', 'registration', 'address']}>
          <Input placeholder={t('placeholder.address')} />
        </FormItem>
        <FormItem label={t('common.city')} name={['addresses', 'registration', 'city']}>
          <Input placeholder={t('placeholder.city')} />
        </FormItem>
        <FormItem label={t('common.country')} name={['addresses', 'registration', 'country']}>
          <Input placeholder={t('placeholder.country')} />
        </FormItem>
        <FormItem
          label=" "
          colon={false}
          style={{ marginBottom: 0 }}
          valuePropName="checked"
          initialValue
          name={['general', 'sameMailAddress']}
        >
          <Checkbox>{t('pages.company-fill.registration-address-is-mail-address')}</Checkbox>
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const sameMailAddress = getFieldValue(['general', 'sameMailAddress']);
            return (
              sameMailAddress !== true && (
                <>
                  <Divider orientation="left">{t('pages.company.mail-address')}</Divider>
                  <FormItem label={t('common.zip-code')} name={['addresses', 'mail', 'zipCode']}>
                    <Input placeholder={t('placeholder.zip-code')} />
                  </FormItem>
                  <FormItem label={t('common.address')} name={['addresses', 'mail', 'address']}>
                    <Input placeholder={t('placeholder.address')} />
                  </FormItem>
                  <FormItem label={t('common.city')} name={['addresses', 'mail', 'city']}>
                    <Input placeholder={t('placeholder.city')} />
                  </FormItem>
                  <FormItem label={t('common.country')} name={['addresses', 'mail', 'country']}>
                    <Input placeholder={t('placeholder.country')} />
                  </FormItem>
                </>
              )
            );
          }}
        </FormItem>
      </Col>
    </Row>
  );
};

export default memo(GeneralFields);
