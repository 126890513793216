import React, { memo } from 'react';

import { Input } from 'antd';

const phonePrefix = '+7';

const Phone = props => {
  const { prefix = phonePrefix } = props;
  return <Input prefix={prefix} type="tel" {...props} />;
};

export default memo(Phone);
