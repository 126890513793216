/* eslint-disable react/display-name */
import React from 'react';

import { ACCOUNT_ROLE } from '@/constants/roles';
import pageUrls from '@/constants/pageUrls';
import {
  Account,
  AccountEdit,
  ChangeEmailSuccess,
  Companies,
  CompanyDetails,
  CompanyEdit,
  CompanyFill,
  Contract,
  Dashboard,
  Employees,
  Landing,
  LinkError,
  LinkExpired,
  MyCompany,
  PasswordChange,
  PasswordChangeFail,
  PasswordChangeSuccess,
  PasswordChangeUsed,
  PasswordRecovery,
  PasswordResetSuccess,
  PrivacyPolicy,
  SignIn,
  SignUp,
  SignUpSuccess,
  Tender,
  TenderAdd,
  Tenders,
  TermsOfUse,
  VerifyEmailSuccess,
  VerifyEmailUsed,
} from '@/pages';

import RoleMappedRoute from './RoleMappedRoute';

export const routes = [
  {
    component: Landing,
    path: '/landing',
  },
  {
    component: ChangeEmailSuccess,
    path: pageUrls.CHANGE_EMAIL_SUCCESS,
  },
  {
    component: LinkError,
    path: pageUrls.LINK.ERROR,
  },
  {
    component: LinkExpired,
    path: pageUrls.LINK.EXPIRED,
  },
  {
    component: SignIn,
    path: pageUrls.SIGN_IN,
  },
  {
    component: SignUp,
    path: pageUrls.SIGN_UP,
  },
  {
    component: SignUpSuccess,
    path: pageUrls.SIGN_UP_SUCCESS,
  },
  {
    component: PasswordRecovery,
    path: pageUrls.PASSWORD_RECOVERY,
  },
  {
    component: PasswordChange,
    path: pageUrls.CHANGE_PASSWORD,
  },
  {
    component: PasswordChangeFail,
    path: pageUrls.CHANGE_PASSWORD_ERROR,
  },
  {
    component: PasswordChangeSuccess,
    path: pageUrls.CHANGE_PASSWORD_SUCCESS,
  },
  {
    component: PasswordChangeUsed,
    path: pageUrls.CHANGE_PASSWORD_USED,
  },
  {
    component: PasswordResetSuccess,
    path: pageUrls.PASSWORD_RESET_SUCCESS,
  },
  {
    component: PrivacyPolicy,
    path: pageUrls.PRIVACY_POLICY,
  },
  {
    component: TermsOfUse,
    path: pageUrls.TERMS,
  },
  {
    component: VerifyEmailSuccess,
    path: pageUrls.VERIFY_EMAIL_SUCCESS,
  },
  {
    component: VerifyEmailUsed,
    path: pageUrls.VERIFY_EMAIL_USED,
  },
];

export const privateRoutes = [
  {
    component: Account,
    exact: true,
    path: pageUrls.ACCOUNT.MAIN,
  },
  {
    component: AccountEdit,
    exact: true,
    path: pageUrls.ACCOUNT.EDIT,
  },
  {
    component: Companies,
    exact: true,
    path: pageUrls.COMPANY.LIST,
  },
  {
    exact: true,
    path: pageUrls.COMPANY.FILL,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.TC]}>
        <CompanyFill />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.COMPANY.MY,
    render: () => (
      <RoleMappedRoute
        role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.TC, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF, ACCOUNT_ROLE.SB]}
      >
        <MyCompany />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.COMPANY.ITEM_PATH,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF, ACCOUNT_ROLE.SB]}>
        <CompanyDetails />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.COMPANY.EDIT.PATH,
    render: () => (
      <RoleMappedRoute
        role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.TC, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF, ACCOUNT_ROLE.SB]}
      >
        <CompanyEdit />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.CONTRACT,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.TC]}>
        <Contract />
      </RoleMappedRoute>
    ),
  },
  {
    component: Dashboard,
    exact: true,
    path: pageUrls.DASHBOARD.MAIN,
  },
  {
    exact: true,
    path: pageUrls.EMPLOYEES.LIST,
    render: () => (
      <RoleMappedRoute
        role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF, ACCOUNT_ROLE.SB, ACCOUNT_ROLE.AU]}
      >
        <Employees />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.TENDERS.EDIT_PATH,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF]}>
        <TenderAdd />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.TENDERS.ADD,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF]}>
        <TenderAdd />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.TENDERS.LIST,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.TC, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF]}>
        <Tenders />
      </RoleMappedRoute>
    ),
  },
  {
    exact: true,
    path: pageUrls.TENDERS.ITEM_PATH,
    render: () => (
      <RoleMappedRoute role={[ACCOUNT_ROLE.AU, ACCOUNT_ROLE.TC, ACCOUNT_ROLE.LG, ACCOUNT_ROLE.LG_CHIEF]}>
        <Tender />
      </RoleMappedRoute>
    ),
  },
];
