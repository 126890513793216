import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';
import Modal from '../Modal';
import { MODALS } from '..';

const FireEmployee = props => {
  const { employee, onOk } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickCancel = useCallback(() => {
    dispatch(hideModal(MODALS.BlockEmployee));
  }, [dispatch]);

  const handleClickBlock = useCallback(() => {
    dispatch(hideModal(MODALS.BlockEmployee));
    if (onOk) {
      onOk();
    }
  }, [dispatch, onOk]);

  return (
    <Modal name={MODALS.BlockEmployee} width={468} bodyStyle={{ padding: '32px' }}>
      <Col span={24}>
        <Typography.Title level={4}>{t('modal.block-employee.title')}</Typography.Title>
      </Col>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Typography.Text>{t('modal.block-employee.description', { employee })}</Typography.Text>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" onClick={handleClickCancel}>
                {t('common.cancel')}
              </Button>
              <Button type="primary" shape="round" onClick={handleClickBlock}>
                {t('common.block')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(FireEmployee);
