import React, { memo } from 'react';

import { Col, Image, Row, Space, Typography } from 'antd';

import logo from './images/logo.svg';

import classes from './styles/Footer.module.scss';

const Footer = () => {
  return (
    <Col span={24} className={classes.root}>
      <Col>
        <Row justify="center">
          <Image src={logo} width={144} wrapperClassName={classes['logo-wrapper']} preview={false} />
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Space size="large">
            <Col>Пользовательское соглашение</Col>
            <Col>Политика конфиденциальности</Col>
            <Col>Правила проведения торгов</Col>
            <Col>Магазин TECHNODOM</Col>
          </Space>
        </Row>
      </Col>
    </Col>
  );
};

export default memo(Footer);
