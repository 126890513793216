import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';

import { DashboardLayout } from '@/containers/layout';
import TendersAU from './TendersAU';
import TendersLG from './TendersLG';
import TendersTC from './TendersTC';

const dashboards = {
  [ACCOUNT_ROLE.AU]: TendersAU,
  [ACCOUNT_ROLE.LG]: TendersLG,
  [ACCOUNT_ROLE.LG_CHIEF]: TendersLG,
  [ACCOUNT_ROLE.SB]: TendersLG,
  [ACCOUNT_ROLE.TC]: TendersTC,
};

const Tenders = () => {
  const { data: account } = useSelector(state => state.account);
  const Component = dashboards[account?.role];
  return <DashboardLayout>{Component && <Component />}</DashboardLayout>;
};

export default memo(Tenders);
