/* eslint-disable react-hooks/rules-of-hooks */

import React, { memo, useCallback, useEffect } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { DATE_TIME_FORMAT } from '@/constants/common';
import { findCompaniesByStatus } from '@/store/actions/companyActions';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import { Table } from '@/components';
import { useHistory } from 'react-router';
import { COMPANY_GROUP, COMPANY_STATUS } from '@/constants/company';

const columns = [
  {
    dataIndex: ['general', 'name'],
    key: 'name',
    title: i18n.t('common.name'),
  },
  {
    dataIndex: ['general', 'transportationType'],
    key: 'type',
    title: i18n.t('pages.company.transportation-type'),
    render: type => type && i18n.t(`pages.company.transportation-type.${type}`),
  },
  {
    dataIndex: ['general', 'lastUpdated'],
    key: 'lastUpdated',
    title: i18n.t('common.updated-at'),
    render: lastUpdated => lastUpdated && moment(lastUpdated).format(DATE_TIME_FORMAT),
  },
];

const Companies = props => {
  const { items, loading, title, status, onCompanyClick } = props;

  return (
    <Table
      viewAll={items?.length > 0}
      viewAllProps={{ to: { pathname: pageUrls.COMPANY.LIST, search: `status=${status}` } }}
      title={title}
      BadgeProps={{ count: items?.length }}
      loading={loading}
      data={items?.slice(0, 4)}
      columns={columns}
      onRowClick={onCompanyClick}
    />
  );
};

const DashboardSB = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const listWaitingForVerification = useSelector(
    state => state.specificCompanies[COMPANY_STATUS.WAITING_FOR_VERIFICATION]
  );
  const listWaitingForReVerification = useSelector(
    state => state.specificCompanies[COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION]
  );
  const listWaitingForVerificationAfterEdit = useSelector(
    state => state.specificCompanies[COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT]
  );
  const listInRework = useSelector(state => state.specificCompanies[COMPANY_STATUS.IN_REWORK]);

  const handleClickCompany = useCallback(
    company => {
      if (company?.general?.id) {
        history.push(pageUrls.COMPANY.ITEM(company?.general?.id));
      }
    },
    [history]
  );

  useEffect(() => {
    dispatch(
      findCompaniesByStatus({
        data: {
          query: [{ field: 'status', value: [COMPANY_STATUS.WAITING_FOR_VERIFICATION] }],
        },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'lastUpdated,asc' },
        status: COMPANY_STATUS.WAITING_FOR_VERIFICATION,
      })
    );
    dispatch(
      findCompaniesByStatus({
        data: {
          query: [{ field: 'status', value: [COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION] }],
        },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'lastUpdated,asc' },
        status: COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION,
      })
    );
    dispatch(
      findCompaniesByStatus({
        data: {
          query: [{ field: 'status', value: [COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT] }],
        },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'lastUpdated,asc' },
        status: COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT,
      })
    );
    dispatch(
      findCompaniesByStatus({
        data: {
          query: [{ field: 'status', value: [COMPANY_STATUS.IN_REWORK] }],
        },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'lastUpdated,asc' },
        status: COMPANY_STATUS.IN_REWORK,
      })
    );
  }, [dispatch]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Companies
          loading={listWaitingForVerification?.status === statusType.LOADING}
          status={COMPANY_STATUS.WAITING_FOR_VERIFICATION}
          items={listWaitingForVerification?.data}
          title={t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION}`)}
          onCompanyClick={handleClickCompany}
        />
      </Col>
      <Col span={12}>
        <Companies
          loading={listWaitingForReVerification?.status === statusType.LOADING}
          status={COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION}
          items={listWaitingForReVerification?.data}
          title={t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION}`)}
          onCompanyClick={handleClickCompany}
        />
      </Col>
      <Col span={12}>
        <Companies
          loading={listWaitingForVerificationAfterEdit?.status === statusType.LOADING}
          status={COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT}
          items={listWaitingForVerificationAfterEdit?.data}
          title={t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT}`)}
          onCompanyClick={handleClickCompany}
        />
      </Col>
      <Col span={12}>
        <Companies
          loading={listInRework?.status === statusType.LOADING}
          status={COMPANY_STATUS.IN_REWORK}
          items={listInRework?.data}
          title={t(`pages.companies.status.${COMPANY_STATUS.IN_REWORK}`)}
          onCompanyClick={handleClickCompany}
        />
      </Col>
    </Row>
  );
};

export default memo(DashboardSB);
