/* eslint-disable sort-keys */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */

import React from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { Row } from 'antd';

import { formatMoney } from '@/utils/format';
import { TenderStatusTag } from '@/components';
import { TENDER_DATE_FORMAT, TENDER_DATETIME_FORMAT, TENDER_TYPE } from '@/constants/tender';

import TenderRoute from '../TenderRoute';

export const colTradingBid = {
  key: 'trading-bid',
  dataIndex: ['bid'],
  title: i18n.t('common.tender.current-min-bid'),
  render: bid => bid?.minimalBid && formatMoney(bid?.minimalBid, bid?.currency),
};

export const colTradingInitialBid = {
  key: 'initial-bid',
  dataIndex: ['bid'],
  title: i18n.t('common.tender.initial-bid'),
  render: bid => bid?.initialBid && formatMoney(bid?.initialBid, bid?.currency),
};

export const colCreationDate = {
  key: 'creation-date',
  dataIndex: ['conditions'],
  title: i18n.t('common.tender.creation-date'),
  render: ({ createdAt }) => createdAt && moment(createdAt).format(TENDER_DATETIME_FORMAT),
};

export const colRoute = {
  key: 'route',
  dataIndex: 'route',
  title: i18n.t('common.route'),
  render: route => (
    <Row align="middle">
      <TenderRoute route={route} />
    </Row>
  ),
};

export const colTenderType = {
  key: 'type',
  dataIndex: ['conditions'],
  title: i18n.t('pages.tender.tender-type'),
  render: ({ tenderInternal }) =>
    i18n.t(`pages.tender.tender-type.${tenderInternal ? TENDER_TYPE.INLAND : TENDER_TYPE.FOREIGN}`),
};

export const colTradingDates = {
  key: 'trading-dates',
  title: i18n.t('common.tender.trading-dates'),
  render: tender =>
    `${moment(tender?.bid?.dealsStarts).format(TENDER_DATE_FORMAT)} - ${moment(tender?.bid?.dealsEnds).format(
      TENDER_DATE_FORMAT
    )}`,
};

export const colTradingStartDate = {
  key: 'trading-start-date',
  title: i18n.t('common.tender.trading-start-date'),
  render: tender => tender?.bid?.dealsStarts && moment(tender?.bid?.dealsStarts).format(TENDER_DATE_FORMAT),
};

export const colTradingFinishDate = {
  key: 'trading-finish-date',
  title: i18n.t('common.tender.trading-finish-date'),
  render: tender => tender?.bid?.dealsEnds && moment(tender?.bid?.dealsEnds).format(TENDER_DATE_FORMAT),
};

export const colTradingFinalBid = {
  key: 'final-bid',
  dataIndex: ['bid'],
  title: i18n.t('common.tender.final-min-bid'),
  render: bid => bid?.minimalBid && formatMoney(bid?.minimalBid, bid?.currency),
};

export const colTradingStage = {
  key: 'trading-stage',
  dataIndex: 'bid',
  title: i18n.t('common.tender.current-trading-stage'),
  render: bid => i18n.t('common.tender.x-trading-stage', { stage: bid?.currentPhase }),
};

export const colTradingState = {
  key: 'trading-state',
  dataIndex: 'bid',
  title: i18n.t('common.tender.current-trading-state'),
  render: tender => null,
};

export const colTradingStatus = {
  key: 'tender-status',
  dataIndex: 'conditions',
  title: i18n.t('common.status'),
  render: conditions => <TenderStatusTag status={conditions?.status} />,
};

export const colTransportationDates = {
  key: 'transportation-dates',
  dataIndex: 'conditions',
  title: i18n.t('common.tender.transportation-dates'),
  render: conditions =>
    `${conditions?.transferStartDate ? moment(conditions?.transferStartDate).format(TENDER_DATE_FORMAT) : ''} ${
      conditions?.transferEndDate ? ` - ${moment(conditions?.transferEndDate).format(TENDER_DATE_FORMAT)}` : ''
    }`,
};
