import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const TagRequired = props => {
  const { t } = useTranslation();
  const { children = t('common.required-for-verification'), ...rest } = props;
  return (
    <Tag color="orange" shape="round" {...rest}>
      {children}
    </Tag>
  );
};

TagRequired.propTypes = {
  children: PropTypes.node,
};

export default memo(TagRequired);
