import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';

import {
  PLACE_AUTOCOMPLETE_ERROR,
  PLACE_AUTOCOMPLETE_LOADING,
  PLACE_AUTOCOMPLETE_SUCCESS,
} from '@/store/constants/actionTypes';

const autoComplete = place => async dispatch => {
  dispatch({
    payload: { place },
    type: PLACE_AUTOCOMPLETE_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.PLACE.AUTOCOMPLETE, { place });

  if (error) {
    dispatch({
      payload: { error, place, status },
      type: PLACE_AUTOCOMPLETE_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response.data, place },
      type: PLACE_AUTOCOMPLETE_SUCCESS,
    });
  }
};

export { autoComplete };
