import { HIDE_MODAL, SHOW_MODAL } from '../constants/actionTypes';

const initialState = {};

const modalsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL: {
      const { modal, ...rest } = payload;
      return {
        ...state,
        [modal]: {
          ...rest,
        },
      };
    }
    case HIDE_MODAL: {
      const { modal } = payload;
      return { ...state, [modal]: false };
    }
    default:
      return state;
  }
};

export default modalsReducer;
