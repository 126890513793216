export const CONTRACT_ACTION = {
  CONFIRM_CONTRACT: 'CONFIRM_CONTRACT', // Подтвердить подписание
  REVOKE_CONTRACT: 'REVOKE_CONTRACT', // Отозвать договор
  SEND_CONTRACT: 'SEND_CONTRACT', // Отправить договор
  SIGN_CONTRACT: 'SIGN_CONTRACT', // Подписать договор
  TERMINATE_CONTRACT: 'TERMINATE_CONTRACT', // Расторгнуть договор

  GENERATE_CONTRACT: 'FAKE_GENERATE_CONTRACT', // сгенерить договор
};

export const CONTRACT_STATUS = {
  CANCELED: 'CANCELED', // подписание отменено
  EXPIRED: 'EXPIRED', // подписание не подтверждено за 8 дней
  GENERATED: 'GENERATED', // документ сгенерирован и доступен для скачивания
  INITIATED: 'INITIATED', // подписание синициированно
  SIGNED: 'SIGNED', // подписание подтверждено
  TERMINATED: 'TERMINATED', // договор расторгнут

  FAKE_NOT_SIGNED: 'FAKE_NOT_SIGNED', // не подписано
  SIGNING_CANCELED: 'SIGNING_CANCELED', // отозван
};

export const CONTRACT_TYPE = {
  AGREEMENT: 'AGREEMENT',
};

