import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import { editCompany, getCompany } from '@/store/actions/companyActions';

import CompanyEditForm from '../components/CompanyEditForm';

const TransportCompanyEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: account } = useSelector(state => state.account);
  const id = useMemo(() => account?.companyId, [account?.companyId]);

  const {
    edit: { [id]: { status: editStatus } = {} },
    get: { [id]: { data: company } = {} },
  } = useSelector(state => state.company);

  const handleClickCancel = useCallback(() => {
    history.push(pageUrls.COMPANY.MY);
  }, [history]);

  const handleClickSave = useCallback(
    fields => {
      const { addresses, general, contacts } = fields;
      const transports = fields?.transports?.filter(item => !!item && !!item?.capacity);

      if (contacts) {
        dispatch(editCompany({ data: { contacts }, id }));
      } else {
        dispatch(
          editCompany({
            data: {
              addresses: { addresses, sameMailAddress: general.sameMailAddress },
              general: { general: { ...company.general, ...general } },
              transports: {
                transports,
                transportsAvailable: general.transportsAvailable,
              },
            },
            id,
          })
        );
      }
    },
    [dispatch, company, id]
  );

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      history.push(pageUrls.COMPANY.MY);
    }
  }, [editStatus, history]);

  useEffect(() => {
    if (id) {
      dispatch(getCompany(id, true));
    }
  }, [dispatch, id]);

  return (
    <CompanyEditForm
      company={company}
      loading={editStatus === statusType.LOADING}
      onSave={handleClickSave}
      onCancel={handleClickCancel}
    />
  );
};

export default memo(TransportCompanyEdit);
