import React, { memo, useCallback } from 'react';

import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import pageUrls from '@/constants/pageUrls';
import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const CompanyVerifyNeeded = props => {
  const { action = true } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickButton = useCallback(() => {
    history.push(pageUrls.COMPANY.MY);
  }, [history]);

  return (
    <Alert
      type={ALERT_TYPE.WARNING}
      message={t('alert.company-verify-needed.title')}
      description={t('alert.company-verify-needed.description')}
      action={
        action && (
          <Button shape="round" type="primary" onClick={handleClickButton}>
            {t('alert.company-verify-needed.button')}
          </Button>
        )
      }
    />
  );
};

export default memo(CompanyVerifyNeeded);
