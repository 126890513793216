import React, { memo, useEffect, useState } from 'react';

import { convertSecondsToHMS } from '@/utils/timeUtils';

import { Text } from '@/components';

const Timer = props => {
  const { time } = props;
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setSeconds(time);
  }, [time]);

  return (
    <Text size={20} height={28} weight="semibold">
      {convertSecondsToHMS(seconds, true)}
    </Text>
  );
};

export default memo(Timer);
