import React, { memo, useCallback } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';
import pageUrls from '@/constants/pageUrls';
import Modal, { MODALS } from '@/views/modals';

const CancelTenderCreation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = useCallback(() => {
    dispatch(hideModal(MODALS.CancelTenderCreation));
  }, [dispatch]);

  const handleClickYes = useCallback(() => {
    history.push(pageUrls.TENDERS.LIST);
  }, [history]);

  return (
    <Modal name="CancelTenderCreation" width={480} bodyStyle={{ padding: '32px' }}>
      <Row gutter={[0, 24]}>
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Space align="start">
              <Col style={{ width: 30 }}>
                <ExclamationCircleOutlined color="calendulagold" style={{ fontSize: 24 }} />
              </Col>
              <Typography.Title level={5}>{t('modal.cancel-tender-creation.title')}</Typography.Title>
            </Space>
          </Col>
          <Col span={24} style={{ paddingLeft: 38 }}>
            <Typography.Text level={3}>{t('modal.cancel-tender-creation.description')}</Typography.Text>
          </Col>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Space size="middle">
              <Button shape="round" onClick={handleClose}>
                {t('modal.cancel-tender-creation.button.no')}
              </Button>
              <Button shape="round" type="primary" htmlType="submit" onClick={handleClickYes}>
                {t('modal.cancel-tender-creation.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(CancelTenderCreation);
