import pageUrls from './pageUrls';

const normalizePath = path => path.split('/:')[0];

const actions = {
  read: 'read',
};

export const ACCOUNT_ROLE = {
  ADMIN: 'ADMIN',
  AU: 'AUDIT',
  LG: 'LOGISTICS_DEPARTMENT',
  LG_CHIEF: 'HEAD_OF_LOGISTIC_DEPARTMENT',
  SB: 'SECURITY',
  TC: 'TRANSPORT_COMPANY',
};

const config = {
  [ACCOUNT_ROLE.AU]: {
    [actions.read]: [
      pageUrls.COMPANY.LIST,
      pageUrls.COMPANY.MY,
      pageUrls.COMPANY.ITEM_PATH,
      pageUrls.DASHBOARD.MAIN,
      pageUrls.EMPLOYEES.LIST,
      pageUrls.TENDERS.ADD,
      pageUrls.TENDERS.ITEM_PATH,
      pageUrls.TENDERS.LIST,
    ],
  },
  [ACCOUNT_ROLE.LG]: {
    [actions.read]: [
      pageUrls.DASHBOARD.MAIN,
      pageUrls.COMPANY.ITEM_PATH,
      pageUrls.COMPANY.LIST,
      pageUrls.COMPANY.MY,
      pageUrls.EMPLOYEES.LIST,
      pageUrls.TENDERS.ITEM_PATH,
      pageUrls.TENDERS.LIST,
      pageUrls.TENDERS.ADD,
    ],
  },
  [ACCOUNT_ROLE.LG_CHIEF]: {
    [actions.read]: [
      pageUrls.DASHBOARD.MAIN,
      pageUrls.COMPANY.ITEM_PATH,
      pageUrls.COMPANY.LIST,
      pageUrls.COMPANY.MY,
      pageUrls.EMPLOYEES.LIST,
      pageUrls.TENDERS.ITEM_PATH,
      pageUrls.TENDERS.LIST,
      pageUrls.TENDERS.ADD,
    ],
  },
  [ACCOUNT_ROLE.SB]: {
    [actions.read]: [
      pageUrls.BIDS.MAIN,
      pageUrls.COMPANY.ITEM_PATH,
      pageUrls.COMPANY.LIST,
      pageUrls.COMPANY.MY,
      pageUrls.EMPLOYEES.LIST,
      pageUrls.DASHBOARD.MAIN,
    ],
  },
  [ACCOUNT_ROLE.TC]: {
    [actions.read]: [
      pageUrls.COMPANY.ADD,
      pageUrls.COMPANY.EDIT.PATH,
      pageUrls.COMPANY.FILL,
      pageUrls.COMPANY.REGISTER,
      pageUrls.CONTRACT,
      pageUrls.COMPANY.MAIN,
      pageUrls.DASHBOARD.MAIN,
      pageUrls.TENDERS.LIST,
      pageUrls.TENDERS.ITEM_PATH,
    ],
  },
};

const can = (role, path, action = actions.read) => {
  const permissions = config[role]?.[action];
  const hasAccess = permissions === '*' || permissions.some(item => path.includes(normalizePath(item)));
  if (!hasAccess) {
    console.error('trying to access forbidden path', role, path, permissions);
  }
  return hasAccess;
};

export default { actions, can };
