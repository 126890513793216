import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Typography } from 'antd';

import imageEmailVerified from '@/assets/images/emailVerified.png';

import { BaseLayout } from '@/containers/layout';
import pageUrls from '@/constants/pageUrls';
import { Card, Text } from '@/components';

import classes from './VerifyEmailSuccess.module.scss';

const { Title } = Typography;

const VerifyEmailSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickEnter = useCallback(() => {
    history.push(pageUrls.MAIN);
  }, [history]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageEmailVerified} preview={false} />
            </Row>
          </Col>
          <Row gutter={[0, 8]} className={classes.description}>
            <Col span={24}>
              <Title level={3} align="center">
                {t('pages.verify-email-success.title')}
              </Title>
            </Col>
            <Col span={24}>
              <Text size={16} align="center" block>
                {t('pages.verify-email-success.description')}
              </Text>
            </Col>
          </Row>
          <Col span={24}>
            <Row justify="center">
              <Button type="primary" shape="round" size="large" onClick={handleClickEnter}>
                <Text align="center">{t('common.enter-application')}</Text>
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(VerifyEmailSuccess);
