import React, { memo } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Col, Typography } from 'antd';

import { LandingLayout } from '@/containers/layout';

import content from './TermsOfUse.content';

const TermsOfUse = () => {
  const { t } = useTranslation();
  return (
    <LandingLayout>
      <Col>
        <Typography.Title level={1}>{t('common.terms-of-use.title')}</Typography.Title>
      </Col>
      <Col>
        <div dangerouslySetInnerHTML={{ __html: content?.[i18next.language] }} />
      </Col>
    </LandingLayout>
  );
};

export default memo(TermsOfUse);
