import React, { memo, useCallback } from 'react';

import Form from 'antd/lib/form/Form';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CHANGE_EMAIL } from '@/store/constants/actionTypes';
import { Email } from '@/components/form';
import env from '@/constants/env';
import errorCodes from '@/constants/errorCodes';
import { hideModal } from '@/store/actions/modalsActions';
import Modal from '@/views/modals/Modal';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';

const ChangeEmail = props => {
  const { onChange } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error, status } = useSelector(state => state.changeEmail);

  const handleClose = useCallback(() => {
    dispatch({ type: CHANGE_EMAIL });
  }, [dispatch]);

  const handleClickClose = useCallback(() => {
    handleClose();
    dispatch(hideModal(MODALS.ChangeEmail));
  }, [dispatch, handleClose]);

  const handleFinishForm = useCallback(
    ({ email }) => {
      onChange(email);
    },
    [onChange]
  );

  const errorText =
    error?.code &&
    (error?.code === errorCodes.USER_EMAIL_CONFLICT ? (
      <Trans i18nKey="pages.account.error.email-exists">
        [<Link underline to={`${pageUrls.SIGN_IN}`} />,
        <Typography.Link underline href={`mailto:${env.SUPPORT_EMAIL}`} target="_blank" />]
      </Trans>
    ) : (
      t('pages.account.error')
    ));

  return (
    <Modal name={MODALS.ChangeEmail} onClose={handleClose}>
      <Form name="email-change" onFinish={handleFinishForm}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>{t('modal.email-change.enter-new-email')}</Typography.Title>
          </Col>
          <Col span={24}>
            <Email label={false} error={errorText} />
          </Col>
          <Col span={24}>
            <Typography.Text level={3}>{t('modal.email-change.description')}</Typography.Text>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Space size="middle">
                <Button shape="round" size="large" onClick={handleClickClose}>
                  {t('common.cancel')}
                </Button>
                <Button
                  shape="round"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={status === statusType.LOADING}
                >
                  {t('common.confirm')}
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default memo(ChangeEmail);
