import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';

import {
  GET_EMAIL_DETAILS_ERROR,
  GET_EMAIL_DETAILS_LOADING,
  GET_EMAIL_DETAILS_SUCCESS,
} from '../constants/actionTypes';

const getEmailDetails = payload => async dispatch => {
  dispatch({
    type: GET_EMAIL_DETAILS_LOADING,
  });

  try {
    const { id, target } = payload;
    const { error, response, status } = await apiService.get(apiUrls.ACCOUNT.SEND_EMAIL_DETAILS(id), {
      target,
    });

    if (error) {
      dispatch({
        payload: { error, status },
        type: GET_EMAIL_DETAILS_ERROR,
      });
    } else {
      const { data } = response;
      dispatch({
        payload: data,
        type: GET_EMAIL_DETAILS_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      payload: { error },
      type: GET_EMAIL_DETAILS_ERROR,
    });
  }
};

export { getEmailDetails };
