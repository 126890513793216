import statusType from '../constants/statusType';
import {
  GET_COMPANY_ACTIONS_ERROR,
  GET_COMPANY_ACTIONS_LOADING,
  GET_COMPANY_ACTIONS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  status: statusType.IDLE,
};

const companyActionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY_ACTIONS_ERROR: {
      return {
        error: payload,
        status: statusType.FAILED,
      };
    }

    case GET_COMPANY_ACTIONS_LOADING: {
      return { status: statusType.LOADING };
    }

    case GET_COMPANY_ACTIONS_SUCCESS: {
      return {
        data: payload,
        status: statusType.SUCCESSED,
      };
    }

    default:
      return state;
  }
};

export default companyActionReducer;
