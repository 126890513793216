import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';

import { EMPLOYEE_STATUS } from '@/constants/employees';
import {
  ADD_EMPLOYEE_ERROR,
  ADD_EMPLOYEE_LOADING,
  ADD_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_ERROR,
  EDIT_EMPLOYEE_LOADING,
  EDIT_EMPLOYEE_SUCCESS,
  FIND_EMPLOYEES_ERROR,
  FIND_EMPLOYEES_LOADING,
  FIND_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_LOADING,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_EMPLOYEES_LOADING,
  GET_EMPLOYEES_SUCCESS,
} from '../constants/actionTypes';

export const normalizeEmployee = employee => ({
  ...employee,
  fullName: `${employee?.lastName ? employee?.lastName : ''} ${employee?.firstName ? employee.firstName : ''}${
    employee?.middleName ? ` ${employee?.middleName}` : ''
  }`,
});

const addEmployee = payload => async (dispatch, getState) => {
  dispatch({
    type: ADD_EMPLOYEE_LOADING,
  });

  const { account } = getState();
  const { confirm, ...rest } = payload;

  const request = {
    ...rest,
    agreeToPersonalDataPolicy: true,
    agreeToSecurityPolicy: true,
    originator: account?.data?.id,
    password: btoa(payload.password),
  };

  const { error, response, status } = await apiService.post(apiUrls.TECHNO.USER.ADD, request);
  if (error) {
    dispatch({
      payload: { status, ...error },
      type: ADD_EMPLOYEE_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: ADD_EMPLOYEE_SUCCESS,
    });
  }
};

const editEmployee = payload => async (dispatch, getState) => {
  dispatch({
    type: EDIT_EMPLOYEE_LOADING,
  });

  const { account } = getState();
  const { id } = payload;
  const request = {
    ...payload,
    originator: account?.data?.id,
  };
  const { error, response, status } = await apiService.put(apiUrls.TECHNO.USER.EDIT(id), request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: EDIT_EMPLOYEE_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: EDIT_EMPLOYEE_SUCCESS,
    });
  }
};

const blockEmployee = payload => async dispatch => {
  dispatch({
    type: EDIT_EMPLOYEE_LOADING,
  });

  const { id } = payload;
  const request = {
    ...payload,
    status: EMPLOYEE_STATUS.BLOCKED,
  };
  const { error, status } = await apiService.put(apiUrls.TECHNO.USER.EDIT(id), request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: EDIT_EMPLOYEE_ERROR,
    });
  } else {
    dispatch({
      type: EDIT_EMPLOYEE_SUCCESS,
    });
  }
};

const findEmployees = payload => async dispatch => {
  const { name } = payload;

  dispatch({
    type: FIND_EMPLOYEES_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.TECHNO.USER.FIND, null, {
    params: {
      queryString: name,
    },
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: FIND_EMPLOYEES_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data?.content,
      type: FIND_EMPLOYEES_SUCCESS,
    });
  }
};

const getEmployee = employeeId => async dispatch => {
  dispatch({
    type: GET_EMPLOYEE_LOADING,
  });

  const { error, response, status } = await apiService.get(apiUrls.TECHNO.USER.GET(employeeId));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_EMPLOYEE_ERROR,
    });
  } else {
    const { data } = response;
    const normalizedEmployee = normalizeEmployee(data);
    dispatch({
      payload: normalizedEmployee,
      type: GET_EMPLOYEE_SUCCESS,
    });
    return normalizedEmployee;
  }
  return null;
};

const getEmployees = companyId => async dispatch => {
  dispatch({
    type: GET_EMPLOYEES_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TECHNO.USER.LIST, {
    companyId,
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_EMPLOYEES_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data?.map(normalizeEmployee),
      type: GET_EMPLOYEES_SUCCESS,
    });
  }
};

const recoverEmployee = payload => async dispatch => {
  dispatch({
    type: EDIT_EMPLOYEE_LOADING,
  });

  const { id } = payload;
  const request = {
    ...payload,
    status: EMPLOYEE_STATUS.ACTIVE,
  };
  const { error, status } = await apiService.put(apiUrls.TECHNO.USER.EDIT(id), request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: EDIT_EMPLOYEE_ERROR,
    });
  } else {
    dispatch({
      type: EDIT_EMPLOYEE_SUCCESS,
    });
  }
};

export { addEmployee, blockEmployee, findEmployees, getEmployee, getEmployees, recoverEmployee, editEmployee };
