import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

const DetailsItem = props => {
  const { label, value, labelCols = 12, valueCols = 12, ...rest } = props;
  return (
    <Col span={24} {...rest}>
      <Row>
        <Col span={labelCols}>{label}:</Col>
        <Col span={valueCols}>{value}</Col>
      </Row>
    </Col>
  );
};

DetailsItem.propTypes = {
  label: PropTypes.node,
  labelCols: PropTypes.number,
  value: PropTypes.node,
  valueCols: PropTypes.number,
};

export default memo(DetailsItem);
