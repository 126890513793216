import React, { memo } from 'react';

import { Button, Col, Image, Row, Space, Typography } from 'antd';

import classes from './styles/Banner1.module.scss';

const t = {
  description: 'Электронные тендеры на перевозки от\nнадёжного грузоотправителя',
  login: 'Войти',
  register: 'Зарегистрироваться',
  title: 'Тендерная платформа\nTECHNODOM',
};

const Banner = () => {
  return (
    <Col span={24} className={classes.root}>
      <Row justify="space-between">
        <Col span={12}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Col span={24}>
                <Typography.Title className={classes.title} level={1}>
                  {t.title}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Text className={classes.description}>{t.description}</Typography.Text>
              </Col>
            </Col>
            <Col span={24}>
              <Space>
                <Button type="primary" shape="round" className={classes.button}>
                  {t.register}
                </Button>
                <Button shape="round" type="ghost" className={classes.button}>
                  {t.login}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default memo(Banner);
