import statusType from '../constants/statusType';
import { GET_MY_COMPANY_ERROR, GET_MY_COMPANY_LOADING, GET_MY_COMPANY_SUCCESS } from '../constants/actionTypes';

const initialState = {
  status: statusType.IDLE,
};

const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_COMPANY_LOADING: {
      return { status: statusType.LOADING };
    }

    case GET_MY_COMPANY_ERROR: {
      return {
        error: payload,
        status: statusType.FAILED,
      };
    }

    case GET_MY_COMPANY_SUCCESS: {
      return {
        data: payload,
        status: statusType.SUCCESSED,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
