import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Image, Row, Space, Typography } from 'antd';

import { BaseLayout } from '@/containers/layout';
import pageUrls from '@/constants/pageUrls';
import { Card, Link } from '@/components';

import imageNotFound from '@/assets/images/not-found.png';

import classes from './NotFound.module.scss';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 64]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageNotFound} preview={false} />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Typography.Title>{t('pages.not-found.title')}</Typography.Title>
            </Row>
          </Col>
        </Row>

        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row justify="center">
              <Typography.Text>{t('pages.not-found.description')}</Typography.Text>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="center">
              <Typography.Title level={5}>{t('pages.not-found.here-usefull-links')}</Typography.Title>
            </Row>
          </Col>
        </Row>
        <Col span={24}>
          <Row justify="center">
            <Space size="large">
              <Link to={pageUrls.MAIN}>{t('menu.menu-item.dashboard')}</Link>
              <Link to={pageUrls.TENDERS.LIST}>{t('menu.menu-item.tenders')}</Link>
              <Link to={pageUrls.BIDS.MAIN}>{t('menu.menu-item.bids')}</Link>
              <Link to={pageUrls.ACCOUNT.MAIN}>{t('menu.menu-item.account')}</Link>
              <Link to={pageUrls.COMPANY.MY}>{t('menu.menu-item.my-company')}</Link>
            </Space>
          </Row>
        </Col>
      </Card>
    </BaseLayout>
  );
};

export default memo(NotFound);
