import statusType from '../constants/statusType';
import {
  FIND_SPECIFIC_COMPANIES_ERROR,
  FIND_SPECIFIC_COMPANIES_LOADING,
  FIND_SPECIFIC_COMPANIES_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const companiesReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case FIND_SPECIFIC_COMPANIES_ERROR: {
      const { field, error } = payload;
      return { ...state, [field]: { error, status: statusType.FAILED } };
    }

    case FIND_SPECIFIC_COMPANIES_LOADING: {
      const { field } = payload;
      return { ...state, [field]: { status: statusType.LOADING } };
    }

    case FIND_SPECIFIC_COMPANIES_SUCCESS: {
      const { field, data, pagination } = payload;
      return { ...state, [field]: { data, pagination, status: statusType.SUCCESSED } };
    }

    default:
      return state;
  }
};

export default companiesReducer;
