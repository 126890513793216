import React, { memo, useCallback, useEffect } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Input, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';
import { Link } from '@/components';
import pageUrls from '@/constants/pageUrls';
import { setAccount } from '@/store/actions/accountActions';
import statusType from '@/store/constants/statusType';

const FORM_ITEM_PROPS = {
  labelAlign: 'left',
  labelCol: { span: 10 },
};

const AccountEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { status } = useSelector(state => state.setAccount);
  const { data: account } = useSelector(state => state.account);

  const [form] = Form.useForm();

  const handleSubmitForm = useCallback(
    fields => {
      dispatch(setAccount(fields));
    },
    [dispatch]
  );

  useEffect(() => {
    form.resetFields();
  }, [account, form]);

  useEffect(() => {
    if (status === statusType.SUCCESSED || status === statusType.FAILED) {
      history.push(pageUrls.ACCOUNT.MAIN);
    }
  }, [history, status]);

  return (
    <DashboardLayout>
      <BaseContent title={t('pages.account-edit.account-settings')}>
        <Form
          form={form}
          layout="horizontal"
          name="account-edit"
          onFinish={handleSubmitForm}
          validateTrigger={['onChange', 'onBlur']}
          initialValues={{
            firstName: account?.firstName,
            lastName: account?.lastName,
            middleName: account?.middleName,
            position: account?.position,
          }}
        >
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Divider orientation="left">{t('pages.account-edit.edit-personal-data')}</Divider>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('common.last-name')}
                    name="lastName"
                    rules={[...fieldRules.required, ...fieldRules.input]}
                    {...FORM_ITEM_PROPS}
                  >
                    <Input placeholder={t('placeholder.lastName')} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('common.first-name')}
                    name="firstName"
                    rules={[...fieldRules.required, ...fieldRules.input]}
                    {...FORM_ITEM_PROPS}
                  >
                    <Input placeholder={t('placeholder.firstName')} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('common.middle-name')}
                    name="middleName"
                    rules={[...fieldRules.input]}
                    {...FORM_ITEM_PROPS}
                  >
                    <Input placeholder={t('placeholder.middleName')} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t('common.position')} name="position" rules={fieldRules.input} {...FORM_ITEM_PROPS}>
                    <Input placeholder={t('placeholder.position')} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="end">
                <Space direction="horizontal">
                  <Link to={pageUrls.ACCOUNT.MAIN}>
                    <Button type="default" shape="round" loading={status === statusType.LOADING}>
                      {t('common.cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" shape="round" htmlType="submit">
                    {t('common.save')}
                  </Button>
                </Space>
              </Row>
            </Col>
          </Row>
        </Form>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(AccountEdit);
