import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { DashboardLayout } from '@/containers/layout';

import DashboardAU from './DashboardAU';
import DashboardLG from './DashboardLG';
import DashboardSB from './DashboardSB';
import DashboardTC from './DashboardTC';

const dashboards = {
  [ACCOUNT_ROLE.AU]: DashboardAU,
  [ACCOUNT_ROLE.LG]: DashboardLG,
  [ACCOUNT_ROLE.LG_CHIEF]: DashboardLG,
  [ACCOUNT_ROLE.SB]: DashboardSB,
  [ACCOUNT_ROLE.TC]: DashboardTC,
};

const Dashboard = () => {
  const { data: account } = useSelector(state => state.account);
  const Component = dashboards[account?.role];
  return <DashboardLayout>{Component && <Component />}</DashboardLayout>;
};

export default memo(Dashboard);
