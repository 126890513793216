import React, { memo } from 'react';

import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';

import TechnoCompanyEdit from './TechnoCompanyEdit';
import TransportCompanyEdit from './TransportCompanyEdit';

const MyCompany = () => {
  const { data: account } = useSelector(state => state.account);
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <BaseContent title={t('pages.company.my-company')}>
        <Col span={24}>{account?.isTechnoUser ? <TechnoCompanyEdit /> : <TransportCompanyEdit />}</Col>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(MyCompany);
