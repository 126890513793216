const BASE_URL = 'api/v1';

const COMPANY_URL = `${BASE_URL}/company`;
const CONTRACT_URL = `${BASE_URL}/contract`;
const PLACE_URL = `${BASE_URL}/place`;
const RESOURCE_URL = '/resource';
const REPORT_URL = `${BASE_URL}/report`;
const TECHNO_URL = `${BASE_URL}/techno`;
const TECHNO_USER_URL = `${TECHNO_URL}/user`;
const TECHNO_COMPANY_URL = `${TECHNO_URL}/company`;
const TENDER_URL = `${BASE_URL}/tender`;
const TENDER_ACTIVITY_URL = `${BASE_URL}/history/tender`;
const TRANSPORT_COMPANY_URL = `${COMPANY_URL}/transport`;
const USER_URL = `${BASE_URL}/user`;

export default {
  ACCOUNT: {
    CHANGE_EMAIL: id => `${USER_URL}/${id}/email`,
    CHANGE_EMAIL_DETAILS: id => `${USER_URL}/${id}/email/details`,
    CHANGE_PASSWORD: (id, opt) => `${USER_URL}/${id}/pass?otp=${opt}`,
    GET: id => `${USER_URL}/${id}`,
    RECOVER_PASSWORD: `${USER_URL}/reset-password`,
    RECOVER_PASSWORD_DETAILS: `${USER_URL}/reset-password/details`,
    SEND_EMAIL: id => `${USER_URL}/${id}/email/send`,
    SEND_EMAIL_DETAILS: id => `${USER_URL}/${id}/email/send/details`,
    SIGN_IN: `${USER_URL}/sign-in`,
    SIGN_IN_DETAILS: `${USER_URL}/sign-in/details`,
    SIGN_OUT: `${USER_URL}/sign-out`,
    SIGN_UP: `${USER_URL}/sign-up`,
  },
  COMPANY: {
    ACTION: id => `${TRANSPORT_COMPANY_URL}/${id}/action`,
    ACTIONS_AVAILABLE: id => `${TRANSPORT_COMPANY_URL}/${id}/actions-available`,
    ADD: `${TRANSPORT_COMPANY_URL}`,
    ARCHIVE: id => `${TRANSPORT_COMPANY_URL}/${id}`,
    CONTRACT: id => `${TRANSPORT_COMPANY_URL}/${id}/contract`,
    EDIT: id => `${TRANSPORT_COMPANY_URL}/${id}`,
    FIND: `${TRANSPORT_COMPANY_URL}/find`,
    GET: id => `${TRANSPORT_COMPANY_URL}/${id}`,
    LIST: `${TRANSPORT_COMPANY_URL}`,
  },
  CONTRACT: {
    COMFIRM: `${CONTRACT_URL}/confirm`,
    GENERATE: `${CONTRACT_URL}/generate`,
    REVOKE: `${CONTRACT_URL}/revoke`,
    TERMINATE: `${CONTRACT_URL}/terminate`,
  },
  PLACE: {
    AUTOCOMPLETE: `${PLACE_URL}/autocomplete`,
  },
  REPORT: {
    COMPANIES: `${REPORT_URL}/companies`,
    TENDERS_COMPLETED: `${REPORT_URL}/tenders/completed`,
    TENDERS_FAILED: `${REPORT_URL}/tenders/failed`,
  },
  RESOURCE: {
    REMOVE: (account, bucket) => `${RESOURCE_URL}/${account}/${bucket}`,
    UPLOAD: (account, bucket, resource) => `${RESOURCE_URL}/${account}/${bucket}/${resource}`,
  },
  TECHNO: {
    COMPANY: {
      EDIT: id => `${TECHNO_COMPANY_URL}/${id}`,
      GET: id => `${TECHNO_COMPANY_URL}/${id}`,
      LIST: `${TECHNO_COMPANY_URL}`,
    },
    USER: {
      ADD: `${TECHNO_USER_URL}`,
      BLOCK: id => `${TECHNO_USER_URL}/${id}`,
      DELETE: id => `${TECHNO_USER_URL}/${id}`,
      EDIT: id => `${TECHNO_USER_URL}/${id}`,
      FIND: `${TECHNO_USER_URL}/find`,
      GET: id => `${TECHNO_USER_URL}/${id}`,
      LIST: `${TECHNO_USER_URL}`,
      RECOVER: id => `${TECHNO_USER_URL}/${id}`,
    },
  },
  TENDER: {
    ACTION: id => `${TENDER_URL}/${id}/action`,
    ACTIVITY: id => `${TENDER_ACTIVITY_URL}/${id}`,
    ADD: `${TENDER_URL}`,
    CANCEL: id => `${TENDER_URL}/${id}`,
    DELETE: id => `${TENDER_URL}/${id}`,
    DETAILS: id => `${TENDER_URL}/${id}/details`,
    DUPLICATE: id => `${TENDER_URL}/${id}`,
    EDIT: id => `${TENDER_URL}/${id}`,
    FIND: `${TENDER_URL}/find`,
    GET: id => `${TENDER_URL}/${id}`,
    LIST: `${TENDER_URL}`,
    RESULTS: id => `${TENDER_URL}/${id}/results`,
  },
};
