import React, { memo } from 'react';

import { TENDER_GROUP, TENDER_STATUS } from '@/constants/tender';

import TendersTable from '../components/TendersTable';
import {
  colCreationDate,
  colRoute,
  colTenderType,
  colTradingBid,
  colTradingDates,
  colTradingFinalBid,
  colTradingStage,
  colTradingStatus,
  colTransportationDates,
} from '../components/TendersTable/columns';

const columns = {
  [TENDER_STATUS.DRAFT]: [colRoute, colTenderType, colTransportationDates, colCreationDate],
  [TENDER_STATUS.ACCEPTING]: [colRoute, colTenderType, colTradingDates],
  [TENDER_STATUS.TRADING]: [colRoute, colTenderType, colTradingDates, colTradingStage, colTradingBid],
  [TENDER_STATUS.CLOSED]: [colRoute, colTenderType, colTradingDates, colTradingFinalBid],
  [TENDER_GROUP.ALL]: [colRoute, colTradingStatus, colTenderType, colCreationDate],
};

const tabs = [
  TENDER_STATUS.DRAFT,
  TENDER_STATUS.ACCEPTING,
  TENDER_STATUS.TRADING,
  TENDER_STATUS.CLOSED,
  TENDER_GROUP.ALL,
];

const Tenders = () => {
  return <TendersTable columns={columns} tabs={tabs} />;
};

export default memo(Tenders);
