export const convertSecondsToHMS = (seconds, format) => {
  const m = Math.floor(seconds / 60);
  const s = seconds - m * 60;
  const h = Math.floor(seconds / 3600);

  if (format) {
    const fH = `${h > 0 ? (h < 10 ? `0${h}` : h) : ''}`;
    const fm = m < 10 ? `0${m}` : m;
    const fs = s < 10 ? `0${s}` : s;
    return `${fH ? `${fH}:` : ''}${fm}:${fs}`;
  }
  return { h, m, s };
};
