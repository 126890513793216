import React, { memo, useCallback, useState } from 'react';

import { Button } from 'antd';

import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import statusType from '@/store/constants/statusType';
import { Upload } from '@/components';

const UploadDocument = props => {
  const { t } = useTranslation();

  const { children, label = t('common.add'), name = 'document', onUpload, onProgress, onChange } = props;

  const [loading, setLoading] = useState(false);

  const handleProgress = useCallback(
    event => {
      if (onProgress) {
        onProgress(event);
      }
      setLoading(event.status === statusType.LOADING);
    },
    [onProgress]
  );

  const handleUpload = useCallback(
    file => {
      if (onUpload) {
        onUpload(file);
      }
      setLoading(false);
    },
    [onUpload]
  );

  return (
    <Upload
      onChange={onChange}
      onProgress={handleProgress}
      onUpload={handleUpload}
      name={name}
      listType="text"
      accept=".jpg, .jpeg, .pdf, .png"
    >
      {children || (
        <Button shape="round" icon={<UploadOutlined />} loading={loading}>
          {label}
        </Button>
      )}
    </Upload>
  );
};

UploadDocument.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onProgress: PropTypes.func,
  onUpload: PropTypes.func,
};

export default memo(UploadDocument);
