import statusType from '../constants/statusType';
import { SIGN_OUT_SUCCESS, SIGN_UP_ERROR, SIGN_UP_LOADING, SIGN_UP_SUCCESS } from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const signUpReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SIGN_OUT_SUCCESS:
      return initialState;

    case SIGN_UP_LOADING:
      return { ...state, status: statusType.LOADING };

    case SIGN_UP_SUCCESS:
      return { ...state, data: payload, status: statusType.SUCCESSED };

    case SIGN_UP_ERROR:
      return { ...state, error: payload, status: statusType.FAILED };

    default:
      return state;
  }
};

export default signUpReducer;
