import React, { memo } from 'react';

import { Card as AntCard } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Card.module.scss';

const Card = props => {
  const { children, className } = props;
  return (
    <AntCard bodyStyle={{ padding: 0 }} bordered={false} className={clsx(classes.card, className)}>
      {children}
    </AntCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default memo(Card);
