import React, { memo, useCallback } from 'react';

import clsx from 'clsx';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Row, Typography } from 'antd';

import { Link } from '@/components';

import classes from './Section.module.scss';

const Section = props => {
  const { action, children, className, name, title, to, onEdit } = props;
  const { t } = useTranslation();

  const handleClickEdit = useCallback(() => {
    if (onEdit) {
      onEdit(name);
    }
  }, [name, onEdit]);

  return (
    <Col span={24} className={clsx(classes.section, className)}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row align="middle" gutter={[30, 0]}>
            <Col flex="auto">
              <Divider orientation="left" className={classes.divider}>
                <Typography className={classes.title}>{title}</Typography>
              </Divider>
            </Col>
            {to && (
              <Col>
                <Link to={to}>
                  <Button icon={<EditOutlined />} shape="round">
                    {t('common.edit')}
                  </Button>
                </Link>
              </Col>
            )}
            {onEdit && (
              <Col>
                <Button icon={<EditOutlined />} shape="round" onClick={handleClickEdit}>
                  {t('common.edit')}
                </Button>
              </Col>
            )}
            {action}
          </Row>
        </Col>
        {children}
      </Row>
    </Col>
  );
};

Section.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default memo(Section);
