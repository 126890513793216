import React, { memo } from 'react';

import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import * as fieldRules from '@/constants/fieldRules';

const rules = {
  confirm: [
    ...fieldRules.max,
    ...fieldRules.required,
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(i18n.t('common.password-doesnt-match')));
      },
    }),
  ],
};

const PasswordConfirm = props => {
  const { t } = useTranslation();

  const {
    dependencies = ['password'],
    label = t('common.confirm-password'),
    name = 'confirm',
    placeholder = t('placeholder.password'),
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      dependencies={dependencies}
      rules={rules.confirm}
      validateTrigger={['onChange', 'onBlur']}
    >
      <Input.Password placeholder={placeholder} />
    </Form.Item>
  );
};

PasswordConfirm.propTypes = {
  dependencies: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default memo(PasswordConfirm);
