import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router';
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLayout } from '@/containers/layout';
import errorCodes from '@/constants/errorCodes';
import errorStatus from '@/constants/errorStatus';
import pageUrls from '@/constants/pageUrls';
import { SIGN_IN_DETAILS } from '@/store/constants/actionTypes';
import statusType from '@/store/constants/statusType';
import storageService from '@/services/storageService';
import { Card, Link, Text } from '@/components';
import { Email, Password } from '@/components/form';
import { getSignInDetails, signIn } from '@/store/actions/accountActions';

import Timer from './components/Timer';

import classes from './SignIn.module.css';

const { Title } = Typography;

const initialRemember = storageService.getSettings()?.signIn?.remember;

const SignIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [accountEmail, setAccountEmail] = useState();
  const { status, error, details } = useSelector(state => state.signIn);

  const intervalRef = useRef();
  const [seconds, setSeconds] = useState();

  const handleSubmitForm = useCallback(
    ({ email, password, remember }) => {
      if (email && password) {
        setAccountEmail(email);
        dispatch(signIn(email, password, remember));
      }
    },
    [dispatch]
  );

  const handleChangeRemember = useCallback(event => {
    const { checked } = event.target;
    storageService.setSettings({ signIn: { remember: checked } });
  }, []);

  const syncSeconds = useCallback(() => {
    dispatch(getSignInDetails(accountEmail));
  }, [accountEmail, dispatch]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => {
      if (s < 1) {
        clearInterval(intervalRef.current);
      }
      return s - 1;
    });
  }, []);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      history.push(pageUrls.MAIN);
    }
  }, [history, status]);

  useEffect(() => {
    if (error?.code === errorCodes.RETRY_SIGN_IN_AFTER) {
      syncSeconds();
    } else if (error?.code === errorCodes.USER_EMAIL_NOT_VERIFIED) {
      history.push({
        pathname: pageUrls.SIGN_UP_SUCCESS,
        search: `?email=${encodeURIComponent(accountEmail)}`,
      });
    }
  }, [history, accountEmail, error, syncSeconds]);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED && details?.data?.secondsTillNextAttempt) {
      clearInterval(intervalRef.current);
      setSeconds(details.data.secondsTillNextAttempt);
      dispatch({ type: SIGN_IN_DETAILS });
      intervalRef.current = setInterval(updateSeconds, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [details, dispatch, updateSeconds]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row className={classes.description}>
          <Col span={24}>
            <Title align="center" level={2}>
              {t('pages.sign-in.sign-in-form')}
            </Title>
          </Col>
          <Col span={24}>
            <Text block align="center" wrap>
              {t('pages.sign-in.sign-in-to-take-part')}
            </Text>
          </Col>
        </Row>
        <Form
          name="sign-in"
          layout="vertical"
          onFinish={handleSubmitForm}
          requiredMark={false}
          className={classes.root}
        >
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Col span={24}>
                <Email autoFocus autoComplete="login" />
              </Col>
              <Col span={24}>
                <Password autoComplete="password" />
              </Col>
            </Col>

            {status === statusType.FAILED && (
              <Col span={24}>
                <Text block align="center" color="red-6">
                  {error?.code === errorCodes.PASSWORD_NOT_MATCH ? (
                    <Trans i18nKey="pages.sign-in.error.incorrect-password-try-again">
                      [<Link to={pageUrls.PASSWORD_RECOVERY} />]
                    </Trans>
                  ) : error?.code === errorCodes.USER_BLOCKED ? (
                    t('pages.sign-in.error.user-blocked')
                  ) : error?.code === errorCodes.USER_NOT_FOUND ? (
                    t('pages.sign-in.error.user-not-found')
                  ) : error?.code === errorCodes.PASSWORD_NOT_SET ? (
                    t('pages.sign-in.error.password-not-set')
                  ) : error?.status === errorStatus.TOO_MANY_REQUESTS ? (
                    t('pages.sign-in.error.incorrect-data-retry-in')
                  ) : (
                    t('pages.sign-in.error')
                  )}

                  {seconds > 0 && (
                    <>
                      <br />
                      <Timer time={seconds} />
                    </>
                  )}
                </Text>
              </Col>
            )}

            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
                block
                loading={status === statusType.LOADING}
                disabled={seconds > 0}
              >
                <Text align="center">{t('common.sign-in')}</Text>
              </Button>
            </Col>

            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Form.Item name="remember" noStyle valuePropName="checked" initialValue={initialRemember}>
                  <Checkbox onChange={handleChangeRemember}>{t('common.remember-password')}</Checkbox>
                </Form.Item>
                <Link to={pageUrls.PASSWORD_RECOVERY}>{t('common.forgot-password?')}</Link>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="center">
                <Trans i18nKey="common.sign-up-if-no-account">
                  [<Link to={pageUrls.SIGN_UP} />]
                </Trans>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="center">
                <Link to={pageUrls.MAIN}>
                  <Text color="gray-5">{t('common.return-to-main-technodom')}</Text>
                </Link>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </BaseLayout>
  );
};

export default memo(SignIn);
