/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import i18n from 'i18next';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Input, Row, Space, Tabs, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_REPORT } from '@/store/constants/actionTypes';
import { createCompaniesReport } from '@/store/actions/reportActions';
import { DashboardLayout } from '@/containers/layout';
import { findCompanies } from '@/store/actions/companyActions';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { parseCompanyId } from '@/utils/companyUtils';
import { REPORT_TYPE } from '@/constants/report';
import { sanitizeObject } from '@/utils/objectUtils';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { Badge, ContractStatusTag, Link, Select, Table, TableEmpty } from '@/components';
import { COMPANY_GROUP, COMPANY_STATUS, companyTransportationType, tagsColors } from '@/constants/company';
import { CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import { hideModal, showModal } from '@/store/actions/modalsActions';

import colors from '@/styles/colors.module.scss';

import { ViewerContext } from '@/context/viewer-context';
import { ACCOUNT_ROLE } from '@/constants/roles';
import { TENDER_GROUP } from '@/constants/tender';
import classes from './Companies.module.scss';

const defaultPaginationProps = {
  size: 10,
};

const companyStatusesByStatus = {
  [COMPANY_STATUS.WAITING_FOR_VERIFICATION]: [
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION}`),
      value: COMPANY_STATUS.WAITING_FOR_VERIFICATION,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION}`),
      value: COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT}`),
      value: COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT,
    },
  ],
  [COMPANY_GROUP.VERIFIED]: [
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT}`),
      value: COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.ACTIVE}`),
      value: COMPANY_STATUS.ACTIVE,
    },
  ],
  [COMPANY_GROUP.NOT_ACTIVE]: [
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.IN_REWORK}`),
      value: COMPANY_STATUS.IN_REWORK,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.VERIFICATION_REJECTED}`),
      value: COMPANY_STATUS.VERIFICATION_REJECTED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.BLOCKED}`),
      value: COMPANY_STATUS.BLOCKED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.ARCHIVED}`),
      value: COMPANY_STATUS.ARCHIVED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.INACTIVE_AFTER_EDIT}`),
      value: COMPANY_STATUS.INACTIVE_AFTER_EDIT,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION}`),
      value: COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION,
    },
  ],
  [COMPANY_GROUP.ALL]: [
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION}`),
      value: COMPANY_STATUS.WAITING_FOR_VERIFICATION,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION}`),
      value: COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT}`),
      value: COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT}`),
      value: COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.ACTIVE}`),
      value: COMPANY_STATUS.ACTIVE,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.IN_REWORK}`),
      value: COMPANY_STATUS.IN_REWORK,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.VERIFICATION_REJECTED}`),
      value: COMPANY_STATUS.VERIFICATION_REJECTED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.BLOCKED}`),
      value: COMPANY_STATUS.BLOCKED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.ARCHIVED}`),
      value: COMPANY_STATUS.ARCHIVED,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.INACTIVE_AFTER_EDIT}`),
      value: COMPANY_STATUS.INACTIVE_AFTER_EDIT,
    },
    {
      label: i18n.t(`pages.companies.status.${COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION}`),
      value: COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION,
    },
  ],
};

const transportationTypeOptions = [
  { label: i18n.t('pages.company.transportation-type.inner'), value: companyTransportationType.INNER },
  { label: i18n.t('pages.company.transportation-type.outer'), value: companyTransportationType.OUTER },
  { label: i18n.t('pages.company.transportation-type.both'), value: companyTransportationType.BOTH },
];

const findContactPerson = contacts => {
  if (!contacts) {
    return null;
  }
  const contactPersonType = Object.keys(contacts).find(key => {
    return contacts?.[key]?.contactPerson === true;
  });
  return contacts?.[contactPersonType];
};

const columns = [
  {
    dataIndex: ['general'],
    key: 'name',
    title: i18n.t('common.name'),
    render: ({ name, id }) => {
      return (
        <Link to={pageUrls.COMPANY.ITEM(id)} style={{ color: 'inherit' }}>
          {name}
        </Link>
      );
    },
  },
  {
    dataIndex: ['general', 'status'],
    key: 'status',
    render: (status = COMPANY_STATUS.NEW) => (
      <Tag shape="round" className={`ant-tag-${tagsColors[status]}`}>
        {i18n.t(`pages.companies.status.${status}`)}
      </Tag>
    ),
    title: i18n.t('common.status'),
  },
  {
    dataIndex: ['contracts'],
    key: 'contract',
    render: contracts => {
      const contractStatus = contracts?.[CONTRACT_TYPE.AGREEMENT]?.status || CONTRACT_STATUS.FAKE_NOT_SIGNED;
      return <ContractStatusTag status={contractStatus} />;
    },
    title: i18n.t('common.contract'),
  },
  {
    dataIndex: ['contacts'],
    key: 'phone',
    render: contacts => findContactPerson(contacts)?.phone,
    title: i18n.t('common.phone'),
  },
  {
    dataIndex: ['contacts'],
    key: 'email',
    render: contacts => findContactPerson(contacts)?.email,
    title: i18n.t('common.email'),
  },
  {
    dataIndex: ['general', 'transportationType'],
    key: 'transportationType',
    render: transportationType =>
      transportationType && i18n.t(`pages.company.transportation-type.${transportationType}`),
    title: i18n.t('pages.company.transportation-type'),
  },
];

const tabsOptions = [
  {
    key: COMPANY_STATUS.WAITING_FOR_VERIFICATION,
    label: i18n.t('pages.companies.tabs.pending-verification-companies'),
  },
  {
    key: COMPANY_GROUP.VERIFIED,
    label: i18n.t('pages.companies.tabs.verified'),
  },
  {
    key: COMPANY_GROUP.NOT_ACTIVE,
    label: i18n.t('pages.companies.tabs.non-active'),
  },
  {
    key: COMPANY_GROUP.ALL,
    label: i18n.t('pages.companies.tabs.all-companies'),
  },
];

const CompanyTabs = props => {
  const { tab, tabs, total, onChange } = props;

  const handleChangeTab = useCallback(
    value => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Tabs activeKey={tab} onChange={handleChangeTab}>
      {tabs?.map(item => {
        return (
          <Tabs.TabPane
            tab={
              <>
                {item?.label}
                {tab === item?.key && <Badge square count={total} color={colors.geekBlue6} />}
              </>
            }
            key={item?.key}
          />
        );
      })}
    </Tabs>
  );
};

const Companies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const [queryStatus, setQueryStatus] = useState(query.get('status'));
  const viewer = useContext(ViewerContext);

  const companies = useSelector(state => state.specificCompanies?.all);
  const report = useSelector(state => state.report?.[REPORT_TYPE.COMPANIES]);

  const [items, setItems] = useState();
  const [searchValue, setSearchValue] = useState();
  const [pagination, setPagination] = useState(defaultPaginationProps);
  const [tab, setTab] = useState(
    queryStatus === COMPANY_STATUS.IN_REWORK ? COMPANY_GROUP.NOT_ACTIVE : COMPANY_STATUS.WAITING_FOR_VERIFICATION
  );
  const [tabStatuses, setTabStatuses] = useState(companyStatusesByStatus[tab]);

  const [availableStatuses, setAvailableStatuses] = useState();
  const [filterSearchValue, setFilterSearchValue] = useState();

  const [baseFilters, setBaseFilters] = useState({
    status:
      queryStatus === COMPANY_STATUS.IN_REWORK
        ? [COMPANY_STATUS.IN_REWORK]
        : queryStatus
        ? [queryStatus]
        : companyStatusesByStatus[tab]?.map(item => item.value),
  });
  const [extraFilters, setExtraFilters] = useState();
  const [filterValues, setFilterValues] = useState(queryStatus ? { status: [queryStatus] } : null);
  const isSearching = useMemo(
    () => searchValue?.length > 0 || (extraFilters && Object.keys(sanitizeObject(extraFilters))?.length > 0),
    [extraFilters, searchValue]
  );

  const handleChangeTab = useCallback(
    value => {
      setTab(value);
      setItems([]);
      setQueryStatus();
      setPagination(defaultPaginationProps);
      setTabStatuses(companyStatusesByStatus[value]);
      setBaseFilters(f => ({
        ...f,
        status: companyStatusesByStatus[value]?.map(item => item.value),
      }));
      if (filterSearchValue) {
        setExtraFilters({ companyName: filterSearchValue });
      } else {
        setExtraFilters();
      }
      setFilterValues();
      if (value === COMPANY_GROUP.ALL) {
        setAvailableStatuses(companyStatusesByStatus[value]);
      }
    },
    [filterSearchValue]
  );

  const handleChangeStatus = useCallback(
    statuses => {
      setPagination(defaultPaginationProps);
      if (statuses?.includes(TENDER_GROUP.ALL) || statuses?.length === 0) {
        if (extraFilters?.status?.length > 0) {
          setExtraFilters();
          setFilterValues(f => sanitizeObject({ ...f, status: [] }));
        } else {
          const statusesList = companyStatusesByStatus[TENDER_GROUP.ALL]?.map(item => item.value);
          setExtraFilters(f => ({
            ...f,
            status: statusesList,
          }));
          setFilterValues(f => sanitizeObject({ ...f, status: statusesList }));
        }
      } else {
        setExtraFilters(f => ({
          ...f,
          status: statuses?.length > 0 ? statuses : companyStatusesByStatus[tab]?.map(item => item.value),
        }));
        setFilterValues(f => sanitizeObject({ ...f, status: statuses }));
      }
    },
    [tab, extraFilters]
  );

  const handleCheckStatus = useCallback(
    statuses => {
      setPagination(defaultPaginationProps);
      setExtraFilters(f => ({
        ...f,
        status: statuses?.length > 0 ? statuses : companyStatusesByStatus[tab]?.map(item => item.value),
      }));
      setFilterValues(f => sanitizeObject({ ...f, status: statuses }));
    },
    [tab]
  );

  const handleChangeTable = useCallback(({ page, pageSize }) => {
    setPagination({ page: page - 1, size: pageSize });
  }, []);

  const handleApplySearch = useCallback(value => {
    setExtraFilters(f => ({ ...f, companyName: value }));
    setFilterSearchValue(value);
  }, []);

  const handleChangeSearch = useCallback(
    event => {
      const { value } = event.target;
      if (value?.length > 2) {
        handleApplySearch(value);
      } else if (!value || value?.length === 0) {
        setExtraFilters(f => ({ ...f, companyName: null }));
        setFilterSearchValue();
      }
      setSearchValue(value);
    },
    [handleApplySearch]
  );

  const handleEnterSearch = useCallback(() => {
    handleApplySearch(searchValue);
  }, [handleApplySearch, searchValue]);

  const handleResetFilters = useCallback(() => {
    setSearchValue();
    setExtraFilters();
    setFilterSearchValue();
    setFilterValues();
  }, []);

  const handleRowClick = useCallback(
    ({ general: { id } }) => {
      history.push(pageUrls.COMPANY.ITEM(id));
    },
    [history]
  );

  const handleChangeTranportationType = useCallback(types => {
    setPagination(defaultPaginationProps);
    setFilterValues(f => sanitizeObject({ ...f, types }));
    setExtraFilters(f => ({ ...f, shipmentType: types?.length > 0 ? types : null }));
  }, []);

  const handleCreateReport = useCallback(
    payload => {
      dispatch(createCompaniesReport(payload));
    },
    [dispatch]
  );

  const handleClickReport = useCallback(() => {
    dispatch(showModal({ modal: MODALS.CreateCompaniesReport, onOk: handleCreateReport }));
  }, [dispatch, handleCreateReport]);

  useEffect(() => {
    if (companies?.status === statusType.SUCCESSED) {
      setItems(companies?.data);
    }
  }, [companies]);

  useEffect(() => {
    if (report?.status === statusType.SUCCESSED) {
      dispatch({ payload: { type: REPORT_TYPE.COMPANIES }, type: CREATE_REPORT });
      dispatch(showModal({ modal: MODALS.CreateReportComplete, onOk: handleClickReport }));
      const a = document.createElement('a');
      a.href = report?.data?.link;
      a.click();
    } else if (report?.status === statusType.FAILED) {
      dispatch({ payload: { type: REPORT_TYPE.COMPANIES }, type: CREATE_REPORT });
      dispatch(hideModal(MODALS.CreateCompaniesReport));
      dispatch(showModal({ modal: MODALS.Oops, onOk: handleClickReport }));
    }
  }, [dispatch, report, handleClickReport]);

  useEffect(() => {
    const filters = { ...baseFilters, ...extraFilters };
    dispatch(
      findCompanies({
        data: {
          query: Object.keys(filters)
            .map(filterKey => {
              const filterValue = filters[filterKey];
              if (filterValue) {
                return {
                  field: filterKey,
                  value: filterValue,
                };
              }
              return false;
            })
            .filter(f => !!f),
        },
        pagination,
        sort: { sort: 'lastUpdated,desc' },
      })
    );
  }, [dispatch, baseFilters, extraFilters, pagination]);

  const isReportAvailable =
    viewer?.role === ACCOUNT_ROLE.AU ||
    viewer?.role === ACCOUNT_ROLE.SB ||
    viewer?.role === ACCOUNT_ROLE.LG ||
    viewer?.role === ACCOUNT_ROLE.LG_CHIEF;

  return (
    <DashboardLayout>
      <Row gutter={[0, 24]} className={classes.content}>
        <Col span={24} className={classes.header}>
          <Row justify="space-between">
            <Col>
              <Typography.Title level={3}>{t('common.transport-companies')}</Typography.Title>
            </Col>
            <Col>
              <Space size="middle">
                {isReportAvailable && (
                  <Button type="primary" shape="round" ghost onClick={handleClickReport}>
                    {t('common.create-report')}
                  </Button>
                )}
                <Input.Search
                  size="small"
                  className={classes.search}
                  value={searchValue}
                  onChange={handleChangeSearch}
                  onPressEnter={handleEnterSearch}
                  placeholder={t('common.search')}
                  enterButton={<Button type="text" icon={<SearchOutlined onClick={handleEnterSearch} />} />}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        {filterSearchValue?.length > 0 && (
          <Col span={24} className={classes.result}>
            <Typography>
              {t('pages.companies.search-result', { count: items?.length, search: filterSearchValue })}
            </Typography>
          </Col>
        )}

        <Col span={24} className={classes.tabs}>
          <CompanyTabs tab={tab} tabs={tabsOptions} total={companies?.pagination?.total} onChange={handleChangeTab} />
        </Col>
        <Col span={24} className={classes.filters}>
          <Row justify="space-between" gutter={[24, 0]}>
            <Col span={12}>
              {tab === COMPANY_GROUP.ALL ? (
                <Space align="start" size="middle">
                  <Typography.Text type="secondary">{t('common.status')}:</Typography.Text>
                  <Select
                    value={filterValues?.status}
                    style={{ minWidth: 300, width: '100%' }}
                    options={[
                      {
                        label: t('common.all'),
                        value: COMPANY_GROUP.ALL,
                      },
                      ...availableStatuses,
                    ]}
                    onChange={handleChangeStatus}
                    mode="multiple"
                    shape="round"
                    showArrow
                    allowClear
                    showSearch={false}
                  />
                </Space>
              ) : (
                <Space align="start" size="middle">
                  <Typography.Text type="secondary">{t('common.status')}:</Typography.Text>
                  <Checkbox.Group
                    value={filterValues?.status}
                    onChange={handleCheckStatus}
                    defaultValue={queryStatus && baseFilters.status}
                  >
                    <Space direction="vertical">
                      {tabStatuses?.map(status => (
                        <Checkbox key={status.value} value={status.value}>
                          {status.label}
                        </Checkbox>
                      ))}
                    </Space>
                  </Checkbox.Group>
                </Space>
              )}
            </Col>
            <Col span={12}>
              <Space size="large">
                <Typography.Text type="secondary">{t('pages.company.transportation-type')}:</Typography.Text>
                <Checkbox.Group
                  value={filterValues?.types}
                  options={transportationTypeOptions}
                  onChange={handleChangeTranportationType}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            onRowClick={handleRowClick}
            className={classes.table}
            columns={columns}
            data={items}
            rowKey={parseCompanyId}
            onChange={handleChangeTable}
            pagination={companies?.pagination}
            loading={companies?.status === statusType.LOADING}
            locale={{
              emptyText: (
                <TableEmpty
                  searching={isSearching}
                  emptySearchText={t('pages.companies.search-result-empty')}
                  onReset={handleResetFilters}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default memo(Companies);
