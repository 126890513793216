import statusType from '../constants/statusType';
import {
  CREATE_REPORT,
  CREATE_REPORT_ERROR,
  CREATE_REPORT_LOADING,
  CREATE_REPORT_SUCCESS,
} from '../constants/actionTypes';

const initialState = {};

const reportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REPORT: {
      return { ...state, [payload?.type]: { status: statusType.IDLE } };
    }

    case CREATE_REPORT_ERROR: {
      return { ...state, [payload?.type]: { error: payload?.error, status: statusType.FAILED } };
    }

    case CREATE_REPORT_LOADING: {
      return { ...state, [payload?.type]: { status: statusType.LOADING } };
    }

    case CREATE_REPORT_SUCCESS: {
      return { ...state, [payload?.type]: { data: payload?.data, status: statusType.SUCCESSED } };
    }

    default:
      return state;
  }
};

export default reportReducer;
