import { combineReducers } from 'redux';

import account from './accountReducer';
import alerts from './alertsReducer';
import changeEmail from './changeEmailReducer';
import companies from './companiesReducer';
import company from './companyReducer';
import companyActions from './companyActionsReducer';
import contract from './contractReducer';
import emailDetails from './emailDetailsReducer';
import employee from './employeeReducer';
import employees from './employeesReducer';
import modals from './modalsReducer';
import myCompany from './myCompanyReducer';
import passwordChange from './passwordChangeReducer';
import passwordReset from './passwordResetReducer';
import place from './placeReducer';
import report from './reportReducer';
import sendEmail from './sendEmailReducer';
import setAccount from './setAccountReducer';
import signIn from './signInReducer';
import signUp from './signUpReducer';
import specificCompanies from './specificCompaniesReducer';
import specificTenders from './specificTendersReducer';
import technoCompany from './technoCompanyReducer';
import tender from './tenderReducer';
import tenders from './tendersReducer';
import upload from './uploadReducer';

export default combineReducers({
  account,
  alerts,
  changeEmail,
  companies,
  company,
  companyActions,
  contract,
  emailDetails,
  employee,
  employees,
  modals,
  myCompany,
  passwordChange,
  passwordReset,
  place,
  report,
  sendEmail,
  setAccount,
  signIn,
  signUp,
  specificCompanies,
  specificTenders,
  technoCompany,
  tender,
  tenders,
  upload,
});
