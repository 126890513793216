import React, { memo, useCallback, useEffect, useMemo } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { CloseCircleOutlined, PlayCircleOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { findTendersByStatus } from '@/store/actions/tendersActions';
import pageUrls from '@/constants/pageUrls';
import TendersWidget from '@/components/TendersWidget';
import TenderVerticalRoute from '@/pages/Tenders/components/TenderVerticalRoute';
import { TENDER_DATE_FORMAT, TENDER_DATETIME_FORMAT, TENDER_STATUS } from '@/constants/tender';

import colors from '@/styles/colors.module.scss';

const columns = [
  {
    dataIndex: 'route',
    key: 'route',
    title: i18n.t('common.route'),
    render: route => (
      <Row align="middle">
        <TenderVerticalRoute route={route} />
      </Row>
    ),
  },
  {
    dataIndex: 'bid',
    key: 'trading-dates',
    title: i18n.t('common.tender.trading-dates'),
    render: bid =>
      bid?.dealsStarts &&
      `${moment(bid?.dealsStarts).format(TENDER_DATE_FORMAT)} - ${moment(bid?.dealsEnds).format(TENDER_DATE_FORMAT)}`,
  },
  {
    dataIndex: 'conditions',
    key: 'transportation-dates',
    title: i18n.t('common.tender.transportation-dates'),
    render: conditions =>
      `${moment(conditions?.transferStartDate).format(TENDER_DATE_FORMAT)}${
        conditions?.transferEndDate ? ` - ${moment(conditions?.transferEndDate).format(TENDER_DATE_FORMAT)}` : ''
      }`,
  },
];

const columnsTrading = [
  ...columns.slice(0, 2),
  {
    dataIndex: 'bid',
    key: 'trading-stages',
    title: i18n.t('common.tender.current-trading-state'),
    render: bid => bid?.currentPhase && i18n.t('common.tender.x-trading-stage', { stage: bid?.currentPhase }),
  },
];

const DashboardAU = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const tenders = useSelector(state => state.specificTenders);

  const listTrading = useMemo(() => tenders?.[TENDER_STATUS.TRADING]?.data || [], [tenders]);
  const listReady = useMemo(() => tenders?.[TENDER_STATUS.READY]?.data || [], [tenders]);
  const listCanceled = useMemo(() => tenders?.[TENDER_STATUS.CANCELED]?.data || [], [tenders]);
  const listFailed = useMemo(() => tenders?.[TENDER_STATUS.FAILED]?.data || [], [tenders]);

  const handleClickViewTenders = useCallback(
    status => {
      history.push(`${pageUrls.TENDERS.LIST}?status=${status}`);
    },
    [history]
  );

  useEffect(() => {
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.TRADING] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'dealsEnds,desc' },
        status: TENDER_STATUS.TRADING,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.READY] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'transferStartDate,asc' },
        status: TENDER_STATUS.READY,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.CANCELED] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'stateChangeTime,desc' },
        status: TENDER_STATUS.CANCELED,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.FAILED] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'stateChangeTime,desc' },
        status: TENDER_STATUS.FAILED,
      })
    );
  }, [dispatch]);

  return (
    <Row>
      <Col span={24}>
        <Row justify="start">
          <Typography.Title level={2}>{t('common.tenders')}</Typography.Title>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <TendersWidget
              title={t('common.trading-tenders')}
              titleIcon={<SyncOutlined style={{ color: colors.main6 }} />}
              columns={columnsTrading}
              data={listTrading}
              viewAllValue={TENDER_STATUS.TRADING}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.ready-tenders')}
              titleIcon={<PlayCircleOutlined style={{ color: colors.geekBlue6 }} />}
              columns={columns}
              data={listReady}
              viewAllValue={TENDER_STATUS.READY}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.canceled-tenders')}
              titleIcon={<CloseCircleOutlined style={{ color: colors.red6 }} />}
              columns={columns}
              data={listCanceled}
              viewAllValue={TENDER_STATUS.CANCELED}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.failed-tenders')}
              titleIcon={<StopOutlined style={{ color: colors.gray9 }} />}
              columns={columns}
              data={listFailed}
              viewAllValue={TENDER_STATUS.FAILED}
              onViewAll={handleClickViewTenders}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(DashboardAU);
