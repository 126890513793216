import React, { memo, useCallback, useEffect, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Tag, Typography } from 'antd';

import UploadDocument from '../UploadDocument';

const CompanyDocumentRow = props => {
  const { allowUpload = true, label, name, onUpload, reqiured = true, value } = props;
  const { t } = useTranslation();

  const [doc, setDoc] = useState();

  const handleUploadDocument = useCallback(
    ({ url }) => {
      setDoc(url);
      if (onUpload) {
        onUpload({ name, url });
      }
    },
    [name, onUpload]
  );

  useEffect(() => {
    if (value) {
      setDoc(value);
    }
  }, [value]);

  return (
    <Col span={24}>
      <Row>
        <Col span={12}>{label}</Col>
        <Col span={12}>
          <Row justify="end" gutter={[34, 0]}>
            <Col>
              {doc ? (
                <Typography.Link href={doc} target="_blank">
                  <Button shape="round" icon={<DownloadOutlined />}>
                    {t('common.download')}
                  </Button>
                </Typography.Link>
              ) : (
                reqiured && (
                  <Tag color="orange" shape="round">
                    {t('common.required-for-verification')}
                  </Tag>
                )
              )}
            </Col>
            {allowUpload && (
              <Col>
                <UploadDocument
                  name={name}
                  label={doc ? t('common.update') : t('common.add')}
                  onUpload={handleUploadDocument}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

CompanyDocumentRow.propTypes = {
  allowUpload: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  onUpload: PropTypes.func,
  reqiured: PropTypes.bool,
  value: PropTypes.string,
};

export default memo(CompanyDocumentRow);
