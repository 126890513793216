import React, { memo, useCallback, useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Layout, Row } from 'antd';

import Alerts from '@/views/alerts/Alerts';
import storage, { KEY_SETTINGS } from '@/services/storageService';

import Sidebar from './components/Sidebar';

import classes from './DashboardLayout.module.scss';

const { Content } = Layout;

const DashboardLayout = props => {
  const { children } = props;

  const settings = storage.getValue(KEY_SETTINGS);
  const [sideCollapsed, setSideCollapsed] = useState(settings?.sidebar?.collapsed || false);

  const handleSideCollapse = useCallback(collapsed => {
    setSideCollapsed(collapsed);
  }, []);

  return (
    <Layout>
      <Sidebar onCollapse={handleSideCollapse} />
      <Layout className={clsx(classes.root, { [classes.collapsed]: sideCollapsed })}>
        <Row justify="center">
          <Content className={classes.body}>
            <Alerts />
            <Layout className={classes.content}>{children}</Layout>
          </Content>
        </Row>
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default memo(DashboardLayout);
