import React, { memo, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, List, Progress, Row, Space, Typography } from 'antd';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';

import colors from '@/styles/colors.module.scss';
import { DOCUMENTS } from '@/pages/Company/constants';
import { parseFileName } from '@/utils/common';
import { removeFile } from '@/store/actions/resourceActions';
import UploadDocument from '@/pages/Company/components/UploadDocument';
import { Link, Text } from '@/components';

import classes from './DocumentsFields.module.scss';

const { Title } = Typography;

const ProgressBar = props => {
  const { progress } = props;
  return (
    <Progress
      style={{ width: 100 }}
      percent={progress}
      showInfo={false}
      strokeColor={colors.geekBlue4}
      strokeWidth={4}
    />
  );
};

const DocumentItem = props => {
  const { name, onChange, value } = props;
  const { t } = useTranslation();
  const [progress, setProgress] = useState();
  const [file, setFile] = useState();
  const dispatch = useDispatch();

  const handleProgress = useCallback(({ progress }) => {
    setProgress(progress);
  }, []);

  const handleChange = useCallback(({ file }) => {
    setFile({ progress: 0 });
  }, []);

  const handleUpload = useCallback(
    ({ url }) => {
      setFile(f => ({ ...f, url }));
      setProgress();
      onChange(url);
    },
    [onChange]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeFile(file.url));
    setFile();
    setProgress();
    onChange();
  }, [dispatch, file, onChange]);

  useEffect(() => {
    if (value) {
      setFile({ name: parseFileName(value), url: value });
    }
  }, [value]);

  return (
    <Row>
      {file?.url && (
        <Space>
          <Typography.Link href={file?.url} target="_blank">
            <Typography className={classes.name}>{file?.name}</Typography>
          </Typography.Link>
          <Button
            className={classes.progress}
            icon={<DeleteOutlined />}
            shape="circle"
            type="text"
            onClick={handleRemove}
          />
        </Space>
      )}

      {progress && (
        <Space>
          <Typography className={classes.progress}>{t('common.uploaded-progress', { progress })}</Typography>
          <ProgressBar progress={progress} />
        </Space>
      )}

      {!file?.url && (
        <UploadDocument
          name={name}
          className={classes.upload}
          key="upload"
          onChange={handleChange}
          onUpload={handleUpload}
          onProgress={handleProgress}
        >
          {progress && ' '}
        </UploadDocument>
      )}
    </Row>
  );
};

const Document = props => {
  const { name, title, description, onChange, value } = props;

  const handleChangeDocument = useCallback(
    url => {
      if (onChange) {
        onChange({ fileName: url, type: name });
      }
    },
    [onChange, name]
  );

  return (
    <List.Item>
      <Col span={16}>
        <List.Item.Meta
          avatar={<FileTextOutlined color="#2F54EB" />}
          title={<Text className={classes.label}>{title}</Text>}
          description={description}
        />
      </Col>
      <Col span={8}>
        <Row justify="end">
          <DocumentItem name={name} value={value?.fileName} onChange={handleChangeDocument} />
        </Row>
      </Col>
    </List.Item>
  );
};

Document.propTypes = {
  description: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
};

const DocumentsFields = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 22]}>
      <Col span={24} className={classes.description}>
        <Row justify="center">
          <Title level={3}>{t('pages.company-fill.upload-required-documents')}</Title>
        </Row>
        <Row justify="center">
          <Text>{t('pages.company-fill.please-upload-documents-on-formats')}</Text>
        </Row>
      </Col>
      <Col span={24}>
        <List>
          <Form.Item name={['documents', DOCUMENTS.STATUTE]} noStyle>
            <Document name={DOCUMENTS.STATUTE} title={t('pages.company.statute')} />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.SGR]} noStyle>
            <Document name={DOCUMENTS.SGR} title={t('pages.company.sgr')} />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.VAT]} noStyle>
            <Document name={DOCUMENTS.VAT} title={t('pages.company.vat-payer')} />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.SGR_EGOV]} noStyle>
            <Document
              name={DOCUMENTS.SGR_EGOV}
              title={t('pages.company.sgr-egov')}
              description={
                <Trans i18nKey="pages.company.sgr-egov-description">
                  [<Link to="//egov.kz" target="_blank" />]
                </Trans>
              }
            />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.REPORT]} noStyle>
            <Document name={DOCUMENTS.REPORT} title={t('pages.company.fixed-assets-report')} />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.GUARANTEE]} noStyle>
            <Document
              name={DOCUMENTS.GUARANTEE}
              title={t('pages.company.letter-of-guarantee')}
              description={t('common.(optional)')}
            />
          </Form.Item>
          <Form.Item name={['documents', DOCUMENTS.PRESENTATION]} noStyle>
            <Document
              name={DOCUMENTS.PRESENTATION}
              title={t('pages.company.presentation')}
              description={t('common.(optional)')}
            />
          </Form.Item>
        </List>
      </Col>
    </Row>
  );
};

export default memo(DocumentsFields);
