import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES } from '@/constants/common';
import { ru } from './locales';

i18n.use(initReactI18next).init({
  fallbackLng: false,
  initImmediate: true,
  interpolation: {
    escapeValue: false,
  },
  lng: LANGUAGES.RU,
  react: {
    useSuspense: false,
    wait: true,
  },
  resources: {
    ru: { translation: ru },
  },
});
