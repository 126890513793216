import React, { memo } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import env from '@/constants/env';
import Modal from '@/views/modals/Modal';
import pageUrls from '@/constants/pageUrls';

import colors from '@/styles/colors.module.scss';

import { MODALS } from '..';

const { Title, Link } = Typography;

const Support = () => {
  const { t } = useTranslation();

  return (
    <Modal name={MODALS.Support} width={428}>
      <Row>
        <Col flex="1">
          <QuestionCircleOutlined style={{ color: colors.gold6, fontSize: 21 }} />
        </Col>
        <Col span={22}>
          <Row gutter={[0, 22]}>
            <Col span={24}>
              <Col>
                <Title level={5}>{t('modal.support.title')}</Title>
                <Trans i18nKey="modal.support.description" values={{ email: env.SUPPORT_EMAIL }}>
                  [<Typography.Link underline href={`mailto:${env.SUPPORT_EMAIL}`} target="_blank" />]
                </Trans>
              </Col>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: '0' }} />
            </Col>
            <Col span={24}>
              <Col>
                <Link href={pageUrls.PRIVACY_POLICY} target="_blank">
                  {t('common.privacy-policy')}
                </Link>
              </Col>
              <Col>
                <Link href={pageUrls.TERMS} target="_blank">
                  {t('common.terms-of-use')}
                </Link>
              </Col>
              <Col>
                <Link href={pageUrls.TRADING_RULES} target="_blank">
                  {t('common.trading-rules')}
                </Link>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(Support);
