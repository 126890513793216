import React, { memo, useCallback, useEffect, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { BaseLayout } from '@/containers/layout';
import { Email } from '@/components/form';
import env from '@/constants/env';
import Input from '@/components/Input';
import pageUrls from '@/constants/pageUrls';
import { signUp } from '@/store/actions/accountActions';
import statusType from '@/store/constants/statusType';
import { Card, Link, Text } from '@/components';

import classes from './SignUp.module.scss';

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState();

  const { status, error, data: account } = useSelector(state => state.signUp);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      history.push({
        pathname: pageUrls.SIGN_UP_SUCCESS,
        search: `?id=${account?.id}&email=${encodeURIComponent(email)}`,
      });
    }
  }, [account, email, history, status]);

  const handleSubmitForm = useCallback(
    fields => {
      setEmail(fields.email);
      dispatch(signUp(fields));
    },
    [dispatch]
  );

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Form
          name="sign-up"
          layout="vertical"
          validateTrigger={['onChange', 'onBlur']}
          onFinish={handleSubmitForm}
          requiredMark={false}
          className={classes.root}
        >
          <Row gutter={[0, 8]} justify="center" className={classes.description}>
            <Col span={24}>
              <Text block size={30} height={38} align="center" weight="semibold">
                {t('pages.sign-up.register-form')}
              </Text>
            </Col>
            <Col span={24}>
              <Text block size={16} height={24} align="center">
                {t('pages.sign-up.sign-up-to-enter-application')}
              </Text>
            </Col>
          </Row>

          <Col span={24}>
            <Email error={error?.code && t(`error.code.${error.code}`, 'error.code')} />
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('common.first-name')}
              name="firstName"
              rules={[...fieldRules.required, ...fieldRules.input]}
            >
              <Input placeholder={t('placeholder.firstName')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('common.last-name')}
              name="lastName"
              rules={[...fieldRules.required, ...fieldRules.input]}
            >
              <Input placeholder={t('placeholder.lastName')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('common.middle-name')} name="middleName" rules={[...fieldRules.input]}>
              <Input placeholder={t('placeholder.middleName')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('common.position')} name="position" rules={[...fieldRules.input]}>
              <Input placeholder={t('placeholder.position')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Input.PasswordStrength label={t('common.password')} />
          </Col>

          <Col span={24}>
            <Input.PasswordConfirm />
          </Col>

          <Col span={24}>
            <Form.Item name="agreeToPersonalDataPolicy" valuePropName="checked" rules={fieldRules.policy} initialValue>
              <Checkbox>
                <Trans i18nKey="common.agree-with-storage-data">
                  [<Typography.Link href={pageUrls.TERMS} target="_blank" />]
                </Trans>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="agreeToSecurityPolicy" valuePropName="checked" rules={fieldRules.policy} initialValue>
              <Checkbox>
                <Trans i18nKey="common.agree-with-privacy-policy">
                  [<Typography.Link href={pageUrls.PRIVACY_POLICY} target="_blank" />]
                </Trans>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="agreeToTradingRules" valuePropName="checked" rules={fieldRules.policy} initialValue>
              <Checkbox>
                <Trans i18nKey="common.agree-with-trading-rules">
                  [<Typography.Link href={pageUrls.TRADING_RULES} target="_blank" />]
                </Trans>
              </Checkbox>
            </Form.Item>
          </Col>

          <Row gutter={[0, 24]}>
            <Form.Item name="captcha" rules={fieldRules.required}>
              <ReCAPTCHA sitekey={env.RECAPTCHA_SITE_KEY} />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              shape="round"
              block
              loading={status === statusType.LOADING}
            >
              <Text align="center">{t('common.sign-up')}</Text>
            </Button>

            <Col span={24}>
              <Row justify="center">
                <Trans i18nKey="pages.sign-up.sign-in-if-account-exists">
                  [<Link to={pageUrls.SIGN_IN} />]
                </Trans>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </BaseLayout>
  );
};

export default memo(SignUp);
