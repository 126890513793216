import { HIDE_ALERT, SHOW_ALERT } from '../constants/actionTypes';

const initialState = {};

const alertsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_ALERT: {
      const { alert, props } = payload;
      return { ...state, [alert]: props || true };
    }

    case HIDE_ALERT: {
      const { alert } = payload;
      return { ...state, [alert]: false };
    }
    default:
      return state;
  }
};

export default alertsReducer;
