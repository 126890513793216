export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR';
export const ADD_EMPLOYEE_LOADING = 'ADD_EMPLOYEE_LOADING';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';

export const ACTION_COMPANY = 'ACTION_COMPANY';
export const ACTION_COMPANY_ERROR = 'ACTION_COMPANY_ERROR';
export const ACTION_COMPANY_LOADING = 'ACTION_COMPANY_LOADING';
export const ACTION_COMPANY_SUCCESS = 'ACTION_COMPANY_SUCCESS';

export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_ERROR = 'ADD_COMPANY_ERROR';
export const ADD_COMPANY_LOADING = 'ADD_COMPANY_LOADING';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';

export const ADD_TENDER = 'ADD_TENDER';
export const ADD_TENDER_ERROR = 'ADD_TENDER_ERROR';
export const ADD_TENDER_LOADING = 'ADD_TENDER_LOADING';
export const ADD_TENDER_SUCCESS = 'ADD_TENDER_SUCCESS';

export const ARCHIVE_COMPANY = 'ARCHIVE_COMPANY';
export const ARCHIVE_COMPANY_ERROR = 'ARCHIVE_COMPANY_ERROR';
export const ARCHIVE_COMPANY_LOADING = 'ARCHIVE_COMPANY_LOADING';
export const ARCHIVE_COMPANY_SUCCESS = 'ARCHIVE_COMPANY_SUCCESS';

export const BID_TENDER = 'BID_TENDER';
export const BID_TENDER_ERROR = 'BID_TENDER_ERROR';
export const BID_TENDER_LOADING = 'BID_TENDER_LOADING';
export const BID_TENDER_SUCCESS = 'BID_TENDER_SUCCESS';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';
export const CHANGE_EMAIL_LOADING = 'CHANGE_EMAIL_LOADING';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';

export const CANCEL_TENDER = 'CANCEL_TENDER';
export const CANCEL_TENDER_ERROR = 'CANCEL_TENDER_ERROR';
export const CANCEL_TENDER_LOADING = 'CANCEL_TENDER_LOADING';
export const CANCEL_TENDER_SUCCESS = 'CANCEL_TENDER_SUCCESS';

export const COMPLETE_TENDER = 'COMPLETE_TENDER';
export const COMPLETE_TENDER_ERROR = 'COMPLETE_TENDER_ERROR';
export const COMPLETE_TENDER_LOADING = 'COMPLETE_TENDER_LOADING';
export const COMPLETE_TENDER_SUCCESS = 'COMPLETE_TENDER_SUCCESS';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const CREATE_REPORT_LOADING = 'CREATE_REPORT_LOADING';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';

export const CONFIRM_CONTRACT = 'CONFIRM_CONTRACT';
export const CONFIRM_CONTRACT_ERROR = 'CONFIRM_CONTRACT_ERROR';
export const CONFIRM_CONTRACT_LOADING = 'CONFIRM_CONTRACT_LOADING';
export const CONFIRM_CONTRACT_SUCCESS = 'CONFIRM_CONTRACT_SUCCESS';

export const DELETE_TENDER = 'DELETE_TENDER';
export const DELETE_TENDER_ERROR = 'DELETE_TENDER_ERROR';
export const DELETE_TENDER_LOADING = 'DELETE_TENDER_LOADING';
export const DELETE_TENDER_SUCCESS = 'DELETE_TENDER_SUCCESS';

export const DUPLICATE_TENDER = 'DUPLICATE_TENDER';
export const DUPLICATE_TENDER_ERROR = 'DUPLICATE_TENDER_ERROR';
export const DUPLICATE_TENDER_LOADING = 'DUPLICATE_TENDER_LOADING';
export const DUPLICATE_TENDER_SUCCESS = 'DUPLICATE_TENDER_SUCCESS';

export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';
export const EDIT_COMPANY_LOADING = 'EDIT_COMPANY_LOADING';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';

export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const EDIT_EMPLOYEE_ERROR = 'EDIT_EMPLOYEE_ERROR';
export const EDIT_EMPLOYEE_LOADING = 'EDIT_EMPLOYEE_LOADING';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';

export const EDIT_TECHNO_COMPANY = 'EDIT_TECHNO_COMPANY';
export const EDIT_TECHNO_COMPANY_ERROR = 'EDIT_TECHNO_COMPANY_ERROR';
export const EDIT_TECHNO_COMPANY_LOADING = 'EDIT_TECHNO_COMPANY_LOADING';
export const EDIT_TECHNO_COMPANY_SUCCESS = 'EDIT_TECHNO_COMPANY_SUCCESS';

export const EDIT_TENDER = 'EDIT_TENDER';
export const EDIT_TENDER_ERROR = 'EDIT_TENDER_ERROR';
export const EDIT_TENDER_LOADING = 'EDIT_TENDER_LOADING';
export const EDIT_TENDER_SUCCESS = 'EDIT_TENDER_SUCCESS';

export const FIND_EMPLOYEES = 'FIND_EMPLOYEES';
export const FIND_EMPLOYEES_ERROR = 'FIND_EMPLOYEES_ERROR';
export const FIND_EMPLOYEES_LOADING = 'FIND_EMPLOYEES_LOADING';
export const FIND_EMPLOYEES_SUCCESS = 'FIND_EMPLOYEES_SUCCESS';

export const FIND_COMPANIES = 'FIND_COMPANIES';
export const FIND_COMPANIES_ERROR = 'FIND_COMPANIES_ERROR';
export const FIND_COMPANIES_LOADING = 'FIND_COMPANIES_LOADING';
export const FIND_COMPANIES_SUCCESS = 'FIND_COMPANIES_SUCCESS';

export const FIND_TENDERS = 'FIND_TENDERS';
export const FIND_TENDERS_ERROR = 'FIND_TENDERS_ERROR';
export const FIND_TENDERS_LOADING = 'FIND_TENDERS_LOADING';
export const FIND_TENDERS_SUCCESS = 'FIND_TENDERS_SUCCESS';

export const FIND_SPECIFIC_COMPANIES = 'FIND_SPECIFIC_COMPANIES';
export const FIND_SPECIFIC_COMPANIES_ERROR = 'FIND_SPECIFIC_COMPANIES_ERROR';
export const FIND_SPECIFIC_COMPANIES_LOADING = 'FIND_SPECIFIC_COMPANIES_LOADING';
export const FIND_SPECIFIC_COMPANIES_SUCCESS = 'FIND_SPECIFIC_COMPANIES_SUCCESS';

export const FIND_SPECIFIC_TENDERS = 'FIND_SPECIFIC_TENDERS';
export const FIND_SPECIFIC_TENDERS_ERROR = 'FIND_SPECIFIC_TENDERS_ERROR';
export const FIND_SPECIFIC_TENDERS_LOADING = 'FIND_SPECIFIC_TENDERS_LOADING';
export const FIND_SPECIFIC_TENDERS_SUCCESS = 'FIND_SPECIFIC_TENDERS_SUCCESS';

export const GENERATE_CONTRACT = 'GENERATE_CONTRACT';
export const GENERATE_CONTRACT_ERROR = 'GENERATE_CONTRACT_ERROR';
export const GENERATE_CONTRACT_LOADING = 'GENERATE_CONTRACT_LOADING';
export const GENERATE_CONTRACT_SUCCESS = 'GENERATE_CONTRACT_SUCCESS';

export const GENERATE_PDF = 'GENERATE_PDF';
export const GENERATE_PDF_ERROR = 'GENERATE_PDF_ERROR';
export const GENERATE_PDF_LOADING = 'GENERATE_PDF_LOADING';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const GET_COMPANY_LOADING = 'GET_COMPANY_LOADING';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';

export const GET_COMPANY_ACTIONS = 'GET_COMPANY_ACTIONS';
export const GET_COMPANY_ACTIONS_ERROR = 'GET_COMPANY_ACTIONS_ERROR';
export const GET_COMPANY_ACTIONS_LOADING = 'GET_COMPANY_ACTIONS_LOADING';
export const GET_COMPANY_ACTIONS_SUCCESS = 'GET_COMPANY_ACTIONS_SUCCESS';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';
export const GET_COMPANIES_LOADING = 'GET_COMPANIES_LOADING';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';

export const GET_EMAIL_DETAILS = 'GET_EMAIL_DETAILS';
export const GET_EMAIL_DETAILS_ERROR = 'GET_EMAIL_DETAILS_ERROR';
export const GET_EMAIL_DETAILS_LOADING = 'GET_EMAIL_DETAILS_LOADING';
export const GET_EMAIL_DETAILS_SUCCESS = 'GET_EMAIL_DETAILS_SUCCESS';

export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_ERROR = 'GET_EMPLOYEE_ERROR';
export const GET_EMPLOYEE_LOADING = 'GET_EMPLOYEE_LOADING';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR';
export const GET_EMPLOYEES_LOADING = 'GET_EMPLOYEES_LOADING';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';

export const GET_MY_COMPANY = 'GET_MY_COMPANY';
export const GET_MY_COMPANY_ERROR = 'GET_MY_COMPANY_ERROR';
export const GET_MY_COMPANY_LOADING = 'GET_MY_COMPANY_LOADING';
export const GET_MY_COMPANY_SUCCESS = 'GET_MY_COMPANY_SUCCESS';

export const GET_TECHNO_COMPANY = 'GET_TECHNO_COMPANY';
export const GET_TECHNO_COMPANY_ERROR = 'GET_TECHNO_COMPANY_ERROR';
export const GET_TECHNO_COMPANY_LOADING = 'GET_TECHNO_COMPANY_LOADING';
export const GET_TECHNO_COMPANY_SUCCESS = 'GET_TECHNO_COMPANY_SUCCESS';

export const GET_TENDER = 'GET_TENDER';
export const GET_TENDER_ERROR = 'GET_TENDER_ERROR';
export const GET_TENDER_LOADING = 'GET_TENDER_LOADING';
export const GET_TENDER_SUCCESS = 'GET_TENDER_SUCCESS';

export const GET_TENDER_ACTIVITY = 'GET_TENDER_ACTIVITY';
export const GET_TENDER_ACTIVITY_ERROR = 'GET_TENDER_ACTIVITY_ERROR';
export const GET_TENDER_ACTIVITY_LOADING = 'GET_TENDER_ACTIVITY_LOADING';
export const GET_TENDER_ACTIVITY_SUCCESS = 'GET_TENDER_ACTIVITY_SUCCESS';

export const GET_TENDER_DETAILS = 'GET_TENDER_DETAILS';
export const GET_TENDER_DETAILS_ERROR = 'GET_TENDER_DETAILS_ERROR';
export const GET_TENDER_DETAILS_LOADING = 'GET_TENDER_DETAILS_LOADING';
export const GET_TENDER_DETAILS_SUCCESS = 'GET_TENDER_DETAILS_SUCCESS';

export const GET_TENDER_RESULTS = 'GET_TENDER_RESULTS';
export const GET_TENDER_RESULTS_ERROR = 'GET_TENDER_RESULTS_ERROR';
export const GET_TENDER_RESULTS_LOADING = 'GET_TENDER_RESULTS_LOADING';
export const GET_TENDER_RESULTS_SUCCESS = 'GET_TENDER_RESULTS_SUCCESS';

export const GET_TENDERS = 'GET_TENDERS';
export const GET_TENDERS_ERROR = 'GET_TENDERS_ERROR';
export const GET_TENDERS_LOADING = 'GET_TENDERS_LOADING';
export const GET_TENDERS_SUCCESS = 'GET_TENDERS_SUCCESS';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';
export const GET_ACCOUNT_LOADING = 'GET_ACCOUNT_LOADING';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';

export const PLACE_AUTOCOMPLETE_ERROR = 'PLACE_AUTOCOMPLETE_ERROR';
export const PLACE_AUTOCOMPLETE_LOADING = 'PLACE_AUTOCOMPLETE_LOADING';
export const PLACE_AUTOCOMPLETE_SUCCESS = 'PLACE_AUTOCOMPLETE_SUCCESS';

export const RECOVER_EMPLOYEE = 'RECOVER_EMPLOYEE';
export const RECOVER_EMPLOYEE_ERROR = 'RECOVER_EMPLOYEE_ERROR';
export const RECOVER_EMPLOYEE_LOADING = 'RECOVER_EMPLOYEE_LOADING';
export const RECOVER_EMPLOYEE_SUCCESS = 'RECOVER_EMPLOYEE_SUCCESS';

export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';
export const PASSWORD_CHANGE_LOADING = 'PASSWORD_CHANGE_LOADING';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const PUBLISH_TENDER_ERROR = 'PUBLISH_TENDER_ERROR';
export const PUBLISH_TENDER_LOADING = 'PUBLISH_TENDER_LOADING';
export const PUBLISH_TENDER_SUCCESS = 'PUBLISH_TENDER_SUCCESS';

export const READY_TENDER = 'READY_TENDER';
export const READY_TENDER_ERROR = 'READY_TENDER_ERROR';
export const READY_TENDER_LOADING = 'READY_TENDER_LOADING';
export const READY_TENDER_SUCCESS = 'READY_TENDER_SUCCESS';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';
export const RECOVER_PASSWORD_LOADING = 'RECOVER_PASSWORD_LOADING';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';

export const RECOVER_PASSWORD_DETAILS = 'RECOVER_PASSWORD_DETAILS';
export const RECOVER_PASSWORD_DETAILS_ERROR = 'RECOVER_PASSWORD_DETAILS_ERROR';
export const RECOVER_PASSWORD_DETAILS_LOADING = 'RECOVER_PASSWORD_DETAILS_LOADING';
export const RECOVER_PASSWORD_DETAILS_SUCCESS = 'RECOVER_PASSWORD_DETAILS_SUCCESS';

export const PASSWORD_RESEND = 'PASSWORD_RESEND';
export const PASSWORD_RESEND_ERROR = 'PASSWORD_RESEND_ERROR';
export const PASSWORD_RESEND_LOADING = 'PASSWORD_RESEND_LOADING';
export const PASSWORD_RESEND_SUCCESS = 'PASSWORD_RESEND_SUCCESS';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const SEND_EMAIL_LOADING = 'SEND_EMAIL_LOADING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';

export const REMOVE_FILE_ERROR = 'REMOVE_FILE_ERROR';
export const REMOVE_FILE_LOADING = 'REMOVE_FILE_LOADING';
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS';

export const REVOKE_CONTRACT = 'REVOKE_CONTRACT';
export const REVOKE_CONTRACT_ERROR = 'REVOKE_CONTRACT_ERROR';
export const REVOKE_CONTRACT_LOADING = 'REVOKE_CONTRACT_LOADING';
export const REVOKE_CONTRACT_SUCCESS = 'REVOKE_CONTRACT_SUCCESS';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SET_USER = 'SET_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

export const SEND_CONTRACT = 'SEND_CONTRACT';
export const SEND_CONTRACT_ERROR = 'SEND_CONTRACT_ERROR';
export const SEND_CONTRACT_LOADING = 'SEND_CONTRACT_LOADING';
export const SEND_CONTRACT_SUCCESS = 'SEND_CONTRACT_SUCCESS';

export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_IN_DETAILS = 'SIGN_IN_DETAILS';
export const SIGN_IN_DETAILS_ERROR = 'SIGN_IN_DETAILS_ERROR';
export const SIGN_IN_DETAILS_LOADING = 'SIGN_IN_DETAILS_LOADING';
export const SIGN_IN_DETAILS_SUCCESS = 'SIGN_IN_DETAILS_SUCCESS';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export const TERMINATE_CONTRACT = 'TERMINATE_CONTRACT';
export const TERMINATE_CONTRACT_ERROR = 'TERMINATE_CONTRACT_ERROR';
export const TERMINATE_CONTRACT_LOADING = 'TERMINATE_CONTRACT_LOADING';
export const TERMINATE_CONTRACT_SUCCESS = 'TERMINATE_CONTRACT_SUCCESS';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_LOADING = 'UPLOAD_FILE_LOADING';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
