export default {
  ACCOUNT: {
    EDIT: '/account/edit',
    MAIN: '/account',
  },
  BIDS: {
    MAIN: '/bid',
  },
  CHANGE_EMAIL_SUCCESS: '/change-email-success',
  CHANGE_PASSWORD: '/password-change',
  CHANGE_PASSWORD_ERROR: '/password-change-error',
  CHANGE_PASSWORD_SUCCESS: '/password-change-success',
  CHANGE_PASSWORD_USED: '/password-change-used',
  COMPANY: {
    ADD: '/company/add',
    EDIT: {
      CONTACTS: '/company/edit/contacts',
      GENERAL: '/company/edit/general',
      PATH: '/company/edit/:section',
    },
    FILL: '/company/fill',
    ITEM: id => `/company/${id}`,
    ITEM_PATH: '/company/:id',
    LIST: '/company/list',
    MAIN: '/company',
    MY: '/company/my',
    REGISTER: '/company/register',
  },
  CONTRACT: '/contract',
  DASHBOARD: {
    MAIN: '/dashboard',
  },
  EMPLOYEES: {
    LIST: '/employee/list',
    MAIN: '/employee',
  },
  LINK: {
    CONFLICT: '/link-conflict',
    ERROR: '/link-error',
    EXPIRED: '/link-expired',
  },
  MAIN: '/',
  NOTIFICATIONS: '/notifications',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_RESET_SUCCESS: '/password-reset-success',
  PRIVACY_POLICY: '/privacy-policy',
  RULES: {
    TENDER_BID: '/rules/tender-bid',
    TENDER_TRADING: '/rules/tender-trading',
  },
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_UP_SUCCESS: '/sign-up-success',
  TENDERS: {
    ADD: '/tender/add',
    EDIT: id => `/tender/edit/${id}`,
    EDIT_PATH: '/tender/edit/:id',
    ITEM: id => `/tender/${id}`,
    ITEM_PATH: '/tender/:id',
    LIST: '/tender/list',
    MAIN: '/tender',
  },
  TERMS: '/terms-of-use',
  TRADING_RULES: '/trading-rules',
  VERIFY_EMAIL_EXPIRED: '/verify-email-expired',
  VERIFY_EMAIL_SUCCESS: '/verify-email-success',
  VERIFY_EMAIL_USED: '/verify-email-used',
};
