import { memo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import roles from '@/constants/roles';
import { useLocation } from 'react-router';

const RoleMappedRoute = props => {
  const { pathname } = useLocation();
  const { data: account } = useSelector(state => state.account);
  const { role, children } = props;
  const matchedRole = role?.find(r => account?.role === r);
  const hasAccess = matchedRole && roles.can(matchedRole, pathname);
  if (!hasAccess) {
    console.warn(account?.role, 'has no access to', pathname);
  }
  return (hasAccess && children) || null;
};

RoleMappedRoute.propTypes = {
  component: PropTypes.node,
  role: PropTypes.arrayOf(PropTypes.string),
};

export default memo(RoleMappedRoute);
