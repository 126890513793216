import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Image, Row, Typography } from 'antd';

import imageError from '@/assets/images/broken.png';

import Modal from '../Modal';
import { MODALS } from '..';

const Error = () => {

  const { t } = useTranslation();

  return (
    <Modal name={MODALS.Error} width={428}>
      <Row gutter={[0, 24]} justify="center">
        <Col>
          <Image src={imageError} preview={false} />
        </Col>
        <Col>
          <Row justify="center">
            <Typography.Title level={3}>{t('modal.oops.title')}</Typography.Title>
            <Typography.Text>{t('modal.oops.description')}</Typography.Text>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(Error);
