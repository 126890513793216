import React, { memo, useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Select, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_GROUP } from '@/constants/company';
import { hideModal } from '@/store/actions/modalsActions';
import { PeriodSelector } from '@/components/form';
import { PlaceAutoComplete } from '@/components';
import { REPORT_TYPE } from '@/constants/report';
import statusType from '@/store/constants/statusType';
import { TENDER_GROUP } from '@/constants/tender';

import Modal from '../Modal';
import { MODALS } from '..';

const transfromDate = date => {
  if (typeof date === 'number') {
    return moment(date);
  }
  return date;
};

const CreateTendersReport = props => {
  const { onOk } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [type, setType] = useState(COMPANY_GROUP.VERIFIED);
  const report = useSelector(state => state.report?.[REPORT_TYPE.TENDERS]);
  const [form] = Form.useForm();

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.CreateTendersReport));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    form.validateFields().then(fields => {
      if (onOk) {
        onOk(type, {
          end: fields?.end?.valueOf(),
          start: fields?.start?.valueOf(),
          to: fields?.to,
        });
      }
    });
  }, [onOk, form, type]);

  const handleChangeType = useCallback(value => {
    setType(value);
  }, []);

  useEffect(() => {
    if (report?.status === statusType.SUCCESSED) {
      dispatch(hideModal(MODALS.CreateTendersReport));
    }
  }, [dispatch, report]);

  return (
    <Modal name={MODALS.CreateTendersReport} width={468}>
      <Row gutter={[0, 24]}>
        <Typography.Title level={4}>{t('modal.create-tenders-report.title')}</Typography.Title>
        <Col span={24}>
          <Form layout="vertical" form={form} requiredMark={false}>
            <Form.Item label={t('common.report-type')} name="type" initialValue={TENDER_GROUP.COMPLETED}>
              <Select size="large" onChange={handleChangeType}>
                <Select.Option value={TENDER_GROUP.COMPLETED}>
                  {t('modal.create-tenders-report.option.completed')}
                </Select.Option>
                <Select.Option value={TENDER_GROUP.FAILED}>
                  {t('modal.create-tenders-report.option.failed')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  <PeriodSelector
                    label={
                      getFieldValue('type') === TENDER_GROUP.COMPLETED
                        ? t('common.tender.transportation-dates')
                        : t('common.tender.annul-dates')
                    }
                    transformValue={transfromDate}
                    StartFormProps={{
                      initialValue: moment().subtract(30, 'days').valueOf(),
                    }}
                    StartPickerProps={{ placeholder: t('common.start-of-period') }}
                    EndPickerProps={{
                      disabledDate: current => current && current >= moment().endOf('day'),
                      placeholder: t('common.end-of-period'),
                    }}
                    EndFormProps={{
                      initialValue: moment().valueOf(),
                    }}
                  />
                );
              }}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="to" label={t('common.route')} hidden={getFieldValue('type') === TENDER_GROUP.FAILED}>
                    <PlaceAutoComplete placeholder={t('pages.tender.route-finish')} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" onClick={handleClickNo}>
                {t('modal.create-tenders-report.button.no')}
              </Button>
              <Button type="primary" shape="round" onClick={handleClickOk}>
                {t('modal.create-tenders-report.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(CreateTendersReport);
