import React, { memo, useCallback } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal } from '@/store/actions/modalsActions';
import statusType from '@/store/constants/statusType';
import Modal, { MODALS } from '@/views/modals';

const PublishTender = props => {
  const { onOk } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { publish: { status } = {} } = useSelector(state => state.tender);

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.PublishTender));
  }, [dispatch]);

  const handleClickYes = useCallback(() => {
    if (onOk) {
      onOk();
    }
  }, [onOk]);

  return (
    <Modal name={MODALS.PublishTender} width={460} bodyStyle={{ padding: '32px' }}>
      <Row gutter={[0, 24]}>
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Space align="start">
              <Col style={{ width: 30 }}>
                <ExclamationCircleOutlined color="calendulagold" style={{ fontSize: 24 }} />
              </Col>
              <Typography.Title level={5}>{t('modal.publish-tender.title')}</Typography.Title>
            </Space>
          </Col>
          <Col span={24} style={{ paddingLeft: 38 }}>
            <Typography.Text level={3}>{t('modal.publish-tender.description')}</Typography.Text>
          </Col>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Space size="middle">
              <Button shape="round" onClick={handleClickNo}>
                {t('modal.publish-tender.button.no')}
              </Button>
              <Button shape="round" type="primary" onClick={handleClickYes} loading={status === statusType.LOADING}>
                {t('modal.publish-tender.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(PublishTender);
