import React, { memo } from 'react';

import { Col, Image, Row, Typography } from 'antd';

import logo from './images/logo.svg';

import classes from './styles/Feature1.module.scss';

const Feature = () => {
  return (
    <Col span={24} className={classes.root}>
      <Col>
        <Row justify="center">
          <Image src={logo} className={classes.logo} preview={false} />
        </Row>
      </Col>
      <Col>
        <Typography.Title className={classes.title}>
          Компания Technodom – ведущая национальная сеть электробытовой и компьютерной техники Казахстана.
        </Typography.Title>
      </Col>
    </Col>
  );
};

export default memo(Feature);
