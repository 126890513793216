import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Col, Image, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLayout } from '@/containers/layout';
import { convertSecondsToHMS } from '@/utils/timeUtils';
import env from '@/constants/env';
import { getEmailDetails } from '@/store/actions/emailDetailsActions';
import { getSignInDetails } from '@/store/actions/accountActions';
import { sendSignUpEmail } from '@/store/actions/sendEmailActions';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { Card, Text } from '@/components';
import { GET_EMAIL_DETAILS, SEND_EMAIL } from '@/store/constants/actionTypes';

import imageSignUpSuccess from '@/assets/images/signUp.png';

import classes from './SignUpSuccess.module.scss';

const SignUpSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const intervalRef = useRef();
  const [seconds, setSeconds] = useState();
  const query = useQuery();

  const details = useSelector(state => state.emailDetails);
  const signIn = useSelector(state => state.signIn);
  const sendEmail = useSelector(state => state.sendEmail);

  const id = useMemo(() => {
    if (query.get('id')) {
      return query.get('id');
    }
    if (signIn?.details?.data?.userId) {
      return signIn?.details?.data?.userId;
    }
    return null;
  }, [query, signIn?.details?.data?.userId]);
  const email = useMemo(() => decodeURIComponent(query.get('email')), [query]);

  const account = useMemo(() => ({ email, id }), [id, email]);

  const handleClickResend = useCallback(() => {
    dispatch(sendSignUpEmail(account));
  }, [dispatch, account]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => s - 1);
  }, []);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED) {
      clearInterval(intervalRef.current);
      setSeconds(details.data?.retryInSeconds);
      setIsResendDisabled(true);
      intervalRef.current = setInterval(updateSeconds, 1000);
      dispatch({
        type: GET_EMAIL_DETAILS,
      });
    }
  }, [details, dispatch, updateSeconds]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      setIsResendDisabled(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (sendEmail?.status === statusType.SUCCESSED) {
      dispatch({
        type: SEND_EMAIL,
      });
      dispatch(getEmailDetails({ id, target: 'email' }));
    }
  }, [dispatch, id, sendEmail]);

  useEffect(() => {
    if (id) {
      dispatch(getEmailDetails({ id, target: 'email' }));
    } else {
      dispatch(getSignInDetails(email));
    }
  }, [dispatch, id, email]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageSignUpSuccess} preview={false} />
            </Row>
          </Col>

          <Row gutter={[0, 8]} justify="center">
            <Col span={24}>
              <Text block size={24} height={32} align="center" weight="semibold">
                {t('pages.sign-up-success.you-successfully-signed-up')}
              </Text>
            </Col>
            <Col span={24}>
              <Trans
                i18nKey="pages.sign-up-success.follow-link-in-your-emal"
                values={{ email: account?.email, supportEmail: env.SUPPORT_EMAIL }}
              >
                [<Text color="main-6" />,<Text color="main-6" />]
              </Trans>
            </Col>
            {seconds > 0 && (
              <Col span={24}>
                <Text align="center" color="red-6" block>
                  <Trans
                    i18nKey="common.resend-email-available-in"
                    values={{ time: convertSecondsToHMS(seconds, true) }}
                  >
                    [<Text size={20} weight="semibold" color="red-6" />]
                  </Trans>
                </Text>
              </Col>
            )}
            <Col span={20}>
              <Button
                shape="round"
                type="primary"
                size="large"
                block
                disabled={isResendDisabled}
                onClick={handleClickResend}
              >
                {t('common.resend')}
              </Button>
            </Col>
          </Row>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(SignUpSuccess);
