import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const CompanyVerifyRejected = props => {
  const { date } = props;

  const { t } = useTranslation();

  return (
    <Alert
      type={ALERT_TYPE.WARNING}
      message={t('alert.company-verify-rejected.title')}
      description={t('alert.company-verify-rejected.description', { date })}
    />
  );
};

export default memo(CompanyVerifyRejected);
