import statusType from '../constants/statusType';
import {
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_ERROR,
  ADD_EMPLOYEE_LOADING,
  ADD_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_ERROR,
  EDIT_EMPLOYEE_LOADING,
  EDIT_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_LOADING,
  GET_EMPLOYEE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  add: {
    status: statusType.IDLE,
  },
  edit: {
    status: statusType.IDLE,
  },
  get: {
    status: statusType.IDLE,
  },
};

const employeesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_EMPLOYEE: {
      return { ...state, add: { status: statusType.IDLE } };
    }

    case ADD_EMPLOYEE_ERROR: {
      return { ...state, add: { error: payload, status: statusType.FAILED } };
    }

    case ADD_EMPLOYEE_LOADING: {
      return { ...state, add: { status: statusType.LOADING } };
    }

    case ADD_EMPLOYEE_SUCCESS: {
      return { ...state, add: { data: payload, status: statusType.SUCCESSED } };
    }

    case EDIT_EMPLOYEE: {
      return { ...state, edit: { status: statusType.IDLE } };
    }

    case EDIT_EMPLOYEE_ERROR: {
      return { ...state, edit: { error: payload, status: statusType.FAILED } };
    }

    case EDIT_EMPLOYEE_LOADING: {
      return { ...state, edit: { status: statusType.LOADING } };
    }

    case EDIT_EMPLOYEE_SUCCESS: {
      return { ...state, edit: { data: payload, status: statusType.SUCCESSED } };
    }

    case GET_EMPLOYEE_ERROR: {
      return { ...state, get: { error: payload, status: statusType.FAILED } };
    }

    case GET_EMPLOYEE_LOADING: {
      return { ...state, get: { status: statusType.LOADING } };
    }

    case GET_EMPLOYEE_SUCCESS: {
      return { ...state, get: { data: payload, status: statusType.SUCCESSED } };
    }
  }

  return state;
};

export default employeesReducer;
