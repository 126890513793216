export const ALERTS = {
  CompanyAddNeeded: 'CompanyAddNeeded',
  CompanyAddSuccessed: 'CompanyAddSuccessed',
  CompanyArchived: 'CompanyArchived',
  CompanyBlockedDueContract: 'CompanyBlockedDueContract',
  CompanyReworkAvailable: 'CompanyReworkAvailable',
  CompanyUpdateNeeded: 'CompanyUpdateNeeded',
  CompanyVerifyNeeded: 'CompanyVerifyNeeded',
  CompanyVerifyRejected: 'CompanyVerifyRejected',
  CompanyVerifySuccessed: 'CompanyVerifySuccessed',
  ContractAgreeNeeded: 'ContractAgreeNeeded',
  ContractSignNeeded: 'ContractSignNeeded',
  WaitForVerification: 'WaitForVerification',
};

export { default } from './Alerts';
