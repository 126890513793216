import { Input } from 'antd';

import Number from './Number';
import PasswordConfirm from './PasswordConfirm';
import PasswordStrength from './PasswordStrength';
import Phone from './Phone';

Input.Number = Number;
Input.PasswordConfirm = PasswordConfirm;
Input.PasswordStrength = PasswordStrength;
Input.Phone = Phone;

export default Input;
