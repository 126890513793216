import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import classes from './Input.module.scss';

const Number = props => {
  const { onChange, min = 0, max = Infinity, value: initialValue = 1 } = props;

  const [value, setValue] = useState(initialValue);

  const updateValue = useCallback(
    v => {
      setValue(v);
      if (onChange) {
        onChange(v);
      }
    },
    [onChange]
  );

  const handleChange = useCallback(
    event => {
      updateValue(parseInt(event.target.value || min));
    },
    [min, updateValue]
  );

  const handleClickMinus = useCallback(() => {
    if (value > min) {
      updateValue(value - 1);
    }
  }, [min, updateValue, value]);

  const handleClickPlus = useCallback(() => {
    if (value < max) {
      updateValue(value + 1);
    }
  }, [max, updateValue, value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value < min) {
      setValue(min);
    }
  }, [min, value]);

  return (
    <Row className={classes.group}>
      <Button icon={<MinusOutlined />} size="large" className={classes.button} onClick={handleClickMinus} />
      <Col>
        <Input type="number" className={classes.input} value={value} onChange={handleChange} />
      </Col>
      <Button icon={<PlusOutlined />} size="large" className={classes.button} onClick={handleClickPlus} />
    </Row>
  );
};

export default memo(Number);
