import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Divider, Input, Row, Typography } from 'antd';

import * as fieldRules from '@/constants/fieldRules';
import { formatPhone } from '@/utils/format';

import FormItem from '../FormItem';

const ContactFields = props => {
  const { children } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 22]}>
      <Col span={24}>
        <Divider orientation="left">{t('pages.company-fill.ceo-info')}</Divider>
        <FormItem label={t('common.ceo.last-name')} name={['contacts', 'ceo', 'lastName']}>
          <Input placeholder={t('placeholder.lastName')} />
        </FormItem>
        <FormItem label={t('common.ceo.first-name')} name={['contacts', 'ceo', 'firstName']}>
          <Input placeholder={t('placeholder.firstName')} />
        </FormItem>
        <FormItem label={t('common.ceo.middle-name')} name={['contacts', 'ceo', 'middleName']}>
          <Input placeholder={t('placeholder.middleName')} />
        </FormItem>
        <FormItem name={['contacts', 'ceo', 'position']} initialValue="ceo" hidden noStyle>
          <Input />
        </FormItem>
        <FormItem label={t('common.email')} name={['contacts', 'ceo', 'email']} rules={[...fieldRules.email]}>
          <Input placeholder={t('placeholder.email')} type="email" />
        </FormItem>
        <FormItem
          label={t('common.phone')}
          name={['contacts', 'ceo', 'phone']}
          rules={fieldRules.phone(10)}
          normalize={formatPhone}
        >
          <Input.Phone placeholder={t('pages.company-fill.placeholder.phone')} />
        </FormItem>
        <FormItem
          label=" "
          colon={false}
          style={{ marginBottom: 0 }}
          name={['contacts', 'ceo', 'contactPerson']}
          initialValue
          valuePropName="checked"
        >
          <Checkbox>{t('pages.company-fill.ceo-is-contact-person')}</Checkbox>
        </FormItem>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const ceoPersonData = getFieldValue(['contacts', 'ceo']);
            const isContactPerson = ceoPersonData?.contactPerson;
            return (
              isContactPerson && (
                <FormItem label=" " colon={false}>
                  <Typography.Text type="secondary">
                    {t('pages.company-fill.contact-person-will-receive-messages')}
                  </Typography.Text>
                </FormItem>
              )
            );
          }}
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const ceoPersonData = getFieldValue(['contacts', 'ceo']);
            const isContactPerson = ceoPersonData?.contactPerson;
            return (
              isContactPerson !== true && (
                <>
                  <Divider orientation="left">{t('pages.company.contact-person')}</Divider>
                  {ceoPersonData && (
                    <FormItem noStyle name={['contacts', 'contact', 'contactPerson']} initialValue={!isContactPerson} />
                  )}
                  <FormItem label={t('common.contact-person.last-name')} name={['contacts', 'contact', 'lastName']}>
                    <Input placeholder={t('placeholder.lastName')} />
                  </FormItem>
                  <FormItem label={t('common.contact-person.first-name')} name={['contacts', 'contact', 'firstName']}>
                    <Input placeholder={t('placeholder.firstName')} />
                  </FormItem>
                  <FormItem label={t('common.contact-person.middle-name')} name={['contacts', 'contact', 'middleName']}>
                    <Input placeholder={t('placeholder.middleName')} />
                  </FormItem>
                  <FormItem label={t('common.contact-person.position')} name={['contacts', 'contact', 'position']}>
                    <Input placeholder={t('placeholder.position')} />
                  </FormItem>
                  <FormItem
                    label={t('common.email')}
                    name={['contacts', 'contact', 'email']}
                    rules={[...fieldRules.email, ...fieldRules.required]}
                  >
                    <Input placeholder={t('placeholder.email')} type="email" />
                  </FormItem>
                  <FormItem label=" " colon={false}>
                    <Typography.Text type="secondary">
                      {t('pages.company-fill.contact-person-will-receive-messages')}
                    </Typography.Text>
                  </FormItem>
                  <FormItem
                    label={t('common.phone')}
                    name={['contacts', 'contact', 'phone']}
                    rules={fieldRules.phone(10)}
                    normalize={formatPhone}
                  >
                    <Input.Phone placeholder={t('pages.company-fill.placeholder.phone')} />
                  </FormItem>
                </>
              )
            );
          }}
        </FormItem>
      </Col>
      {children}
    </Row>
  );
};

export default memo(ContactFields);
