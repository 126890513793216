import React, { memo, useCallback } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Row, Typography } from 'antd';

import { EmployeeAutoComplete, PlaceAutoComplete, Select } from '@/components';
import { TENDER_STATUS, TENDER_TYPE, tenderStatusOptions } from '@/constants/tender';

const FilterPersonInCharge = props => {
  const { t } = useTranslation();
  const { onChange, value, children } = props;
  return (
    <Row align="middle" gutter={[0, 12]}>
      <Col span={10}>
        <Typography.Text type="secondary">{t('pages.tenders.person-in-charge')}:</Typography.Text>
      </Col>
      <Col span={14}>
        <EmployeeAutoComplete onChange={onChange} showSearch={false} size="middle" showClear value={value} />
      </Col>
      {children}
    </Row>
  );
};

const FilterStatus = props => {
  const { t } = useTranslation();
  const { onChange, options, value } = props;
  return (
    <Row align="middle">
      <Col span={10}>
        <Typography.Text type="secondary">{t('pages.tender.status')}:</Typography.Text>
      </Col>
      <Col span={14}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          shape="round"
          size="large"
          showArrow
          value={value}
          options={options}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

const FilterRoute = props => {
  const { t } = useTranslation();
  const { onChangeStart, onChangeFinish, valueStart, valueFinish } = props;
  return (
    <Row align="middle">
      <Col span={6}>
        <Typography.Text type="secondary">{t('pages.tenders.tender-route')}:</Typography.Text>
      </Col>
      <Col span={18}>
        <Row align="middle" justify="space-between">
          <Col span={11}>
            <PlaceAutoComplete
              placeholder={t('pages.tender.route-start')}
              prefixIcon={false}
              onChange={onChangeStart}
              value={valueStart}
            />
          </Col>
          <ArrowRightOutlined />
          <Col span={11}>
            <PlaceAutoComplete
              placeholder={t('pages.tender.route-finish')}
              prefixIcon={false}
              onChange={onChangeFinish}
              value={valueFinish}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const FilterType = props => {
  const { t } = useTranslation();
  const { onChange, value } = props;
  return (
    <Row align="middle">
      <Col span={6}>
        <Typography.Text type="secondary">{t('pages.tenders.tender-type')}:</Typography.Text>
      </Col>
      <Col span={18}>
        <Checkbox.Group onChange={onChange} value={value}>
          <Checkbox key="tender-type.inland" value={TENDER_TYPE.INLAND}>
            {t(`pages.tenders.tender-type.${TENDER_TYPE.INLAND}`)}
          </Checkbox>
          <Checkbox key="tender-type.foreign" value={TENDER_TYPE.FOREIGN}>
            {t(`pages.tenders.tender-type.${TENDER_TYPE.FOREIGN}`)}
          </Checkbox>
        </Checkbox.Group>
      </Col>
    </Row>
  );
};

const FilterSubStatus = props => {
  const { t } = useTranslation();
  const { onChange, value } = props;
  return (
    <Row align="stretch">
      <Col span={6}>
        <Typography.Text type="secondary">{t('pages.tenders.tender-status')}:</Typography.Text>
      </Col>
      <Col span={18}>
        <Checkbox.Group onChange={onChange} value={value}>
          <Checkbox key="tender-status.closes" value={TENDER_STATUS.CLOSED}>
            {t(`common.tender.status.${TENDER_STATUS.CLOSED}`)}
          </Checkbox>
          <Checkbox key="tender-status.canceled" value={TENDER_STATUS.CANCELED}>
            {t(`common.tender.status.${TENDER_STATUS.CANCELED}`)}
          </Checkbox>
        </Checkbox.Group>
      </Col>
    </Row>
  );
};

const FilterCreator = props => {
  const { t } = useTranslation();
  const { onChange, value } = props;
  return (
    <Row align="middle">
      <Col span={10}>
        <Typography.Text type="secondary">{t('pages.tenders.tender-creator')}:</Typography.Text>
      </Col>
      <Col span={14}>
        <EmployeeAutoComplete onChange={onChange} showSearch={false} size="middle" showClear value={value} />
      </Col>
    </Row>
  );
};

const TenderFilters = props => {
  const { t } = useTranslation();
  const {
    onChange,
    options,
    withStatusFilter,
    withUnAssignee,
    withType = true,
    withCreator = true,
    withResponsible = true,
    withSubStatus = false,
    values,
  } = props;

  const handleChangeStatus = useCallback(
    status => {
      if (onChange) {
        onChange('tenderStatuses', status?.length > 0 ? status : null);
      }
    },
    [onChange]
  );

  const handleChangeSubStatus = useCallback(
    status => {
      if (onChange) {
        onChange('subStatuses', status?.length > 0 ? status : null);
      }
    },
    [onChange]
  );

  const handleChangeType = useCallback(
    type => {
      if (onChange) {
        onChange('isTenderInternal', type?.length > 0 ? type : null);
      }
    },
    [onChange]
  );

  const handleChangeRouteStart = useCallback(
    route => {
      if (onChange) {
        onChange('from', route);
      }
    },
    [onChange]
  );

  const handleChangeRouteFinish = useCallback(
    route => {
      if (onChange) {
        onChange('destination', route);
      }
    },
    [onChange]
  );

  const handleChangeResponsible = useCallback(
    value => {
      if (onChange) {
        onChange('tenderResponsibleId', value);
      }
    },
    [onChange]
  );

  const handleChangeNoResponsible = useCallback(
    e => {
      if (onChange) {
        onChange('noResponsibleAssigned', e.target.checked);
      }
    },
    [onChange]
  );

  const handleChangeCreator = useCallback(
    value => {
      if (onChange) {
        onChange('tenderCreatorId', value);
      }
    },
    [onChange]
  );

  return (
    <Row gutter={[32, 0]} align="top">
      {(withCreator || withResponsible || withUnAssignee || withStatusFilter) && (
        <Col span={12}>
          <Row gutter={[0, 16]}>
            {withCreator && (
              <Col span={24}>
                <FilterCreator onChange={handleChangeCreator} value={values?.tenderCreatorId} />
              </Col>
            )}
            {withResponsible && (
              <Col span={24}>
                <FilterPersonInCharge onChange={handleChangeResponsible} value={values?.tenderResponsibleId}>
                  {withUnAssignee && (
                    <Col offset={10}>
                      <Checkbox value="noResponsibleAssigned" onChange={handleChangeNoResponsible}>
                        {t('pages.tenders.without-person-in-charge')}
                      </Checkbox>
                    </Col>
                  )}
                </FilterPersonInCharge>
              </Col>
            )}
            {withStatusFilter && (
              <Col span={24}>
                <FilterStatus
                  onChange={handleChangeStatus}
                  options={options?.status || tenderStatusOptions}
                  value={values?.tenderStatuses}
                />
              </Col>
            )}
          </Row>
        </Col>
      )}
      <Col span={12}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <FilterRoute
              onChangeStart={handleChangeRouteStart}
              onChangeFinish={handleChangeRouteFinish}
              valueStart={values?.from}
              valueFinish={values?.destination}
            />
          </Col>
          {withType && (
            <Col span={24}>
              <FilterType onChange={handleChangeType} value={values?.isTenderInternal} />
            </Col>
          )}
        </Row>
      </Col>
      {withSubStatus && (
        <Col span={12}>
          <FilterSubStatus onChange={handleChangeSubStatus} value={values?.subStatuses} />
        </Col>
      )}
    </Row>
  );
};

export default memo(TenderFilters);
