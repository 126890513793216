import statusType from '../constants/statusType';
import {
  EDIT_TECHNO_COMPANY,
  EDIT_TECHNO_COMPANY_ERROR,
  EDIT_TECHNO_COMPANY_LOADING,
  EDIT_TECHNO_COMPANY_SUCCESS,
  GET_TECHNO_COMPANY,
  GET_TECHNO_COMPANY_ERROR,
  GET_TECHNO_COMPANY_LOADING,
  GET_TECHNO_COMPANY_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  edit: {
    status: statusType.IDLE,
  },
  get: {
    status: statusType.IDLE,
  },
};

const technoCompanyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_TECHNO_COMPANY: {
      return {
        ...state,
        edit: {
          status: statusType.IDLE,
        },
      };
    }

    case EDIT_TECHNO_COMPANY_ERROR: {
      return {
        ...state,
        edit: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case EDIT_TECHNO_COMPANY_LOADING: {
      return { ...state, edit: { status: statusType.LOADING } };
    }

    case EDIT_TECHNO_COMPANY_SUCCESS: {
      return {
        ...state,
        edit: {
          status: statusType.SUCCESSED,
        },
      };
    }

    case GET_TECHNO_COMPANY: {
      return {
        ...state,
        get: {
          status: statusType.IDLE,
        },
      };
    }

    case GET_TECHNO_COMPANY_ERROR: {
      return {
        ...state,
        get: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case GET_TECHNO_COMPANY_LOADING: {
      return { ...state, get: { status: statusType.LOADING } };
    }

    case GET_TECHNO_COMPANY_SUCCESS: {
      return {
        ...state,
        get: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    default:
      return state;
  }
};

export default technoCompanyReducer;
