import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getTenderActivity, getTenderDetails, getTenderResults } from '@/store/actions/tenderActions';
import { TENDER_DETAILS, TENDER_STATUS } from '@/constants/tender';

import TenderActivity from '../TenderActivity';
import TenderMembers from '../TenderMembers';
import TenderResult from '../TenderResult';

const { TabPane } = Tabs;

const TenderDetails = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tender, tabs = [TENDER_DETAILS.ACTIVITY, TENDER_DETAILS.MEMBERS, TENDER_DETAILS.RESULT] } = props;

  const activityAvailable = useMemo(() => tabs?.includes(TENDER_DETAILS.ACTIVITY), [tabs]);
  const membersAvailable = useMemo(() => tabs?.includes(TENDER_DETAILS.MEMBERS), [tabs]);
  const resultAvailable = useMemo(() => tabs?.includes(TENDER_DETAILS.RESULT), [tabs]);

  const resultEnabled = useMemo(() => {
    const status = tender?.conditions?.status;
    return status === TENDER_STATUS.CLOSED || status === TENDER_STATUS.READY || status === TENDER_STATUS.COMPLETED;
  }, [tender?.conditions?.status]);

  const {
    activity: { data: activity } = {},
    details: { data: details } = {},
    results: { data: results } = {},
  } = useSelector(state => state.tender);

  const handleChangeTab = useCallback(
    key => {
      switch (key) {
        case TENDER_DETAILS.ACTIVITY:
          dispatch(getTenderActivity(tender?.id));
          break;

        case TENDER_DETAILS.MEMBERS:
          dispatch(getTenderDetails(tender?.id));
          break;

        case TENDER_DETAILS.RESULT:
          dispatch(getTenderResults(tender?.id));
          break;
      }
    },
    [dispatch, tender]
  );

  useEffect(() => {
    if (tender?.id) {
      dispatch(getTenderActivity(tender?.id));
    }
  }, [dispatch, tender?.id]);

  return (
    <Tabs onChange={handleChangeTab}>
      {activityAvailable && (
        <TabPane tab={t('common.activity')} key={TENDER_DETAILS.ACTIVITY}>
          <TenderActivity activity={activity} currency={tender?.bid?.currency} />
        </TabPane>
      )}
      {membersAvailable && (
        <TabPane tab={t('common.members-and-bids')} key={TENDER_DETAILS.MEMBERS}>
          <TenderMembers details={details?.map(detail => ({ ...detail, currency: tender?.bid?.currency }))} />
        </TabPane>
      )}
      {resultAvailable && (
        <TabPane tab={t('common.results')} key={TENDER_DETAILS.RESULT} disabled={!resultEnabled}>
          <TenderResult results={results} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default memo(TenderDetails);
