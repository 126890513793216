/* eslint-disable react/display-name */
/* eslint-disable sort-keys */
import React, { memo, useCallback, useEffect, useState } from 'react';

import { BaseContent } from '@/containers/content';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Typography } from 'antd';
import Icon, { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardLayout } from '@/containers/layout';
import { EMPLOYEE_STATUS } from '@/constants/employees';
import { MODALS } from '@/views/modals';
import statusType from '@/store/constants/statusType';
import { Table } from '@/components';
import { ADD_EMPLOYEE, EDIT_EMPLOYEE } from '@/store/constants/actionTypes';
import { addEmployee, editEmployee, getEmployees } from '@/store/actions/employeesActions';
import { hideModal, showModal } from '@/store/actions/modalsActions';

import { ReactComponent as IconEdit } from '@/assets/icons/add-edit.svg';

import colors from '@/styles/colors.module.scss';

import classes from './Employees.module.scss';

const Text = props => {
  const { disabled, children } = props;
  return <Typography.Text style={{ opacity: disabled && 0.5 }}>{children}</Typography.Text>;
};

const columns = [
  {
    key: 'fullName',
    title: i18n.t('common.full-name'),
    render: ({ status, fullName }) => {
      return (
        <>
          {fullName}
          {status === EMPLOYEE_STATUS.BLOCKED && (
            <Typography.Paragraph style={{ color: 'red' }}>
              {i18n.t(`common.employee.status.${status?.toLocaleLowerCase()}`)}
            </Typography.Paragraph>
          )}
        </>
      );
    },
    defaultSortOrder: 'ascend',
    sorter: (emplA, emplB) => emplA?.lastName?.localeCompare(emplB?.lastName),
  },
  {
    dataIndex: 'role',
    key: 'role',
    title: i18n.t('common.role'),
    render: (role, { status, admin }) => (
      <Text disabled={status === EMPLOYEE_STATUS.BLOCKED}>
        {i18n.t(`common.department.${role?.toLocaleLowerCase()}`)} {admin && i18n.t('common.(admin)')}
      </Text>
    ),
  },
  {
    dataIndex: 'position',
    key: 'position',
    title: i18n.t('common.position'),
    render: (position, { status }) => <Text disabled={status === EMPLOYEE_STATUS.BLOCKED}>{position}</Text>,
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: i18n.t('common.email'),
    render: (email, { status }) => <Text disabled={status === EMPLOYEE_STATUS.BLOCKED}>{email}</Text>,
  },
  {
    dataIndex: 'phone',
    key: 'phone',
    title: i18n.t('common.phone'),
    render: (phone, { status }) => <Text disabled={status === EMPLOYEE_STATUS.BLOCKED}>{phone}</Text>,
  },
  {
    dataIndex: 'status',
    key: 'edit',
    render: status => (
      <Row justify="end">
        <Button
          type="link"
          icon={
            status === EMPLOYEE_STATUS.BLOCKED ? (
              <Icon component={IconEdit} style={{ color: colors.geekBlue6 }} />
            ) : (
              <EditOutlined style={{ color: colors.geekBlue6 }} />
            )
          }
        />
      </Row>
    ),
  },
];

const Employees = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { add: { status: addStatus } = {}, edit: { status: editStatus } = {} } = useSelector(state => state.employee);
  const { data: employees } = useSelector(state => state.employees);
  const [pageSize, setPageSize] = useState(10);

  const handleChangePageSize = useCallback((page, size) => {
    setPageSize(size);
  }, []);

  const handleAddEmployee = useCallback(
    employee => {
      dispatch(addEmployee(employee));
    },
    [dispatch]
  );

  const handleEditEmployee = useCallback(
    employee => {
      dispatch(editEmployee(employee));
    },
    [dispatch]
  );

  const handleClickAddEmployee = useCallback(() => {
    dispatch(showModal({ modal: MODALS.AddEmployee, onAdd: handleAddEmployee }));
  }, [dispatch, handleAddEmployee]);

  const handleClickEditEmployee = useCallback(
    employee => {
      dispatch(showModal({ employee, modal: MODALS.EditEmployee, onEdit: handleEditEmployee }));
    },
    [dispatch, handleEditEmployee]
  );

  const getRowColor = useCallback(({ status }, index) => {
    return status !== EMPLOYEE_STATUS.ACTIVE && classes.blocked;
  }, []);

  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (addStatus === statusType.SUCCESSED) {
      dispatch({
        type: ADD_EMPLOYEE,
      });
      dispatch(hideModal(MODALS.AddEmployee));
      dispatch(getEmployees());
    }
  }, [dispatch, addStatus]);

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      dispatch({
        type: EDIT_EMPLOYEE,
      });
      dispatch(getEmployees());
    }
  }, [dispatch, editStatus]);

  return (
    <DashboardLayout>
      <BaseContent
        contentSpan={24}
        header={
          <Row justify="space-between">
            <Typography.Title level={4}>{t('pages.employees.employees')}</Typography.Title>
            <Button shape="round" type="primary" onClick={handleClickAddEmployee}>
              {t('pages.employees.add-employee')}
            </Button>
          </Row>
        }
      >
        <Col span={24}>
          <Table
            columns={columns}
            data={employees}
            className={classes.table}
            onRowClick={handleClickEditEmployee}
            pagination={{
              onChange: handleChangePageSize,
              pageSize,
            }}
            rowClassName={getRowColor}
            loading={employees?.status === statusType.LOADING}
            tableLayout="fixed"
          />
        </Col>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(Employees);
