import React, { memo, useCallback, useEffect } from 'react';

import { Upload as AntUpload } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import statusType from '@/store/constants/statusType';
import { uploadFile } from '@/store/actions/resourceActions';

const customRequest = () => Promise.resolve();

const Upload = props => {
  const { accept, children, name, onChange, onUpload, onProgress, showUploadList = false, ...rest } = props;
  const dispatch = useDispatch();

  const uploadState = useSelector(state => state.upload[name]);

  const handleUploadComplete = useCallback(
    file => {
      if (onUpload) {
        onUpload(file);
      }
    },
    [onUpload]
  );

  const handleAction = useCallback(
    file => {
      dispatch(uploadFile(name, file, handleUploadComplete));
    },
    [dispatch, handleUploadComplete, name]
  );

  useEffect(() => {
    if (onProgress && uploadState?.status === statusType.LOADING) {
      onProgress({ progress: uploadState?.progress, status: uploadState?.status });
    }
  }, [onProgress, uploadState]);

  return (
    <AntUpload
      accept={accept}
      action={handleAction}
      customRequest={customRequest}
      showUploadList={showUploadList}
      onChange={onChange}
      {...rest}
    >
      {children}
    </AntUpload>
  );
};

Upload.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onProgress: PropTypes.func,
  onUpload: PropTypes.func,
  showUploadList: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
};

export default memo(Upload);
