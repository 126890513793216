import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Col, Image, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import imageEmailSent from '@/assets/images/email.png';

import { convertSecondsToHMS } from '@/utils/timeUtils';
import Modal from '@/views/modals/Modal';
import statusType from '@/store/constants/statusType';
import { Text } from '@/components';
import { getRecoverPasswordDetails, recoverPassword } from '@/store/actions/passwordActions';
import { RECOVER_PASSWORD, RECOVER_PASSWORD_DETAILS } from '@/store/constants/actionTypes';

import errorCodes from '@/constants/errorCodes';
import { MODALS } from '..';

import classes from './RecoverPasswordSuccess.module.scss';

const RecoverPasswordSuccess = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const { email, onClose } = props;

  const { error, status, details } = useSelector(state => state.passwordReset);

  const intervalRef = useRef();
  const [seconds, setSeconds] = useState();

  const handleClickResend = useCallback(() => {
    dispatch(recoverPassword(email));
    setIsResendDisabled(true);
  }, [dispatch, email]);

  const handleClose = useCallback(() => {
    clearInterval(intervalRef.current);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => s - 1);
  }, []);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED && details?.data?.retryInSeconds) {
      setIsResendDisabled(true);
      clearInterval(intervalRef.current);
      setSeconds(details.data.retryInSeconds);
      dispatch({ type: RECOVER_PASSWORD_DETAILS });
      intervalRef.current = setInterval(updateSeconds, 1000);
    } else if (details?.status === statusType.FAILED) {
      setIsResendDisabled(false);
      clearInterval(intervalRef.current);
      dispatch({ type: RECOVER_PASSWORD_DETAILS });
    }
  }, [details, dispatch, updateSeconds]);

  useEffect(() => {
    if (status === statusType.SUCCESSED || error?.code === errorCodes.RETRY_SEND_EMAIL_AFTER) {
      dispatch({ type: RECOVER_PASSWORD });
      dispatch(getRecoverPasswordDetails(email));
    }
  }, [dispatch, email, status, error]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      setIsResendDisabled(false);
    }
  }, [intervalRef, seconds]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  return (
    <Modal
      name={MODALS.RecoverPasswordSuccess}
      className={classes.modal}
      onClose={handleClose}
      width={600}
      bodyStyle={{
        padding: '48px 86px 64px',
        whiteSpace: 'break-spaces',
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row justify="center">
            <Image src={imageEmailSent} preview={false} />
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text block size={24} height={32} align="center" weight="semibold">
                {t('common.forgot-password?')}
              </Text>
            </Col>
            <Col span={24}>
              <Text block size={16} height={24} align="center">
                <Trans i18nKey="pages.password-reset.we-sent-email" values={{ email }}>
                  [<Text color="main-6" />]
                </Trans>
              </Text>
            </Col>
            {seconds > 0 && (
              <Col span={24}>
                <Text align="center" block>
                  <Trans
                    i18nKey="common.resend-email-available-in"
                    values={{ time: convertSecondsToHMS(seconds, true) }}
                  >
                    [<Typography.Title level={4} />]
                  </Trans>
                </Text>
              </Col>
            )}
            <Col span={24}>
              <Button
                type="primary"
                shape="round"
                block
                size="large"
                disabled={isResendDisabled}
                onClick={handleClickResend}
              >
                {t('common.resend')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

RecoverPasswordSuccess.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default memo(RecoverPasswordSuccess);
