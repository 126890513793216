import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import CompanyAddNeeded from './CompanyAddNeeded';
import CompanyAddSuccessed from './CompanyAddSuccessed';
import CompanyArchived from './CompanyArchived';
import CompanyBlockedDueContract from './CompanyBlockedDueContract';
import CompanyReworkAvailable from './CompanyReworkAvailable';
import CompanyUpdateNeeded from './CompanyUpdateNeeded';
import CompanyVerifyNeeded from './CompanyVerifyNeeded';
import CompanyVerifyRejected from './CompanyVerifyRejected';
import CompanyVerifySuccessed from './CompanyVerifySuccessed';
import ContractAgreeNeeded from './ContractAgreeNeeded';
import ContractSignNeeded from './ContractSignNeeded';
import WaitForVerification from './WaitForVerification';

const Alerts = () => {
  const alerts = useSelector(state => state.alerts);
  return (
    <>
      {alerts.CompanyAddNeeded && <CompanyAddNeeded {...alerts.CompanyAddNeeded} />}
      {alerts.CompanyAddSuccessed && <CompanyAddSuccessed {...alerts.CompanyAddSuccessed} />}
      {alerts.CompanyArchived && <CompanyArchived {...alerts.CompanyArchived} />}
      {alerts.CompanyBlockedDueContract && <CompanyBlockedDueContract {...alerts.CompanyBlockedDueContract} />}
      {alerts.CompanyReworkAvailable && <CompanyReworkAvailable {...alerts.CompanyReworkAvailable} />}
      {alerts.CompanyUpdateNeeded && <CompanyUpdateNeeded {...alerts.CompanyUpdateNeeded} />}
      {alerts.CompanyVerifyNeeded && <CompanyVerifyNeeded {...alerts.CompanyVerifyNeeded} />}
      {alerts.CompanyVerifyRejected && <CompanyVerifyRejected {...alerts.CompanyVerifyRejected} />}
      {alerts.CompanyVerifySuccessed && <CompanyVerifySuccessed {...alerts.CompanyVerifySuccessed} />}
      {alerts.ContractAgreeNeeded && <ContractAgreeNeeded {...alerts.ContractAgreeNeeded} />}
      {alerts.ContractSignNeeded && <ContractSignNeeded {...alerts.ContractSignNeeded} />}
      {alerts.WaitForVerification && <WaitForVerification {...alerts.WaitForVerification} />}
    </>
  );
};

export default memo(Alerts);
