import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';

import Modal from '../Modal';
import { MODALS } from '..';

const ConfirmInitialBid = props => {
  const { onOk, date, bid } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmInitialBid));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    if (onOk) {
      onOk(bid);
      dispatch(hideModal(MODALS.ConfirmInitialBid));
    }
  }, [dispatch, onOk, bid]);

  return (
    <Modal name={MODALS.ConfirmInitialBid} width={452}>
      <Row gutter={[0, 24]} justify="center">
        <Row>
          <Typography.Title level={3}>{t('modal.confirm-initial-bid.title')}</Typography.Title>
        </Row>
        <Row>
          <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
            {t('modal.confirm-initial-bid.description', { bid, date })}
          </Typography.Text>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Space size="middle">
              <Button shape="round" size="large" onClick={handleClickClose}>
                {t('common.cancel')}
              </Button>
              <Button type="primary" shape="round" size="large" onClick={handleClickOk}>
                {t('common.apply')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(ConfirmInitialBid);
