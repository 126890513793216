import { HIDE_ALERT, SHOW_ALERT } from '../constants/actionTypes';

const showAlert = (alert, props) => async dispatch => {
  dispatch({
    payload: { alert, props },
    type: SHOW_ALERT,
  });
};

const hideAlert = alert => async dispatch => {
  dispatch({
    payload: { alert },
    type: HIDE_ALERT,
  });
};

export { showAlert, hideAlert };
