import statusType from '../constants/statusType';
import {
  GET_EMAIL_DETAILS,
  GET_EMAIL_DETAILS_ERROR,
  GET_EMAIL_DETAILS_LOADING,
  GET_EMAIL_DETAILS_SUCCESS,
  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const sendEmailReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAIL_DETAILS:
    case GET_EMAIL_DETAILS_ERROR:
    case GET_EMAIL_DETAILS_LOADING:
      return { ...state, details: null };

    case GET_EMAIL_DETAILS_SUCCESS: {
      const { secondsTillNextAttempt } = payload;
      return { ...state, details: { retryInSeconds: secondsTillNextAttempt, status: statusType.SUCCESSED } };
    }

    case SEND_EMAIL:
      return { ...state, status: statusType.IDLE };

    case SEND_EMAIL_LOADING:
      return { ...state, status: statusType.LOADING };

    case SEND_EMAIL_SUCCESS: {
      return { ...state, status: statusType.SUCCESSED };
    }

    case SEND_EMAIL_ERROR:
      return { ...state, error: payload, status: statusType.FAILED };

    default:
      return state;
  }
};

export default sendEmailReducer;
