import i18n from 'i18next';

export const length = len => [
  {
    len,
    message: i18n.t('error.field-must-contain-length-numbers', { length: len }),
  },
];

export const positive = [
  {
    message: i18n.t('error.field.positive'),
    transform: value => Number(value),
    validator(_, value) {
      if (value < 0) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  },
];

export const max = [
  {
    max: 255,
    message: i18n.t('error.field.max', { max: 255 }),
  },
];

export const numbers = [
  {
    message: i18n.t('error.field.only-numbers'),
    pattern: /^[0-9]+$/,
  },
];

export const required = [
  {
    message: i18n.t('error.field.required'),
    required: true,
  },
];

export const phone = (len = 13) => [
  {
    len,
    message: i18n.t('error.field.incorrect-phone-format'),
    pattern: /\d{3}\s\d{3}\s\d{2}\s\d{2}/g,
  },
];

export const domain = [
  {
    message: i18n.t('error.field.incorrect-domain-format'),
    pattern: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
  },
];

export const policy = [
  {
    message: i18n.t('error.field.policy'),
    required: true,
    validator(_, value) {
      if (value !== true) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  },
];

export const email = [
  {
    message: i18n.t('error.field.incorrect-email'),
    type: 'email',
  },
];

export const input = [...max];
export const password = [...required];
export const bin = [...numbers, ...required, ...length(12)];
