import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import { COMPANY_ACTION } from '@/constants/company';

import { parsePagination } from '@/utils/common';
import { sanitizeObject } from '@/utils/objectUtils';
import { CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import {
  ACTION_COMPANY_ERROR,
  ACTION_COMPANY_LOADING,
  ACTION_COMPANY_SUCCESS,
  ADD_COMPANY_ERROR,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_LOADING,
  EDIT_COMPANY_SUCCESS,
  EDIT_TECHNO_COMPANY_ERROR,
  EDIT_TECHNO_COMPANY_LOADING,
  EDIT_TECHNO_COMPANY_SUCCESS,
  FIND_COMPANIES_ERROR,
  FIND_COMPANIES_LOADING,
  FIND_COMPANIES_SUCCESS,
  FIND_SPECIFIC_COMPANIES_ERROR,
  FIND_SPECIFIC_COMPANIES_LOADING,
  FIND_SPECIFIC_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_LOADING,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_ACTIONS_ERROR,
  GET_COMPANY_ACTIONS_LOADING,
  GET_COMPANY_ACTIONS_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_MY_COMPANY_ERROR,
  GET_MY_COMPANY_LOADING,
  GET_MY_COMPANY_SUCCESS,
  GET_TECHNO_COMPANY_ERROR,
  GET_TECHNO_COMPANY_LOADING,
  GET_TECHNO_COMPANY_SUCCESS,
  SEND_CONTRACT_ERROR,
  SEND_CONTRACT_LOADING,
  SEND_CONTRACT_SUCCESS,
} from '../constants/actionTypes';

export const normalizeCompany = company => {
  const normalized = { ...company };
  if (normalized.addresses) {
    normalized.addresses = normalized.addresses.reduce((acc, curr) => ({ ...acc, [curr.type]: { ...curr } }), {});
  }
  if (normalized.contacts) {
    normalized.contacts = normalized.contacts.reduce((acc, curr) => ({ ...acc, [curr.type]: { ...curr } }), {});
  }
  if (normalized.documents) {
    normalized.documents = normalized.documents.reduce((acc, curr) => ({ ...acc, [curr.type]: { ...curr } }), {});
  }
  if (normalized.agreements) {
    normalized.contracts = {
      [CONTRACT_TYPE.AGREEMENT]: normalized.agreements?.find(({ type }) => type === CONTRACT_TYPE.AGREEMENT),
    };
  }
  return normalized;
};

const normalizeCompanyRequest = company => {
  const data = { ...company };
  Object.keys(data).forEach(key => {
    const sectionData = data[key] || {};

    switch (key) {
      case 'addresses': {
        data[key] = {
          ...data[key],
          addresses: Object.keys(sectionData[key]).map(type => ({ type, ...sectionData[key][type] })),
        };
        break;
      }

      case 'contacts': {
        const contactsKeys = Object.keys(sectionData);
        data[key] = {
          contacts: contactsKeys.map(type => ({ type, ...sectionData[type] })),
        };
        break;
      }

      case 'documents': {
        const documentsKeys = Object.keys(sectionData) || [];
        data[key] = { documents: documentsKeys.map(type => ({ type, ...sectionData[type] })) };
        break;
      }
    }
  });
  return data;
};

const actionCompany = payload => async dispatch => {
  const { action, id, ...rest } = payload;

  dispatch({
    payload: { action },
    type: ACTION_COMPANY_LOADING,
  });

  const { error, status, response } = await apiService.post(apiUrls.COMPANY.ACTION(id), {
    action,
    ...rest,
  });

  if (error) {
    dispatch({
      payload: { action, error: { status, ...error } },
      type: ACTION_COMPANY_ERROR,
    });
  } else {
    dispatch({
      payload: { action, data: response.data },
      type: ACTION_COMPANY_SUCCESS,
    });
  }
};

const agreeTerms = payload => async (dispatch, getState) => {
  const { account } = getState();
  const action = COMPANY_ACTION.AGREE_TO_TERMS;
  dispatch(
    actionCompany({
      action,
      additionalInfo: [
        {
          fieldName: 'contract_text',
          value: payload.text,
        },
      ],
      id: account?.data?.companyId,
    })
  );
};

const activateCompany = id => async dispatch => {
  const action = COMPANY_ACTION.ACTIVATE_AFTER_RULES_VIOLATION;
  dispatch(actionCompany({ action, id }));
};

const findCompany = payload => async dispatch => {
  const { name } = payload;

  dispatch({
    type: FIND_COMPANIES_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.COMPANY.FIND, {
    query: [
      {
        field: 'companyName',
        value: name,
      },
    ],
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: FIND_COMPANIES_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data?.content,
      type: FIND_COMPANIES_SUCCESS,
    });
  }
};

const findCompanies =
  (payload = {}) =>
  async dispatch => {
    const field = 'all';
    dispatch({
      payload: { field },
      type: FIND_SPECIFIC_COMPANIES_LOADING,
    });

    const { error, response, status } = await apiService.post(apiUrls.COMPANY.FIND, payload.data, {
      params: { ...payload?.pagination, ...payload?.sort },
    });

    if (error) {
      dispatch({
        payload: { error: { status, ...error }, field },
        type: FIND_SPECIFIC_COMPANIES_ERROR,
      });
    } else {
      const data = response?.data?.content?.map(company => normalizeCompany(company));
      const pagination = parsePagination(response?.data);
      dispatch({
        payload: { data, field, pagination },
        type: FIND_SPECIFIC_COMPANIES_SUCCESS,
      });
    }
  };

const findCompaniesByStatus = payload => async dispatch => {
  const { data, pagination, sort, status: field } = payload;

  dispatch({
    payload: { field },
    type: FIND_SPECIFIC_COMPANIES_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.COMPANY.FIND, data, {
    params: { ...pagination, ...sort },
  });

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, field },
      type: FIND_SPECIFIC_COMPANIES_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data?.content, field, pagination: parsePagination(response?.data) },
      type: FIND_SPECIFIC_COMPANIES_SUCCESS,
    });
  }
};

const createCompany = payload => async (dispatch, getState) => {
  dispatch({
    type: ADD_COMPANY_LOADING,
  });

  const { account } = getState();
  const { error, response, status } = await apiService.post(apiUrls.COMPANY.ADD, {
    ...payload,
    account: account?.data?.id,
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: ADD_COMPANY_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: ADD_COMPANY_SUCCESS,
    });
  }
};

const editCompany = payload => async dispatch => {
  const { id, data } = payload;

  dispatch({
    payload: { id },
    type: EDIT_COMPANY_LOADING,
  });

  const normalized = normalizeCompanyRequest(data);
  const { error, response, status } = await apiService.put(apiUrls.COMPANY.EDIT(id), normalized);

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, id },
      type: EDIT_COMPANY_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data, id },
      type: EDIT_COMPANY_SUCCESS,
    });
  }
};

const editTechnoCompany = payload => async dispatch => {
  const { id, data } = payload;

  dispatch({
    type: EDIT_TECHNO_COMPANY_LOADING,
  });

  const normalized = normalizeCompanyRequest(data);
  const { error, response, status } = await apiService.put(apiUrls.TECHNO.COMPANY.EDIT(id), normalized);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: EDIT_TECHNO_COMPANY_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data,
      type: EDIT_TECHNO_COMPANY_SUCCESS,
    });
  }
};

const enableEditCompany = id => async dispatch => {
  const action = COMPANY_ACTION.ENABLE_EDIT;
  dispatch(actionCompany({ action, id }));
};

const getCompany = (id, own) => async dispatch => {
  dispatch({
    payload: { id },
    type: GET_COMPANY_LOADING,
  });

  if (own) {
    dispatch({
      type: GET_MY_COMPANY_LOADING,
    });
  }

  const { status, error, response } = await apiService.get(apiUrls.COMPANY.GET(id));

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, id },
      type: GET_COMPANY_ERROR,
    });
    if (own) {
      dispatch({
        payload: { status, ...error },
        type: GET_MY_COMPANY_ERROR,
      });
    }
  } else {
    const company = normalizeCompany(sanitizeObject(response.data));
    dispatch({
      payload: { data: company, id },
      type: GET_COMPANY_SUCCESS,
    });
    if (own) {
      dispatch({
        payload: company,
        type: GET_MY_COMPANY_SUCCESS,
      });
    }
    return company;
  }
  return null;
};

const getCompanyAvailableActions = (id, actions) => async dispatch => {
  dispatch({
    type: GET_COMPANY_ACTIONS_LOADING,
  });

  const { status, error, response } = await apiService.post(apiUrls.COMPANY.ACTIONS_AVAILABLE(id), { actions });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_COMPANY_ACTIONS_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data?.actionsCanBeApplied,
      type: GET_COMPANY_ACTIONS_SUCCESS,
    });
  }
};

const getCompanies = () => async dispatch => {
  dispatch({
    type: GET_COMPANIES_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.COMPANY.LIST);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_COMPANIES_ERROR,
    });
  } else {
    const items = response.data?.map(company => normalizeCompany(company));
    dispatch({
      payload: items,
      type: GET_COMPANIES_SUCCESS,
    });
  }
};

const getTechnoCompany = id => async dispatch => {
  dispatch({
    type: GET_TECHNO_COMPANY_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TECHNO.COMPANY.GET(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TECHNO_COMPANY_ERROR,
    });
  } else {
    const company = normalizeCompany(response.data);
    dispatch({
      payload: company,
      type: GET_TECHNO_COMPANY_SUCCESS,
    });
  }
};

const archiveCompany = id => async dispatch => {
  const action = COMPANY_ACTION.SEND_TO_ARCHIVE;
  dispatch(actionCompany({ action, id }));
};

const blockCompany = payload => async dispatch => {
  const action = COMPANY_ACTION.BLOCK;
  const { id, comment } = payload;
  dispatch(
    actionCompany({
      action,
      ...(comment && {
        additionalInfo: [
          {
            fieldName: 'commentOnStateChange',
            value: comment,
          },
        ],
      }),
      id,
    })
  );
};

const refineCompany = payload => async dispatch => {
  const action = COMPANY_ACTION.SEND_FOR_REWORK;
  const { id, comment } = payload;
  dispatch(
    actionCompany({
      action,
      ...(comment && {
        additionalInfo: [
          {
            fieldName: 'commentOnStateChange',
            value: comment,
          },
        ],
      }),
      id,
    })
  );
};

const rejectCompany = payload => async dispatch => {
  const action = COMPANY_ACTION.REJECT_VERIFICATION;
  const { id, comment } = payload;
  dispatch(
    actionCompany({
      action,
      ...(comment && {
        additionalInfo: [
          {
            fieldName: 'commentOnStateChange',
            value: comment,
          },
        ],
      }),
      id,
    })
  );
};

const sendContract = payload => async dispatch => {
  const { id } = payload;

  dispatch({
    payload: { id },
    type: SEND_CONTRACT_LOADING,
  });

  const { error, status, response } = await apiService.post(apiUrls.COMPANY.CONTRACT(id), {
    id,
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: SEND_CONTRACT_ERROR,
    });
  } else {
    dispatch({
      payload: { ...response?.data, id },
      type: SEND_CONTRACT_SUCCESS,
    });
  }
};

const sendForVerification = id => async dispatch => {
  const action = COMPANY_ACTION.SUBMIT_FOR_VERIFICATION;
  dispatch(actionCompany({ action, id }));
};
const sendForVerificationAfterEdit = id => async dispatch => {
  const action = COMPANY_ACTION.SEND_TO_VERIFICATION_AFTER_EDIT;
  dispatch(actionCompany({ action, id }));
};

const sendForReVerification = id => async dispatch => {
  const action = COMPANY_ACTION.SUBMIT_FOR_RE_VERIFICATION;
  dispatch(actionCompany({ action, id }));
};

const verifyCompany = payload => async dispatch => {
  const action = COMPANY_ACTION.CONFIRM_VERIFICATION;
  const { id } = payload;
  dispatch(actionCompany({ action, id }));
};

const unArchiveCompany = id => async dispatch => {
  const action = COMPANY_ACTION.RESTORE_FROM_ARCHIVE;
  dispatch(actionCompany({ action, id }));
};

const unBlockCompany = payload => async dispatch => {
  const action = COMPANY_ACTION.UN_BLOCK;
  const { id } = payload;
  dispatch(actionCompany({ action, id }));
};

const reworkCompany = id => async dispatch => {
  const action = COMPANY_ACTION.TAKE_IN_REWORK;
  dispatch(actionCompany({ action, id }));
};

export {
  activateCompany,
  agreeTerms,
  archiveCompany,
  blockCompany,
  createCompany,
  editCompany,
  enableEditCompany,
  findCompanies,
  findCompaniesByStatus,
  findCompany,
  getCompanies,
  getCompany,
  getCompanyAvailableActions,
  editTechnoCompany,
  getTechnoCompany,
  rejectCompany,
  refineCompany,
  reworkCompany,
  verifyCompany,
  sendContract,
  sendForVerification,
  sendForReVerification,
  sendForVerificationAfterEdit,
  unArchiveCompany,
  unBlockCompany,
};
