import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_ACTION } from '@/constants/company';
import { hideModal } from '@/store/actions/modalsActions';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';

import imageSuccess from '@/assets/images/create-company-success.png';

import Modal from '../Modal';
import { MODALS } from '..';

export const MODE = {
  FILL: 'fill',
  VERIFY: 'verify',
};

const AddCompanySuccess = props => {
  const { mode = MODE.FILL, onOk, onCancel } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const companyState = useSelector(state => state.company);
  const verifyState = companyState[COMPANY_ACTION.SUBMIT_FOR_VERIFICATION];

  const handleClickCancel = useCallback(() => {
    dispatch(hideModal(MODALS.AddCompanySuccess));
    if (onCancel) {
      onCancel();
    }
  }, [dispatch, onCancel]);

  const handleClickOk = useCallback(() => {
    if (mode === MODE.VERIFY) {
      dispatch(hideModal(MODALS.AddCompanySuccess));
      if (onOk) {
        onOk();
      }
    } else {
      dispatch(hideModal(MODALS.AddCompanySuccess));
      history.push(pageUrls.COMPANY.FILL);
    }
  }, [dispatch, history, mode, onOk]);

  return (
    <Modal name="AddCompanySuccess" width={650} bodyStyle={{ padding: '32px' }}>
      <Row justify="center" gutter={[0, 24]}>
        <Image src={imageSuccess} preview={false} />

        <Col span={20} style={{ textAlign: 'center' }}>
          <Typography.Title level={4}>{t('modal.create-company-success.title')}</Typography.Title>
          <Typography.Text>{t(`modal.create-company-success.description-${mode}`)}</Typography.Text>
        </Col>

        <Row justify="center">
          <Space size="large">
            <Button shape="round" size="large" onClick={handleClickCancel}>
              {t('modal.create-company-success.button.no')}
            </Button>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleClickOk}
              loading={verifyState?.status === statusType.LOADING}
            >
              {t(`modal.create-company-success.button.yes-${mode}`)}
            </Button>
          </Space>
        </Row>
      </Row>
    </Modal>
  );
};

export default memo(AddCompanySuccess);
