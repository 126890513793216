import React, { memo, useState } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Select } from 'antd';

const vehicleList = [
  { label: i18n.t('common.vehicle-type-car'), value: 'car' },
  { label: i18n.t('common.vehicle-type-wagon'), value: 'wagon' },
  { label: i18n.t('common.vehicle-type-container'), value: 'container' },
];

const vehicleType = vehicleList[0].value;

const allCapacityList = {
  car: [
    { label: i18n.t('common.capacity-m3', { capacity: 86 }), value: '86' },
    { label: i18n.t('common.capacity-m3', { capacity: 120 }), value: '120' },
  ],
  container: [
    { label: i18n.t('common.capacity-ft', { capacity: 20 }), value: '20' },
    { label: i18n.t('common.capacity-ft', { capacity: 40 }), value: '40' },
  ],
  wagon: [
    { label: i18n.t('common.capacity-m3', { capacity: 175 }), value: '175' },
    { label: i18n.t('common.capacity-m3', { capacity: 250 }), value: '250' },
  ],
};

const TransportSelector = props => {
  const { FormItemProps, onRemove } = props;
  const { name } = FormItemProps;

  const { t } = useTranslation();

  const [capacityList, setCapacityList] = useState(allCapacityList[vehicleType]);

  return (
    <Row gutter={[8, 0]}>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {({ setFieldsValue, getFieldValue }) => {
            const transports = getFieldValue('transports');
            const vehicle = transports?.[name]?.vehicle;

            if (vehicle) {
              setCapacityList(allCapacityList[vehicle]);
            }

            const onChange = value => {
              transports[name] = transports[name] || {};
              transports[name].vehicle = value;
              transports[name].capacity = null;

              setFieldsValue({ transports });
              setCapacityList(allCapacityList[value]);
            };

            return (
              <Select
                value={vehicle}
                onChange={onChange}
                placeholder={t('pages.company-fill.select-vehicle-type')}
                options={vehicleList}
              />
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {({ setFieldsValue, getFieldValue }) => {
            const transports = getFieldValue('transports');
            const vehicle = transports?.[name]?.vehicle;
            const capacity = transports?.[name]?.capacity;

            const onChange = value => {
              transports[name].capacity = value;
              setFieldsValue({ transports });
            };

            return (
              <Select
                disabled={!vehicle}
                value={capacity}
                onChange={onChange}
                placeholder={t('pages.company-fill.select-vehicle-capacity')}
                options={capacityList}
              />
            );
          }}
        </Form.Item>
        <Button style={{ padding: 0 }} type="link" icon={<CloseCircleOutlined />} onClick={() => onRemove(name)}>
          {t('pages.company-fill.remove-transport')}
        </Button>
      </Col>
    </Row>
  );
};

TransportSelector.propTypes = {
  FormItemProps: PropTypes.shape({}),
  name: PropTypes.string,
  onRemove: PropTypes.func,
};

export default memo(TransportSelector);
