import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tag } from '@/components';
import { tagsColors } from '@/constants/company';

const CompanyStatusTag = props => {
  const { status } = props;
  const { t } = useTranslation();
  return (
    <Tag shape="round" className={`ant-tag-${tagsColors[status]}`}>
      {t(`pages.companies.status.${status}`)}
    </Tag>
  );
};

CompanyStatusTag.propTypes = {
  status: PropTypes.string,
};

export default memo(CompanyStatusTag);
