import React, { memo } from 'react';

import ContactFields from '@/pages/CompanyFill/components/ContactFields';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Input } from 'antd';

import FormItem from '@/pages/CompanyFill/components/FormItem';

const TechnoContactFields = () => {
  const { t } = useTranslation();
  return (
    <ContactFields>
      <Col span={24}>
        <Divider orientation="left">{t('common.logistic-head')}</Divider>
        <FormItem label={t('common.last-name')} name={['contacts', 'head', 'lastName']}>
          <Input placeholder={t('placeholder.lastName')} />
        </FormItem>
        <FormItem label={t('common.first-name')} name={['contacts', 'head', 'firstName']}>
          <Input placeholder={t('placeholder.firstName')} />
        </FormItem>
        <FormItem label={t('common.middle-name')} name={['contacts', 'head', 'middleName']}>
          <Input placeholder={t('placeholder.middleName')} />
        </FormItem>
      </Col>
    </ContactFields>
  );
};

export default memo(TechnoContactFields);
