import React, { memo, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_TECHNO_COMPANY } from '@/store/constants/actionTypes';
import { hideModal } from '@/store/actions/modalsActions';
import statusType from '@/store/constants/statusType';

import Modal from '../Modal';
import { MODALS } from '..';

const ConfirmDeleteDomain = props => {
  const { domain, onOk } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    edit: { status },
  } = useSelector(state => state.technoCompany);

  const handleClickClose = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmDeleteDomain));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    if (onOk) {
      onOk(domain);
    }
  }, [domain, onOk]);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      dispatch({
        type: EDIT_TECHNO_COMPANY,
      });
      dispatch(hideModal(MODALS.ConfirmDeleteDomain));
    }
  }, [dispatch, status]);

  return (
    <Modal name={MODALS.ConfirmDeleteDomain} width={468}>
      <Row gutter={[0, 24]} justify="center">
        <Row>
          <Typography.Title level={3}>{t('modal.delete-domain-confirm.title')}</Typography.Title>
        </Row>
        <Row>
          <Typography.Text>{t('modal.delete-domain-confirm.description')}</Typography.Text>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Space size="middle">
              <Button shape="round" size="large" onClick={handleClickClose}>
                {t('common.cancel')}
              </Button>
              <Button danger shape="round" size="large" onClick={handleClickOk} loading={status === statusType.LOADING}>
                {t('common.delete-domain')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

ConfirmDeleteDomain.propTypes = {
  domain: PropTypes.string,
  onOk: PropTypes.func,
};

export default memo(ConfirmDeleteDomain);
