import { PASSWORD_CHANGE_ERROR, PASSWORD_CHANGE_LOADING, PASSWORD_CHANGE_SUCCESS } from '../constants/actionTypes';

import statusType from '../constants/statusType';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const passwordChangeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PASSWORD_CHANGE_LOADING:
      return { ...state, error: null, status: statusType.LOADING };

    case PASSWORD_CHANGE_SUCCESS:
      return { ...state, error: null, status: statusType.SUCCESSED };

    case PASSWORD_CHANGE_ERROR:
      return { ...state, error: payload, status: statusType.FAILED };
    default:
      return state;
  }
};

export default passwordChangeReducer;
