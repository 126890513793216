import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Space, Typography } from 'antd';

import { BaseLayout } from '@/containers/layout';
import pageUrls from '@/constants/pageUrls';
import { Card, Text } from '@/components';

import imagePasswordChangeSuccess from '@/assets/images/password.png';

import classes from './PasswordChangeUsed.module.scss';

const PasswordChangeSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickChangePassword = useCallback(() => {
    history.push(pageUrls.PASSWORD_RECOVERY);
  }, [history]);

  const handleClickEnter = useCallback(() => {
    history.push(pageUrls.MAIN);
  }, [history]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imagePasswordChangeSuccess} preview={false} />
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="center">
              <Typography.Title level={3}>{t('pages.password-change-used.title')}</Typography.Title>
            </Row>
            <Row justify="center">
              <Typography.Text level={3}>{t('pages.password-change-used.description')}</Typography.Text>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="center">
              <Space size="large">
                <Button size="large" shape="round" onClick={handleClickChangePassword}>
                  <Text align="center">{t('common.change-password')}</Text>
                </Button>
                <Button htmlType="submit" type="primary" size="large" shape="round" onClick={handleClickEnter}>
                  <Text align="center">{t('common.enter')}</Text>
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordChangeSuccess);
