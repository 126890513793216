export default {
  COMPANY_BIN_CONFLICT: 'ERR_COMPANY_BIN_CONFLICT',
  PASSWORD_NOT_MATCH: 'ERR_USR_PASS_NOT_MATCH',
  PASSWORD_NOT_SET: 'ERR_USR_PASS_NOT_SET',
  RETRY_SEND_EMAIL_AFTER: 'ERR_USR_EMAIL_SENDING_PROHIBITED_DUE_TIMEOUT_CONFLICT',
  RETRY_SIGN_IN_AFTER: 'ERR_USR_LOGIN_PROHIBITED_DUE_TIMEOUT_CONFLICT',
  USER_BLOCKED: 'ERR_USR_LOGIN_FROM_RETIRED',
  USER_EMAIL_CONFLICT: 'ERR_USR_EMAIL_CONFLICT',
  USER_EMAIL_NOT_VERIFIED: 'ERR_USR_EMAIL_NOT_VERIFIED',
  USER_NOT_FOUND: 'ERR_USR_EMAIL_NOT_FOUND',
};