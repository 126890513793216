import React, { memo } from 'react';

import { Col, Row } from 'antd';

import { anyFalsy } from '@/utils/arrayUtils';
import { joinKeys } from '@/utils/objectUtils';
import { TagRequired } from '@/components';

const RequiredValue = props => {
  const { fields, joinBy, target, NoData = <TagRequired /> } = props;

  if (fields) {
    return !target ? (
      NoData
    ) : anyFalsy(fields, target) ? (
      <Col>
        <Row>{joinKeys(fields, target, joinBy)}</Row>
        <Row>{NoData}</Row>
      </Col>
    ) : (
      joinKeys(fields, target, joinBy)
    );
  }

  return target || NoData;
};
export default memo(RequiredValue);
