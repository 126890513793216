import statusType from '../constants/statusType';
import {
  FIND_TENDERS_ERROR,
  FIND_TENDERS_LOADING,
  FIND_TENDERS_SUCCESS,
  GET_TENDERS_ERROR,
  GET_TENDERS_LOADING,
  GET_TENDERS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const tendersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FIND_TENDERS_LOADING:
    case GET_TENDERS_LOADING:
      return { status: statusType.LOADING };

    case FIND_TENDERS_SUCCESS:
      return {
        ...payload,
        status: statusType.SUCCESSED,
      };

    case GET_TENDERS_SUCCESS:
      return {
        data: payload,
        status: statusType.SUCCESSED,
      };

    case FIND_TENDERS_ERROR:
    case GET_TENDERS_ERROR:
      return { error: payload, status: statusType.FAILED };

    default:
      return state;
  }
};

export default tendersReducer;
