import React, { memo, useCallback } from 'react';

import i18n from 'i18next';
import { Tabs } from 'antd';

import { Badge } from '@/components';
import { TENDER_GROUP, TENDER_STATUS } from '@/constants/tender';

import colors from '@/styles/colors.module.scss';

const tabsMap = {
  [TENDER_GROUP.ALL]: {
    label: i18n.t('pages.tenders.tabs.all'),
  },
  [TENDER_GROUP.NEW]: {
    label: i18n.t('pages.tenders.tabs.new'),
  },
  [TENDER_GROUP.TRADING]: {
    label: i18n.t('pages.tenders.tabs.trading'),
  },
  [TENDER_GROUP.PENDING]: {
    label: i18n.t('pages.tenders.tabs.pending'),
  },
  [TENDER_GROUP.COMPLETED]: {
    label: i18n.t('pages.tenders.tabs.completed'),
  },
  [TENDER_STATUS.DRAFT]: {
    label: i18n.t('pages.tenders.tabs.draft'),
  },
  [TENDER_STATUS.ACCEPTING]: {
    label: i18n.t('pages.tenders.tabs.accepting'),
  },
  [TENDER_STATUS.TRADING]: {
    label: i18n.t('pages.tenders.tabs.trading'),
  },
  [TENDER_STATUS.CLOSED]: {
    label: i18n.t('pages.tenders.tabs.closed'),
  },
};

const TenderTabs = props => {
  const { tab, onChange, total, tabs } = props;

  const handleChangeTab = useCallback(
    value => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Tabs activeKey={tab} onChange={handleChangeTab}>
      {tabs?.map(key => {
        const { label } = tabsMap[key];
        return (
          <Tabs.TabPane
            tab={
              <>
                {label}
                {tab === key && <Badge square count={total} color={colors.geekBlue6} />}
              </>
            }
            key={key}
          />
        );
      })}
    </Tabs>
  );
};

export default memo(TenderTabs);
