import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';

import errorCodes from '@/constants/errorCodes';
import { getEmailDetails } from './emailDetailsActions';
import { SEND_EMAIL_ERROR, SEND_EMAIL_LOADING, SEND_EMAIL_SUCCESS } from '../constants/actionTypes';

const EMAIL_TYPE = {
  RECOVER_PASSWORD: 'password',
  SIGN_UP: 'email',
};

const sendSignUpEmail = payload => async dispatch => {
  dispatch({
    type: SEND_EMAIL_LOADING,
  });

  try {
    const { id } = payload;
    const target = EMAIL_TYPE.SIGN_UP;
    const { error, response, status } = await apiService.post(apiUrls.ACCOUNT.SEND_EMAIL(id), {
      target,
    });

    if (error) {
      dispatch({
        payload: { error, status },
        type: SEND_EMAIL_ERROR,
      });

      if (error?.code === errorCodes.RETRY_SEND_EMAIL_AFTER) {
        dispatch(getEmailDetails({ id, target }));
      }
    } else {
      const { data } = response;
      dispatch({
        payload: data,
        type: SEND_EMAIL_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      payload: { error },
      type: SEND_EMAIL_ERROR,
    });
  }
};

const sendResetPasswordEmail = payload => async dispatch => {
  dispatch({
    type: SEND_EMAIL_LOADING,
  });

  const { id } = payload;
  try {
    const { error, response, status } = await apiService.post(apiUrls.ACCOUNT.SEND_EMAIL(id), {
      target: EMAIL_TYPE.RECOVER_PASSWORD,
    });

    if (error) {
      dispatch({
        payload: { error, status },
        type: SEND_EMAIL_ERROR,
      });
    } else {
      const { data } = response;
      dispatch({
        payload: data,
        type: SEND_EMAIL_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      payload: { error },
      type: SEND_EMAIL_ERROR,
    });
  }
};

export { sendSignUpEmail, sendResetPasswordEmail };
