import React, { memo, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { ADD_EMPLOYEE } from '@/store/constants/actionTypes';
import { Email } from '@/components/form';
import { formatPhone } from '@/utils/format';
import { hideModal } from '@/store/actions/modalsActions';
import { ROLES_OPTIONS } from '@/constants/employees';
import statusType from '@/store/constants/statusType';

import Modal from '../Modal';
import { MODALS } from '..';

const AddEmployee = props => {
  const { onAdd } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    add: { status: addStatus, error },
  } = useSelector(state => state.employee);

  const handleFinishForm = useCallback(
    employee => {
      if (onAdd) {
        onAdd(employee);
      }
    },
    [onAdd]
  );

  const handleClickCancel = useCallback(() => {
    dispatch(hideModal(MODALS.AddEmployee));
    dispatch({
      type: ADD_EMPLOYEE,
    });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch({
      type: ADD_EMPLOYEE,
    });
  }, [dispatch]);

  const handleChangeEmail = useCallback(() => {
    if (error) {
      dispatch({
        type: ADD_EMPLOYEE,
      });
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (error?.code) {
      form.setFields([
        {
          errors: [t(`error.code.${error?.code}`)],
          name: 'email',
        },
      ]);
    }
  }, [error, form, t]);

  return (
    <Modal name={MODALS.AddEmployee} onClose={handleClose} width={560}>
      <Form name="add-employee" layout="vertical" form={form} requiredMark={false} onFinish={handleFinishForm}>
        <Col span={24}>
          <Typography.Title level={4}>{t('modal.add-employee.create-employee')}</Typography.Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('common.last-name')}
            name={['lastName']}
            rules={[...fieldRules.required, ...fieldRules.max]}
          >
            <Input placeholder={t('placeholder.lastName')} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={t('common.first-name')}
                name={['firstName']}
                rules={[...fieldRules.required, ...fieldRules.max]}
              >
                <Input placeholder={t('placeholder.firstName')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('common.middle-name')} name={['middleName']} rules={[...fieldRules.max]}>
                <Input placeholder={t('placeholder.middleName')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.role')} name={['role']} rules={[...fieldRules.required]}>
            <Select size="large" placeholder={t('placeholder.select-from-list')} options={ROLES_OPTIONS} />
          </Form.Item>
          <Form.Item name={['admin']}>
            <Checkbox>{t('modal.add-employee.is-admin')}</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={t('common.phone')}
                name={['phone']}
                rules={[...fieldRules.phone(10)]}
                normalize={formatPhone}
              >
                <Input.Phone placeholder={t('placeholder.phone')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Email onChange={handleChangeEmail} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.position')} name={['position']} rules={[...fieldRules.max]}>
            <Input placeholder={t('placeholder.position')} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Input.PasswordStrength />
        </Col>
        <Col span={24}>
          <Input.PasswordConfirm />
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" disabled={addStatus === statusType.LOADING} onClick={handleClickCancel}>
                {t('common.cancel')}
              </Button>
              <Button type="primary" shape="round" htmlType="submit" loading={addStatus === statusType.LOADING}>
                {t('common.add')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Modal>
  );
};

export default memo(AddEmployee);
