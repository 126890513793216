import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Table, Typography } from 'antd';

import pageUrls from '@/constants/pageUrls';
import { parseTenderId } from '@/utils/tenderUtils';
import { TENDER_STATUS } from '@/constants/tender';
import { Badge, TableEmpty } from '@/components';

import classes from './TendersWidget.module.scss';

const TendersWidget = props => {
  const { BadgeProps, columns, data, loading, onViewAll, title, titleIcon, viewAllValue = false } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickViewAll = useCallback(() => {
    if (onViewAll) {
      onViewAll(viewAllValue);
    }
  }, [onViewAll, viewAllValue]);

  const renderTitle = useCallback(
    () => (
      <Row justify="space-between">
        <Col>
          <Row align="middle" gutter={[8, 0]}>
            {titleIcon && <Col>{titleIcon}</Col>}
            <Col>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
            </Col>
            <Badge square {...BadgeProps} />
          </Row>
        </Col>
        {viewAllValue && (
          <Col>
            <Button shape="round" onClick={handleClickViewAll}>
              {t('common.view-all')}
            </Button>
          </Col>
        )}
      </Row>
    ),
    [BadgeProps, handleClickViewAll, title, t, viewAllValue]
  );

  const handleClickRow = useCallback(
    (id, status) => {
      history.push(status === TENDER_STATUS.DRAFT ? pageUrls.TENDERS.EDIT(id) : pageUrls.TENDERS.ITEM(id));
    },
    [history]
  );

  const onRow = useCallback(
    ({ conditions }) => ({
      onClick: () => handleClickRow(conditions?.id, conditions.status),
    }),
    [handleClickRow]
  );

  return (
    <Table
      rowKey={parseTenderId}
      className={classes.widget}
      loading={loading}
      title={renderTitle}
      columns={columns}
      dataSource={data}
      pagination={false}
      onRow={onRow}
      locale={{ emptyText: <TableEmpty /> }}
    />
  );
};

TendersWidget.propTypes = {
  BadgeProps: PropTypes.shape({}),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.node,
  viewAll: PropTypes.bool,
};

export default memo(TendersWidget);
