import statusType from '../constants/statusType';
import {
  ACTION_COMPANY_ERROR,
  ACTION_COMPANY_LOADING,
  ACTION_COMPANY_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_ERROR,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_LOADING,
  EDIT_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  add: {
    status: statusType.IDLE,
  },
  edit: {
    status: statusType.IDLE,
  },
  get: {
    status: statusType.IDLE,
  },
};

const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_COMPANY:
      return { ...state, add: initialState.add };

    case ADD_COMPANY_LOADING:
      return { ...state, add: { status: statusType.LOADING } };

    case ADD_COMPANY_ERROR:
      return {
        ...state,
        add: {
          error: payload,
          status: statusType.FAILED,
        },
      };

    case ADD_COMPANY_SUCCESS: {
      return {
        ...state,
        add: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case EDIT_COMPANY_LOADING: {
      const { id } = payload;
      return { ...state, edit: { [id]: { status: statusType.LOADING } } };
    }

    case EDIT_COMPANY_ERROR: {
      const { id, error } = payload;
      return {
        ...state,
        edit: {
          [id]: {
            error,
            status: statusType.FAILED,
          },
        },
      };
    }

    case EDIT_COMPANY_SUCCESS: {
      const { id } = payload;
      return {
        ...state,
        edit: {
          [id]: {
            status: statusType.SUCCESSED,
          },
        },
      };
    }

    case GET_COMPANY_LOADING: {
      const { id } = payload;
      return { ...state, get: { [id]: { status: statusType.LOADING } } };
    }

    case GET_COMPANY_ERROR: {
      const { id, error } = payload;
      return {
        ...state,
        get: {
          [id]: {
            error,
            status: statusType.FAILED,
          },
        },
      };
    }

    case GET_COMPANY_SUCCESS: {
      const { id, data } = payload;
      return {
        ...state,
        get: {
          [id]: {
            data,
            status: statusType.SUCCESSED,
          },
        },
      };
    }

    case ACTION_COMPANY_LOADING:
      return { ...state, [payload.action]: { status: statusType.LOADING } };

    case ACTION_COMPANY_ERROR:
      return {
        ...state,
        [payload.action]: {
          error: payload.error,
          status: statusType.FAILED,
        },
      };

    case ACTION_COMPANY_SUCCESS: {
      return {
        ...state,
        [payload.action]: {
          data: payload.data,
          status: statusType.SUCCESSED,
        },
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
