import React, { memo, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import DateTimePicker from '@/pages/TenderAdd/components/DateTimePicker';
import { EmployeeAutoComplete } from '@/components';
import { findCompany } from '@/store/actions/companyActions';

const itemProps = {
  labelAlign: 'left',
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const currencyOptions = [
  { label: 'EUR', value: 'EUR' },
  { label: 'USD', value: 'USD' },
  { label: 'KZT', value: 'KZT' },
];

const Companies = props => {
  const { onChange, onSearch, companies, value } = props;

  const [company, setCompany] = useState();
  const [options, setOptions] = useState(
    companies?.length > 0 ? companies : value?.map(item => ({ label: item.name, value: item.id }))
  );

  const handleSearchCompany = useCallback(
    e => {
      if (onSearch) {
        onSearch(e);
      }
    },
    [onSearch]
  );

  const handleChangeCompany = useCallback(
    ids => {
      if (onChange) {
        onChange(ids);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setCompany(value?.[0]?.id ? value?.map(item => item.id) : value);
  }, [value]);

  useEffect(() => {
    if (companies?.length > 0) {
      setOptions(companies);
    }
  }, [companies]);

  return (
    <Select
      size="large"
      mode="multiple"
      value={company}
      onSearch={handleSearchCompany}
      onChange={handleChangeCompany}
      filterOption={false}
    >
      {options?.map(item => {
        return (
          <Select.Option key={item?.value} value={item?.value}>
            {item?.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const BidFields = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    get: { data: tender },
  } = useSelector(state => state.tender);

  const { data: companiesData } = useSelector(state => state.companies?.find);

  const [dateFrom, setDateFrom] = useState();

  const [companies, setCompanies] = useState([]);

  const handleChangeFrom = useCallback(date => {
    setDateFrom(date);
  }, []);

  const disabledDateTo = useCallback(
    current => {
      return current && current < dateFrom;
    },
    [dateFrom]
  );

  const handleSearchCompany = useCallback(
    name => {
      if (name?.length > 2) {
        dispatch(findCompany({ name }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (companiesData?.length > 0) {
      setCompanies(
        companiesData?.map(company => {
          return {
            label: company?.general?.name,
            value: company?.general?.id,
          };
        })
      );
    }
  }, [companiesData]);

  return (
    <Row>
      <Col span={24}>
        <Form.Item label={t('pages.tender.bid-start-datetime')} name={['bid', 'dealsStarts']} {...itemProps}>
          <DateTimePicker onChange={handleChangeFrom} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t('pages.tender.bid-finish-datetime')} name={['bid', 'dealsEnds']} {...itemProps}>
          <DateTimePicker DatePickerProps={{ disabledDate: disabledDateTo }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={t('pages.tender.tender-currency')}
          name={['bid', 'currency']}
          initialValue={tender?.conditions?.tenderInternal && currencyOptions[2].value}
          {...itemProps}
        >
          <Select
            placeholder={t('common.select-option')}
            size="large"
            options={currencyOptions}
            disabled={tender?.conditions?.tenderInternal}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={t('pages.tender.average-price')}
          name={['bid', 'averageCargoPrice']}
          rules={fieldRules.positive}
          {...itemProps}
        >
          <Input type="number" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={t('pages.tender.initial-bid')}
          name={['bid', 'initialBidChecked']}
          valuePropName="checked"
          {...itemProps}
        >
          <Checkbox>{t('pages.tender.set-initial-bid')}</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const initialBid = getFieldValue(['bid', 'initialBid']);
            const initialBidChecked = getFieldValue(['bid', 'initialBidChecked']);
            const initialBidAvailable = initialBidChecked;
            return (
              initialBidAvailable && (
                <Form.Item
                  label
                  hidden={!initialBid && !initialBidChecked}
                  colon={false}
                  name={['bid', 'initialBid']}
                  rules={[...fieldRules.positive, ...fieldRules.required]}
                  extra={t(
                    `pages.tender.${tender?.conditions?.tenderInternal ? 'inland' : 'foreign'}-initital-bid-description`
                  )}
                  {...itemProps}
                >
                  <Input type="number" />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item label={t('pages.tender.initial-bid-comment')} name={['bid', 'initialBidComment']} {...itemProps}>
          <Input.TextArea placeholder={t('common.leave-comment')} size="large" autoSize={{ maxRows: 2, minRows: 2 }} />
        </Form.Item>
        <Form.Item
          label={t('common.tender.bid-step')}
          name={['bid', 'dealBidStep']}
          rules={fieldRules.positive}
          {...itemProps}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item shouldUpdate label={t('pages.tender.tender-min-bid-participants')} {...itemProps}>
          {({ getFieldValue }) => {
            const initialBidChecked = getFieldValue(['bid', 'initialBidChecked']);
            const minimalNumberOfParticipants = getFieldValue(['bid', 'minimalNumberOfParticipants']);
            const initialValue = minimalNumberOfParticipants || initialBidChecked ? 3 : 1;

            return (
              <Form.Item name={['bid', 'minimalNumberOfParticipants']} initialValue={initialValue} noStyle>
                <Input.Number min={1} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label={t('pages.tender.exclude-companies')}
          name={['bid', 'excludeCompanies']}
          tooltip={t('pages.tender.exclude-companies.tooltip')}
          {...itemProps}
        >
          <Companies companies={companies} onSearch={handleSearchCompany} />
        </Form.Item>
        <Form.Item label={t('pages.tender.person-in-charge')} name={['bid', 'responsibleAssignee']} {...itemProps}>
          <EmployeeAutoComplete />
        </Form.Item>
        <Form.Item label={t('pages.tender.tender-comment')} name={['bid', 'comments']} {...itemProps}>
          <Input.TextArea placeholder={t('common.leave-comment')} size="large" autoSize={{ maxRows: 2, minRows: 2 }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(BidFields);
