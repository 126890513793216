import React, { memo, useCallback, useMemo, useState } from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Image, Layout, List, Menu, Row, Tag } from 'antd';
import {
  CarOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { COMPANY_STATUS } from '@/constants/company';
import { Link } from '@/components';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { showModal } from '@/store/actions/modalsActions';
import { signOut } from '@/store/actions/accountActions';
import { CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import storage, { KEY_SETTINGS } from '@/services/storageService';

import logoKZBlack from '@/assets/images/logo-kz-white.png';

import classes from './Sidebar.module.scss';

const { Sider } = Layout;

const MENU_ITEM = {
  [pageUrls.ACCOUNT.MAIN]: {
    icon: <SettingOutlined />,
    label: i18n.t('menu.menu-item.account'),
    match: [pageUrls.ACCOUNT.MAIN],
    to: pageUrls.ACCOUNT.MAIN,
  },
  [pageUrls.TENDERS.LIST]: {
    icon: <NotificationOutlined />,
    label: i18n.t('menu.menu-item.tenders'),
    match: [pageUrls.TENDERS.MAIN],
    to: pageUrls.TENDERS.LIST,
  },
  [pageUrls.COMPANY.LIST]: {
    icon: <CarOutlined />,
    label: i18n.t('menu.menu-item.transport-companies'),
    match: [pageUrls.COMPANY.LIST, /company\/(?!my).*/gm],
    to: pageUrls.COMPANY.LIST,
  },
  [pageUrls.COMPANY.MY]: {
    icon: <FolderOpenOutlined />,
    label: i18n.t('menu.menu-item.my-company'),
    match: [pageUrls.COMPANY.MY],
    to: pageUrls.COMPANY.MY,
  },
  [pageUrls.EMPLOYEES.LIST]: {
    icon: <UsergroupAddOutlined />,
    label: i18n.t('menu.menu-item.employees'),
    to: pageUrls.EMPLOYEES.LIST,
  },
};

const MenuItem = props => {
  // eslint-disable-next-line react/prop-types
  const { icon, children, to, ...rest } = props;

  return (
    <Menu.Item key={to} icon={icon} {...rest}>
      <Link to={to}>{children}</Link>
    </Menu.Item>
  );
};

const Section = props => {
  const { children } = props;
  return (
    <Col span={24}>
      <Divider className={classes.divider} />
      {children}
    </Col>
  );
};

const SubMenu = props => {
  const { items = [], withFullName = true, collapsed } = props;
  const { data: account } = useSelector(state => state.account);

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const selectedKeys = useMemo(() => items.map(({ to }) => to).filter(to => to === pathname), [items, pathname]);
  return (
    <Section>
      {!collapsed && (account.admin || withFullName) && (
        <Col span={24} className={classes.meta}>
          {account.admin && (
            <Row className={classes.admin}>
              <Tag className="ant-tag-polargreen">Admin</Tag>
            </Row>
          )}
          {withFullName && account?.isTechnoUser && (
            <List.Item.Meta
              title={account?.firstName}
              description={t(`common.department.${account?.role?.toLocaleLowerCase()}`)}
            />
          )}
        </Col>
      )}
      <Menu theme="dark" className={classes.menu} selectedKeys={selectedKeys}>
        {items.map(({ icon, label, to }) => (
          <MenuItem key={to} icon={icon} to={to}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Section>
  );
};

const TCSubMenu = props => {
  const items = useMemo(() => [MENU_ITEM[pageUrls.ACCOUNT.MAIN]], []);

  return <SubMenu items={items} withFullName={false} {...props} />;
};

const AUSubMenu = props => {
  const items = useMemo(() => [MENU_ITEM[pageUrls.ACCOUNT.MAIN]], []);

  return <SubMenu items={items} {...props} />;
};

const SBSubMenu = props => {
  const items = useMemo(() => [MENU_ITEM[pageUrls.ACCOUNT.MAIN]], []);

  return <SubMenu items={items} {...props} />;
};

const LGSubMenu = props => {
  const items = useMemo(() => [MENU_ITEM[pageUrls.ACCOUNT.MAIN]], []);

  return <SubMenu items={items} {...props} />;
};

const Sidebar = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const settings = storage.getValue(KEY_SETTINGS);
  const { data: accountData } = useSelector(state => state.account);
  const { data: companyData } = useSelector(state => state.myCompany);
  const [collapsed, setCollapsed] = useState(settings?.sidebar?.collapsed || false);
  const { onCollapse } = props;

  const handleClickMenu = useCallback(() => {
    setCollapsed(c => {
      storage.setValue(KEY_SETTINGS, { sidebar: { collapsed: !c } });
      if (onCollapse) {
        onCollapse(!c);
      }
      return !c;
    });
  }, [onCollapse]);

  const handleClickSupport = useCallback(() => {
    dispatch(showModal({ modal: MODALS.Support }));
  }, [dispatch]);

  const handleClickLogout = useCallback(async () => {
    await dispatch(signOut());
    history.push(pageUrls.SIGN_IN);
  }, [dispatch, history]);

  const items = useMemo(() => {
    const common = [{ icon: <HomeOutlined />, label: i18n.t('menu.menu-item.dashboard'), to: pageUrls.DASHBOARD.MAIN }];
    const isAdmin = accountData.admin === true;

    const au =
      accountData?.role === ACCOUNT_ROLE.AU
        ? [
            MENU_ITEM[pageUrls.TENDERS.LIST],
            MENU_ITEM[pageUrls.COMPANY.LIST],
            isAdmin && MENU_ITEM[pageUrls.COMPANY.MY],
            isAdmin && MENU_ITEM[pageUrls.EMPLOYEES.LIST],
          ]
        : [];

    const sb =
      accountData?.role === ACCOUNT_ROLE.SB
        ? [
            MENU_ITEM[pageUrls.COMPANY.LIST],
            isAdmin && MENU_ITEM[pageUrls.COMPANY.MY],
            isAdmin && MENU_ITEM[pageUrls.EMPLOYEES.LIST],
          ]
        : [];

    const tc =
      accountData?.role === ACCOUNT_ROLE.TC
        ? [
            MENU_ITEM[pageUrls.TENDERS.LIST],
            accountData?.companyId && MENU_ITEM[pageUrls.COMPANY.MY],
            (companyData?.general?.status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
              companyData?.general?.status === COMPANY_STATUS.ACTIVE ||
              companyData?.contracts?.[CONTRACT_TYPE.AGREEMENT]?.status === CONTRACT_STATUS.SIGNED ||
              companyData?.contracts?.[CONTRACT_TYPE.AGREEMENT]?.status === CONTRACT_STATUS.GENERATED) && {
              icon: <FileDoneOutlined />,
              label: i18n.t('menu.menu-item.contract'),
              to: pageUrls.CONTRACT,
            },
          ]
        : [];

    const lg =
      accountData?.role === ACCOUNT_ROLE.LG || accountData?.role === ACCOUNT_ROLE.LG_CHIEF
        ? [
            MENU_ITEM[pageUrls.COMPANY.LIST],
            MENU_ITEM[pageUrls.TENDERS.LIST],
            isAdmin && MENU_ITEM[pageUrls.COMPANY.MY],
            isAdmin && MENU_ITEM[pageUrls.EMPLOYEES.LIST],
          ]
        : [];

    return [...common, ...au, ...lg, ...sb, ...tc].filter(i => !!i);
  }, [accountData, companyData]);

  const selectedKeys = useMemo(
    () =>
      items.reduce((acc, { match, to }) => {
        const paths = match || [to];
        paths.forEach(path => {
          if (pathname.match(path)) {
            acc.push(to);
          }
        });
        return acc;
      }, []),
    [items, pathname]
  );

  return (
    <Sider width={256} className={classes.sidebar} collapsed={collapsed}>
      <Row className={classes.content}>
        <Col span={24}>
          <Row justify="space-between" className={classes.header} align="middle">
            {!collapsed && <Image src={logoKZBlack} preview={false} width={144} />}
            <Row>
              <Button
                type="text"
                icon={<MenuOutlined style={{ color: '#fff', fontSize: 12 }} />}
                onClick={handleClickMenu}
              />
            </Row>
          </Row>
          <Section>
            <Menu theme="dark" className={classes.menu} selectedKeys={selectedKeys}>
              {items.map(({ icon, label, to }) => (
                <MenuItem key={to} icon={icon} to={to}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Section>
          {accountData?.role === ACCOUNT_ROLE.AU && <AUSubMenu collapsed={collapsed} />}
          {accountData?.role === ACCOUNT_ROLE.TC && <TCSubMenu collapsed={collapsed} />}
          {accountData?.role === ACCOUNT_ROLE.LG && <LGSubMenu collapsed={collapsed} />}
          {accountData?.role === ACCOUNT_ROLE.LG_CHIEF && <LGSubMenu collapsed={collapsed} />}
          {accountData?.role === ACCOUNT_ROLE.SB && <SBSubMenu collapsed={collapsed} />}
        </Col>
        <Section>
          <Menu theme="dark" className={classes.menu} selectable={false}>
            {accountData?.role === ACCOUNT_ROLE.TC && (
              <>
                <Menu.Item key="support" icon={<QuestionCircleOutlined />} onClick={handleClickSupport}>
                  {t('menu.menu-item.support')}
                </Menu.Item>
                <Menu.Divider className={classes.divider} />
              </>
            )}
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleClickLogout}>
              {t('menu.menu-item.logout')}
            </Menu.Item>
          </Menu>
        </Section>
      </Row>
    </Sider>
  );
};

export default memo(Sidebar);
