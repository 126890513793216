import React, { memo } from 'react';

import { CheckCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Col, Row, Space, Timeline, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { formatMoney } from '@/utils/format';
import pageUrls from '@/constants/pageUrls';
import { TIME_FORMAT } from '@/constants/common';
import { Link, Tag, TenderStatusTag } from '@/components';
import { TENDER_ACTIVITY_TYPE, TENDER_STATUS } from '@/constants/tender';

import { ReactComponent as IconBid } from '@/assets/icons/icon-bid.svg';
import { ReactComponent as IconFail } from '@/assets/icons/icon-fail.svg';

import colors from '@/styles/colors.module.scss';

const Item = props => {
  const { time, children } = props;
  return (
    <Timeline.Item color="#E1E8F0">
      <Row align="middle">
        <Col span={2}>{time}</Col>
        <Col span={22}>{children}</Col>
      </Row>
    </Timeline.Item>
  );
};

const ItemAction = props => {
  const { action, author } = props;
  const { t } = useTranslation();
  return (
    <Space>
      <CheckCircleFilled s style={{ color: colors.blueGray5, fontSize: 32 }} />
      <Typography.Text>
        {author?.fullName
          ? `${author?.fullName} (${t(`common.role.${author.role?.toLowerCase()}`)}) ${t(
              'common.tender.initial-bid-changed-by'
            )}`
          : t('common.tender.initial-bid-changed')}
      </Typography.Text>
      <Typography.Text>{action.value}</Typography.Text>
    </Space>
  );
};

const ItemDuplicate = props => {
  const { action } = props;
  const { t } = useTranslation();
  return (
    <Space>
      <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
      <Typography.Text>
        {t('common.tender.duplicate-created')} <Link to={pageUrls.TENDERS.ITEM(action?.value)}>{action?.value}</Link>
      </Typography.Text>
    </Space>
  );
};

const ItemStage = props => {
  const { action } = props;
  const { t } = useTranslation();
  return (
    <Space>
      <IconBid />
      <Typography.Text>
        {t(`common.tender.stage-changed.${action?.name}`, {
          bid: formatMoney(action?.value, action?.currency),
        })}{' '}
        {action?.name === 'phase_1' && <TenderStatusTag status={TENDER_STATUS.TRADING} />}
      </Typography.Text>
    </Space>
  );
};

const ItemStatus = props => {
  const { action, author } = props;
  const { t } = useTranslation();

  if (action?.value === TENDER_ACTIVITY_TYPE.TENDER_CANCELED) {
    return (
      <Space>
        <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
        <Typography.Text>{t('common.tender.status-changed.canceled-by', { author: author?.fullName })}</Typography.Text>
        <TenderStatusTag status={action.value} />
      </Space>
    );
  }

  if (action?.value === TENDER_ACTIVITY_TYPE.TENDER_FAILED) {
    return (
      <Space>
        <IconFail />
        <Typography.Text>{t('common.tender.status-changed.failed')}</Typography.Text>
        <TenderStatusTag status={action.value} />
      </Space>
    );
  }

  if (action?.value === TENDER_ACTIVITY_TYPE.TENDER_TRADING_FINISHED) {
    return (
      <Space>
        <CheckCircleFilled style={{ color: colors.green6, fontSize: 32 }} />
        <Trans
          i18nKey="common.tender.status-changed.trading-finished"
          values={{ bid: formatMoney(action?.bid, action?.currency) }}
        >
          [<TenderStatusTag status={action.value} style={{ marginRight: 0 }} />]
        </Trans>
      </Space>
    );
  }

  if (action?.value === TENDER_ACTIVITY_TYPE.TENDER_COMPLETED) {
    return (
      <Space>
        <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
        <Typography.Text>{t('common.tender.status-changed.completed')}</Typography.Text>
        <TenderStatusTag status={action.value} />
      </Space>
    );
  }

  if (action?.value === TENDER_ACTIVITY_TYPE.TENDER_PUBLISHED) {
    return (
      <Space>
        <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
        <Typography.Text>{t('common.tender.status-changed')}</Typography.Text>
        <TenderStatusTag status={action.value} />
      </Space>
    );
  }

  if (action?.value === TENDER_ACTIVITY_TYPE.F) {
    return (
      <Space>
        <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
        <Typography.Text>{t('common.tender.status-changed')}</Typography.Text>
        <TenderStatusTag status={action.value} />
      </Space>
    );
  }

  return (
    <Space>
      <CheckCircleFilled style={{ color: colors.blueGray5, fontSize: 32 }} />
      <Typography.Text>{t('common.tender.status-changed')}</Typography.Text>
      <TenderStatusTag status={action.value} />
    </Space>
  );
};

const TenderActivity = props => {
  const { activity = {}, currency } = props;
  const dates = Object.keys(activity) || [];
  return (
    <Row>
      {dates?.map((date, groupIndex) => {
        return (
          <Col span={24} key={`${date}-${groupIndex}`}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Typography.Text type="secondary">{date}</Typography.Text>
              </Col>
              <Col span={24}>
                <Timeline style={{ marginLeft: '32px' }}>
                  {activity[date]?.map((item, itemIndex) => {
                    const time = moment(item?.date).format(TIME_FORMAT);
                    const { action } = item;
                    switch (action?.name) {
                      case 'action':
                        return (
                          <Item time={time} key={`${action?.name}-${itemIndex}`}>
                            <ItemAction {...item} />
                          </Item>
                        );
                      case 'duplicate':
                        return (
                          <Item time={time} key={`${action?.name}-${itemIndex}`}>
                            <ItemDuplicate {...item} />
                          </Item>
                        );
                      case 'phase_1':
                      case 'phase_2':
                      case 'phase_3':
                        return (
                          <Item time={time} key={`${action?.name}-${itemIndex}`}>
                            <ItemStage {...item} />
                          </Item>
                        );
                      case 'status':
                        return (
                          <Item time={time} key={`${action?.name}-${itemIndex}`}>
                            <ItemStatus {...item} />
                          </Item>
                        );
                    }
                    return null;
                  })}
                </Timeline>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default memo(TenderActivity);
