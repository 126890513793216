import React, { memo, useContext } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { Tag } from '@/components';
import { ViewerContext } from '@/context/viewer-context';
import { tagsColors, TENDER_STATUS, tenderStatusIcon } from '@/constants/tender';

import classes from './TenderStatusTag.module.scss';

const normalizeStatus = (status, role) => {
  if (role === ACCOUNT_ROLE.TC) {
    switch (status) {
      case TENDER_STATUS.READY:
      case TENDER_STATUS.CLOSED:
        return TENDER_STATUS.COMPLETED;
    }
  }
  return status;
};

const TenderStatusTag = props => {
  const { status, style } = props;
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext);
  const normalizedStatus = normalizeStatus(status, viewer?.role);
  return (
    <Tag shape="round" className={clsx(`ant-tag-${tagsColors[normalizedStatus]}`, classes.tag)} style={style}>
      {tenderStatusIcon[normalizedStatus]} {t(`common.tender.status.${normalizedStatus}`)}
    </Tag>
  );
};

TenderStatusTag.propTypes = {
  status: PropTypes.string,
};

export default memo(TenderStatusTag);
