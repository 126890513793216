import React, { memo, useCallback, useEffect } from 'react';

import i18n from 'i18next';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { BaseLayout } from '@/containers/layout';
import { changePassword } from '@/store/actions/passwordActions';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { Card, Text } from '@/components';

import classes from './PasswordChange.module.scss';

const rules = {
  confirm: [
    ...fieldRules.required,
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(i18n.t('common.password-doesnt-match')));
      },
    }),
  ],
};

const PasswordChange = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  const id = query.get('u');
  const otp = query.get('o');

  const { error, status } = useSelector(state => state.passwordChange);

  const handleSubmitForm = useCallback(
    ({ password }) => {
      dispatch(changePassword(id, otp, password));
    },
    [dispatch, id, otp]
  );

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      history.push(pageUrls.CHANGE_PASSWORD_SUCCESS);
    } else if (status === statusType.FAILED) {
      history.push(pageUrls.CHANGE_PASSWORD_ERROR);
    }
  }, [history, status]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Form
          name="password-change"
          layout="vertical"
          onFinish={handleSubmitForm}
          scrollToFirstError
          requiredMark={false}
          className={classes.root}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Row>
            <Col span={24} className={classes.description}>
              <Text block size={24} height={32} align="center" weight="semibold">
                {t('common.edit-password')}
              </Text>
            </Col>
            <Col span={24}>
              <Input.PasswordStrength
                name="password"
                label={t('common.password')}
                placeholder={t('placeholder.password')}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('common.confirm-password')}
                name="confirm"
                dependencies={['password']}
                rules={rules.confirm}
              >
                <Input.Password placeholder="Пароль" />
              </Form.Item>
            </Col>
            {error && (
              <Col span={24}>
                <Text block align="center" color="red-6">
                  {error.message}
                </Text>
              </Col>
            )}
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
                block
                loading={status === statusType.LOADING}
              >
                <Text align="center">{t('common.save-password')}</Text>
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordChange);
