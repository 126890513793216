import React, { memo } from 'react';

import { Badge as AntBadge } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import colors from '@/styles/colors.module.scss';

import classes from './Badge.module.scss';

const Badge = props => {
  const { square, color = colors.green6, ...rest } = props;
  return (
    <AntBadge
      className={clsx({ [classes.square]: square })}
      offset={[4]}
      style={{ backgroundColor: color }}
      {...rest}
    />
  );
};

Badge.propTypes = {
  color: PropTypes.string,
  square: PropTypes.bool,
};

export default memo(Badge);
