import axios from 'axios';

import env from '@/constants/env';
import pageUrls from '@/constants/pageUrls';
import storageService, { KEY_ACCOUNT } from './storageService';

const service = axios.create({
  baseURL: env.APP_PROXY_URL,
  timeout: 10000,
  withCredentials: true,
});

service.interceptors.response.use(
  response => response,
  error => {
    const { status } = error?.response;
    if (status === 401) {
      storageService.clearValue(KEY_ACCOUNT);
      window.location.href = pageUrls.SIGN_IN;
    }
    return Promise.reject(error);
  }
);

const deleteM = async (url, payload, config) => {
  try {
    const { data: response, status } = await service.delete(url, {
      data: payload,
      ...config,
    });
    return { response, status };
  } catch (error) {
    const status = error?.response?.status;
    return {
      error: {
        code: error?.code,
        message: error?.message,
      },
      status,
    };
  }
};

const get = async (url, payload) => {
  try {
    const { data: response, status } = await service.get(url, {
      params: payload,
    });
    return { response, status };
  } catch (error) {
    const status = error?.response?.status;
    return {
      error: {
        code: error?.code,
        message: error?.message,
      },
      status,
    };
  }
};

const post = async (url, payload, config) => {
  try {
    const { data: response, headers, status } = await service.post(url, payload, config);
    return { headers, response, status };
  } catch (error) {
    const { data, status } = error?.response || {};
    const { code, message } = data?.error || {};
    return {
      error: {
        code,
        message,
      },
      status,
    };
  }
};

const put = async (url, payload, config) => {
  try {
    const { data: response, status } = await service.put(url, payload, config);
    return { response, status };
  } catch (error) {
    console.dir(error);
    const { data, status } = error?.response || {};
    const { code, message } = data?.error || {};
    return {
      error: {
        code,
        message: message || error.message,
      },
      status,
    };
  }
};

export default { delete: deleteM, get, post, put };
