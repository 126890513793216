import moment from 'moment';

import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import { DATE_FORMAT } from '@/constants/common';
import { getTenderType } from '@/utils/tenderUtils';
import { sanitizeObject } from '@/utils/objectUtils';
import { TENDER_ACTION } from '@/constants/tender';

import { getCompany, normalizeCompany } from './companyActions';
import { getEmployee, normalizeEmployee } from './employeesActions';

import {
  ADD_TENDER_ERROR,
  ADD_TENDER_LOADING,
  ADD_TENDER_SUCCESS,
  BID_TENDER_ERROR,
  BID_TENDER_LOADING,
  BID_TENDER_SUCCESS,
  CANCEL_TENDER_ERROR,
  CANCEL_TENDER_LOADING,
  CANCEL_TENDER_SUCCESS,
  COMPLETE_TENDER_ERROR,
  COMPLETE_TENDER_LOADING,
  COMPLETE_TENDER_SUCCESS,
  DELETE_TENDER_ERROR,
  DELETE_TENDER_LOADING,
  DELETE_TENDER_SUCCESS,
  DUPLICATE_TENDER_ERROR,
  DUPLICATE_TENDER_LOADING,
  DUPLICATE_TENDER_SUCCESS,
  EDIT_TENDER_ERROR,
  EDIT_TENDER_LOADING,
  EDIT_TENDER_SUCCESS,
  GET_TENDER_ACTIVITY_ERROR,
  GET_TENDER_ACTIVITY_LOADING,
  GET_TENDER_ACTIVITY_SUCCESS,
  GET_TENDER_DETAILS_ERROR,
  GET_TENDER_DETAILS_LOADING,
  GET_TENDER_DETAILS_SUCCESS,
  GET_TENDER_ERROR,
  GET_TENDER_LOADING,
  GET_TENDER_RESULTS_ERROR,
  GET_TENDER_RESULTS_LOADING,
  GET_TENDER_RESULTS_SUCCESS,
  GET_TENDER_SUCCESS,
  PUBLISH_TENDER_ERROR,
  PUBLISH_TENDER_LOADING,
  PUBLISH_TENDER_SUCCESS,
  READY_TENDER_ERROR,
  READY_TENDER_LOADING,
  READY_TENDER_SUCCESS,
} from '../constants/actionTypes';

export const normalizeTender = tender => {
  const normalizedTender = {
    bid: tender['deals-settings'],
    cargo: tender.cargo,
    conditions: { ...tender?.['tender-settings'], transportType: getTenderType(tender['geo-points']) },
    route: tender['geo-points'],
    transport: tender.transport,
  };

  return normalizedTender;
};

/*
{
  "status": "ok",
  "data": [
    {
      "partitionKey": "tender#20211101-0002",
      "sortKey": 1635759916183,
      "authorOfChangeId": "91be0864-2234-4989-80ac-91fee19846ab",
      "authorOfChangeEmail": "taras.korolyov+admin@waveo.io",
      "diff": {
        "fieldName": "status",
        "oldValue": "DRAFT",
        "newValue": "ACCEPT_SIGNINGS"
      }
    },
    {
      "partitionKey": "tender#20211101-0002",
      "sortKey": 1635760002558,
      "authorOfChangeId": "9c53d9b8-b4f5-4e0b-8d6f-fa9c7c7edb6a",
      "authorOfChangeEmail": "demotest@AllFreeMail.net",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "participant_added"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "9c8b2cf3-a6e1-40c5-8635-6d258b602721"
        },
        {
          "fieldName": "initialBid",
          "oldValue": "",
          "newValue": "95000"
        }
      ]
    },
    {
      "partitionKey": "tender#20211101-0002",
      "sortKey": 1635760321035,
      "authorOfChangeId": "9c53d9b8-b4f5-4e0b-8d6f-fa9c7c7edb6a",
      "authorOfChangeEmail": "demotest@AllFreeMail.net",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "bid_for_phase_1"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "9c8b2cf3-a6e1-40c5-8635-6d258b602721"
        },
        {
          "fieldName": "bid",
          "oldValue": "",
          "newValue": "93000"
        }
      ]
    },
    {
      "partitionKey": "tender#20211101-0002",
      "sortKey": 1635761955658,
      "authorOfChangeId": "9c53d9b8-b4f5-4e0b-8d6f-fa9c7c7edb6a",
      "authorOfChangeEmail": "demotest@AllFreeMail.net",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "bid_for_phase_3"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "9c8b2cf3-a6e1-40c5-8635-6d258b602721"
        },
        {
          "fieldName": "bid",
          "oldValue": "",
          "newValue": "92000"
        }
      ]
    }
  ]
}
{
  "status": "ok",
  "data": [
    {
      "partitionKey": "tender#20211026-0003",
      "sortKey": 1635188009689,
      "authorOfChangeId": "00818362-5b85-4fc1-8348-faedb6847f94",
      "authorOfChangeEmail": "olha.tsvilii+logist@waveo.io",
      "diff": {
        "fieldName": "status",
        "oldValue": "DRAFT",
        "newValue": "ACCEPT_SIGNINGS"
      }
    },
    {
      "partitionKey": "tender#20211026-0003",
      "sortKey": 1635594652532,
      "authorOfChangeId": "43905895-87b2-492c-9207-7025a5d4999e",
      "authorOfChangeEmail": "wivorih899@d3ff.com",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "participant_added"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "d32d90f7-a61d-40a0-ae6e-deb0817ac79f"
        },
        {
          "fieldName": "initialBid",
          "oldValue": "",
          "newValue": "15000"
        }
      ]
    },
    {
      "partitionKey": "tender#20211026-0003",
      "sortKey": 1635596080383,
      "authorOfChangeId": "f97e30ac-7e91-438f-accd-6a0cc1e74a12",
      "authorOfChangeEmail": "alik@coreteka.us",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "participant_added"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "ffa5aeeb-06b2-49ea-ad4d-caad6ecc6544"
        },
        {
          "fieldName": "initialBid",
          "oldValue": "",
          "newValue": "14000"
        }
      ]
    },
    {
      "partitionKey": "tender#20211026-0003",
      "sortKey": 1635712785051,
      "authorOfChangeId": "817e400b-3f21-4058-a753-c49c846b2787",
      "authorOfChangeEmail": "TK23@tempr.email",
      "diff": [
        {
          "fieldName": "action",
          "oldValue": "",
          "newValue": "participant_added"
        },
        {
          "fieldName": "companyId",
          "oldValue": "",
          "newValue": "c9826be4-dc0a-4d7f-b001-83f300ec4488"
        },
        {
          "fieldName": "initialBid",
          "oldValue": "",
          "newValue": "12000"
        }
      ]
    },
    {
      "partitionKey": "tender#20211026-0003",
      "sortKey": 1636037557399,
      "authorOfChangeId": "41d1c8e7-d4f8-437b-822f-f51d7a48be8f",
      "authorOfChangeEmail": "alik.ovsepyan+admin.lg@waveo.io",
      "diff": [
        {
          "fieldName": "status",
          "oldValue": "ACCEPT_SIGNINGS",
          "newValue": "CANCELED"
        },
        {
          "fieldName": "state:comment",
          "oldValue": "",
          "newValue": "проба"
        }
      ]
    }
  ]
}
 */

export const normalizeTenderActivity = async activity => {
  const normalized = await Promise.all(
    activity?.map(async item => {
      const diff = JSON.parse(item?.diff);

      const author = {
        email: item?.authorOfChangeEmail,
        id: item?.authorOfChangeId,
      };

      if (author.email && author.id) {
        try {
          const { response } = await apiService.get(apiUrls.TECHNO.USER.GET(author.id));
          if (response?.data) {
            const profile = normalizeEmployee(response?.data);
            author.fullName = profile.fullName;
            author.role = profile.role;
          }
        } catch (err) {
          //
        }

        if (!author?.fullName) {
          try {
            const { response } = await apiService.get(apiUrls.ACCOUNT.GET(author.id));
            if (response?.data) {
              const profile = normalizeEmployee(response?.data);
              author.fullName = profile.fullName;
              author.role = profile.role;
            }
          } catch (err) {
            //
          }
        }
      }

      const action = {};

      if (diff?.fieldName) {
        action.name = diff?.fieldName;
        action.value = diff?.newValue;
      } else if (diff?.[0]?.fieldName) {
        const [record, a, b] = diff;
        action.name = record?.fieldName;
        action.value = record?.newValue;

        switch (action.value) {
          case 'bid_for_phase_1':
          case 'bid_for_phase_2':
          case 'bid_for_phase_3':
          case 'participant_added':
            return false;
        }

        switch (action.name) {
          case 'action':
          case 'status':
            action.value = record?.newValue;
            action.bid = a?.newValue;
            action.currency = b?.newValue;
            break;
          case 'duplicate':
            action.value = a?.newValue;
            break;
          case 'phase_1':
          case 'phase_2':
          case 'phase_3':
            action.value = parseFloat(a?.newValue);
            action.currency = b?.newValue;
            break;
          default:
            return false;
        }
      }

      return { action, author, date: item?.sortKey };
    })
  );

  return normalized
    .filter(i => !!i)
    .reverse()
    .reduce((acc, curr) => {
      const date = moment(curr?.date).format(DATE_FORMAT);
      acc[date] = acc[date] || [];
      acc[date].push(curr);
      return acc;
    }, {});
};

const normalizeTenderDetails = async details => {
  const normalized = await Promise.all(
    details?.participants?.map(async item => {
      const { response } = await apiService.get(apiUrls.COMPANY.GET(item.companyId));
      if (response?.data) {
        return {
          ...item,
          company: response?.data,
        };
      }
      return item;
    })
  );
  return normalized;
};

const normalizeTenderResults = async results => {
  const companies = await Promise.all(
    results?.companiesWithFinalBid?.map(async item => {
      const { response } = await apiService.get(apiUrls.COMPANY.GET(item.companyId));
      if (response?.data) {
        return {
          ...item,
          company: normalizeCompany(response?.data),
        };
      }
      return item;
    })
  );
  return {
    companies: companies?.sort((a, b) => a?.minimalBid - b?.minimalBid),
    protocol: results?.linkForTenderProtocol,
  };
};

const addTender = tender => async dispatch => {
  dispatch({
    type: ADD_TENDER_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ADD, tender);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: ADD_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: normalizeTender(data),
      type: ADD_TENDER_SUCCESS,
    });
  }
};

const getTender = (id, config) => async dispatch => {
  dispatch({
    type: GET_TENDER_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TENDER.GET(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TENDER_ERROR,
    });
  } else {
    const normalizedTender = sanitizeObject(normalizeTender(response.data));

    try {
      if (normalizedTender?.bid?.responsibleAssignee) {
        const employee = await dispatch(getEmployee(normalizedTender?.bid?.responsibleAssignee));
        if (employee) {
          normalizedTender.bid.responsibleAssignee = {
            fullName: employee.fullName,
            id: employee.id,
          };
        }
      }
    } catch (err) {
      //
    }

    if (config?.excludeCompanies !== true) {
      try {
        if (normalizedTender?.bid?.excludeCompanies?.length > 0) {
          const companies = await Promise.all(
            normalizedTender?.bid?.excludeCompanies?.map(companyId => dispatch(getCompany(companyId)))
          );

          normalizedTender.bid.excludeCompanies = companies.map(company => ({
            id: company?.general?.id,
            name: company?.general?.name,
          }));
        }
      } catch (err) {
        //
      }
    }

    dispatch({
      payload: normalizedTender,
      type: GET_TENDER_SUCCESS,
    });
  }
};

const getTenderActivity = id => async dispatch => {
  dispatch({
    type: GET_TENDER_ACTIVITY_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TENDER.ACTIVITY(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TENDER_ACTIVITY_ERROR,
    });
  } else {
    const activity = await normalizeTenderActivity(response.data);
    dispatch({
      payload: activity,
      type: GET_TENDER_ACTIVITY_SUCCESS,
    });
  }
};

const getTenderDetails = id => async dispatch => {
  dispatch({
    type: GET_TENDER_DETAILS_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TENDER.DETAILS(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TENDER_DETAILS_ERROR,
    });
  } else {
    const details = await normalizeTenderDetails(response.data);
    dispatch({
      payload: details,
      type: GET_TENDER_DETAILS_SUCCESS,
    });
  }
};

const getTenderResults = id => async dispatch => {
  dispatch({
    type: GET_TENDER_RESULTS_LOADING,
  });

  const { status, error, response } = await apiService.get(apiUrls.TENDER.RESULTS(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: GET_TENDER_RESULTS_ERROR,
    });
  } else {
    const results = await normalizeTenderResults(response.data);
    dispatch({
      payload: results,
      type: GET_TENDER_RESULTS_SUCCESS,
    });
  }
};

const editTender = payload => async dispatch => {
  dispatch({
    type: EDIT_TENDER_LOADING,
  });

  const { id, data: request } = payload;
  const { error, response, status } = await apiService.put(apiUrls.TENDER.EDIT(id), request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: EDIT_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: EDIT_TENDER_SUCCESS,
    });
  }
};

const deleteTender = id => async dispatch => {
  dispatch({
    type: DELETE_TENDER_LOADING,
  });

  const { error, response, status } = await apiService.delete(apiUrls.TENDER.DELETE(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: DELETE_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: DELETE_TENDER_SUCCESS,
    });
  }
};

const duplicateTender = id => async dispatch => {
  dispatch({
    type: DUPLICATE_TENDER_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.TENDER.DUPLICATE(id));

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: DUPLICATE_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: sanitizeObject(normalizeTender(data)),
      type: DUPLICATE_TENDER_SUCCESS,
    });
  }
};

const cancelTender = payload => async dispatch => {
  dispatch({
    type: CANCEL_TENDER_LOADING,
  });
  const { comment, id } = payload;

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.CANCEL,
    ...(comment && {
      additionalInfo: [
        {
          fieldName: 'commentOnStateChange',
          value: comment,
        },
      ],
    }),
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: CANCEL_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: CANCEL_TENDER_SUCCESS,
    });
  }
};

const completeTender = payload => async dispatch => {
  dispatch({
    type: COMPLETE_TENDER_LOADING,
  });
  const { comment, id } = payload;

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.COMPLETE_DEALS,
    ...(comment && {
      additionalInfo: [
        {
          fieldName: 'commentOnStateChange',
          value: comment,
        },
        {
          fieldName: 'forceComplete',
          value: true,
        },
      ],
    }),
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: COMPLETE_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: COMPLETE_TENDER_SUCCESS,
    });
  }
};

const makeBid = payload => async dispatch => {
  dispatch({
    type: BID_TENDER_LOADING,
  });
  const { id, bid } = payload;

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.MAKE_BID,
    additionalInfo: [
      {
        fieldName: 'bid',
        value: bid,
      },
    ],
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: BID_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: BID_TENDER_SUCCESS,
    });
  }
};

const makeInitialBid = payload => async dispatch => {
  dispatch({
    type: BID_TENDER_LOADING,
  });
  const { id, bid } = payload;

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.SIGN_UP,
    additionalInfo: [{ fieldName: 'initialBid', value: bid }],
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: BID_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: BID_TENDER_SUCCESS,
    });
  }
};

const publishTender = id => async dispatch => {
  dispatch({
    type: PUBLISH_TENDER_LOADING,
  });

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.PUBLISH,
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: PUBLISH_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: PUBLISH_TENDER_SUCCESS,
    });
  }
};

const readyTender = payload => async dispatch => {
  dispatch({
    type: READY_TENDER_LOADING,
  });

  const { id } = payload;

  const { error, response, status } = await apiService.post(apiUrls.TENDER.ACTION(id), {
    action: TENDER_ACTION.MOVE_TO_READY,
  });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: READY_TENDER_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: READY_TENDER_SUCCESS,
    });
  }
};

export {
  addTender,
  cancelTender,
  completeTender,
  deleteTender,
  duplicateTender,
  editTender,
  getTender,
  getTenderActivity,
  getTenderDetails,
  getTenderResults,
  makeInitialBid,
  makeBid,
  publishTender,
  readyTender,
};
