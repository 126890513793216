import React, { memo } from 'react';

import { Col, Image } from 'antd';

import map from './images/map.png';

const Map = () => {
  return (
    <Col>
      <Image wrapperStyle={{ display: 'block' }} src={map} preview={false} />
    </Col>
  );
};

export default memo(Map);
