import React, { memo, useCallback, useEffect, useState } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Select, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_GROUP } from '@/constants/company';
import { hideModal } from '@/store/actions/modalsActions';
import { PeriodSelector } from '@/components/form';
import { REPORT_TYPE } from '@/constants/report';
import statusType from '@/store/constants/statusType';

import moment from 'moment';
import Modal from '../Modal';
import { MODALS } from '..';

const getPeriodLabel = companiesGroup => {
  switch (companiesGroup) {
    case COMPANY_GROUP.VERIFIED:
      return i18next.t('modal.create-companies-report.date-verify-confirmed');
    case COMPANY_GROUP.NOT_VERIFIED:
      return i18next.t('modal.create-companies-report.date-verify');
    case COMPANY_GROUP.NOT_ACTIVE:
      return i18next.t('modal.create-companies-report.date-inactive');
  }
  return '';
};

const transfromDate = date => {
  if (typeof date === 'number') {
    return moment(date);
  }
  return date;
};

const CreateCompaniesReport = props => {
  const { onOk } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [group, setGroup] = useState(COMPANY_GROUP.VERIFIED);
  const report = useSelector(state => state.report?.[REPORT_TYPE.COMPANIES]);
  const [form] = Form.useForm();

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.CreateCompaniesReport));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    form.validateFields().then(fields => {
      if (onOk) {
        onOk({
          end: fields?.end?.valueOf(),
          start: fields?.start?.valueOf(),
          status: fields.status,
        });
      }
    });
  }, [onOk, form]);

  const handleChangeGroup = useCallback(value => {
    setGroup(value);
  }, []);

  useEffect(() => {
    if (report?.status === statusType.SUCCESSED) {
      dispatch(hideModal(MODALS.CreateCompaniesReport));
    }
  }, [dispatch, report]);

  return (
    <Modal name={MODALS.CreateCompaniesReport} width={468}>
      <Row gutter={[0, 24]}>
        <Typography.Title level={4}>{t('modal.create-companies-report.title')}</Typography.Title>
        <Col span={24}>
          <Form layout="vertical" form={form} requiredMark={false}>
            <Form.Item label={t('common.report-type')} name="status" initialValue={group}>
              <Select size="large" onChange={handleChangeGroup}>
                <Select.Option value={COMPANY_GROUP.VERIFIED}>
                  {t('modal.create-companies-report.option.verified')}
                </Select.Option>
                <Select.Option value={COMPANY_GROUP.NOT_VERIFIED}>
                  {t('modal.create-companies-report.option.non-verified')}
                </Select.Option>
                <Select.Option value={COMPANY_GROUP.NOT_ACTIVE}>
                  {t('modal.create-companies-report.option.non-active')}
                </Select.Option>
              </Select>
            </Form.Item>
            <PeriodSelector
              label={getPeriodLabel(group)}
              transformValue={transfromDate}
              StartFormProps={{
                initialValue: moment().subtract(30, 'days').valueOf(),
              }}
              StartPickerProps={{
                placeholder: t('common.start-of-period'),
              }}
              EndFormProps={{
                initialValue: moment().valueOf(),
              }}
              EndPickerProps={{ placeholder: t('common.end-of-period') }}
            />
          </Form>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" onClick={handleClickNo}>
                {t('modal.create-companies-report.button.no')}
              </Button>
              <Button
                type="primary"
                shape="round"
                onClick={handleClickOk}
                loading={report?.status === statusType.LOADING}
              >
                {t('modal.create-companies-report.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(CreateCompaniesReport);
