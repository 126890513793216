import React, { memo } from 'react';

import { Button, Col, Image, Row, Typography } from 'antd';

import classes from './styles/Feature3.module.scss';

const Feature = () => {
  return (
    <Col span={24} className={classes.root}>
      <Row justify="center">
        <Col className={classes.card}>
          <Col span={13}>
            <Typography.Title className={classes.title}>
              Получите все преимущества автоматизированного процесса участия в тендерах на перевозки
            </Typography.Title>
            <Button type="primary" shape="round">
              Зарегистрироваться
            </Button>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default memo(Feature);
