import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import {
  PASSWORD_CHANGE_ERROR,
  PASSWORD_CHANGE_LOADING,
  PASSWORD_CHANGE_SUCCESS,
  RECOVER_PASSWORD_DETAILS_ERROR,
  RECOVER_PASSWORD_DETAILS_LOADING,
  RECOVER_PASSWORD_DETAILS_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_LOADING,
  RECOVER_PASSWORD_SUCCESS,
} from '@/store/constants/actionTypes';
import storage, { KEY_ACCOUNT } from '@/services/storageService';

const changePassword = (id, otp, password) => async dispatch => {
  dispatch({
    type: PASSWORD_CHANGE_LOADING,
  });

  const request = {
    password: btoa(password),
  };
  const { error, response, status } = await apiService.put(apiUrls.ACCOUNT.CHANGE_PASSWORD(id, otp), request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: PASSWORD_CHANGE_ERROR,
    });
  } else {
    dispatch({
      payload: response?.data,
      type: PASSWORD_CHANGE_SUCCESS,
    });
  }
};

const recoverPassword = email => async dispatch => {
  dispatch({
    type: RECOVER_PASSWORD_LOADING,
  });

  try {
    const account = await storage.getValue(KEY_ACCOUNT);
    const { error, response, status } = await apiService.put(apiUrls.ACCOUNT.RECOVER_PASSWORD, {
      email: encodeURIComponent(email || account.email),
    });

    if (error) {
      dispatch({
        payload: { status, ...error },
        type: RECOVER_PASSWORD_ERROR,
      });
    } else {
      const { data } = response;
      dispatch({
        payload: data,
        type: RECOVER_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      payload: error,
      type: RECOVER_PASSWORD_ERROR,
    });
  }
};

const getRecoverPasswordDetails = email => async dispatch => {
  dispatch({
    type: RECOVER_PASSWORD_DETAILS_LOADING,
  });

  try {
    const { error, response, status } = await apiService.get(apiUrls.ACCOUNT.RECOVER_PASSWORD_DETAILS, {
      email,
    });

    if (error) {
      dispatch({
        payload: { status, ...error },
        type: RECOVER_PASSWORD_DETAILS_ERROR,
      });
    } else {
      dispatch({
        payload: response.data,
        type: RECOVER_PASSWORD_DETAILS_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({
      payload: error,
      type: RECOVER_PASSWORD_DETAILS_ERROR,
    });
  }
};

export { changePassword, getRecoverPasswordDetails, recoverPassword };
