import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { agreeTerms } from '@/store/actions/companyActions';
import { BaseContent } from '@/containers/content';
import { COMPANY_ACTION } from '@/constants/company';
import { ContractStatusTag } from '@/components';
import { DashboardLayout } from '@/containers/layout';
import { generateContract } from '@/store/actions/contractActions';
import { joinKeys } from '@/utils/objectUtils';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';
import { CONTRACT_ACTION, CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import { policy, required } from '@/constants/fieldRules';

import { kz, rus } from '@/assets/templates/contract';

import classes from './Contract.module.scss';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const template = {
  defaultStyle: {
    alignment: 'justify',
    columnGap: 20,
    fontSize: 12,
  },
  styles: {
    header: {
      bold: true,
      fontSize: 14,
    },
  },
};

const Contract = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [pdfURL, setPDFURL] = useState();
  const [fields, setFields] = useState();

  const { data: company } = useSelector(state => state.myCompany);
  const { data: account } = useSelector(state => state.account);
  const agreeState = useSelector(state => state.company?.[COMPANY_ACTION.AGREE_TO_TERMS]);
  const contractState = useSelector(state => state.contract);

  const contract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];
  const contractObject = useMemo(() => {
    return {
      ...template,
      content: [
        {
          columns: [
            {
              text: rus
                .split('{{directorBasis}}')
                .join(fields?.directorBasis)
                .split('{{fullName}}')
                .join(account?.fullName)
                .split('{{companyBIN}}')
                .join(company?.general?.bin)
                .split('{{companyAddress}}')
                .join(joinKeys(['zipCode', 'country', 'city', 'address'], company?.addresses?.registration))
                .split('{{bankIIK}}')
                .join(fields?.bankIIK)
                .split('{{bankLabel}}')
                .join(fields?.bankLabel)
                .split('{{bankBIK}}')
                .join(fields?.bankBIK),
            },
            {
              text: kz
                .split('{{directorBasis}}')
                .join(fields?.directorBasis)
                .split('{{fullName}}')
                .join(account?.fullName)
                .split('{{companyBIN}}')
                .join(company?.general?.bin)
                .split('{{companyAddress}}')
                .join(joinKeys(['zipCode', 'country', 'city', 'address'], company?.addresses?.registration))
                .split('{{bankIIK}}')
                .join(fields?.bankIIK)
                .split('{{bankLabel}}')
                .join(fields?.bankLabel)
                .split('{{bankBIK}}')
                .join(fields?.bankBIK),
            },
          ],
        },
      ],
    };
  }, [account, company, fields]);

  const contractStatus = contract?.status || CONTRACT_STATUS.FAKE_NOT_SIGNED;

  const termsAccepted =
    contractStatus === CONTRACT_STATUS.EXPIRED ||
    contractStatus === CONTRACT_STATUS.INITIATED ||
    contractStatus === CONTRACT_STATUS.SIGNED ||
    company?.general?.contractConcentGranted;

  const formAvailable =
    termsAccepted &&
    contract &&
    contractStatus !== CONTRACT_STATUS.GENERATED &&
    contractStatus !== CONTRACT_STATUS.SIGNED &&
    contractStatus !== CONTRACT_STATUS.SIGNING_CANCELED &&
    contractStatus !== CONTRACT_STATUS.TERMINATED;

  const handleClickAgree = useCallback(() => {
    form.validateFields().then(() => {
      dispatch(
        agreeTerms({
          text: 'sample',
        })
      );
    });
  }, [dispatch, form]);

  const handleClickGenerate = useCallback(() => {
    form.validateFields().then(() => {
      const doc = pdfMake.createPdf(contractObject);
      doc.getBlob(blob => {
        dispatch(
          generateContract({
            company: company?.general?.id,
            contract: company?.contracts?.[CONTRACT_TYPE.AGREEMENT]?.id,
            file: blob,
          })
        );
      });
    });
  }, [dispatch, form, company, contractObject]);

  const handleChangeForm = useCallback(() => {
    setFields(form.getFieldsValue());
  }, [form]);

  useEffect(() => {
    const generateStatus = contractState[CONTRACT_ACTION.GENERATE_CONTRACT]?.status;
    if (agreeState?.status === statusType.SUCCESSED || generateStatus === statusType.SUCCESSED) {
      history.replace(pageUrls.CONTRACT);
    }
  }, [history, agreeState?.status, contractState]);

  useEffect(() => {
    if (account && company) {
      if (contract?.linkToGeneratedDoc) {
        setPDFURL(contract?.linkToGeneratedDoc);
      } else {
        const pdf = pdfMake.createPdf(contractObject);
        pdf.getDataUrl(url => setPDFURL(url));
      }
    }
  }, [account, company, contract, contractObject]);

  return (
    <DashboardLayout>
      <BaseContent contentSpan={24}>
        {!company ? (
          <Skeleton />
        ) : (
          <Col span={24}>
            <Form
              form={form}
              onFinish={handleClickAgree}
              onBlur={handleChangeForm}
              validateTrigger={['onChange', 'onBlur']}
              layout="vertical"
            >
              <Row gutter={[24, 24]}>
                {formAvailable && (
                  <Col span={8}>
                    <Typography.Title level={3}>{t('common.contract-details')}</Typography.Title>
                    <Form.Item label={t('pages.contract.director-basis')} name="directorBasis" rules={required}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={t('pages.contract.bank-iik')} name="bankIIK" rules={required}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={t('pages.contract.bank-label')} name="bankLabel" rules={required}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={t('pages.contract.bank-bik')} name="bankBIK" rules={required}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="valid" valuePropName="checked" rules={required}>
                      <Checkbox>{t('pages.contract.confirm-values')}</Checkbox>
                    </Form.Item>
                    <Alert type={ALERT_TYPE.INFO} message={t('pages.contract.contract-generate-warn')} />
                    <Button type="primary" shape="round" onClick={handleClickGenerate}>
                      {t('common.generate-contract')}
                    </Button>
                  </Col>
                )}
                <Col flex="1">
                  <Space align="baseline" size="middle">
                    <Typography.Title level={3}>{t('common.contract')}</Typography.Title>
                    {company && <ContractStatusTag status={contractStatus} />}
                  </Space>

                  <Alert
                    style={{ width: 'fit-content' }}
                    type={ALERT_TYPE.WARNING}
                    message={t('common.best-with-chrome')}
                  />

                  <iframe className={classes.frame} title="contract" src={pdfURL} />
                </Col>

                {!termsAccepted && contractStatus !== CONTRACT_STATUS.GENERATED ? (
                  <>
                    <Col span={24}>
                      <Form.Item name="agree" initialValue valuePropName="checked" rules={policy}>
                        <Checkbox>{t('common.agree-with-terms')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="guarantee" valuePropName="checked" rules={policy}>
                        <Checkbox>{t('pages.contract.guarantee-to-sign')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        loading={agreeState?.status === statusType.LOADING}
                      >
                        {t('common.accept-terms')}
                      </Button>
                    </Col>
                  </>
                ) : (
                  (contractStatus === CONTRACT_STATUS.SIGNED || contractStatus === CONTRACT_STATUS.GENERATED) && (
                    <Col span={24}>
                      <Typography.Link href={contract?.linkToGeneratedDoc} target="_blank">
                        <Button type="primary" shape="round">
                          {t('common.download-contract')}
                        </Button>
                      </Typography.Link>
                    </Col>
                  )
                )}
              </Row>
            </Form>
          </Col>
        )}
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(Contract);
