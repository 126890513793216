import React, { memo, useCallback } from 'react';

import { Col, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CompanyDocumentRow } from '@/pages/Company/components';
import { DOCUMENTS } from '@/pages/Company/constants';
import { editCompany } from '@/store/actions/companyActions';
import { Link, Section } from '@/components';

import classes from './SectionDocuments.module.scss';

const { Text } = Typography;

const SectionDocuments = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allowEdit, data: company = {}, reqiured = true, onEdit } = props;
  const { data: accountData } = useSelector(state => state.account);

  const handleDocumentUpload = useCallback(
    ({ name, url }) => {
      const documents = { ...company.documents, [name]: { fileName: url, type: name } };
      dispatch(editCompany({ data: { documents }, id: accountData?.companyId }));
    },
    [accountData?.companyId, company, dispatch]
  );

  const { documents } = company;

  return (
    <Section title={t('pages.company.documents-information')} className={classes.detailsList} onEdit={onEdit}>
      <CompanyDocumentRow
        allowUpload={allowEdit}
        name={DOCUMENTS.STATUTE}
        reqiured={reqiured}
        label={t('pages.company.statute')}
        value={documents?.[DOCUMENTS.STATUTE]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        name={DOCUMENTS.SGR}
        reqiured={reqiured}
        label={t('pages.company.sgr')}
        value={documents?.[DOCUMENTS.SGR]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        name={DOCUMENTS.VAT}
        reqiured={reqiured}
        label={t('pages.company.vat-payer')}
        value={documents?.[DOCUMENTS.VAT]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        name={DOCUMENTS.SGR_EGOV}
        reqiured={reqiured}
        label={
          <>
            <Col span={24}>{t('pages.company.sgr-extract')}</Col>
            <Col span={24}>
              <Text className={classes.description}>
                <Trans i18nKey="pages.company.sgr-egov-description">
                  [<Link to="//egov.kz" target="_blank" />]
                </Trans>
              </Text>
            </Col>
          </>
        }
        value={documents?.[DOCUMENTS.SGR_EGOV]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        name={DOCUMENTS.REPORT}
        reqiured={reqiured}
        label={t('pages.company.fixed-assets-report')}
        value={documents?.[DOCUMENTS.REPORT]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        reqiured={false}
        name={DOCUMENTS.GUARANTEE}
        label={t('pages.company.letter-of-guarantee')}
        value={documents?.[DOCUMENTS.GUARANTEE]?.fileName}
        onUpload={handleDocumentUpload}
      />
      <CompanyDocumentRow
        allowUpload={allowEdit}
        reqiured={false}
        name={DOCUMENTS.PRESENTATION}
        label={t('pages.company.presentation')}
        value={documents?.[DOCUMENTS.PRESENTATION]?.fileName}
        onUpload={handleDocumentUpload}
      />
    </Section>
  );
};

export default memo(SectionDocuments);
