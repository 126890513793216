import React, { memo, useCallback } from 'react';

import { Modal as AntModal } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { hideModal } from '@/store/actions/modalsActions';

const Modal = props => {
  const { name, children, className, bodyStyle, footer = null, onClose, width = 600, ...rest } = props;
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    dispatch(hideModal(name));
  }, [dispatch, name, onClose]);

  return (
    <AntModal
      centered
      closable
      destroyOnClose
      className={className}
      footer={footer}
      onCancel={handleClose}
      visible
      bodyStyle={{
        padding: '24px 32px',
        whiteSpace: 'break-spaces',
        ...bodyStyle,
      }}
      width={width}
      {...rest}
    >
      {children}
    </AntModal>
  );
};

Modal.propTypes = {
  bodyStyle: PropTypes.shape({}),
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  name: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.number,
};

export default memo(Modal);
