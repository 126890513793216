import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Dropdown, Menu, Row, Space, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';
import { DATE_FORMAT } from '@/constants/tender';
import { CompanyStatusTag, ContractStatusTag, Link } from '@/components';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import { ViewerContext } from '@/context/viewer-context';
import { COMPANY_ACTION, COMPANY_STATUS, contractStatusIcons, tagsColors } from '@/constants/company';
import { confirmContractSign, revokeContract, terminateContract } from '@/store/actions/contractActions';
import { CONTRACT_ACTION, CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import { hideModal, showModal } from '@/store/actions/modalsActions';

import {
  activateCompany,
  blockCompany,
  getCompany,
  refineCompany,
  rejectCompany,
  sendContract,
  unBlockCompany,
  verifyCompany,
} from '@/store/actions/companyActions';

import colors from '@/styles/colors.module.scss';

import SectionContacts from '../MyCompany/components/SectionContacts';
import SectionDocuments from '../MyCompany/components/SectionDocuments';
import SectionGeneral from '../MyCompany/components/SectionGeneral';

const { Title } = Typography;

const getCompanyActions = (role, status, contractStatus) => {
  switch (role) {
    case ACCOUNT_ROLE.SB:
      return [
        { key: COMPANY_ACTION.CONFIRM_VERIFICATION, label: i18n.t('pages.company-details.actions.verify') },
        { key: COMPANY_ACTION.SEND_FOR_REWORK, label: i18n.t('pages.company-details.actions.refine') },
        { key: COMPANY_ACTION.REJECT_VERIFICATION, label: i18n.t('pages.company-details.actions.reject') },
      ];
    case ACCOUNT_ROLE.LG:
    case ACCOUNT_ROLE.LG_CHIEF:
      switch (status) {
        case COMPANY_STATUS.WAITING_FOR_VERIFICATION:
        case COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT:
          return [{ key: COMPANY_ACTION.BLOCK, label: i18n.t('pages.company-details.actions.block') }];
        case COMPANY_STATUS.ACTIVE:
          return [
            { key: COMPANY_ACTION.BLOCK, label: i18n.t('pages.company-details.actions.block') },
            contractStatus !== CONTRACT_STATUS.INITIATED &&
              contractStatus !== CONTRACT_STATUS.GENERATED &&
              contractStatus !== CONTRACT_STATUS.SIGNED && {
                key: CONTRACT_ACTION.SEND_CONTRACT,
                label: i18n.t('pages.company-details.actions.send-contract'),
              },
            (contractStatus === CONTRACT_STATUS.GENERATED || contractStatus === CONTRACT_STATUS.EXPIRED) && {
              key: CONTRACT_ACTION.CONFIRM_CONTRACT,
              label: i18n.t('pages.company-details.actions.confirm-contract'),
            },
            (contractStatus === CONTRACT_STATUS.INITIATED || contractStatus === CONTRACT_STATUS.GENERATED) && {
              key: CONTRACT_ACTION.REVOKE_CONTRACT,
              label: i18n.t('pages.company-details.actions.revoke-contract'),
            },
            contractStatus === CONTRACT_STATUS.SIGNED && {
              key: CONTRACT_ACTION.TERMINATE_CONTRACT,
              label: i18n.t('pages.company-details.actions.terminate-contract'),
            },
          ];
        case COMPANY_STATUS.IN_REWORK:
        case COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION:
        case COMPANY_STATUS.INACTIVE_AFTER_EDIT:
        case COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT:
        case COMPANY_STATUS.VERIFICATION_REJECTED:
        case COMPANY_STATUS.ARCHIVED:
          return [
            { key: COMPANY_ACTION.BLOCK, label: i18n.t('pages.company-details.actions.block') },
            contractStatus === CONTRACT_STATUS.SIGNED && {
              key: CONTRACT_ACTION.TERMINATE_CONTRACT,
              label: i18n.t('pages.company-details.actions.terminate-contract'),
            },
          ];

        case COMPANY_STATUS.BLOCKED:
          return [
            { key: COMPANY_ACTION.UN_BLOCK, label: i18n.t('pages.company-details.actions.unblock') },
            contractStatus === CONTRACT_STATUS.SIGNED && {
              key: CONTRACT_ACTION.TERMINATE_CONTRACT,
              label: i18n.t('pages.company-details.actions.terminate-contract'),
            },
          ];
        case COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION:
          return [
            { key: COMPANY_ACTION.BLOCK, label: i18n.t('pages.company-details.actions.block') },

            contractStatus !== CONTRACT_STATUS.SIGNED &&
              contractStatus !== CONTRACT_STATUS.TERMINATED &&
              contractStatus !== CONTRACT_STATUS.SIGNING_CANCELED && {
                key: CONTRACT_ACTION.CONFIRM_CONTRACT,
                label: i18n.t('pages.company-details.actions.confirm-contract'),
              },

            (contractStatus === CONTRACT_STATUS.INITIATED ||
              contractStatus === CONTRACT_STATUS.GENERATED ||
              contractStatus === CONTRACT_STATUS.EXPIRED) && {
              key: CONTRACT_ACTION.REVOKE_CONTRACT,
              label: i18n.t('pages.company-details.actions.revoke-contract'),
            },

            contractStatus === CONTRACT_STATUS.SIGNED && {
              key: CONTRACT_ACTION.TERMINATE_CONTRACT,
              label: i18n.t('pages.company-details.actions.terminate-contract'),
            },

            (contractStatus === CONTRACT_STATUS.SIGNED ||
              contractStatus === CONTRACT_STATUS.SIGNING_CANCELED ||
              contractStatus === CONTRACT_STATUS.TERMINATED) && {
              key: COMPANY_ACTION.ACTIVATE_AFTER_RULES_VIOLATION,
              label: i18n.t('pages.company-details.actions.activate'),
            },
          ];
      }
  }
  return [];
};

const CompanyActionsDropdown = props => {
  const { onClick, options } = props;
  const { t } = useTranslation();
  return (
    <Dropdown
      trigger="click"
      overlay={
        <Menu onClick={onClick}>
          {options.map(({ key, label }) => (
            <Menu.Item key={key}>{label}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button shape="round" danger>
        {t('pages.company-details.actions')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const viewer = useContext(ViewerContext);

  const companyState = useSelector(state => state.company);
  const contractState = useSelector(state => state.contract);
  const { [id]: { data: company } = {} } = useSelector(state => state.company.get);
  const companyActions = useMemo(() => {
    const actions = getCompanyActions(
      viewer?.role,
      company?.general?.status,
      company?.contracts?.[CONTRACT_TYPE.AGREEMENT]?.status
    );
    return actions.filter(i => !!i);
  }, [viewer?.role, company?.general?.status, company?.contracts]);

  const isSBActionsAvailable = useMemo(() => {
    const status = company?.general?.status;
    return (
      viewer?.role === ACCOUNT_ROLE.SB &&
      (status === COMPANY_STATUS.NEW ||
        status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
        status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION ||
        status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT)
    );
  }, [company, viewer]);

  const isLGActionsAvailable = useMemo(() => {
    const status = company?.general?.status;
    return (
      (viewer?.role === ACCOUNT_ROLE.LG || viewer?.role === ACCOUNT_ROLE.LG_CHIEF) &&
      (status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
        status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
        status === COMPANY_STATUS.ACTIVE ||
        status === COMPANY_STATUS.IN_REWORK ||
        status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION ||
        status === COMPANY_STATUS.INACTIVE_AFTER_EDIT ||
        status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT ||
        status === COMPANY_STATUS.VERIFICATION_REJECTED ||
        status === COMPANY_STATUS.BLOCKED ||
        status === COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION ||
        status === COMPANY_STATUS.ARCHIVED)
    );
  }, [company, viewer]);

  const handleBlock = useCallback(
    comment => {
      dispatch(hideModal(MODALS.ConfirmCompanyBlock));
      dispatch(blockCompany({ comment, id }));
    },
    [dispatch, id]
  );

  const handleUnBlock = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmCompanyUnBlock));
    dispatch(unBlockCompany({ id }));
  }, [dispatch, id]);

  const handleVerify = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmCompanyVerify));
    dispatch(verifyCompany({ id }));
  }, [dispatch, id]);

  const handleRefine = useCallback(
    comment => {
      dispatch(hideModal(MODALS.ConfirmCompanyRefine));
      dispatch(refineCompany({ comment, id }));
    },
    [dispatch, id]
  );

  const handleReject = useCallback(
    comment => {
      dispatch(rejectCompany({ comment, id }));
    },
    [dispatch, id]
  );

  const handleConfirmContract = useCallback(
    date => {
      const contract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];
      dispatch(confirmContractSign({ company: id, contract: contract?.id, date }));
    },
    [dispatch, id, company]
  );

  const handleConfirmTerminate = useCallback(
    comment => {
      const contract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];
      dispatch(terminateContract({ comment, company: id, contract: contract?.id }));
    },
    [dispatch, id, company]
  );

  const handleClickAction = useCallback(
    ({ key }) => {
      switch (key) {
        case COMPANY_ACTION.ACTIVATE_AFTER_RULES_VIOLATION:
          dispatch(activateCompany(id));
          break;
        case COMPANY_ACTION.BLOCK:
          dispatch(showModal({ modal: MODALS.ConfirmCompanyBlock, onOk: handleBlock }));
          break;
        case COMPANY_ACTION.UN_BLOCK:
          dispatch(showModal({ modal: MODALS.ConfirmCompanyUnBlock, onOk: handleUnBlock }));
          break;
        case COMPANY_ACTION.CONFIRM_VERIFICATION:
          dispatch(showModal({ modal: MODALS.ConfirmCompanyVerify, onOk: handleVerify }));
          break;
        case COMPANY_ACTION.SEND_FOR_REWORK:
          dispatch(showModal({ modal: MODALS.ConfirmCompanyRefine, onOk: handleRefine }));
          break;
        case COMPANY_ACTION.REJECT_VERIFICATION:
          dispatch(showModal({ modal: MODALS.ConfirmCompanyReject, onOk: handleReject }));
          break;
        case CONTRACT_ACTION.REVOKE_CONTRACT: {
          const contract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];
          dispatch(revokeContract({ company: id, contract: contract?.id }));
          break;
        }
        case CONTRACT_ACTION.CONFIRM_CONTRACT: {
          dispatch(showModal({ modal: MODALS.ConfirmContractSign, onOk: handleConfirmContract }));
          break;
        }
        case CONTRACT_ACTION.SEND_CONTRACT:
          dispatch(sendContract({ id }));
          break;
        case CONTRACT_ACTION.TERMINATE_CONTRACT: {
          dispatch(showModal({ modal: MODALS.ConfirmContractTerminate, onOk: handleConfirmTerminate }));
          break;
        }
      }
    },
    [
      dispatch,
      handleBlock,
      handleRefine,
      handleReject,
      handleVerify,
      handleUnBlock,
      handleConfirmContract,
      handleConfirmTerminate,
      id,
      company?.contracts,
    ]
  );

  useEffect(() => {
    dispatch(getCompany(id));
  }, [id, dispatch]);

  useEffect(() => {
    const activateState = companyState[COMPANY_ACTION.ACTIVATE_AFTER_RULES_VIOLATION];
    const blockState = companyState[COMPANY_ACTION.BLOCK];
    const verifyState = companyState[COMPANY_ACTION.CONFIRM_VERIFICATION];
    const refineState = companyState[COMPANY_ACTION.SEND_FOR_REWORK];
    const rejectState = companyState[COMPANY_ACTION.REJECT_VERIFICATION];
    const unblockState = companyState[COMPANY_ACTION.UN_BLOCK];

    const confirmContractState = contractState[CONTRACT_ACTION.CONFIRM_CONTRACT];
    const revokeContractState = contractState[CONTRACT_ACTION.REVOKE_CONTRACT];
    const signContractState = contractState[CONTRACT_ACTION.SIGN_CONTRACT];
    const sendContractState = contractState[CONTRACT_ACTION.SEND_CONTRACT];
    const terminateContractState = contractState[CONTRACT_ACTION.TERMINATE_CONTRACT];

    if (
      activateState?.status === statusType.SUCCESSED ||
      blockState?.status === statusType.SUCCESSED ||
      verifyState?.status === statusType.SUCCESSED ||
      refineState?.status === statusType.SUCCESSED ||
      rejectState?.status === statusType.SUCCESSED ||
      unblockState?.status === statusType.SUCCESSED ||
      confirmContractState?.status === statusType.SUCCESSED ||
      revokeContractState?.status === statusType.SUCCESSED ||
      signContractState?.status === statusType.SUCCESSED ||
      sendContractState?.status === statusType.SUCCESSED ||
      terminateContractState?.status === statusType.SUCCESSED
    ) {
      history.push(pageUrls.COMPANY.ITEM(id));
    }
  }, [history, companyState, contractState, id]);

  const { general } = company || {};
  const contract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];

  return (
    <DashboardLayout>
      <BaseContent
        header={
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={pageUrls.COMPANY.LIST} style={{ color: colors.blueGray5 }}>
                    <ArrowLeftOutlined /> {t('common.transport-companies')}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row justify="space-between">
                <Col>
                  <Space direction="horizontal" align="end">
                    <Title level={4} style={{ margin: 0 }}>
                      {general?.propertyType && t(`pages.company.ownership-type-short-${general.propertyType}`)}{' '}
                      {general?.name}
                    </Title>
                    {general?.status && <CompanyStatusTag status={general?.status} />}
                  </Space>
                </Col>
                <Col>
                  {(isSBActionsAvailable || isLGActionsAvailable) && (
                    <CompanyActionsDropdown onClick={handleClickAction} options={companyActions} />
                  )}
                </Col>
                <Col span={24}>
                  <Typography.Text type="secondary" style={{ color: colors.blueGray4, fontSize: 12 }}>
                    {t('common.since')} {moment(general?.lastUpdated).format(DATE_FORMAT)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>

            {(general?.status === COMPANY_STATUS.IN_REWORK ||
              general?.status === COMPANY_STATUS.BLOCKED ||
              general?.status === COMPANY_STATUS.VERIFICATION_REJECTED) &&
              general?.commentOnLastStateChange && (
                <Col span={16}>
                  <Typography.Text type="secondary" style={{ color: colors.blueGray5 }}>
                    {t('common.reason')}: {general?.commentOnLastStateChange}
                  </Typography.Text>
                </Col>
              )}
            <Col span={24}>
              <Row style={{ color: colors.blueGray5 }}>
                <Col span={24}>
                  {t('common.contract')}: <ContractStatusTag status={contract?.status} />
                </Col>
                {contract?.terminationReason && (
                  <Col span={16}>
                    <Typography.Text type="secondary" style={{ color: colors.blueGray5 }}>
                      {t('common.terminate-reason')}: {contract?.terminationReason}
                    </Typography.Text>
                  </Col>
                )}
                {contract?.validFrom && (
                  <Col span={24}>
                    <Typography.Text type="secondary" style={{ color: colors.blueGray5 }}>
                      {t('common.contract-since')}: {moment(contract?.validFrom).format(DATE_FORMAT)}
                    </Typography.Text>
                  </Col>
                )}
                {(contract?.status === CONTRACT_STATUS.EXPIRED ||
                  contract?.status === CONTRACT_STATUS.GENERATED ||
                  contract?.status === CONTRACT_STATUS.INITIATED) &&
                  contract?.createdOn && (
                    <Col span={24}>
                      <Typography.Text type="secondary" style={{ color: colors.blueGray5 }}>
                        {t('common.contract-created')}: {moment(contract?.createdOn).format(DATE_FORMAT)}
                      </Typography.Text>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
        }
        contentSpan={24}
      >
        <Row gutter={[0, 32]}>
          <SectionGeneral allowEdit={false} data={company} NoData={t('common.no-data')} />
          <SectionContacts allowEdit={false} data={company} NoData={t('common.no-data')} />
          <SectionDocuments allowEdit={false} data={company} reqiured={false} NoData={t('common.no-data')} />
        </Row>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(CompanyDetails);
