import React, { memo } from 'react';

import classes from './styles/Home.module.scss';

import Banner1 from './Banner1';
import Content from './Content';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature3 from './Feature3';
import Feature4 from './Feature4';
import Footer from './Footer';
import Header from './Header';
import Map from './Map';

const Home = () => {
  return (
    <div className={classes.layout}>
      <Header />
      <Banner1 />
      <Content />
      <Feature1 />
      <Feature2 />
      <Map />
      <Feature3 />
      <Feature4 />
      <Footer />
    </div>
  );
};

export default memo(Home);
