import { CONTRACT_ACTION } from '@/constants/contract';
import statusType from '../constants/statusType';
import {
  CONFIRM_CONTRACT_ERROR,
  CONFIRM_CONTRACT_LOADING,
  CONFIRM_CONTRACT_SUCCESS,
  GENERATE_CONTRACT_ERROR,
  GENERATE_CONTRACT_LOADING,
  GENERATE_CONTRACT_SUCCESS,
  REVOKE_CONTRACT_ERROR,
  REVOKE_CONTRACT_LOADING,
  REVOKE_CONTRACT_SUCCESS,
  SEND_CONTRACT_ERROR,
  SEND_CONTRACT_LOADING,
  SEND_CONTRACT_SUCCESS,
  TERMINATE_CONTRACT_ERROR,
  TERMINATE_CONTRACT_LOADING,
  TERMINATE_CONTRACT_SUCCESS,
} from '../constants/actionTypes';

const initialState = {};

const alertsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEND_CONTRACT_ERROR:
      return { ...state, [CONTRACT_ACTION.SEND_CONTRACT]: { status: statusType.FAILED } };

    case SEND_CONTRACT_LOADING:
      return { ...state, [CONTRACT_ACTION.SEND_CONTRACT]: { data: payload, status: statusType.LOADING } };

    case SEND_CONTRACT_SUCCESS:
      return { ...state, [CONTRACT_ACTION.SEND_CONTRACT]: { data: payload, status: statusType.SUCCESSED } };

    case CONFIRM_CONTRACT_ERROR:
      return { ...state, [CONTRACT_ACTION.CONFIRM_CONTRACT]: { status: statusType.FAILED } };

    case CONFIRM_CONTRACT_LOADING:
      return { ...state, [CONTRACT_ACTION.CONFIRM_CONTRACT]: { status: statusType.LOADING } };

    case CONFIRM_CONTRACT_SUCCESS:
      return { ...state, [CONTRACT_ACTION.CONFIRM_CONTRACT]: { status: statusType.SUCCESSED } };

    case REVOKE_CONTRACT_ERROR:
      return { ...state, [CONTRACT_ACTION.REVOKE_CONTRACT]: { status: statusType.FAILED } };

    case REVOKE_CONTRACT_LOADING:
      return { ...state, [CONTRACT_ACTION.REVOKE_CONTRACT]: { status: statusType.LOADING } };

    case REVOKE_CONTRACT_SUCCESS:
      return { ...state, [CONTRACT_ACTION.REVOKE_CONTRACT]: { status: statusType.SUCCESSED } };

    case TERMINATE_CONTRACT_ERROR:
      return { ...state, [CONTRACT_ACTION.TERMINATE_CONTRACT]: { status: statusType.FAILED } };

    case TERMINATE_CONTRACT_LOADING:
      return { ...state, [CONTRACT_ACTION.TERMINATE_CONTRACT]: { status: statusType.LOADING } };

    case TERMINATE_CONTRACT_SUCCESS:
      return { ...state, [CONTRACT_ACTION.TERMINATE_CONTRACT]: { status: statusType.SUCCESSED } };

    case GENERATE_CONTRACT_ERROR:
      return { ...state, [CONTRACT_ACTION.GENERATE_CONTRACT]: { status: statusType.FAILED } };

    case GENERATE_CONTRACT_LOADING:
      return { ...state, [CONTRACT_ACTION.GENERATE_CONTRACT]: { status: statusType.LOADING } };

    case GENERATE_CONTRACT_SUCCESS:
      return { ...state, [CONTRACT_ACTION.GENERATE_CONTRACT]: { status: statusType.SUCCESSED } };

    default:
      return state;
  }
};

export default alertsReducer;
