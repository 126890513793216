import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Col, Image, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLayout } from '@/containers/layout';
import { convertSecondsToHMS } from '@/utils/timeUtils';
import { getEmailDetails } from '@/store/actions/emailDetailsActions';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { Card, Text } from '@/components';
import { CHANGE_EMAIL, GET_EMAIL_DETAILS } from '@/store/constants/actionTypes';
import { changeEmail, getAccount } from '@/store/actions/accountActions';

import imageEmail from '@/assets/images/email.png';

import classes from './ChangeEmailSuccess.module.scss';

const ChangeEmailSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const intervalRef = useRef();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [seconds, setSeconds] = useState();
  const query = useQuery();

  const id = query.get('id');
  const email = query.get('email');

  const account = useSelector(state => state.account);
  const details = useSelector(state => state.emailDetails);
  const { status: changeEmailStatus } = useSelector(state => state.changeEmail);

  const handleClickResend = useCallback(() => {
    dispatch(changeEmail({ email, id, otps: { email: account?.data?.otps?.email } }));
  }, [dispatch, account, id, email]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => s - 1);
  }, []);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED) {
      clearInterval(intervalRef.current);
      if (details?.data?.retryInSeconds > 0) {
        setIsResendDisabled(true);
        setSeconds(details.data.retryInSeconds);
        intervalRef.current = setInterval(updateSeconds, 1000);
      } else {
        setIsResendDisabled(false);
      }
      dispatch({
        type: GET_EMAIL_DETAILS,
      });
    }
  }, [details, dispatch, updateSeconds]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      setIsResendDisabled(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (changeEmailStatus === statusType.SUCCESSED) {
      dispatch(getEmailDetails({ id, target: 'email' }));
      dispatch({
        type: CHANGE_EMAIL,
      });
    }
  }, [dispatch, changeEmailStatus, id]);

  useEffect(() => {
    dispatch(getEmailDetails({ id, target: 'email' }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAccount({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageEmail} preview={false} />
            </Row>
          </Col>
          <Row gutter={[0, 16]} className={classes.description}>
            <Col span={24}>
              <Row justify="center">
                <Typography.Title level={3} weight="semibold" align="center">
                  {t('pages.change-email-success.title')}
                </Typography.Title>
              </Row>
            </Col>
            <Col span={24}>
              <Text block size={14} height={22} align="center">
                <Trans i18nKey="pages.change-email-success.description" values={{ email }}>
                  [<Text color="main-6" />]
                </Trans>
              </Text>
            </Col>
            {seconds > 0 && (
              <Col span={24}>
                <Text align="center" color="red-6" block>
                  <Trans
                    i18nKey="common.resend-email-available-in"
                    values={{ time: convertSecondsToHMS(seconds, true) }}
                  >
                    [<Text size={20} weight="semibold" color="red-6" />]
                  </Trans>
                </Text>
              </Col>
            )}
            <Col span={24}>
              <Button
                type="primary"
                shape="round"
                size="large"
                disabled={isResendDisabled}
                block
                onClick={handleClickResend}
              >
                {t('common.resend')}
              </Button>
            </Col>
          </Row>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(ChangeEmailSuccess);
