import storage, { KEY_ACCOUNT } from '@/services/storageService';

import statusType from '../constants/statusType';
import {
  GET_ACCOUNT_LOADING,
  GET_ACCOUNT_SUCCESS,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_UP_SUCCESS,
} from '../constants/actionTypes';

const account = storage.getValue(KEY_ACCOUNT);

const initialState = {
  authorized: !!account,
  data: account,
  status: statusType.IDLE,
};

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACCOUNT_LOADING:
      return { ...state, status: statusType.LOADING };

    case GET_ACCOUNT_SUCCESS:
      return { ...state, data: payload, status: statusType.SUCCESSED };

    case SIGN_IN_SUCCESS:
      return { ...state, authorized: true, data: payload };

    case SIGN_OUT_SUCCESS:
      return initialState;

    case SIGN_UP_SUCCESS:
      return { ...state, data: payload, status: statusType.SUCCESSED };

    default:
      return state;
  }
};

export default accountReducer;
