import React, { memo, useCallback, useEffect, useState } from 'react';

import { AutoComplete, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { findEmployees } from '@/store/actions/employeesActions';
import { useTranslation } from 'react-i18next';

const EmployeeAutoComplete = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    onChange,
    onSearch,
    placeholder = t('placeholder.fullName'),
    showSearch = true,
    showClear = false,
    size = 'large',
    value,
  } = props;
  const { data: employeesData } = useSelector(state => state.employees?.find);
  const [employee, setEmployee] = useState(value?.fullName || value);
  const [employees, setEmployees] = useState([]);

  const handleSearchEmployee = useCallback(
    name => {
      setEmployee(name);

      if (name?.length > 2) {
        dispatch(findEmployees({ name }));
      } else if (!name || name?.length === 0) {
        onChange();
      }
      if (onSearch) {
        onSearch(name);
      }
    },
    [dispatch, onChange, onSearch]
  );

  const handleSelectEmployee = useCallback(
    (id, { label }) => {
      setEmployee(label);
      if (onChange) {
        onChange(id);
      }
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    if (onChange) {
      onChange();
    }
  }, [onChange]);

  useEffect(() => {
    if (employeesData?.length > 0) {
      setEmployees(
        employeesData?.map(({ firstName, lastName, middleName, id }) => {
          return {
            label: `${lastName || ''} ${firstName || ''} ${middleName || ''}`,
            value: id,
          };
        })
      );
    }
  }, [employeesData]);

  useEffect(() => {
    if (!value) {
      setEmployee(null);
    }
  }, [value]);

  return (
    <AutoComplete
      style={{ width: '100%' }}
      showSearch={showSearch}
      allowClear={showClear}
      value={employee}
      options={employees}
      onSearch={handleSearchEmployee}
      onSelect={handleSelectEmployee}
      onClear={handleClear}
    >
      {showSearch ? (
        <Input.Search size={size} placeholder={placeholder} />
      ) : (
        <Input size={size} placeholder={placeholder} />
      )}
    </AutoComplete>
  );
};

export default memo(EmployeeAutoComplete);
