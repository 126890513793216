import React, { memo } from 'react';

import { TENDER_GROUP, TENDER_STATUS } from '@/constants/tender';

import TendersTable from '../components/TendersTable';
import {
  colCreationDate,
  colRoute,
  colTenderType,
  colTradingDates,
  colTradingStatus,
  colTransportationDates,
} from '../components/TendersTable/columns';

const columns = {
  [TENDER_GROUP.ALL]: [
    colRoute,
    colTradingStatus,
    colTenderType,
    colTradingDates,
    colTransportationDates,
    colCreationDate,
  ],
};

const tabDefaultFilters = tab => {
  switch (tab) {
    case TENDER_GROUP.ALL:
      return [
        TENDER_STATUS.ACCEPTING,
        TENDER_STATUS.TRADING,
        TENDER_STATUS.CLOSED,
        TENDER_STATUS.READY,
        TENDER_STATUS.COMPLETED,
        TENDER_STATUS.CANCELED,
        TENDER_STATUS.FAILED,
      ];
  }
  return [tab];
};
const tabs = [TENDER_GROUP.ALL];

const Tenders = () => {
  return <TendersTable columns={columns} tabs={tabs} transformTabFilter={tabDefaultFilters} />;
};

export default memo(Tenders);
