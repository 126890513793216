import React, { memo } from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

const FormItem = props => {
  const { span = 10 } = props;
  return <Form.Item labelCol={{ span }} labelAlign="left" wrapperCol={{ span: 14 }} {...props} />;
};

FormItem.propTypes = {
  span: PropTypes.number,
};

export default memo(FormItem);
