import React, { memo, useCallback, useEffect } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Image, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal } from '@/store/actions/modalsActions';
import pageUrls from '@/constants/pageUrls';
import { recoverPassword } from '@/store/actions/passwordActions';
import statusType from '@/store/constants/statusType';
import storageService, { KEY_ACCOUNT } from '@/services/storageService';

import imageEmail from '@/assets/images/email.png';

import Modal from '../Modal';
import { MODALS } from '..';

const ConfirmPasswordChange = props => {
  const { email } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { status } = useSelector(state => state.passwordReset);

  const handleClickClose = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmPasswordChange));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    dispatch(recoverPassword(email));
  }, [dispatch, email]);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      dispatch(hideModal(MODALS.ConfirmPasswordChange));
      storageService.clearValue(KEY_ACCOUNT);
      history.push(`${pageUrls.PASSWORD_RESET_SUCCESS}?&email=${encodeURIComponent(email)}`);
    }
  }, [dispatch, history, status, email]);

  return (
    <Modal name={MODALS.ConfirmPasswordChange} width={600}>
      <Row gutter={[0, 24]} justify="center">
        <Row>
          <Image src={imageEmail} preview={false} />
        </Row>
        <Row>
          <Typography.Title level={3}>{t('modal.password-change-confirm.title')}</Typography.Title>
        </Row>
        <Row>
          <Typography.Text>{t('modal.password-change-confirm.description')}</Typography.Text>
        </Row>
        <Row>
          <Space size="middle">
            <Button shape="round" size="large" onClick={handleClickClose}>
              {t('common.cancel')}
            </Button>
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={handleClickOk}
              loading={status === statusType.LOADING}
            >
              {t('common.change-password')}
            </Button>
          </Space>
        </Row>
      </Row>
    </Modal>
  );
};

export default memo(ConfirmPasswordChange);
