import React, { memo } from 'react';

import { EnvironmentFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Col, Row, Space, Typography } from 'antd';

import colors from '@/styles/colors.module.scss';

import classes from './TenderVerticalRoute.module.scss';

const TenderVerticalRoute = props => {
  const { route = [] } = props;
  const pointA = route[0];
  const pointB = route[route.length - 1];
  return (
    route.length > 0 && (
      <Row>
        <Col span={24}>
          <Space wrap={false}>
            <div className={classes.ellipse} />
            <Typography className={classes.location}>{`${pointA?.city}, ${pointA?.country}`}</Typography>
          </Space>
        </Col>
        <Col span={24} className={classes.route} />
        <Col span={24}>
          <Space wrap={false}>
            <EnvironmentFilled style={{ color: colors.blueGray05 }} />
            <Typography className={classes.location}>{`${pointB?.city}, ${pointB?.country}`}</Typography>
          </Space>
        </Col>
      </Row>
    )
  );
};

TenderVerticalRoute.propTypes = {
  route: PropTypes.arrayOf(PropTypes.shape({ city: PropTypes.string, country: PropTypes.string })),
};

export default memo(TenderVerticalRoute);
