import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import pageUrls from '@/constants/pageUrls';
import { DetailsItem, Section } from '@/components';

import RequiredValue from '../RequiredValue';

import classes from './SectionTechnoContacts.module.scss';

const SectionTechnoContacts = props => {
  const { t } = useTranslation();

  const { data: { contacts } = {}, NoData } = props;
  const { head, ceo, contact } = contacts || {};

  return (
    <Section
      title={t('pages.company.contact-information')}
      to={pageUrls.COMPANY.EDIT.CONTACTS}
      className={classes.detailsList}
    >
      <DetailsItem
        label={t('common.logistic-director')}
        value={
          <RequiredValue fields={['lastName', 'firstName', 'middleName']} target={ceo} NoData={NoData} joinBy=" " />
        }
      />
      <DetailsItem label={t('common.ceo.email')} value={<RequiredValue target={ceo?.email} NoData={NoData} />} />
      <DetailsItem label={t('common.ceo.phone')} value={<RequiredValue target={ceo?.phone} NoData={NoData} />} />
      {ceo?.contactPerson === true ? (
        <DetailsItem
          label={t('pages.company.contact-person')}
          value={t('common.contact-person.ceo-is-contact-person')}
        />
      ) : (
        <>
          <DetailsItem
            label={t('pages.company.contact-person')}
            value={
              <RequiredValue
                fields={['lastName', 'firstName', 'middleName']}
                target={contact}
                joinBy={' '}
                NoData={NoData}
              />
            }
          />
          <DetailsItem
            label={t('pages.company.contact-person.position')}
            value={<RequiredValue target={contact?.position} NoData={NoData} />}
          />
          <DetailsItem
            label={t('pages.company.contact-person.email')}
            value={<RequiredValue target={contact?.email} NoData={NoData} />}
          />
          <DetailsItem
            label={t('pages.company.contact-person.phone')}
            value={<RequiredValue target={contact?.phone} NoData={NoData} />}
          />
        </>
      )}
      <DetailsItem
        label={t('common.logistic-head')}
        value={
          <RequiredValue fields={['lastName', 'firstName', 'middleName']} target={head} NoData={NoData} joinBy=" " />
        }
      />
    </Section>
  );
};

export default memo(SectionTechnoContacts);
