import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import * as fieldRules from '@/constants/fieldRules';

const Email = props => {
  const { t } = useTranslation();
  const {
    autoFocus,
    autoComplete,
    error,
    label = t('common.email'),
    name = 'email',
    onChange,
    placeholder = 'example@expl.com',
    rules = [],
    ...rest
  } = props;

  const formProps = {
    label,
    name,
    rules: [...fieldRules.email, ...fieldRules.required, ...rules],
    ...rest,
  };
  if (error) {
    formProps.validateStatus = 'error';
    formProps.help = error;
  }

  const handleChange = useCallback(
    event => {
      const { value } = event.target;
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Form.Item {...formProps}>
      <Input
        autoComplete={autoComplete}
        name={name}
        placeholder={placeholder}
        type="email"
        onChange={handleChange}
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};

Email.propTypes = {
  autoComplete: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(Email);
