import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Link as RoutLink } from 'react-router-dom';
import { Typography } from 'antd';

const Link = props => {
  const { children, to, ...rest } = props;

  return (
    <RoutLink to={to} component={Typography.Link} {...rest}>
      {children}
    </RoutLink>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default memo(Link);
