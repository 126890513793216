import React, { memo, useCallback, useEffect, useState } from 'react';

import { Input } from 'antd';
import moment from 'moment';

import { DatePicker, TimePicker } from '@/components';

const DateTimePicker = props => {
  const { value, onChange, DatePickerProps, TimePickerProps } = props;

  const [dateMoment, setDateMoment] = useState();
  const [dateValue, setDateValue] = useState();
  const [timeValue, setTimeValue] = useState();

  const handleChangeDate = useCallback(
    newDate => {
      setDateMoment(newDate);

      const { date, months, years } = newDate?.toObject();
      const ts = moment({ date, months, years }).valueOf();
      if (onChange) {
        onChange(ts);
      }
    },
    [onChange]
  );

  const handleChangeTime = useCallback(
    newTime => {
      const { hours = 0, minutes = 0, seconds = 0 } = newTime?.toObject() || {};
      const { date, months, years } = dateMoment?.toObject() || {};

      const ts = moment({ date, months, years, hours, minutes, seconds }).valueOf();
      if (onChange) {
        onChange(ts);
      }
    },
    [dateMoment, onChange]
  );

  useEffect(() => {
    if (value) {
      setDateValue(moment(value));
      setTimeValue(moment(value));
    }
  }, [value]);

  return (
    <Input.Group>
      <DatePicker
        style={{ marginRight: 2, width: 'calc(60% - 2px)' }}
        onChange={handleChangeDate}
        value={dateValue}
        {...DatePickerProps}
      />
      <TimePicker
        style={{ width: '40%' }}
        size="large"
        format="HH:mm"
        showNow={false}
        onChange={handleChangeTime}
        value={timeValue}
        {...TimePickerProps}
      />
    </Input.Group>
  );
};

export default memo(DateTimePicker);
