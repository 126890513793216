import React, { memo } from 'react';

import i18next from 'i18next';
import { Avatar, Row, Space } from 'antd';

import { formatMoney } from '@/utils/format';
import pageUrls from '@/constants/pageUrls';
import { Link, Table } from '@/components';

const columns = [
  {
    key: 'name',
    dataIndex: ['company', 'general'],
    title: i18next.t('common.company-name'),
    render: ({ logo, name, id }) => (
      <Link to={pageUrls.COMPANY.ITEM(id)}>
        <Space>
          <Avatar size={40} src={logo} />
          {name}
        </Space>
      </Link>
    ),
  },
  {
    key: 'bid',
    title: i18next.t('common.tender.initial-bid'),
    render: detail => <Row align="middle">{formatMoney(detail?.initialBid, detail?.currency)}</Row>,
  },
  {
    key: 'stage-1',
    title: i18next.t('common.tender.stage-1'),
    render: detail =>
      detail?.bidOnPhaseOne && <Row align="middle">{formatMoney(detail?.bidOnPhaseOne, detail?.currency)}</Row>,
  },
  {
    key: 'stage-2',
    title: i18next.t('common.tender.stage-2'),
    render: detail =>
      detail?.bidOnPhaseTwo && <Row align="middle">{formatMoney(detail?.bidOnPhaseTwo, detail?.currency)}</Row>,
  },
  {
    key: 'stage-3',
    title: i18next.t('common.tender.stage-3'),
    render: detail =>
      detail?.bidOnPhaseThree && <Row align="middle">{formatMoney(detail?.bidOnPhaseThree, detail?.currency)}</Row>,
  },
];

const TenderMembers = props => {
  const { details } = props;
  return <Table data={details} rowKey="companyId" columns={columns} />;
};

export default memo(TenderMembers);
