import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Divider, Row, Tag } from 'antd';

import pageUrls from '@/constants/pageUrls';
import { DetailsItem, Section, TagRequired } from '@/components';

import RequiredValue from '../RequiredValue';

import classes from './SectionGeneral.module.scss';

const SectionGeneral = props => {
  const { t } = useTranslation();

  const {
    allowEdit = true,
    data: { addresses, general, transports } = {},
    NoData = <TagRequired />,
    showEmptyMailAddress = false,
    excludeFields,
    onEdit,
  } = props;

  return (
    <Section
      title={t('pages.company.general-information')}
      to={allowEdit && pageUrls.COMPANY.EDIT.GENERAL}
      className={classes.detailsList}
      onEdit={onEdit}
    >
      <Row align="middle" gutter={[16, 0]}>
        <Col>
          <Avatar size={64} src={general?.logo} />
        </Col>
        <Col>{general?.name}</Col>
      </Row>

      <Divider className={classes.divider} />

      <DetailsItem label={t('pages.company.bin-number')} value={general?.bin} />
      <DetailsItem
        label={t('pages.company.company-type')}
        value={
          general?.propertyType
            ? t(`pages.company.ownership-type-${general?.propertyType?.toLocaleLowerCase()}`, general?.propertyType)
            : NoData
        }
      />
      <DetailsItem
        label={t('pages.company.vat-certificate-number')}
        value={general?.vatCertificateNumber ? general.vatCertificateNumber : NoData}
      />
      {excludeFields?.indexOf('transportationType') >= 0 || (
        <DetailsItem
          label={t('pages.company.transportation-type')}
          value={
            general?.transportationType ? t(`pages.company.transportation-type.${general.transportationType}`) : NoData
          }
        />
      )}
      {excludeFields?.indexOf('transportsAvailable') >= 0 || (
        <DetailsItem
          label={t('pages.company.transport-park')}
          value={
            general?.transportsAvailable === false
              ? t('pages.company.transport-park-empty')
              : transports?.length > 0
              ? transports.map(({ vehicle, capacity }, index) => (
                  <Row key={`key-vehicle-${index}`}>
                    {`${t(`common.vehicle-type-${vehicle}`)} - ${t(`common.vehicle-capacity-${vehicle}`, {
                      capacity,
                    })}`}
                  </Row>
                ))
              : NoData
          }
        />
      )}
      <DetailsItem
        label={t('pages.company.registration-address')}
        value={
          <RequiredValue
            fields={['zipCode', 'country', 'city', 'address']}
            target={addresses?.registration}
            NoData={NoData}
          />
        }
      />
      {general?.sameMailAddress ? (
        <DetailsItem label={t('pages.company.mail-address')} value={t('pages.company.same-mail-address')} />
      ) : (
        (showEmptyMailAddress || addresses?.mail) && (
          <DetailsItem
            label={t('pages.company.mail-address')}
            value={
              <RequiredValue
                fields={['zipCode', 'country', 'city', 'address']}
                target={addresses?.mail}
                NoData={NoData}
              />
            }
          />
        )
      )}
    </Section>
  );
};

PropTypes.propTypes = {
  data: PropTypes.shape({}),
  NoData: PropTypes.node,
  onEdit: PropTypes.func,
};

export default memo(SectionGeneral);
