import statusType from '../constants/statusType';
import {
  ADD_TENDER,
  ADD_TENDER_ERROR,
  ADD_TENDER_LOADING,
  ADD_TENDER_SUCCESS,
  BID_TENDER_ERROR,
  BID_TENDER_LOADING,
  BID_TENDER_SUCCESS,
  CANCEL_TENDER_ERROR,
  CANCEL_TENDER_LOADING,
  CANCEL_TENDER_SUCCESS,
  COMPLETE_TENDER_ERROR,
  COMPLETE_TENDER_LOADING,
  COMPLETE_TENDER_SUCCESS,
  DELETE_TENDER_ERROR,
  DELETE_TENDER_LOADING,
  DELETE_TENDER_SUCCESS,
  DUPLICATE_TENDER_ERROR,
  DUPLICATE_TENDER_LOADING,
  DUPLICATE_TENDER_SUCCESS,
  EDIT_TENDER_ERROR,
  EDIT_TENDER_LOADING,
  EDIT_TENDER_SUCCESS,
  GET_TENDER_ACTIVITY_ERROR,
  GET_TENDER_ACTIVITY_LOADING,
  GET_TENDER_ACTIVITY_SUCCESS,
  GET_TENDER_DETAILS_ERROR,
  GET_TENDER_DETAILS_LOADING,
  GET_TENDER_DETAILS_SUCCESS,
  GET_TENDER_ERROR,
  GET_TENDER_LOADING,
  GET_TENDER_RESULTS_ERROR,
  GET_TENDER_RESULTS_LOADING,
  GET_TENDER_RESULTS_SUCCESS,
  GET_TENDER_SUCCESS,
  PUBLISH_TENDER_ERROR,
  PUBLISH_TENDER_LOADING,
  PUBLISH_TENDER_SUCCESS,
  READY_TENDER,
  READY_TENDER_ERROR,
  READY_TENDER_LOADING,
  READY_TENDER_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  acitivity: {
    status: statusType.IDLE,
  },
  add: {
    status: statusType.IDLE,
  },
  cancel: {
    status: statusType.IDLE,
  },
  delete: {
    status: statusType.IDLE,
  },
  duplicate: {
    status: statusType.IDLE,
  },
  edit: {
    status: statusType.IDLE,
  },
  get: {
    status: statusType.IDLE,
  },
  publish: {
    status: statusType.IDLE,
  },
};

const tenderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_TENDER: {
      return {
        ...state,
        add: {
          status: statusType.IDLE,
        },
      };
    }

    case ADD_TENDER_ERROR: {
      return {
        ...state,
        add: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case ADD_TENDER_LOADING: {
      return {
        ...state,
        add: {
          status: statusType.LOADING,
        },
      };
    }

    case ADD_TENDER_SUCCESS: {
      return {
        ...state,
        add: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case BID_TENDER_ERROR: {
      return {
        ...state,
        bid: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case BID_TENDER_LOADING: {
      return {
        ...state,
        bid: {
          status: statusType.LOADING,
        },
      };
    }

    case BID_TENDER_SUCCESS: {
      return {
        ...state,
        bid: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case COMPLETE_TENDER_ERROR: {
      return {
        ...state,
        complete: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case COMPLETE_TENDER_LOADING: {
      return {
        ...state,
        complete: {
          status: statusType.LOADING,
        },
      };
    }

    case COMPLETE_TENDER_SUCCESS: {
      return {
        ...state,
        complete: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case CANCEL_TENDER_ERROR: {
      return {
        ...state,
        cancel: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case CANCEL_TENDER_LOADING: {
      return {
        ...state,
        cancel: {
          status: statusType.LOADING,
        },
      };
    }

    case CANCEL_TENDER_SUCCESS: {
      return {
        ...state,
        cancel: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case DELETE_TENDER_ERROR: {
      return {
        ...state,
        delete: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case DELETE_TENDER_LOADING: {
      return {
        ...state,
        delete: {
          status: statusType.LOADING,
        },
      };
    }

    case DELETE_TENDER_SUCCESS: {
      return {
        ...state,
        delete: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case DUPLICATE_TENDER_ERROR: {
      return {
        ...state,
        duplicate: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case DUPLICATE_TENDER_LOADING: {
      return {
        ...state,
        duplicate: {
          status: statusType.LOADING,
        },
      };
    }

    case DUPLICATE_TENDER_SUCCESS: {
      return {
        ...state,
        duplicate: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case EDIT_TENDER_ERROR: {
      return {
        ...state,
        edit: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case EDIT_TENDER_LOADING: {
      return {
        ...state,
        edit: {
          status: statusType.LOADING,
        },
      };
    }

    case EDIT_TENDER_SUCCESS: {
      return {
        ...state,
        edit: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case GET_TENDER_ERROR: {
      return {
        ...state,
        get: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case GET_TENDER_LOADING: {
      return {
        ...state,
        get: {
          status: statusType.LOADING,
        },
      };
    }

    case GET_TENDER_SUCCESS: {
      return {
        ...state,
        get: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case GET_TENDER_ACTIVITY_ERROR: {
      return {
        ...state,
        activity: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case GET_TENDER_ACTIVITY_LOADING: {
      return {
        ...state,
        activity: {
          status: statusType.LOADING,
        },
      };
    }

    case GET_TENDER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        activity: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case GET_TENDER_DETAILS_ERROR: {
      return {
        ...state,
        details: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case GET_TENDER_DETAILS_LOADING: {
      return {
        ...state,
        details: {
          status: statusType.LOADING,
        },
      };
    }

    case GET_TENDER_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case GET_TENDER_RESULTS_ERROR: {
      return {
        ...state,
        results: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case GET_TENDER_RESULTS_LOADING: {
      return {
        ...state,
        results: {
          status: statusType.LOADING,
        },
      };
    }

    case GET_TENDER_RESULTS_SUCCESS: {
      return {
        ...state,
        results: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case PUBLISH_TENDER_ERROR: {
      return {
        ...state,
        publish: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case PUBLISH_TENDER_LOADING: {
      return {
        ...state,
        publish: {
          status: statusType.LOADING,
        },
      };
    }

    case PUBLISH_TENDER_SUCCESS: {
      return {
        ...state,
        publish: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    case READY_TENDER: {
      return {
        ...state,
        ready: {
          status: statusType.IDLE,
        },
      };
    }

    case READY_TENDER_ERROR: {
      return {
        ...state,
        ready: {
          error: payload,
          status: statusType.FAILED,
        },
      };
    }

    case READY_TENDER_LOADING: {
      return {
        ...state,
        ready: {
          status: statusType.LOADING,
        },
      };
    }

    case READY_TENDER_SUCCESS: {
      return {
        ...state,
        ready: {
          data: payload,
          status: statusType.SUCCESSED,
        },
      };
    }

    default:
      return state;
  }
};

export default tenderReducer;
