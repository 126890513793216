import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Col, Image, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import imageSuccess from '@/assets/images/emailVerified.png';

import { BaseLayout } from '@/containers/layout';
import { convertSecondsToHMS } from '@/utils/timeUtils';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { Card, Text } from '@/components';
import { getRecoverPasswordDetails, recoverPassword } from '@/store/actions/passwordActions';
import { RECOVER_PASSWORD, RECOVER_PASSWORD_DETAILS } from '@/store/constants/actionTypes';

import classes from './PasswordResetSuccess.module.scss';

const PasswordResetSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const intervalRef = useRef();
  const query = useQuery();
  const email = query.get('email');
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const { status, details } = useSelector(state => state.passwordReset);
  const [seconds, setSeconds] = useState();

  const handleClickResend = useCallback(() => {
    dispatch(recoverPassword(email));
    setIsResendDisabled(true);
  }, [dispatch, email]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => s - 1);
  }, []);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED) {
      clearInterval(intervalRef.current);
      if (details?.data?.retryInSeconds) {
        setIsResendDisabled(true);
        setSeconds(details.data.retryInSeconds);
        intervalRef.current = setInterval(updateSeconds, 1000);
      }
      dispatch({ type: RECOVER_PASSWORD_DETAILS });
    } else if (details?.status === statusType.FAILED) {
      setIsResendDisabled(false);
      clearInterval(intervalRef.current);
      dispatch({ type: RECOVER_PASSWORD_DETAILS });
    }
  }, [details, dispatch, updateSeconds]);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      dispatch(getRecoverPasswordDetails(email));
      dispatch({ type: RECOVER_PASSWORD });
    }
  }, [dispatch, email, status]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      setIsResendDisabled(false);
    }
  }, [intervalRef, seconds]);

  useEffect(() => {
    if (email) {
      dispatch(getRecoverPasswordDetails(email));
    }
  }, [dispatch, email]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]} justify="center">
          <Col span={24}>
            <Row justify="center">
              <Image src={imageSuccess} preview={false} />
            </Row>
          </Col>
          <Row>
            <Typography.Title level={3}>{t('pages.password-reset-success.title')}</Typography.Title>
          </Row>
          <Row>
            <Text block size={14} height={22} align="center">
              <Trans i18nKey="pages.password-reset-success.description" values={{ email }}>
                [<Text color="main-6" />]
              </Trans>
            </Text>
          </Row>
          {seconds > 0 && (
            <Col span={24}>
              <Text align="center" block>
                <Trans i18nKey="common.resend-email-available-in" values={{ time: convertSecondsToHMS(seconds, true) }}>
                  [<Typography.Title level={4} />]
                </Trans>
              </Text>
            </Col>
          )}
          <Col span={24}>
            <Button
              type="primary"
              shape="round"
              size="large"
              block
              disabled={isResendDisabled}
              onClick={handleClickResend}
            >
              {t('common.resend')}
            </Button>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordResetSuccess);
