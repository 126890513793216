import React, { memo } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Col, Divider, Row, Typography } from 'antd';

import classes from './BaseContent.module.scss';

const { Title } = Typography;

const BaseContent = props => {
  const {
    children,
    className,
    bodyClassname,
    header,
    headerDivider = true,
    footer,
    title,
    contentSpan = 18,
    contentGutter = [0, 12],
    contentAlign = 'center',
    titleLevel = 4,
  } = props;

  return (
    <Row className={clsx(classes.content, className)} justify="center" gutter={contentGutter && contentGutter}>
      {(title || header) && (
        <Col span={24}>
          <Col span={24} className={classes.header}>
            {title && (
              <Title level={titleLevel} className={clsx(classes.title)}>
                {title}
              </Title>
            )}
            {header}
          </Col>
          {headerDivider && <Divider className={classes.divider} />}
        </Col>
      )}
      <Col span={contentSpan} className={clsx(classes.body, bodyClassname)}>
        <Row justify={contentAlign}>{children}</Row>
      </Col>
      {footer && (
        <Col span={24} className={classes.footer}>
          {footer}
        </Col>
      )}
    </Row>
  );
};

BaseContent.propTypes = {
  bodyClassname: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentAlign: PropTypes.string,
  contentGutter: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.bool]),
  contentSpan: PropTypes.number,
  footer: PropTypes.node,
  header: PropTypes.node,
  headerDivider: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  titleLevel: PropTypes.number,
};

export default memo(BaseContent);
