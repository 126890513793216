import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { CONTRACT_STATUS } from '@/constants/contract';
import { contractStatusIcons } from '@/constants/company';

const ContractStatusTag = props => {
  const { status = CONTRACT_STATUS.FAKE_NOT_SIGNED } = props;
  const { t } = useTranslation();
  return (
    <Space>
      {contractStatusIcons[status]}
      {t(`pages.companies.contract.status.${status}`)}
    </Space>
  );
};

ContractStatusTag.propTypes = {
  status: PropTypes.string,
};

export default memo(ContractStatusTag);
