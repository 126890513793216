import React, { memo } from 'react';

import { Col, Image, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import imageFail from '@/assets/images/error.png';

import { BaseLayout } from '@/containers/layout';
import pageUrls from '@/constants/pageUrls';
import { Card, Link, Text } from '@/components';

import classes from './LinkExpired.module.scss';

const { Title } = Typography;

const LinkExpired = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageFail} preview={false} />
            </Row>
          </Col>
          <Row gutter={[0, 8]} className={classes.description}>
            <Col span={24}>
              <Title level={3} align="center">
                {t('pages.link-expired.link-has-expired')}
              </Title>
            </Col>
            <Col span={24}>
              <Text align="center" block>
                <Trans i18nKey="pages.link-expired.link-has-expired-try-again">
                  [<Link to={pageUrls.SIGN_UP} />,<Link to={pageUrls.PASSWORD_RECOVERY} />]
                </Trans>
              </Text>
            </Col>
          </Row>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(LinkExpired);
