import React, { memo, useCallback } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';
import Modal, { MODALS } from '@/views/modals';

const DeleteTenderDraft = props => {
  const { onOk, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = useCallback(() => {
    dispatch(hideModal(MODALS.DeleteTenderDraft));
    if (onCancel) {
      onCancel();
    }
  }, [dispatch, onCancel]);

  const handleClickYes = useCallback(() => {
    if (onOk) {
      onOk();
    }
  }, [onOk]);

  return (
    <Modal name={MODALS.DeleteTenderDraft} width={480} bodyStyle={{ padding: '32px' }} onClose={handleClickClose}>
      <Row gutter={[0, 24]}>
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Space align="start">
              <Col style={{ width: 30 }}>
                <ExclamationCircleOutlined color="calendulagold" style={{ fontSize: 24 }} />
              </Col>
              <Typography.Title level={5}>{t('modal.delete-tender-draft.title')}</Typography.Title>
            </Space>
          </Col>
          <Col span={24} style={{ paddingLeft: 38 }}>
            <Typography.Text level={3}>{t('modal.delete-tender-draft.description')}</Typography.Text>
          </Col>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Space size="middle">
              <Button shape="round" onClick={handleClickYes}>
                {t('modal.delete-tender-draft.button.yes')}
              </Button>
              <Button shape="round" type="primary" onClick={handleClickClose}>
                {t('modal.delete-tender-draft.button.no')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(DeleteTenderDraft);
