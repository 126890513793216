const storage = window.localStorage;

export const KEY_ACCOUNT = 'account';
export const KEY_SETTINGS = 'settings';

const setValue = (key, value) => {
  storage.setItem(key, JSON.stringify(value));
};

const getValue = key => {
  try {
    return JSON.parse(storage.getItem(key));
  } catch (err) {
    return {};
  }
};

const getSettings = () => getValue(KEY_SETTINGS);

const setSettings = override => {
  const settings = getSettings();
  setValue(KEY_SETTINGS, { ...settings, ...override });
};

const clearValue = key => {
  storage.removeItem(key);
};

export default { clearValue, getSettings, getValue, setSettings, setValue };
