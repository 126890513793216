import React, { memo, useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';

import * as fieldRules from '@/constants/fieldRules';
import { DatePicker } from '@/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PeriodSelector = props => {
  const { fromDate, FromPickerProps, ToPickerProps } = props;
  const [dateFrom, setDateFrom] = useState(fromDate);
  const { t } = useTranslation();

  const handleChangeFrom = useCallback(date => {
    setDateFrom(date);
  }, []);

  const disabledDateTo = useCallback(
    current => {
      return current && current <= moment(dateFrom).startOf('day');
    },
    [dateFrom]
  );

  const validateDateTo = useCallback(() => {
    return {
      message: t('error.field.incorrect-date-to'),
      validator(_, value) {
        if (value >= fromDate) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
    };
  }, [fromDate, t]);

  return (
    <Row gutter={[24, 0]}>
      <Col span={12} style={{ paddingRight: 0 }}>
        <Form.Item name={['conditions', 'transferStartDate']} noStyle rules={fieldRules.required}>
          <DatePicker onChange={handleChangeFrom} {...FromPickerProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={['conditions', 'transferEndDate']} noStyle rules={[...fieldRules.required, validateDateTo()]}>
          <DatePicker {...ToPickerProps} disabledDate={disabledDateTo} />
        </Form.Item>
      </Col>
    </Row>
  );
};

PeriodSelector.propTypes = {
  FromPickerProps: PropTypes.shape({}),
  ToPickerProps: PropTypes.shape({}),
};

export default memo(PeriodSelector);
