export const MODALS = {
  AddCompany: 'AddCompany',
  AddCompanySuccess: 'AddCompanySuccess',
  AddDomain: 'AddDomain',
  AddEmployee: 'AddEmployee',
  BlockEmployee: 'BlockEmployee',
  CancelTenderCreation: 'CancelTenderCreation',
  ChangeEmail: 'ChangeEmail',
  ConfirmBid: 'ConfirmBid',
  ConfirmCancelTender: 'ConfirmCancelTender',
  ConfirmCompanyArchive: 'ConfirmCompanyArchive',
  ConfirmCompanyBlock: 'ConfirmCompanyBlock',
  ConfirmCompanyEdit: 'ConfirmCompanyEdit',
  ConfirmCompanyRefine: 'ConfirmCompanyRefine',
  ConfirmCompanyReject: 'ConfirmCompanyReject',
  ConfirmCompanyUnBlock: 'ConfirmCompanyUnBlock',
  ConfirmCompanyVerify: 'ConfirmCompanyVerify',
  ConfirmContractSign: 'ConfirmContractSign',
  ConfirmContractTerminate: 'ConfirmContractTerminate',
  ConfirmDeleteDomain: 'ConfirmDeleteDomain',
  ConfirmInitialBid: 'ConfirmInitialBid',
  ConfirmPasswordChange: 'ConfirmPasswordChange',
  ConfirmTenderClose: 'ConfirmTenderClose',
  ConfirmTenderReady: 'ConfirmTenderReady',
  ContractSignInstructions: 'ContractSignInstructions',
  CreateCompaniesReport: 'CreateCompaniesReport',
  CreateReportComplete: 'CreateReportComplete',
  CreateTendersReport: 'CreateTendersReport',
  DeleteTenderDraft: 'DeleteTenderDraft',
  DuplicateTender: 'DuplicateTender',
  EditEmployee: 'EditEmployee',
  Error: 'Error',
  Oops: 'Oops',
  PublishTender: 'PublishTender',
  RecoverPasswordSuccess: 'RecoverPasswordSuccess',
  Support: 'Support',
};

export { default } from './Modal';
