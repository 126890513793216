import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row } from 'antd';

import { BaseLayout } from '@/containers/layout';
import pageUrls from '@/constants/pageUrls';
import { Card, Text } from '@/components';

import imagePasswordChangeSuccess from '@/assets/images/password.png';

import classes from './PasswordChangeSuccess.module.scss';

const PasswordChangeSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickEnter = useCallback(() => {
    history.push(pageUrls.MAIN);
  }, [history]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imagePasswordChangeSuccess} preview={false} />
            </Row>
          </Col>
          <Col span={24}>
            <Text block size={24} height={32} align="center" weight="semibold">
              {t('common.you-successfully-changed-password')}
            </Text>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Button htmlType="submit" type="primary" size="large" shape="round" onClick={handleClickEnter}>
                <Text align="center">{t('common.enter-application')}</Text>
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordChangeSuccess);
