import React, { memo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_ACTION } from '@/constants/company';
import { hideModal } from '@/store/actions/modalsActions';
import Modal from '@/views/modals/Modal';
import statusType from '@/store/constants/statusType';

import { MODALS } from '..';

const { Title, Text } = Typography;

const ConfirmCompanyUnBlock = props => {
  const { onOk } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(state => state.company?.[COMPANY_ACTION.UN_BLOCK]?.status);

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmCompanyUnBlock));
  }, [dispatch]);

  return (
    <Modal name={MODALS.ConfirmCompanyUnBlock} width={468}>
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <Title level={4}>{t('modal.confirm-company-unblock.title')}</Title>
          <Text>{t('modal.confirm-company-unblock.description')}</Text>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" onClick={handleClickNo}>
                {t('modal.confirm-company-unblock.button.no')}
              </Button>
              <Button type="primary" shape="round" loading={status === statusType.LOADING} onClick={onOk}>
                {t('modal.confirm-company-unblock.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(ConfirmCompanyUnBlock);
