import React, { memo } from 'react';

import { Alert as AntAlert } from 'antd';
import PropTypes from 'prop-types';

const Alert = props => {
  const { closable = false, onClose, ...rest } = props;

  return <AntAlert showIcon closable={closable} onClose={onClose} {...rest} />;
};

Alert.propTypes = {
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

export const ALERT_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export default memo(Alert);
