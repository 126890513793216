import statusType from '../constants/statusType';
import {
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_DETAILS,
  RECOVER_PASSWORD_DETAILS_ERROR,
  RECOVER_PASSWORD_DETAILS_LOADING,
  RECOVER_PASSWORD_DETAILS_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_LOADING,
  RECOVER_PASSWORD_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const passwordResetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RECOVER_PASSWORD:
    case RECOVER_PASSWORD_DETAILS:
      return { ...state, details: null };

    case RECOVER_PASSWORD_LOADING:
      return { error: null, status: statusType.LOADING };

    case RECOVER_PASSWORD_SUCCESS:
      return { ...state, data:payload, error: null, status: statusType.SUCCESSED };

    case RECOVER_PASSWORD_ERROR:
      return { ...state, data:null, error: payload, status: statusType.FAILED };

    case RECOVER_PASSWORD_DETAILS_LOADING:
      return { ...state, details: { status: statusType.LOADING } };

    case RECOVER_PASSWORD_DETAILS_SUCCESS: {
      const { secondsTillNextAttempt } = payload;
      return { ...state, details: { data: { retryInSeconds: secondsTillNextAttempt }, status: statusType.SUCCESSED } };
    }

    case RECOVER_PASSWORD_DETAILS_ERROR:
      return { ...state, details: { error: payload, status: statusType.FAILED } };

    default:
      return state;
  }
};

export default passwordResetReducer;
