import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import AddCompany from '@/views/modals/AddCompany';
import AddCompanySuccess from '@/views/modals/AddCompanySuccess';
import AddDomain from '@/views/modals/AddDomain';
import AddEmployee from '@/views/modals/AddEmployee';
import BlockEmployee from '@/views/modals/BlockEmployee';
import CancelTenderCreation from '@/views/modals/CancelTenderCreation';
import ChangeEmail from '@/views/modals/ChangeEmail';
import ConfirmBid from '@/views/modals/ConfirmBid';
import ConfirmCancelTender from '@/views/modals/ConfirmCancelTender';
import ConfirmCompanyArchive from '@/views/modals/ConfirmCompanyArchive';
import ConfirmCompanyBlock from '@/views/modals/ConfirmCompanyBlock';
import ConfirmCompanyEdit from '@/views/modals/ConfirmCompanyEdit';
import ConfirmCompanyRefine from '@/views/modals/ConfirmCompanyRefine';
import ConfirmCompanyReject from '@/views/modals/ConfirmCompanyReject';
import ConfirmCompanyUnBlock from '@/views/modals/ConfirmCompanyUnBlock';
import ConfirmCompanyVerify from '@/views/modals/ConfirmCompanyVerify';
import ConfirmContractSign from '@/views/modals/ConfirmContractSign';
import ConfirmContractTerminate from '@/views/modals/ConfirmContractTerminate';
import ConfirmDeleteDomain from '@/views/modals/ConfirmDeleteDomain';
import ConfirmInitialBid from '@/views/modals/ConfirmInitialBid';
import ConfirmPasswordChange from '@/views/modals/ConfirmPasswordChange';
import ConfirmTenderClose from '@/views/modals/ConfirmTenderClose';
import ConfirmTenderReady from '@/views/modals/ConfirmTenderReady';
import ContractSignInstructions from '@/views/modals/ContractSignInstructions';
import CreateCompaniesReport from '@/views/modals/CreateCompaniesReport';
import CreateReportComplete from '@/views/modals/CreateReportComplete';
import CreateTendersReport from '@/views/modals/CreateTendersReport';
import DeleteTenderDraft from '@/views/modals/DeleteTenderDraft';
import DuplicateTender from '@/views/modals/DuplicateTender';
import EditEmployee from '@/views/modals/EditEmployee';
import Error from '@/views/modals/Error';
import Oops from '@/views/modals/Oops';
import PublishTender from '@/views/modals/PublishTender';
import RecoverPasswordSuccess from '@/views/modals/RecoverPasswordSuccess';
import Support from '@/views/modals/Support';

const Modals = () => {
  const modals = useSelector(state => state.modals);
  return (
    <>
      {modals.AddCompany && <AddCompany {...modals.AddCompany} />}
      {modals.AddCompanySuccess && <AddCompanySuccess {...modals.AddCompanySuccess} />}
      {modals.AddDomain && <AddDomain {...modals.AddDomain} />}
      {modals.AddEmployee && <AddEmployee {...modals.AddEmployee} />}
      {modals.BlockEmployee && <BlockEmployee {...modals.BlockEmployee} />}
      {modals.CancelTenderCreation && <CancelTenderCreation {...modals.CancelTenderCreation} />}
      {modals.ChangeEmail && <ChangeEmail {...modals.ChangeEmail} />}
      {modals.ConfirmBid && <ConfirmBid {...modals.ConfirmBid} />}
      {modals.ConfirmCancelTender && <ConfirmCancelTender {...modals.ConfirmCancelTender} />}
      {modals.ConfirmCompanyArchive && <ConfirmCompanyArchive {...modals.ConfirmCompanyArchive} />}
      {modals.ConfirmCompanyBlock && <ConfirmCompanyBlock {...modals.ConfirmCompanyBlock} />}
      {modals.ConfirmCompanyEdit && <ConfirmCompanyEdit {...modals.ConfirmCompanyEdit} />}
      {modals.ConfirmCompanyReject && <ConfirmCompanyReject {...modals.ConfirmCompanyReject} />}
      {modals.ConfirmCompanyRefine && <ConfirmCompanyRefine {...modals.ConfirmCompanyRefine} />}
      {modals.ConfirmCompanyUnBlock && <ConfirmCompanyUnBlock {...modals.ConfirmCompanyUnBlock} />}
      {modals.ConfirmCompanyVerify && <ConfirmCompanyVerify {...modals.ConfirmCompanyVerify} />}
      {modals.ConfirmContractSign && <ConfirmContractSign {...modals.ConfirmContractSign} />}
      {modals.ConfirmContractTerminate && <ConfirmContractTerminate {...modals.ConfirmContractTerminate} />}
      {modals.ConfirmDeleteDomain && <ConfirmDeleteDomain {...modals.ConfirmDeleteDomain} />}
      {modals.ConfirmInitialBid && <ConfirmInitialBid {...modals.ConfirmInitialBid} />}
      {modals.ConfirmPasswordChange && <ConfirmPasswordChange {...modals.ConfirmPasswordChange} />}
      {modals.ConfirmTenderReady && <ConfirmTenderReady {...modals.ConfirmTenderReady} />}
      {modals.ConfirmTenderClose && <ConfirmTenderClose {...modals.ConfirmTenderClose} />}
      {modals.ContractSignInstructions && <ContractSignInstructions {...modals.ContractSignInstructions} />}
      {modals.CreateCompaniesReport && <CreateCompaniesReport {...modals.CreateCompaniesReport} />}
      {modals.CreateReportComplete && <CreateReportComplete {...modals.CreateReportComplete} />}
      {modals.CreateTendersReport && <CreateTendersReport {...modals.CreateTendersReport} />}
      {modals.DeleteTenderDraft && <DeleteTenderDraft {...modals.DeleteTenderDraft} />}
      {modals.DuplicateTender && <DuplicateTender {...modals.DuplicateTender} />}
      {modals.Error && <Error {...modals.Error} />}
      {modals.EditEmployee && <EditEmployee {...modals.EditEmployee} />}
      {modals.Oops && <Oops {...modals.Oops} />}
      {modals.PublishTender && <PublishTender {...modals.PublishTender} />}
      {modals.RecoverPasswordSuccess && <RecoverPasswordSuccess {...modals.RecoverPasswordSuccess} />}
      {modals.Support && <Support {...modals.Support} />}
    </>
  );
};

export default memo(Modals);
