import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Col, Form, Image, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import imageForgotPassword from '@/assets/images/password.png';

import { BaseLayout } from '@/containers/layout';
import { Email } from '@/components/form';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { recoverPassword } from '@/store/actions/passwordActions';
import statusType from '@/store/constants/statusType';
import { Card, Link, Text } from '@/components';
import { hideModal, showModal } from '@/store/actions/modalsActions';

import errorCodes from '@/constants/errorCodes';

import classes from './PasswordRecovery.module.scss';

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState();

  const { status, error } = useSelector(state => state.passwordReset);

  const sendEmail = useCallback(
    value => {
      dispatch(hideModal(MODALS.RecoverPasswordSuccess));
      dispatch(recoverPassword(value || email));
    },
    [dispatch, email]
  );

  const handleSubmitForm = useCallback(
    form => {
      setEmail(form.email);
      sendEmail(form.email);
    },
    [sendEmail]
  );

  useEffect(() => {
    if (status === statusType.SUCCESSED || error?.code === errorCodes.RETRY_SEND_EMAIL_AFTER) {
      dispatch(showModal({ email, modal: MODALS.RecoverPasswordSuccess }));
    } else if (error && error?.code !== errorCodes.USER_NOT_FOUND) {
      dispatch(showModal({ modal: MODALS.Error }));
    }
  }, [dispatch, email, error, status]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Form
          name="password-reset"
          layout="vertical"
          onFinish={handleSubmitForm}
          requiredMark={false}
          className={classes.root}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row justify="center">
                <Image src={imageForgotPassword} preview={false} />
              </Row>
            </Col>
            <Row gutter={[0, 8]} className={classes.description}>
              <Col span={24}>
                <Row justify="center">
                  <Text size={24} height={32} weight="semibold" align="center">
                    {t('common.forgot-password?')}
                  </Text>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Text size={16} height={24} align="center">
                    {t('pages.password-reset.description')}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Col span={24}>
              <Email error={error?.code && t(`error.code.${error.code}`)} />
            </Col>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
                block
                loading={status === statusType.LOADING}
              >
                <Text align="center">{t('common.change-password')}</Text>
              </Button>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Link to={pageUrls.SIGN_IN}>{t('pages.password-reset.return-to-sign-in-form')}</Link>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Trans i18nKey="common.sign-up-if-no-account">
                  [<Link to={pageUrls.SIGN_UP} />]
                </Trans>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordRecovery);
