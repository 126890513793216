export const joinKeys = (fields, obj, by = ', ') =>
  fields
    .map(field => obj?.[field])
    .filter(i => !!i)
    .join(by);

export const findProperty = (name, target) => {
  const nameTree = name.split('.');

  let currName = nameTree.shift();
  let lastBranch = target?.[currName];

  while (nameTree.length) {
    currName = nameTree.shift();
    lastBranch = lastBranch?.[currName];
  }

  return !!lastBranch;
};

export const findProperties = (names, target) => {
  return names.every(name => findProperty(name, target));
};

export const sanitizeObject = object => {

  const type = typeof object;
  if (type === 'boolean' || type === 'string' || type === 'number') {
    return object;
  }
  
  const sanitizied = Object.keys(object)
    .filter(key => {
      return object[key] !== null && object[key] !== 'null' && object[key] !== undefined && object[key] !== 'undefined';
    })
    .reduce((acc, key) => {
      acc[key] =
        typeof object[key] === 'object' && !Array.isArray(object[key])
          ? sanitizeObject(object[key])
          : Array.isArray(object[key])
          ? object[key].map(item => sanitizeObject(item))
          : object[key];
      return acc;
    }, {});
  return sanitizied;
};