import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Form, Row, Space, Steps, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';
import { getTenderType } from '@/utils/tenderUtils';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import {
  addTender,
  deleteTender,
  duplicateTender,
  editTender,
  getTender,
  publishTender,
} from '@/store/actions/tenderActions';
import { hideModal, showModal } from '@/store/actions/modalsActions';
import { reorderPlaces, TENDER_SECTION, TENDER_STATUS, TENDER_TYPE, validForPublish } from '@/constants/tender';

import BidFields from './components/BidFields';
import CargoFields from './components/CargoFields';
import ConditionFields from './components/ConditionFields';
import SummaryFields from './components/SummaryFields';

import TenderId from './components/TenderId';
import { TRANSPORT_NUMBER_TYPE } from './components/ConditionFields/ConditionFields';

import classes from './TenderAdd.module.scss';

const stepFields = [ConditionFields, CargoFields, BidFields, SummaryFields];
const MAX_STEPS = stepFields.length;

const tenderDefaultValues = {
  bid: {
    excludeCompanies: [],
    initialBidChecked: true,
    minimalNumberOfParticipants: 1,
  },
};

const TenderAdd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditMode = !!useRouteMatch(pageUrls.TENDERS.EDIT_PATH);
  const params = useParams();
  const [form] = Form.useForm();
  const [tenderId, setTenderId] = useState();
  const [pendingDelete, setPendingDelete] = useState(false);
  const [step, setStep] = useState(isEditMode ? MAX_STEPS : 1);

  const {
    add: { status: createStatus, data: addData },
    delete: { status: deleteStatus },
    duplicate: { data: duplicateData, status: duplicateStatus },
    get: { data: tender },
    edit: { status: editStatus },
    publish: { status: publishStatus },
  } = useSelector(state => state.tender);

  const handleDeleteTender = useCallback(() => {
    dispatch(deleteTender(tenderId));
  }, [dispatch, tenderId]);

  const handleCancelDeleteTender = useCallback(() => {
    setPendingDelete(false);
  }, []);

  const handleDuplicateTender = useCallback(() => {
    dispatch(duplicateTender(tenderId));
  }, [dispatch, tenderId]);

  const handlePublishTender = useCallback(() => {
    dispatch(publishTender(tenderId));
  }, [dispatch, tenderId]);

  const handleClickCancel = useCallback(() => {
    dispatch(showModal({ modal: MODALS.CancelTenderCreation }));
  }, [dispatch]);

  const handleClickDelete = useCallback(() => {
    setPendingDelete(true);
    dispatch(
      showModal({ modal: MODALS.DeleteTenderDraft, onOk: handleDeleteTender, onCancel: handleCancelDeleteTender })
    );
  }, [dispatch, handleDeleteTender, handleCancelDeleteTender]);

  const handleClickDuplicate = useCallback(() => {
    dispatch(showModal({ modal: MODALS.DuplicateTender, onOk: handleDuplicateTender }));
  }, [dispatch, handleDuplicateTender]);

  const handleClickPublish = useCallback(async () => {
    dispatch(showModal({ modal: MODALS.PublishTender, onOk: handlePublishTender }));
  }, [dispatch, handlePublishTender]);

  const handleClickPrev = useCallback(() => {
    setStep(s => {
      if (s > 1) {
        return s - 1;
      }
      return s;
    });
  }, []);

  const handleClickNext = useCallback(() => {
    form.validateFields().then(fields => {
      const tenderFields = {
        ...fields,
      };

      if (tenderFields?.bid?.responsibleAssignee?.id) {
        tenderFields.bid.responsibleAssignee = tenderFields?.bid?.responsibleAssignee?.id;
      }

      if (tenderFields?.route) {
        tenderFields.route = reorderPlaces(tenderFields.route);
        tenderFields.conditions.tenderInternal = getTenderType(tenderFields?.route) === TENDER_TYPE.INLAND;
      }

      if (fields?.conditions?.transferDate && typeof fields.conditions.transferDate !== 'number') {
        tenderFields.conditions.transferStartDate = fields.conditions.transferDate.valueOf();
        delete tenderFields.conditions.transferDate;
      }

      if (fields?.conditions?.transferStartDate && typeof fields.conditions.transferStartDate !== 'number') {
        tenderFields.conditions.transferStartDate = fields.conditions.transferStartDate.valueOf();
      }

      if (fields?.conditions?.transferEndDate && typeof fields.conditions.transferEndDate !== 'number') {
        tenderFields.conditions.transferEndDate = fields.conditions.transferEndDate.valueOf();
      }

      if (fields?.typeOfTransfers === TRANSPORT_NUMBER_TYPE.once) {
        tenderFields.conditions.numberOfTransfers = 1;
      }

      if (tenderId) {
        dispatch(editTender({ data: tenderFields, id: tenderId }));
      } else {
        dispatch(addTender(tenderFields));
      }
    });
  }, [dispatch, form, tenderId]);

  const handleChangeStep = useCallback(value => {
    setStep(value + 1);
  }, []);

  const handleClickEdit = useCallback(section => {
    let newStep = 1;
    switch (section) {
      case TENDER_SECTION.CONDITIONS:
        newStep = 1;
        break;

      case TENDER_SECTION.CARGO:
      case TENDER_SECTION.TRANSPORT:
        newStep = 2;
        break;

      case TENDER_SECTION.BID:
        newStep = 3;
        break;
    }
    setStep(newStep);
  }, []);

  useEffect(() => {
    if (tenderId) {
      dispatch(getTender(tenderId));
    }
  }, [dispatch, tenderId, step]);

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      setStep(s => s + 1);
    }
  }, [dispatch, editStatus]);

  useEffect(() => {
    if (duplicateStatus === statusType.SUCCESSED) {
      if (duplicateData?.conditions?.id) {
        history.push(`${pageUrls.TENDERS.EDIT(duplicateData?.conditions.id)}`);
      }
    }
  }, [dispatch, history, duplicateStatus, duplicateData]);

  useEffect(() => {
    if (publishStatus === statusType.SUCCESSED) {
      dispatch(hideModal(MODALS.PublishTender));
      history.push(pageUrls.TENDERS.ITEM(tenderId));
    } else if (publishStatus === statusType.FAILED) {
      dispatch(hideModal(MODALS.PublishTender));
    }
  }, [dispatch, history, tenderId, publishStatus]);

  useEffect(() => {
    if (deleteStatus === statusType.SUCCESSED) {
      history.push(pageUrls.TENDERS.LIST);
    }
  }, [dispatch, history, deleteStatus]);

  useEffect(() => {
    if (addData?.conditions?.id) {
      setTenderId(addData?.conditions.id);
      setStep(s => s + 1);
    }
  }, [dispatch, history, addData]);

  useEffect(() => {
    if (tender) {
      form.setFieldsValue(tender);
    }
  }, [form, tender]);

  useEffect(() => {
    const id = params?.id;
    if (id) {
      setTenderId(id);
    }
  }, [params]);

  useEffect(() => {
    const handleCloseWindow = event => {
      if (step === 1) {
        event.preventDefault();
        event.returnValue = '';
      } else {
        window.removeEventListener('beforeunload', handleCloseWindow);
      }
    };

    window.removeEventListener('beforeunload', handleCloseWindow);

    if (!pendingDelete) {
      window.addEventListener('beforeunload', handleCloseWindow);
    }

    return () => {
      window.removeEventListener('beforeunload', handleCloseWindow);
    };
  }, [form, step, pendingDelete]);

  const Fields = useMemo(() => stepFields[step - 1], [step]);
  const isValidForPublish = validForPublish(tender);

  return (
    <DashboardLayout>
      <BaseContent
        contentSpan={22}
        className={classes.content}
        bodyClassname={classes.body}
        header={
          <Row justify="center" gutter={[0, 36]}>
            <Col span={24}>
              <Typography.Title level={3} className={clsx('text-align-center')}>
                {t('pages.tender-add.new-tender')}
              </Typography.Title>
            </Col>
            <Col span={18}>
              <Steps labelPlacement="vertical" current={step - 1} onChange={isEditMode ? handleChangeStep : null}>
                <Steps.Step description={t('pages.tender.transport-conditions')} />
                <Steps.Step description={t('pages.tender.cargo-and-transport')} />
                <Steps.Step description={t('pages.tender.tender-details')} />
                <Steps.Step description={t('pages.tender-add.tender-confirmation')} />
              </Steps>
            </Col>
          </Row>
        }
        footer={
          <Row justify="space-between">
            {step === 1 ? (
              tenderId ? (
                <Button shape="round" size="large" onClick={handleClickDelete}>
                  {t('common.delete-draft')}
                </Button>
              ) : (
                <Button shape="round" size="large" onClick={handleClickCancel}>
                  {t('common.cancel')}
                </Button>
              )
            ) : step === MAX_STEPS ? (
              <Space size="middle">
                <Button shape="round" size="large" onClick={handleClickDelete}>
                  {t('common.delete-draft')}
                </Button>
                <Button shape="round" size="large" onClick={handleClickDuplicate}>
                  {t('common.duplicate')}
                </Button>
              </Space>
            ) : (
              <Button shape="round" size="large" onClick={handleClickDelete}>
                {t('common.delete-draft')}
              </Button>
            )}
            <Space>
              {step !== 1 && (
                <Button shape="round" size="large" onClick={handleClickPrev}>
                  {t('common.back')}
                </Button>
              )}
              {step !== MAX_STEPS ? (
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={handleClickNext}
                  loading={createStatus === statusType.LOADING || editStatus === statusType.LOADING}
                >
                  {t('common.save-and-continue')}
                </Button>
              ) : (
                <Tooltip title={!isValidForPublish && t('pages.tender.fill-all-data-to-publish')}>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    onClick={handleClickPublish}
                    loading={publishStatus === statusType.LOADING}
                    disabled={!isValidForPublish}
                  >
                    {t('common.publish')}
                  </Button>
                </Tooltip>
              )}
            </Space>
          </Row>
        }
      >
        {step === MAX_STEPS && <TenderId tender={tender} />}
        <Col span={24}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Form name="tender-add" form={form} requiredMark={false} initialValues={tenderDefaultValues}>
                <Fields onEdit={handleClickEdit} />
              </Form>
            </Col>
          </Row>
        </Col>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(TenderAdd);
