import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { findTendersByStatus } from '@/store/actions/tendersActions';
import pageUrls from '@/constants/pageUrls';
import { TENDER_GROUP, TENDER_STATUS } from '@/constants/tender';

import { COMPANY_STATUS } from '@/constants/company';
import { MyTenders, NewTenders, TradingTenders } from '../components/widgets';

const MY_TENDERS_KEY = 'my-tenders';

const DashboardTC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenders = useSelector(state => state.specificTenders);
  const { data: account } = useSelector(state => state.account);
  const { [account?.companyId]: { data: company } = {} } = useSelector(state => state.company.get);

  const listMy = useMemo(() => tenders?.[MY_TENDERS_KEY]?.data || [], [tenders]);
  const listNew = useMemo(() => tenders?.[TENDER_STATUS.ACCEPTING]?.data || [], [tenders]);
  const listTrading = useMemo(() => tenders?.[TENDER_STATUS.TRADING]?.data || [], [tenders]);

  const tendersAvailable = useMemo(() => {
    if (company?.general?.status) {
      const status = company?.general?.status;
      return (
        status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
        status === COMPANY_STATUS.ACTIVE ||
        status === COMPANY_STATUS.BLOCKED
      );
    }
    return false;
  }, [company?.general?.status]);

  const handleClickViewTenders = useCallback(
    tab => {
      history.push(`${pageUrls.TENDERS.LIST}?tab=${tab}`);
    },
    [history]
  );

  useEffect(() => {
    if (company?.general?.id) {
      if (tendersAvailable) {
        dispatch(
          findTendersByStatus({
            data: {
              companyId: company?.general?.id,
              participant: true,
              tenderStatuses: [TENDER_STATUS.ACCEPTING],
              transportationType: company?.general?.transportationType,
            },
            pagination: { page: 0, size: 4 },
            sort: { sort: 'dealsStart,asc' },
            status: MY_TENDERS_KEY,
          })
        );
        dispatch(
          findTendersByStatus({
            data: {
              companyId: company?.general?.id,
              participant: false,
              tenderStatuses: [TENDER_STATUS.ACCEPTING],
              transportationType: company?.general?.transportationType,
            },
            pagination: { page: 0, size: 4 },
            sort: { sort: 'dealsStart,asc' },
            status: TENDER_STATUS.ACCEPTING,
          })
        );
        dispatch(
          findTendersByStatus({
            data: {
              companyId: company?.general?.id,
              participant: true,
              tenderStatuses: [TENDER_STATUS.TRADING],
              transportationType: company?.general?.transportationType,
            },
            pagination: { page: 0, size: 4 },
            sort: { sort: 'dealsEnds,asc' },
            status: TENDER_STATUS.TRADING,
          })
        );
      }
    }
  }, [dispatch, company, tendersAvailable]);

  if (!tendersAvailable) {
    return null;
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <NewTenders data={listNew} viewAllValue={TENDER_GROUP.NEW} onViewAll={handleClickViewTenders} />
      </Col>
      <Col span={12}>
        <MyTenders data={listMy} viewAllValue={TENDER_GROUP.PENDING} onViewAll={handleClickViewTenders} />
      </Col>
      <Col span={12}>
        <TradingTenders data={listTrading} viewAllValue={TENDER_GROUP.TRADING} onViewAll={handleClickViewTenders} />
      </Col>
    </Row>
  );
};

export default memo(DashboardTC);
