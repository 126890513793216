import React, { memo, useCallback, useMemo } from 'react';

import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from '@/constants/common';

const disablePastDate = current => current && current <= moment().startOf('day');

const Picker = props => {
  const { onChange, transformValue, disabledDate = disablePastDate, ...rest } = props;

  const value = useMemo(() => {
    if (transformValue) {
      return transformValue(rest?.value);
    }
    return rest?.value;
  }, [rest, transformValue]);

  const handleChange = useCallback(
    date => {
      if (onChange) {
        onChange(date);
      }
    },
    [onChange]
  );

  return (
    <DatePicker
      format={DATE_FORMAT}
      style={{ width: '100%' }}
      size="large"
      locale={locale}
      onChange={handleChange}
      disabledDate={disabledDate}
      {...rest}
      value={value}
    />
  );
};

Picker.propTypes = {
  disabledDate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onChange: PropTypes.func,
  transformValue: PropTypes.func,
};

export default memo(Picker);
