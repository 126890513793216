import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Divider, Form, Input, Radio, Row, Select, Typography } from 'antd';
import Icon, { CarOutlined } from '@ant-design/icons';

import * as fieldRules from '@/constants/fieldRules';
import {
  CARGO_TYPE,
  containerTypeOptions,
  TRANSPORT_TYPE,
  transportTonnageOptions,
  transportVolumeOptions,
} from '@/constants/tender';

import { ReactComponent as IconTrain } from '@/assets/icons/train.svg';
import { ReactComponent as IconTruck } from '@/assets/icons/truck.svg';

import classes from './CargoFields.module.scss';

const itemProps = {
  labelAlign: 'left',
  labelCol: { span: 10 },
};

const CargoFields = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={4}>{t('common.cargo')}</Typography.Title>
        <Divider className={classes.divider} />
        <Form.Item label={t('pages.tender.cargo-type')} name={['cargo', 'type']} {...itemProps}>
          <Select placeholder={t('common.select-option')} size="large">
            <Select.Option value={CARGO_TYPE.BUILD_IN}>
              {t(`pages.tender.cargo-type.${CARGO_TYPE.BUILD_IN}`)}
            </Select.Option>
            <Select.Option value={CARGO_TYPE.LARGE_HOUSEHOLD}>
              {t(`pages.tender.cargo-type.${CARGO_TYPE.LARGE_HOUSEHOLD}`)}
            </Select.Option>
            <Select.Option value={CARGO_TYPE.SMALL_HOUSEHOLD}>
              {t(`pages.tender.cargo-type.${CARGO_TYPE.SMALL_HOUSEHOLD}`)}
            </Select.Option>
            <Select.Option value={CARGO_TYPE.DIGITAL}>
              {t(`pages.tender.cargo-type.${CARGO_TYPE.DIGITAL}`)}
            </Select.Option>
            <Select.Option value={CARGO_TYPE.OTHER}>{t(`pages.tender.cargo-type.${CARGO_TYPE.OTHER}`)}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const cargoType = getFieldValue(['cargo', 'type']);
            return (
              cargoType === CARGO_TYPE.OTHER && (
                <Form.Item
                  label
                  colon={false}
                  name={['cargo', 'typeDescription']}
                  rules={fieldRules.required}
                  {...itemProps}
                >
                  <Input pattern="text" />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item label={t('pages.tender.load-type')} name={['cargo', 'loadType']} {...itemProps}>
          <Select placeholder={t('common.select-option')} size="large">
            <Select.Option value="scattering">{t('pages.tender.load-type.scattering')}</Select.Option>
            <Select.Option value="pallets">{t('pages.tender.load-type.pallets')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('pages.tender.cargo-capacity.m3')}
          name={['cargo', 'volume']}
          rules={fieldRules.positive}
          {...itemProps}
        >
          <Input pattern="numbers" />
        </Form.Item>
        <Form.Item
          label={t('pages.tender.cargo-weight.t')}
          name={['cargo', 'weight']}
          rules={fieldRules.positive}
          {...itemProps}
        >
          <Input pattern="numbers" />
        </Form.Item>
        <Form.Item label={t('pages.tender.cargo-properties')} name={['cargo', 'properties']} {...itemProps}>
          <Input.TextArea placeholder={t('common.leave-comment')} size="large" autoSize={{ maxRows: 2, minRows: 2 }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Typography.Title level={4}>{t('common.transport')}</Typography.Title>
        <Divider className={classes.divider} />
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => {
            const onChangeType = event => {
              const { value } = event.target;
              const transportData = getFieldValue('transport');
              const type = value;

              const volumes = transportVolumeOptions[type];
              const volume = volumes?.[0]?.value;

              const tonnages = transportTonnageOptions[type];
              const tonnage = type === TRANSPORT_TYPE.container ? tonnages?.[3]?.value : null;

              const cargoVolumes = transportVolumeOptions[type];
              const cargoVolume = cargoVolumes?.[0]?.value;

              setFieldsValue({
                transport: {
                  ...transportData,
                  cargoVolume,
                  tonnage,
                  type,
                  volume,
                },
              });
            };
            return (
              <Form.Item label={t('pages.tender.transport-type')} name={['transport', 'type']} {...itemProps}>
                <Radio.Group size="large" buttonStyle="solid" optionType="button" onChange={onChangeType}>
                  <Radio.Button value="auto">
                    <CarOutlined /> {t('pages.tender.transport-type.auto')}
                  </Radio.Button>
                  <Radio.Button value="container">
                    <Icon component={IconTruck} /> {t('pages.tender.transport-type.container')}
                  </Radio.Button>
                  <Radio.Button value="train">
                    <Icon component={IconTrain} /> {t('pages.tender.transport-type.train')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const transportType = getFieldValue(['transport', 'type']);
            const selectorAvailable = transportType === TRANSPORT_TYPE.auto || transportType === TRANSPORT_TYPE.train;
            const selectorOptions = transportVolumeOptions[transportType] || [];
            return (
              selectorAvailable && (
                <Form.Item
                  label={t('pages.tender.transport-volume')}
                  name={['transport', 'cargoVolume']}
                  {...itemProps}
                >
                  <Select placeholder={t('common.select-option')} size="large" options={selectorOptions} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const transportType = getFieldValue(['transport', 'type']);
            const selectorAvailable = transportType === TRANSPORT_TYPE.container;
            const selectorOptions = containerTypeOptions;
            return (
              selectorAvailable && (
                <Form.Item
                  label={t('pages.tender.container-type')}
                  name={['transport', 'cargoVolume']}
                  initialValue={selectorOptions?.[0]?.value}
                  {...itemProps}
                >
                  <Select placeholder={t('common.select-option')} size="large" options={selectorOptions} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate {...itemProps}>
          {({ getFieldValue }) => {
            const transportType = getFieldValue(['transport', 'type']);
            const selectorOptions = transportTonnageOptions[transportType] || [];
            return (
              <Form.Item
                label={t('pages.tender.transport-load-capacity')}
                name={['transport', 'tonnage']}
                {...itemProps}
              >
                <Select placeholder={t('common.select-option')} size="large" options={selectorOptions} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label={t('pages.tender.transport-amount')}
          name={['transport', 'numberRequired']}
          initialValue={1}
          {...itemProps}
        >
          <Input.Number />
        </Form.Item>
        <Form.Item label={t('pages.tender.transport-properties')} name={['transport', 'comments']} {...itemProps}>
          <Input.TextArea placeholder={t('common.leave-comment')} size="large" autoSize={{ maxRows: 2, minRows: 2 }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(CargoFields);
