const {
  REACT_APP_PROXY_URL,
  REACT_APP_RESOURCE_URL,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_SUPPORT_EMAIL = 'technodom@mail.com',
} = process.env;

export default {
  APP_PROXY_URL: REACT_APP_PROXY_URL,
  APP_RESOURCE_URL: REACT_APP_RESOURCE_URL,
  RECAPTCHA_SITE_KEY: REACT_APP_RECAPTCHA_SITE_KEY,
  SUPPORT_EMAIL: REACT_APP_SUPPORT_EMAIL,
};
