import React, { memo } from 'react';

import { Col, Image } from 'antd';

import { BaseLayout } from '@/containers/layout';
import logoImage from '@/assets/images/logo-tender-white.svg';

import classes from './LandingLayout.module.scss';

const Header = () => {
  return <div className={classes.header} />;
};

const Logo = () => {
  return <Image src={logoImage} wrapperClassName={classes.logo} preview={false} />;
};

const LandingLayout = props => {
  const { children, contentSpan = 16 } = props;
  return (
    <BaseLayout logo={false} className={classes.layout}>
      <Col span={contentSpan} style={{ minHeight: 0 }}>
        <Logo />
      </Col>
      <Header />
      <Col span={contentSpan}>{children}</Col>
    </BaseLayout>
  );
};

export default memo(LandingLayout);
