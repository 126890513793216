import statusType from '@/store/constants/statusType';
import React, { memo, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_TECHNO_COMPANY } from '@/store/constants/actionTypes';
import { getTechnoCompany } from '@/store/actions/companyActions';
import SectionDomains from '../components/SectionDomains';
import SectionGeneral from '../components/SectionGeneral';
import SectionTechnoContacts from '../components/SectionTechnoContacts';

import CompanyDetails from '../containers/CompanyDetails';

const TechnoCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: accountData } = useSelector(state => state.account);
  const companyId = useMemo(() => accountData?.companyId, [accountData?.companyId]);

  const {
    edit: { status: editStatus },
    get: { status: getStatus, data: company } = {},
  } = useSelector(state => state.technoCompany);

  useEffect(() => {
    if (companyId) {
      dispatch(getTechnoCompany(companyId));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      dispatch({
        type: EDIT_TECHNO_COMPANY,
      });
      dispatch(getTechnoCompany(companyId));
    }
  }, [dispatch, companyId, editStatus]);

  const { general } = company || {};

  return (
    <CompanyDetails loading={getStatus === statusType.LOADING} visible={general?.id}>
      <SectionGeneral
        data={company}
        NoData={t('common.no-data')}
        showEmptyMailAddress
        excludeFields={['transportationType', 'transportsAvailable']}
      />
      <SectionTechnoContacts data={company} NoData={t('common.no-data')} />
      <SectionDomains data={company} NoData={t('common.no-data')} />
    </CompanyDetails>
  );
};
export default memo(TechnoCompany);
