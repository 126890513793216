import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';

import { ReactComponent as IconEmpty } from '@/assets/icons/empty.svg';
import { ReactComponent as IconEmptySearch } from '@/assets/icons/search-empty.svg';

const TableEmpty = props => {
  const { t } = useTranslation();
  const {
    onReset,
    searching,
    emptyText = t('common.empty-data'),
    emptySearchText = t('common.search-result-empty'),
  } = props;

  const handleClickReset = useCallback(() => {
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={24} />
      <Col span={24}>{searching ? <IconEmptySearch /> : <IconEmpty />}</Col>
      <Col span={16}> {searching ? emptySearchText : emptyText}</Col>
      {searching && (
        <Col span={24}>
          <Button shape="round" type="primary" onClick={handleClickReset}>
            {t('common.reset-search-result')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

TableEmpty.propTypes = {
  emptySearchText: PropTypes.string,
  emptyText: PropTypes.string,
  onReset: PropTypes.func,
  searching: PropTypes.bool,
};

export default memo(TableEmpty);
