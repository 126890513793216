import React from 'react';
import ReactDOM from 'react-dom';

import Home from './Home';

const Landing = () =>
  ReactDOM.createPortal(
    <div style={{ position: 'absolute', display: 'flex', top: 0, left: 0 }}>
      <Home />
    </div>,
    document.body
  );
export default Landing;
