import React, { memo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_ACTION } from '@/constants/company';
import { hideModal } from '@/store/actions/modalsActions';
import Modal from '@/views/modals/Modal';
import statusType from '@/store/constants/statusType';

import { MODALS } from '..';

const { Title, Text } = Typography;

const ConfirmCompanyEdit = props => {
  const { onOk, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const status = useSelector(state => state.company?.[COMPANY_ACTION.ENABLE_EDIT]?.status);

  const handleClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleClickNo = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    dispatch(hideModal(MODALS.ConfirmCompanyEdit));
  }, [dispatch, onCancel]);

  const handleClickYes = useCallback(() => {
    if (onOk) {
      onOk();
    }
  }, [onOk]);

  return (
    <Modal name={MODALS.ConfirmCompanyEdit} width={468} onClose={handleClose}>
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <Title level={4}>{t('modal.confirm-company-edit.title')}</Title>
          <Text>{t('modal.confirm-company-edit.description')}</Text>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" loading={status === statusType.LOADING} onClick={handleClickYes}>
                {t('modal.confirm-company-edit.button.yes')}
              </Button>
              <Button shape="round" type="primary" onClick={handleClickNo}>
                {t('modal.confirm-company-edit.button.no')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(ConfirmCompanyEdit);
