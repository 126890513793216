import statusType from '../constants/statusType';
import {
  FIND_SPECIFIC_TENDERS_ERROR,
  FIND_SPECIFIC_TENDERS_LOADING,
  FIND_SPECIFIC_TENDERS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {};

const tendersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FIND_SPECIFIC_TENDERS_ERROR: {
      const { field, error } = payload;
      return { ...state, [field]: { error, status: statusType.FAILED } };
    }

    case FIND_SPECIFIC_TENDERS_LOADING: {
      const { field } = payload;
      return { ...state, [field]: { status: statusType.LOADING } };
    }

    case FIND_SPECIFIC_TENDERS_SUCCESS: {
      const { field, data } = payload;
      return { ...state, [field]: { data, status: statusType.SUCCESSED } };
    }

    default:
      return state;
  }
};

export default tendersReducer;
