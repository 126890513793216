import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { BaseContent } from '@/containers/content';
import { CREATE_REPORT } from '@/store/constants/actionTypes';
import { findTenders } from '@/store/actions/tendersActions';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { parseTenderId } from '@/utils/tenderUtils';
import { REPORT_TYPE } from '@/constants/report';
import { sanitizeObject } from '@/utils/objectUtils';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { createCompletedTendersReport, createFailedTendersReport } from '@/store/actions/reportActions';
import { hideModal, showModal } from '@/store/actions/modalsActions';
import { Table, TableEmpty } from '@/components';
import { TENDER_GROUP, TENDER_STATUS, TENDER_TYPE, tenderStatusOptions } from '@/constants/tender';

import TenderFilters from '../TenderFilters';
import TenderTabs from '../TenderTabs';

const { Title } = Typography;

const tabDefaultFilters = tab => {
  return [tab];
};

const tabDefaultSort = tab => {
  switch (tab) {
    case TENDER_STATUS.DRAFT:
      return {
        sort: 'createdAt,desc',
      };

    case TENDER_STATUS.ACCEPTING:
      return {
        sort: 'dealsStart,asc',
      };

    case TENDER_STATUS.TRADING:
      return {
        sort: 'dealsEnds,asc',
      };

    case TENDER_STATUS.CLOSED:
      return {
        sort: 'dealsEnds,desc',
      };

    case TENDER_GROUP.ALL:
      return {
        sort: 'createdAt,desc',
      };
  }
  return {};
};

const defaultPaginationProps = {
  size: 10,
};

const auStatusFilterOptions = tenderStatusOptions.filter(({ value }) => value !== TENDER_STATUS.DRAFT);

const TendersTable = props => {
  const {
    normalizeTabBaseFilters,
    tabs,
    tendersAvailable = true,
    transformTabFilter = tabDefaultFilters,
    transformTabSort = tabDefaultSort,
    columns,
    withStatusFilter = false,
    onChangeTab,
    onChangeFilter,
    FilterComponent = TenderFilters,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const queryStatus = query.get('status');
  const queryTab = query.get('tab');
  const history = useHistory();
  const tenders = useSelector(state => state.tenders);
  const { data: account } = useSelector(state => state.account);
  const report = useSelector(state => state.report?.[REPORT_TYPE.TENDERS]);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState(defaultPaginationProps);
  const [tab, setTab] = useState(queryStatus !== null ? TENDER_GROUP.ALL : queryTab !== null ? queryTab : tabs?.[0]);

  const [baseFilters, setBaseFilters] = useState({
    tenderStatuses: queryStatus ? null : transformTabFilter(tab),
    ...(normalizeTabBaseFilters && normalizeTabBaseFilters(tab)),
  });

  const [extraFilters, setExtraFilters] = useState(queryStatus ? { tenderStatuses: [queryStatus] } : null);
  const [filterValues, setFilterValues] = useState(queryStatus ? { tenderStatuses: [queryStatus] } : null);
  const isSearching = useMemo(() => extraFilters && Object.keys(extraFilters)?.length > 0, [extraFilters]);

  const handleClickAdd = useCallback(() => {
    history.push(pageUrls.TENDERS.ADD);
  }, [history]);

  const handleCreateReport = useCallback(
    (type, payload) => {
      if (type === TENDER_GROUP.FAILED) {
        dispatch(createFailedTendersReport(payload));
      } else {
        dispatch(createCompletedTendersReport(payload));
      }
    },
    [dispatch]
  );

  const handleClickReport = useCallback(() => {
    dispatch(showModal({ modal: MODALS.CreateTendersReport, onOk: handleCreateReport }));
  }, [dispatch, handleCreateReport]);

  const handleClickRow = useCallback(
    ({ conditions }) => {
      if (conditions?.status === TENDER_STATUS.DRAFT) {
        history.push(`${pageUrls.TENDERS.EDIT(conditions?.id)}`);
      } else {
        history.push(pageUrls.TENDERS.ITEM(conditions?.id));
      }
    },
    [history]
  );

  const handleChangeTable = useCallback(({ page, pageSize }) => {
    setPagination({ page: page - 1, size: pageSize });
  }, []);

  const handleChangeTab = useCallback(
    value => {
      setPagination(defaultPaginationProps);
      setExtraFilters();
      setFilterValues();
      setTab(value);
      setItems([]);
      setBaseFilters({
        tenderStatuses: value !== TENDER_GROUP.ALL ? transformTabFilter(value) : null,
        ...(normalizeTabBaseFilters && normalizeTabBaseFilters(value)),
      });
      if (onChangeTab) {
        onChangeTab(value);
      }
    },
    [normalizeTabBaseFilters, transformTabFilter, onChangeTab]
  );

  const handleChangeFilter = useCallback(
    (filter, value) => {
      setFilterValues(f => sanitizeObject({ ...f, [filter]: value }));
      setPagination(defaultPaginationProps);

      switch (filter) {
        case 'isTenderInternal':
          setExtraFilters(f =>
            sanitizeObject({
              ...f,
              [filter]: !value || value?.length === 0 || value?.length === 2 ? null : value?.[0] === TENDER_TYPE.INLAND,
            })
          );
          break;

        default: {
          setExtraFilters(f => sanitizeObject({ ...f, [filter]: value, ...onChangeFilter?.(filter, value) }));
        }
      }
    },
    [onChangeFilter]
  );

  const handleResetFilters = useCallback(() => {
    setExtraFilters();
    setFilterValues();
  }, []);

  useEffect(() => {
    if (tenders?.status === statusType.SUCCESSED) {
      if (tenders?.data?.length > 0) {
        setItems(tenders?.data);
      } else {
        setItems([]);
      }
    }
  }, [tenders]);

  useEffect(() => {
    if (tendersAvailable) {
      dispatch(
        findTenders({
          data: { ...baseFilters, ...extraFilters },
          pagination,
          sort: transformTabSort(tab),
        })
      );
    }
  }, [dispatch, baseFilters, extraFilters, tab, pagination, tendersAvailable, transformTabSort]);

  useEffect(() => {
    if (report?.status === statusType.SUCCESSED) {
      dispatch({ payload: { type: REPORT_TYPE.TENDERS }, type: CREATE_REPORT });
      dispatch(showModal({ modal: MODALS.CreateReportComplete, onOk: handleClickReport }));
      const a = document.createElement('a');
      a.href = report?.data?.link;
      a.click();
    } else if (report?.status === statusType.FAILED) {
      dispatch({ payload: { type: REPORT_TYPE.TENDERS }, type: CREATE_REPORT });
      dispatch(hideModal(MODALS.CreateTendersReport));
      dispatch(showModal({ modal: MODALS.Oops, onOk: handleClickReport }));
    }
  }, [dispatch, report, handleClickReport]);

  const role = account?.role;

  return (
    <BaseContent
      contentSpan={24}
      contentGutter={false}
      header={
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Title level={4} style={{ margin: 0 }}>
                  {t('pages.tenders.title')}
                </Title>
              </Col>

              <Col>
                <Space>
                  {(role === ACCOUNT_ROLE.LG || role === ACCOUNT_ROLE.LG_CHIEF || role === ACCOUNT_ROLE.AU) && (
                    <Col>
                      <Button type="primary" shape="round" ghost onClick={handleClickReport}>
                        {t('pages.tenders.create-report')}
                      </Button>
                    </Col>
                  )}

                  {(role === ACCOUNT_ROLE.LG || role === ACCOUNT_ROLE.LG_CHIEF) && (
                    <Col>
                      <Button type="primary" shape="round" onClick={handleClickAdd}>
                        {t('pages.tenders.create-new-tender')}
                      </Button>
                    </Col>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          {tabs?.length > 1 && (
            <Col span={24}>
              <TenderTabs tab={tab} tabs={tabs} onChange={handleChangeTab} total={tenders?.pagination?.total} />
            </Col>
          )}
          <Col span={24}>
            <FilterComponent
              options={{
                status: role === ACCOUNT_ROLE.AU && auStatusFilterOptions,
              }}
              values={filterValues}
              withCreator={role !== ACCOUNT_ROLE.TC}
              withResponsible={role !== ACCOUNT_ROLE.TC}
              withType={role !== ACCOUNT_ROLE.TC}
              withStatusFilter={tab === TENDER_GROUP.ALL || withStatusFilter}
              withUnAssignee={tab === TENDER_STATUS.DRAFT}
              onChange={handleChangeFilter}
            />
          </Col>
        </Row>
      }
      headerDivider={false}
    >
      <Col span={24}>
        <Table
          pagination={tenders?.pagination}
          columns={columns?.[tab]}
          data={items}
          rowKey={parseTenderId}
          onRowClick={handleClickRow}
          onChange={handleChangeTable}
          loading={tenders?.status === statusType.LOADING}
          locale={{
            emptyText: (
              <TableEmpty
                onReset={handleResetFilters}
                searching={isSearching}
                emptySearchText={t('pages.tenders.search-result-empty')}
              />
            ),
          }}
        />
      </Col>
    </BaseContent>
  );
};

TendersTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  normalizeTabBaseFilters: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  tendersAvailable: PropTypes.bool,
  transformTabFilter: PropTypes.func,
  transformTabSort: PropTypes.func,
};

export default memo(TendersTable);
