import React, { memo, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_ACTION } from '@/constants/company';

import { CONTRACT_ACTION } from '@/constants/contract';
import { hideModal } from '@/store/actions/modalsActions';
import Modal from '@/views/modals/Modal';
import statusType from '@/store/constants/statusType';

import { DatePicker } from '@/components';
import { MODALS } from '..';

import classes from './ConfirmContractSign.module.scss';

const { Title, Text } = Typography;

const ConfirmContractSign = props => {
  const { onOk } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState();

  const status = useSelector(state => state.contract?.[CONTRACT_ACTION.CONFIRM_CONTRACT]?.status);

  const handleChangeDate = useCallback(momentDate => {
    setDate(momentDate.valueOf());
  }, []);

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.ConfirmContractSign));
  }, [dispatch]);

  const handleClickYes = useCallback(() => {
    if (onOk) {
      onOk(date);
    }
  }, [onOk, date]);

  return (
    <Modal name={MODALS.ConfirmContractSign} width={468}>
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <Title level={4}>{t('modal.confirm-contract-sign.title')}</Title>
          <Text className={classes.description}>{t('modal.confirm-contract-sign.description')}</Text>
        </Col>
        <Col span={24}>
          <Form layout="vertical">
            <Form.Item label={t('modal.confirm-contract-sign.reason')}>
              <DatePicker disabledDate={false} onChange={handleChangeDate} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Space>
              <Button shape="round" onClick={handleClickNo}>
                {t('modal.confirm-contract-sign.button.no')}
              </Button>
              <Button type="primary" shape="round" loading={status === statusType.LOADING} onClick={handleClickYes}>
                {t('modal.confirm-contract-sign.button.yes')}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(ConfirmContractSign);
