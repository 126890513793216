import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { CompanyStatusTag } from '@/components';
import { MODALS } from '@/views/modals';
import { MODE } from '@/views/modals/AddCompanySuccess/AddCompanySuccess';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import useQuery from '@/hooks/useQuery';
import { ViewerContext } from '@/context/viewer-context';
import {
  archiveCompany,
  enableEditCompany,
  getCompany,
  getCompanyAvailableActions,
  sendForReVerification,
  sendForVerification,
  sendForVerificationAfterEdit,
  unArchiveCompany,
} from '@/store/actions/companyActions';
import { COMPANY_ACTION, COMPANY_STATUS, validForVerify } from '@/constants/company';
import { hideModal, showModal } from '@/store/actions/modalsActions';

import colors from '@/styles/colors.module.scss';

import CompanyDetails from '../containers/CompanyDetails';
import SectionContacts from '../components/SectionContacts';
import SectionDocuments from '../components/SectionDocuments';
import SectionGeneral from '../components/SectionGeneral';

const TransportCompany = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const viewer = useContext(ViewerContext);

  const { data: accountData } = useSelector(state => state.account);
  const companyId = useMemo(() => accountData?.companyId, [accountData?.companyId]);
  const { data: availableActions } = useSelector(state => state.companyActions);

  const companyState = useSelector(state => state.company);

  const {
    get: { [companyId]: { status: getStatus, data: company } = {} },
    edit: { [companyId]: { status: editStatus } = {} },
  } = useSelector(state => state.company);

  const [editPath, setEditPath] = useState();
  const [isValidForVerify, setIsValidForVerify] = useState(false);
  const isEditable = useMemo(() => {
    const status = company?.general?.status;
    return (
      status &&
      (status === COMPANY_STATUS.NEW ||
        status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
        status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
        (status === COMPANY_STATUS.ACTIVE && availableActions?.[COMPANY_ACTION.ENABLE_EDIT] === true) ||
        status === COMPANY_STATUS.IN_REWORK ||
        status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION ||
        status === COMPANY_STATUS.INACTIVE_AFTER_EDIT ||
        status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT)
    );
  }, [company, availableActions]);

  const isDocsEditable = useMemo(() => {
    const status = company?.general?.status;
    return (
      status &&
      (status === COMPANY_STATUS.NEW ||
        status === COMPANY_STATUS.INACTIVE_AFTER_EDIT ||
        status === COMPANY_STATUS.IN_REWORK)
    );
  }, [company]);

  const canDocsEdit = useMemo(() => {
    const status = company?.general?.status;
    return (
      status &&
      availableActions?.[COMPANY_ACTION.ENABLE_EDIT] === true &&
      status !== COMPANY_STATUS.INACTIVE_AFTER_EDIT &&
      status !== COMPANY_STATUS.BLOCKED &&
      status !== COMPANY_STATUS.VERIFICATION_REJECTED &&
      status !== COMPANY_STATUS.NEW
    );
  }, [company, availableActions]);

  const isVerifyAvaialable = useMemo(() => {
    const status = company?.general?.status;
    return (
      status === COMPANY_STATUS.NEW ||
      status === COMPANY_STATUS.IN_REWORK ||
      status === COMPANY_STATUS.INACTIVE_AFTER_EDIT
    );
  }, [company]);

  const isArchiveAvaialable = useMemo(() => {
    const status = company?.general?.status;
    return (
      status === COMPANY_STATUS.ACTIVE ||
      status === COMPANY_STATUS.IN_REWORK ||
      status === COMPANY_STATUS.INACTIVE_AFTER_EDIT ||
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
      status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT ||
      status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION
    );
  }, [company]);

  const isUnArchiveAvaialable = useMemo(() => company?.general?.status === COMPANY_STATUS.ARCHIVED, [company]);

  const warnBeforeEdit = useMemo(() => {
    const status = company?.general?.status;
    return (
      status === COMPANY_STATUS.ACTIVE ||
      status === COMPANY_STATUS.ARCHIVED ||
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
      status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION ||
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT ||
      status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT
    );
  }, [company]);

  const getCompanyDetails = useCallback(() => {
    if (accountData?.companyId) {
      dispatch(getCompany(accountData.companyId, true));
    }
  }, [accountData, dispatch]);

  const handleArchive = useCallback(() => {
    dispatch(archiveCompany(companyId));
  }, [dispatch, companyId]);

  const handleEdit = useCallback(() => {
    dispatch(enableEditCompany(companyId));
  }, [dispatch, companyId]);

  const handleCancelEdit = useCallback(() => {
    setEditPath();
  }, []);

  const handleClickFill = useCallback(() => {
    history.push(pageUrls.COMPANY.FILL);
  }, [history]);

  const handleClickArchive = useCallback(() => {
    dispatch(showModal({ modal: MODALS.ConfirmCompanyArchive, onOk: handleArchive }));
  }, [dispatch, handleArchive]);

  const handleClickVerify = useCallback(() => {
    if (company?.general?.status === COMPANY_STATUS.INACTIVE_AFTER_EDIT) {
      dispatch(sendForVerificationAfterEdit(companyId));
    } else if (company?.general?.status === COMPANY_STATUS.IN_REWORK) {
      dispatch(sendForReVerification(companyId));
    } else {
      dispatch(sendForVerification(companyId));
    }
  }, [dispatch, companyId, company]);

  const handleClickUnArchive = useCallback(() => {
    dispatch(unArchiveCompany(companyId));
  }, [dispatch, companyId]);

  const handleClickCancelVerify = useCallback(() => {
    dispatch(hideModal(MODALS.AddCompanySuccess));
    history.replace(pageUrls.COMPANY.MY);
  }, [dispatch, history]);

  const handleClickEditContacts = useCallback(() => {
    if (warnBeforeEdit) {
      setEditPath(pageUrls.COMPANY.EDIT.CONTACTS);
      dispatch(showModal({ modal: MODALS.ConfirmCompanyEdit, onOk: handleEdit, onCancel: handleCancelEdit }));
    } else {
      history.push(pageUrls.COMPANY.EDIT.CONTACTS);
    }
  }, [dispatch, history, handleEdit, handleCancelEdit, warnBeforeEdit]);

  const handleClickEditDocuments = useCallback(() => {
    dispatch(showModal({ modal: MODALS.ConfirmCompanyEdit, onOk: handleEdit, onCancel: handleCancelEdit }));
  }, [dispatch, handleEdit, handleCancelEdit]);

  const handleClickEditGeneral = useCallback(() => {
    if (warnBeforeEdit) {
      setEditPath(pageUrls.COMPANY.EDIT.GENERAL);
      dispatch(showModal({ modal: MODALS.ConfirmCompanyEdit, onOk: handleEdit, onCancel: handleCancelEdit }));
    } else {
      history.push(pageUrls.COMPANY.EDIT.GENERAL);
    }
  }, [dispatch, history, handleEdit, handleCancelEdit, warnBeforeEdit]);

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      getCompanyDetails();
    }
  }, [editStatus, getCompanyDetails]);

  useEffect(() => {
    const isValid = validForVerify(company);
    setIsValidForVerify(isValid);

    if (query?.get('modal') === 'suggestVerify') {
      if (isValid) {
        dispatch(
          showModal({
            id: company?.general?.id,
            modal: MODALS.AddCompanySuccess,
            mode: MODE.VERIFY,
            onCancel: handleClickCancelVerify,
            onOk: handleClickVerify,
          })
        );
      } else {
        dispatch(
          showModal({
            modal: MODALS.AddCompanySuccess,
            onCancel: handleClickCancelVerify,
            onOk: handleClickFill,
          })
        );
      }
    }
  }, [dispatch, company, query, handleClickFill, handleClickVerify, handleClickCancelVerify]);

  useEffect(() => {
    const archiveState = companyState[COMPANY_ACTION.SEND_TO_ARCHIVE];
    const editState = companyState[COMPANY_ACTION.ENABLE_EDIT];
    const verifyState = companyState[COMPANY_ACTION.SUBMIT_FOR_VERIFICATION];
    const verifyAfterEditState = companyState[COMPANY_ACTION.SEND_TO_VERIFICATION_AFTER_EDIT];
    const reVerifyState = companyState[COMPANY_ACTION.SUBMIT_FOR_RE_VERIFICATION];
    const unArchiveState = companyState[COMPANY_ACTION.RESTORE_FROM_ARCHIVE];

    if (editState?.status === statusType.SUCCESSED && editPath) {
      history.push(editPath);
    } else {
      const success =
        archiveState?.status === statusType.SUCCESSED ||
        editState?.status === statusType.SUCCESSED ||
        verifyState?.status === statusType.SUCCESSED ||
        verifyAfterEditState?.status === statusType.SUCCESSED ||
        reVerifyState?.status === statusType.SUCCESSED ||
        unArchiveState?.status === statusType.SUCCESSED;

      if (success) {
        history.push(pageUrls.COMPANY.MY);
      }
    }
  }, [history, companyState, editPath]);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyAvailableActions(companyId, [COMPANY_ACTION.SEND_TO_ARCHIVE, COMPANY_ACTION.ENABLE_EDIT]));
    }
  }, [dispatch, companyId]);

  const { general } = company || {};
  const status = general?.status;

  return (
    <CompanyDetails
      loading={getStatus === statusType.LOADING}
      title={false}
      header={
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <Row gutter={[8, 0]} align="middle">
                <Col>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    {t('pages.company.my-company')}
                  </Typography.Title>
                </Col>
                {status && (
                  <Col>
                    <CompanyStatusTag status={status} />
                  </Col>
                )}
              </Row>
              <Space>
                {isArchiveAvaialable && (
                  <Tooltip
                    title={
                      availableActions?.[COMPANY_ACTION.SEND_TO_ARCHIVE] === false &&
                      t('pages.company.error.archive-disabled-due-tender')
                    }
                  >
                    <Button
                      shape="round"
                      onClick={handleClickArchive}
                      disabled={availableActions?.[COMPANY_ACTION.SEND_TO_ARCHIVE] === false}
                    >
                      {t('pages.company.button.archive')}
                    </Button>
                  </Tooltip>
                )}
                {isUnArchiveAvaialable && (
                  <Button shape="round" onClick={handleClickUnArchive}>
                    {t('pages.company.button.unarchive')}
                  </Button>
                )}
                {isVerifyAvaialable && (
                  <Tooltip
                    placement="bottom"
                    title={!isValidForVerify && t('pages.company.error.verify-disabled-due-required-fields')}
                  >
                    <Button shape="round" type="primary" onClick={handleClickVerify} disabled={!isValidForVerify}>
                      {t('pages.company.button.send-to-verification')}
                    </Button>
                  </Tooltip>
                )}
              </Space>
            </Row>
          </Col>
          <Col span={24}>
            {(general?.status === COMPANY_STATUS.IN_REWORK ||
              general?.status === COMPANY_STATUS.VERIFICATION_REJECTED ||
              (viewer?.role === ACCOUNT_ROLE.TC && general?.status === COMPANY_STATUS.BLOCKED)) &&
              general?.commentOnLastStateChange && (
                <Col span={12}>
                  <Typography.Text type="secondary" style={{ color: colors.blueGray5 }}>
                    {t('common.reason')}: {general?.commentOnLastStateChange}
                  </Typography.Text>
                </Col>
              )}
          </Col>
        </Row>
      }
      visible={general?.id}
    >
      <SectionGeneral allowEdit={false} data={company} onEdit={isEditable && handleClickEditGeneral} />
      <SectionContacts allowEdit={false} data={company} onEdit={isEditable && handleClickEditContacts} />
      <SectionDocuments allowEdit={isDocsEditable} data={company} onEdit={canDocsEdit && handleClickEditDocuments} />
    </CompanyDetails>
  );
};

export default memo(TransportCompany);
