import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const CompanyBlockedDueContract = () => {
  const { t } = useTranslation();

  return (
    <Alert
      type={ALERT_TYPE.WARNING}
      message={t('alert.company-blocked-due-contract.title')}
      description={t('alert.company-blocked-due-contract.description')}
    />
  );
};

export default memo(CompanyBlockedDueContract);
