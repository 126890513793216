import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import { REPORT_TYPE } from '@/constants/report';

import { CREATE_REPORT_ERROR, CREATE_REPORT_LOADING, CREATE_REPORT_SUCCESS } from '../constants/actionTypes';

const createCompaniesReport = payload => async dispatch => {
  dispatch({
    payload: { type: REPORT_TYPE.COMPANIES },
    type: CREATE_REPORT_LOADING,
  });

  const { error, status, response } = await apiService.post(apiUrls.REPORT.COMPANIES, payload);

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, type: REPORT_TYPE.COMPANIES },
      type: CREATE_REPORT_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data, type: REPORT_TYPE.COMPANIES },
      type: CREATE_REPORT_SUCCESS,
    });
  }
};

const createCompletedTendersReport = payload => async dispatch => {
  dispatch({
    payload: { type: REPORT_TYPE.TENDERS },
    type: CREATE_REPORT_LOADING,
  });

  const { error, status, response } = await apiService.post(apiUrls.REPORT.TENDERS_COMPLETED, payload);

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, type: REPORT_TYPE.TENDERS },
      type: CREATE_REPORT_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data, type: REPORT_TYPE.TENDERS },
      type: CREATE_REPORT_SUCCESS,
    });
  }
};

const createFailedTendersReport = payload => async dispatch => {
  dispatch({
    payload: { type: REPORT_TYPE.TENDERS },
    type: CREATE_REPORT_LOADING,
  });

  const { error, status, response } = await apiService.post(apiUrls.REPORT.TENDERS_FAILED, payload);

  if (error) {
    dispatch({
      payload: { error: { status, ...error }, type: REPORT_TYPE.TENDERS },
      type: CREATE_REPORT_ERROR,
    });
  } else {
    dispatch({
      payload: { data: response?.data, type: REPORT_TYPE.TENDERS },
      type: CREATE_REPORT_SUCCESS,
    });
  }
};

export { createCompaniesReport, createCompletedTendersReport, createFailedTendersReport };
