import React, { memo, useCallback } from 'react';

import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MODALS } from '@/views/modals';
import { showModal } from '@/store/actions/modalsActions';
import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const CompanyAddNeeded = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickButton = useCallback(() => {
    dispatch(showModal({ modal: MODALS.AddCompany }));
  }, [dispatch]);

  return (
    <Alert
      type={ALERT_TYPE.WARNING}
      message={t('alert.company-add-needed.title')}
      description={t('alert.company-add-needed.description')}
      action={
        <Button shape="round" type="primary" onClick={handleClickButton}>
          {t('alert.company-add-needed.button')}
        </Button>
      }
    />
  );
};

export default memo(CompanyAddNeeded);
