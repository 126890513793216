import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Button, Col, Form, Row, Spin, Steps, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';
import env from '@/constants/env';
import errorCodes from '@/constants/errorCodes';
import { getAccount } from '@/store/actions/accountActions';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import { editCompany, getCompany } from '@/store/actions/companyActions';

import ContactFields from './components/ContactFields';
import DocumentsFields from './components/DocumentsFields';
import GeneralFields from './components/GeneralFields';

import classes from './CompanyFill.module.scss';

const { Title, Text } = Typography;

const CompanyFill = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [filled, setFilled] = useState(false);
  const [form] = Form.useForm();

  const { data: account } = useSelector(state => state.account);
  const id = useMemo(() => account?.companyId, [account?.companyId]);

  const {
    get: { [id]: { status: getStatus, data: company } = {} },
    edit: { [id]: { status: editStatus, error: editError } = {} },
  } = useSelector(state => state.company);

  const updateCompany = useCallback(
    portion => {
      switch (step) {
        case 1: {
          const { addresses, general, transports = [] } = portion;
          dispatch(
            editCompany({
              data: {
                addresses: { addresses, sameMailAddress: general.sameMailAddress },
                general: { general: { ...company.general, ...general } },
                transports: {
                  transports,
                  transportsAvailable: general.transportsAvailable,
                },
              },
              id,
            })
          );
          break;
        }
        case 2: {
          const { contacts } = portion;
          dispatch(editCompany({ data: { contacts }, id }));
          break;
        }
      }
    },
    [company, dispatch, id, step]
  );

  const handleClickBack = useCallback(() => {
    setFilled(false);
    setStep(s => s - 1);
    dispatch(getCompany(id, true));
  }, [id, dispatch]);

  const handleClickNext = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        const portion = form.getFieldsValue();
        updateCompany(portion);
      })
      .catch(() => {});
  }, [form, updateCompany]);

  const handleClickCancel = useCallback(() => {
    history.push(pageUrls.COMPANY.MY);
  }, [history]);

  const handleClickSave = useCallback(() => {
    form.validateFields().then(() => {
      const portion = form.getFieldsValue();
      dispatch(editCompany({ data: portion, id }));
      setFilled(true);
    });
  }, [dispatch, id, form]);

  useEffect(() => {
    if (company?.general?.id) {
      form.resetFields();
    }
  }, [form, company]);

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      if (filled) {
        history.push(`${pageUrls.COMPANY.MY}?modal=suggestVerify`);
      } else {
        setStep(s => s + 1);
      }
    } else if (editStatus === statusType.FAILED) {
      const errorCode = editError?.code;
      switch (errorCode) {
        case errorCodes.COMPANY_BIN_CONFLICT: {
          form.setFields([
            {
              errors: [
                <Trans key="t" i18nKey={`pages.company-add.error.code.${errorCodes.COMPANY_BIN_CONFLICT}`}>
                  [<Typography.Link underline href={`mailto:${env.SUPPORT_EMAIL}`} target="_blank" />]
                </Trans>,
              ],
              name: ['general', 'bin'],
            },
          ]);
          break;
        }
      }
    }
  }, [dispatch, history, editError, editStatus, filled, form]);

  useEffect(() => {
    if (id) {
      dispatch(getCompany(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!account?.companyId) {
      dispatch(getAccount());
    }
  }, [dispatch, account]);

  return (
    <DashboardLayout>
      <BaseContent
        header={
          <Row justify="center" gutter={[0, 36]}>
            <Col span={24}>
              <Title level={3} className={clsx(classes.title, 'text-align-center')}>
                {t('pages.company.create-company-to-access-tenders')}
              </Title>
            </Col>
            <Col span={18}>
              <Steps labelPlacement="vertical" current={step - 1}>
                <Steps.Step description={t('pages.company-fill.company-info')} />
                <Steps.Step description={t('pages.company.contact-information')} />
                <Steps.Step description={t('pages.company-fill.required-documents')} />
              </Steps>
            </Col>
          </Row>
        }
      >
        <Col span={24} className={classes.form}>
          <Spin spinning={getStatus === statusType.LOADING || editStatus === statusType.LOADING || !id}>
            <Form form={form} name="company-fill" layout="horizontal" initialValues={company}>
              <Row gutter={[0, 22]}>
                <Col span={24}>
                  {step === 1 && (
                    <>
                      <Col span={24} className={classes.title}>
                        <Title level={3}>{t('pages.company-fill.company-info')}</Title>
                        <Text>{t('pages.company-fill.fill-form-to-access-application')}</Text>
                      </Col>
                      <GeneralFields />
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <Col span={24} className={classes.title}>
                        <Title level={3}>{t('pages.company.contact-information')}</Title>
                        <Text>{t('pages.company-fill.fill-form-to-access-application')}</Text>
                      </Col>
                      <ContactFields />
                    </>
                  )}
                  {step === 3 && <DocumentsFields />}
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[12, 0]}>
                    <Col>
                      {step === 1 ? (
                        <Button type="default" shape="round" onClick={handleClickCancel}>
                          {t('common.cancel')}
                        </Button>
                      ) : (
                        <Button type="default" shape="round" onClick={handleClickBack} icon={<LeftOutlined />}>
                          {t('common.back')}
                        </Button>
                      )}
                    </Col>
                    <Col>
                      {step < 3 ? (
                        <Button
                          type="primary"
                          shape="round"
                          onClick={handleClickNext}
                          loading={editStatus === statusType.LOADING}
                        >
                          {t('common.next-step')}
                        </Button>
                      ) : (
                        <Button type="primary" shape="round" onClick={handleClickSave}>
                          {t('common.save')}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(CompanyFill);
