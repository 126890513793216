import React, { forwardRef, memo, useCallback, useState } from 'react';

import clsx from 'clsx';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';

import { Text } from '@/components';

import classes from './PasswordStrength.module.scss';

const validate = (value, { min }) => {
  const errors = {};

  if (!value) {
    errors.required = true;
  }
  if (!value?.match(/\d+/g)) {
    errors.numbers = true;
  }
  if (value?.toLowerCase() === value) {
    errors.uppercase = true;
  }
  if (value?.toUpperCase() === value) {
    errors.lowercase = true;
  }
  if (value?.length < min) {
    errors.min = true;
  }
  if (!value?.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g)) {
    errors.symbol = true;
  }
  // eslint-disable-next-line no-control-regex
  if (value?.match(/[^\x00-\x7F]+/g)) {
    errors.ascii = true;
  }

  if (Object.keys(errors).length > 0) {
    return Promise.reject(errors);
  }

  return Promise.resolve();
};

const normalize = value => value.replace(/\s/g, '');

const validator = (value, { min }) =>
  validate(value, { min })
    .then(Promise.resolve())
    .catch(error => {
      const errorMessage = error?.required
        ? i18n.t('error.field.required')
        : error?.ascii
        ? i18n.t('error.field.must-contain-only-ascii')
        : error?.numbers
        ? i18n.t('error.field.at-least-one-digit')
        : error?.lowercase
        ? i18n.t('error.field.lowercase')
        : error?.uppercase
        ? i18n.t('error.field.uppercase')
        : error?.min
        ? i18n.t('error.field.min', { min })
        : error?.symbol
        ? i18n.t('error.field.at-least-one-symbol')
        : '';
      return Promise.reject(errorMessage);
    });

const PasswordStrength = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    min = 8,
    name = 'password',
    label = t('common.fill-password'),
    placeholder = t('placeholder.password'),
  } = props;

  const [error, setError] = useState({ lowercase: true, min: true, numbers: true, symbol: true, uppercase: true });

  const handleChange = useCallback(
    event => {
      const { value } = event.target;
      validate(value, { min })
        .then(() => setError())
        .catch(err => setError(err));
    },
    [min]
  );

  return (
    <Row className={classes.root}>
      <Col span={24}>
        <Form.Item
          normalize={normalize}
          validateTrigger={['onChange', 'onBlur']}
          label={label}
          name={name}
          rules={[
            () => ({
              validator: (_, value) => validator(value, { min }),
            }),
          ]}
          style={{ marginBottom: 4 }}
        >
          <Input.Password ref={ref} placeholder={placeholder} onChange={handleChange} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 0]}>
          <Col>
            <Text className={clsx(classes.status, { [classes.success]: !error?.numbers })}>{t('common.numbers')}</Text>
          </Col>
          <Col>
            <Text className={clsx(classes.status, { [classes.success]: !error?.lowercase })}>
              {t('common.lowercase-chars')}
            </Text>
          </Col>
          <Col>
            <Text className={clsx(classes.status, { [classes.success]: !error?.uppercase })}>
              {t('common.uppercase-chars')}
            </Text>
          </Col>
          <Col>
            <Text className={clsx(classes.status, { [classes.success]: !error?.min })}>
              {t('common.min-x-chars', { min })}
            </Text>
          </Col>
          <Col>
            <Text className={clsx(classes.status, { [classes.success]: !error?.symbol })}>
              {t('common.special-symbol')}
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

PasswordStrength.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default memo(PasswordStrength);
