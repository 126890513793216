import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import errorCodes from '@/constants/errorCodes';
import { formatPhone } from '@/utils/format';
import { sendSignUpEmail } from '@/store/actions/sendEmailActions';
import statusType from '@/store/constants/statusType';
import { Text } from '@/components';
import { blockEmployee, recoverEmployee } from '@/store/actions/employeesActions';
import { EMPLOYEE_STATUS, ROLES_OPTIONS } from '@/constants/employees';
import { hideModal, showModal } from '@/store/actions/modalsActions';
import { getEmailDetails } from '@/store/actions/emailDetailsActions';
import { GET_EMAIL_DETAILS, SEND_EMAIL } from '@/store/constants/actionTypes';
import { convertSecondsToHMS } from '@/utils/timeUtils';
import Modal from '../Modal';
import { MODALS } from '..';

const EditEmployee = props => {
  const { employee, onEdit } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const details = useSelector(state => state.emailDetails);
  const { edit: { data: editData, status: editStatus } = {} } = useSelector(state => state.employee);
  const { data: account } = useSelector(state => state.account);

  const [seconds, setSeconds] = useState();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const intervalRef = useRef();
  const sendEmail = useSelector(state => state.sendEmail);

  const handleClickSendLink = useCallback(() => {
    // dispatch(hideModal(MODALS.EditEmployee));
    dispatch(sendSignUpEmail({ id: employee?.id }));
    setIsResendDisabled(true);
  }, [dispatch, employee]);

  const handleClickCancel = useCallback(() => {
    dispatch(hideModal(MODALS.EditEmployee));
  }, [dispatch]);

  const handleBlockEmployee = useCallback(() => {
    dispatch(hideModal(MODALS.EditEmployee));
    dispatch(blockEmployee(employee));
  }, [dispatch, employee]);

  const handleClickBlock = useCallback(() => {
    dispatch(showModal({ modal: MODALS.BlockEmployee, onOk: handleBlockEmployee, employee: employee?.fullName }));
  }, [dispatch, handleBlockEmployee, employee]);

  const handleClickRecover = useCallback(() => {
    dispatch(recoverEmployee(employee));
  }, [dispatch, employee]);

  const handleFinishForm = useCallback(
    fields => {
      if (onEdit) {
        onEdit({
          ...employee,
          ...fields,
        });
      }
    },
    [employee, onEdit]
  );

  useEffect(() => {
    if (editStatus === statusType.SUCCESSED) {
      if (editData?.email?.error?.code === errorCodes.USER_EMAIL_CONFLICT) {
        form.setFields([
          {
            errors: [
              t(
                `modal.edit-employee.error.code.${errorCodes.USER_EMAIL_CONFLICT}`,
                `error.code.${errorCodes.USER_EMAIL_CONFLICT}`
              ),
            ],
            name: ['email'],
          },
        ]);
      } else {
        dispatch(hideModal(MODALS.EditEmployee));
      }
    }
  }, [dispatch, editStatus, editData, form, t]);

  useEffect(() => {
    dispatch(getEmailDetails({ id: employee?.id, target: 'email' }));
  }, [dispatch, employee]);

  const updateSeconds = useCallback(() => {
    setSeconds(s => s - 1);
  }, []);

  useEffect(() => {
    if (details?.status === statusType.SUCCESSED) {
      clearInterval(intervalRef.current);
      setSeconds(details.data?.retryInSeconds);
      setIsResendDisabled(true);
      intervalRef.current = setInterval(updateSeconds, 1000);
      dispatch({
        type: GET_EMAIL_DETAILS,
      });
    }
  }, [details, dispatch, updateSeconds]);

  useEffect(() => {
    if (sendEmail?.status === statusType.SUCCESSED) {
      dispatch({
        type: SEND_EMAIL,
      });
      dispatch(getEmailDetails({ id: employee?.id, target: 'email' }));
    }
  }, [dispatch, sendEmail, employee]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      setIsResendDisabled(false);
    }
  }, [seconds]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  const fieldDisabled = employee?.status !== EMPLOYEE_STATUS.ACTIVE;

  return (
    <Modal name={MODALS.EditEmployee} width={600} bodyStyle={{ padding: '32px 24px' }}>
      <Form
        name="edit-employee"
        layout="vertical"
        requiredMark={false}
        onFinish={handleFinishForm}
        initialValues={employee}
        form={form}
      >
        <Col span={24}>
          <Typography.Title level={4}>{t('modal.edit-employee.title')}</Typography.Title>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.last-name')} name={['lastName']}>
            <Input placeholder={t('placeholder.lastName')} disabled={fieldDisabled} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label={t('common.first-name')} name={['firstName']}>
                <Input placeholder={t('placeholder.firstName')} disabled={fieldDisabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('common.middle-name')} name={['middleName']}>
                <Input placeholder={t('placeholder.middleName')} disabled={fieldDisabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.role')} name={['role']}>
            <Select placeholder={t('placeholder.select-from-list')} options={ROLES_OPTIONS} disabled={fieldDisabled} />
          </Form.Item>
          <Form.Item name={['admin']} valuePropName="checked">
            <Checkbox disabled={employee?.id === account?.id || fieldDisabled}>
              {t('modal.add-employee.is-admin')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.position')} name={['position']}>
            <Input placeholder={t('placeholder.position')} disabled={fieldDisabled} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={t('common.phone')}
                name={['phone']}
                rules={[...fieldRules.phone(10)]}
                normalize={formatPhone}
              >
                <Input.Phone placeholder={t('placeholder.phone')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('common.email')} name={['email']}>
                <Input placeholder={t('placeholder.email')} disabled={fieldDisabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              {employee?.status !== EMPLOYEE_STATUS.BLOCKED && (
                <Button shape="round" onClick={handleClickBlock} danger disabled={editStatus === statusType.LOADING}>
                  {t('modal.edit-employee.button.block')}
                </Button>
              )}
            </Col>
            <Col>
              {employee?.emailConfirmed !== true && (
                <Tooltip
                  title={
                    seconds > 0 && (
                      <Text align="center" color="blue-gray-1" block>
                        <Trans
                          i18nKey="common.resend-email-available-in"
                          values={{ time: convertSecondsToHMS(seconds, true) }}
                        >
                          [<Text size={20} weight="semibold" color="main-6" />]
                        </Trans>
                      </Text>
                    )
                  }
                >
                  <Button shape="round" onClick={handleClickSendLink} disabled={isResendDisabled}>
                    {t('common.send-link')}
                  </Button>
                </Tooltip>
              )}
            </Col>
            <Space>
              <Button shape="round" disabled={editStatus === statusType.LOADING} onClick={handleClickCancel}>
                {t('common.cancel')}
              </Button>

              {employee?.status === EMPLOYEE_STATUS.BLOCKED ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={handleClickRecover}
                  loading={editStatus === statusType.LOADING}
                >
                  {t('modal.edit-employee.button.recover')}
                </Button>
              ) : (
                <Button type="primary" shape="round" htmlType="submit" loading={editStatus === statusType.LOADING}>
                  {t('common.edit')}
                </Button>
              )}
            </Space>
          </Row>
        </Col>
      </Form>
    </Modal>
  );
};

export default memo(EditEmployee);
