import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';

import './i18n';
import App from './App';
import store from './store';

import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter forceRefresh>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
