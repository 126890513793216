import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Card, Checkbox, Col, Image, Row, Space, Timeline, Typography } from 'antd';

import clsx from 'clsx';
import box from './images/box.svg';
import formCompany from './images/form-company.png';
import formRegister from './images/form-register.png';
import formTender from './images/form-tender.png';

import classes from './styles/Content.module.scss';

const t = {
  login: 'Войти',
  'process.title': 'Стать логистическим\nпартнёром легко',
  register: 'Зарегистрироваться',
};

const Step = props => {
  const { title, description, form, reverse } = props;
  return (
    <Col span={24}>
      <Row align="middle" justify="space-between">
        <Col span={11} order={reverse ? 2 : 1}>
          <Typography.Title level={2}>{title}</Typography.Title>
          <Typography.Text className={classes.description}>{description}</Typography.Text>
        </Col>
        <Col span={11} order={reverse ? 1 : 2}>
          {form}
        </Col>
      </Row>
    </Col>
  );
};

const Stepline = () => {
  const ref = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);
  const handleScroll = useCallback(() => {
    const { current } = ref;
    if (current) {
      const diff = window.scrollY - 600;
      if (diff > 0) {
        if (diff > 840) {
          setLineHeight(840);
        } else {
          setLineHeight(diff);
        }
      } else {
        setLineHeight(0);
      }
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Col ref={ref} className={classes.stepline}>
      <div className={classes.ghost} />
      <div className={classes.line} style={{ height: lineHeight }} />
      <Col className={clsx(classes.step, classes.active)}>1</Col>
      <Col className={clsx(classes.step, { [classes.active]: lineHeight > 370 })}>2</Col>
      <Col className={clsx(classes.step, { [classes.active]: lineHeight > 800 })}>3</Col>
    </Col>
  );
};

const Content = () => {
  return (
    <Col span={24} className={classes.root}>
      <Card className={classes.content}>
        <Image src={box} wrapperClassName={classes['logo-wrapper']} className={classes['logo-image']} preview={false} />
        <Row>
          <Col span={24}>
            <Row justify="center">
              <Typography.Title level={1} className={classes.title}>
                {t['process.title']}
              </Typography.Title>
            </Row>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 80]}>
          <Stepline />
          <Step
            title="Регистрируйся"
            description="Зарегистрироваться может любая транспортная или экспедиторская компания плательщик НДС с формой
                  собственности ТОО или АО со сроком регистрации более одного года. Собственный транспортный парк будет
                  вашим преимуществом."
            form={<Image src={formRegister} preview={false} />}
          />
          <Step
            reverse
            title="Верифицируйся"
            description="Служба безопасности проверит информацию о вашей компании. После успешной верификации вам будет
                  предложено ознакомиться с договором и согласиться с условиями. С этого момента будет открыт доступ к
                  участию в тендерах."
            form={<Image src={formCompany} preview={false} />}
          />
          <Step
            title="Участвуй в тендерах"
            description="Большой выбор тендеров на автомобильные, железнодорожные и контейнерные перевозки по внешним и
                  внутренним направлениям. Автоматизированный процесс торгов. С полными правилами участия в тендерах
                  можно ознакомиться в правилах проведения торгов."
            form={<Image src={formTender} preview={false} />}
          />
          <Col span={24}>
            <Row justify="center">
              <Button type="primary" shape="round">
                Зарегистрироваться
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default memo(Content);
