import statusType from '../constants/statusType';
import {
  GET_EMAIL_DETAILS,
  GET_EMAIL_DETAILS_ERROR,
  GET_EMAIL_DETAILS_LOADING,
  GET_EMAIL_DETAILS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const emailDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAIL_DETAILS:
      return { status: statusType.IDLE };

    case GET_EMAIL_DETAILS_ERROR:
      return { error: payload, status: statusType.FAILED };

    case GET_EMAIL_DETAILS_LOADING:
      return { status: statusType.LOADING };

    case GET_EMAIL_DETAILS_SUCCESS: {
      const { secondsTillNextAttempt } = payload;
      return {
        data: { retryInSeconds: secondsTillNextAttempt },
        status: statusType.SUCCESSED,
      };
    }

    default:
      return state;
  }
};

export default emailDetailsReducer;
