import React, { memo, useCallback, useEffect, useState } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { AutoComplete, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { autoComplete } from '@/store/actions/placeAction';

import classes from './PlaceAutoComplete.module.scss';

const PlaceAutoComplete = props => {
  const { placeholder, min = 3, onSelect, onChange, prefixIcon = true, value: initialValue } = props;
  const dispatch = useDispatch();
  const placeData = useSelector(state => state.place);
  const [place, setPlace] = useState();
  const [placeValue, setPlaceValue] = useState();
  const [options, setOptions] = useState([]);

  const handleSelect = useCallback(
    value => {
      const option = placeData[place].data.find(p => p.id === value);
      if (option) {
        if (onSelect) {
          onSelect(option);
        }
        if (onChange) {
          onChange(option);
        }
        const { city, country } = option;
        setPlaceValue(`${city}, ${country}`);
      }
    },
    [place, placeData, onChange, onSelect]
  );

  const handleSearch = useCallback(
    value => {
      setPlace(value);
      setPlaceValue(value);
      if (value?.length >= min) {
        dispatch(autoComplete(value));
      } else {
        setOptions([]);
      }
    },
    [dispatch, min]
  );

  const handleClear = useCallback(() => {
    setPlace();
    setPlaceValue();
    if (onSelect) {
      onSelect();
    }
    if (onChange) {
      onChange();
    }
  }, [onChange, onSelect]);

  useEffect(() => {
    if (placeData?.[place]?.data) {
      setOptions(
        placeData[place].data.map(option => ({
          label: `${option.city}, ${option.country}`,
          value: option.id,
        }))
      );
    }
  }, [place, placeData]);

  useEffect(() => {
    if (initialValue) {
      const { city, country } = initialValue;
      setPlaceValue(`${city}, ${country}`);
    }else{
      setPlaceValue(null);
    }
  }, [initialValue]);

  return (
    <AutoComplete
      className={classes.root}
      allowClear
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      options={options}
      value={placeValue}
    >
      <Input
        prefix={prefixIcon && <EnvironmentOutlined style={{ color: '#64748B' }} />}
        size="large"
        placeholder={placeholder}
        className={classes.input}
      />
    </AutoComplete>
  );
};

PlaceAutoComplete.propTypes = {
  min: PropTypes.number,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  prefixIcon: PropTypes.bool,
};

export default memo(PlaceAutoComplete);
