import React, { memo } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Image, Layout, Row } from 'antd';

import logoImage from '@/assets/images/logo.svg';

import classes from './BaseLayout.module.scss';

const BaseLayout = props => {
  const { children, className, logo = true } = props;
  return (
    <Layout className={clsx(classes.layout, className, 'fill-height')}>
      {logo && (
        <Row justify="center">
          <Image src={logoImage} className={classes.logo} preview={false} />
        </Row>
      )}
      <Layout className={clsx(classes.content, 'fill-height')}>
        <Row justify="center">{children}</Row>
      </Layout>
    </Layout>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  logo: PropTypes.bool,
};

export default memo(BaseLayout);
