import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';

import env from '@/constants/env';
import {
  CONFIRM_CONTRACT_ERROR,
  CONFIRM_CONTRACT_LOADING,
  CONFIRM_CONTRACT_SUCCESS,
  GENERATE_CONTRACT_ERROR,
  GENERATE_CONTRACT_LOADING,
  GENERATE_CONTRACT_SUCCESS,
  REVOKE_CONTRACT_ERROR,
  REVOKE_CONTRACT_LOADING,
  REVOKE_CONTRACT_SUCCESS,
  TERMINATE_CONTRACT_ERROR,
  TERMINATE_CONTRACT_LOADING,
  TERMINATE_CONTRACT_SUCCESS,
} from '../constants/actionTypes';

const revokeContract = payload => async dispatch => {
  dispatch({
    type: REVOKE_CONTRACT_LOADING,
  });

  const { error, status, response } = await apiService.put(apiUrls.CONTRACT.REVOKE, payload);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: REVOKE_CONTRACT_ERROR,
    });
  } else {
    dispatch({
      payload: response.data,
      type: REVOKE_CONTRACT_SUCCESS,
    });
  }
};

const confirmContractSign = payload => async dispatch => {
  dispatch({
    type: CONFIRM_CONTRACT_LOADING,
  });

  const { error, status, response } = await apiService.put(apiUrls.CONTRACT.COMFIRM, payload);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: CONFIRM_CONTRACT_ERROR,
    });
  } else {
    dispatch({
      payload: response.data,
      type: CONFIRM_CONTRACT_SUCCESS,
    });
  }
};

const terminateContract = payload => async dispatch => {
  dispatch({
    type: TERMINATE_CONTRACT_LOADING,
  });

  const { error, status, response } = await apiService.put(apiUrls.CONTRACT.TERMINATE, payload);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: TERMINATE_CONTRACT_ERROR,
    });
  } else {
    dispatch({
      payload: response.data,
      type: TERMINATE_CONTRACT_SUCCESS,
    });
  }
};

const generateContract = payload => async dispatch => {
  dispatch({
    type: GENERATE_CONTRACT_LOADING,
  });

  const { file, company, contract } = payload;
  const form = new FormData();
  form.append('contract', file, 'contract.pdf');

  const upload = await apiService.post(apiUrls.RESOURCE.UPLOAD(company, 'contract'), form, {
    baseURL: env.APP_RESOURCE_URL,
    withCredentials: false,
  });
  const link = upload?.response?.data?.url;

  if (link) {
    const { error, status, response } = await apiService.put(apiUrls.CONTRACT.GENERATE, {
      company,
      contract,
      link,
    });
    if (error) {
      dispatch({
        payload: { status, ...error },
        type: GENERATE_CONTRACT_ERROR,
      });
    } else {
      dispatch({
        payload: response.data,
        type: GENERATE_CONTRACT_SUCCESS,
      });
    }
  } else {
    dispatch({
      payload: { message: 'contract url is not valid', status: upload?.status },
      type: GENERATE_CONTRACT_ERROR,
    });
  }
};

export { generateContract, revokeContract, terminateContract, confirmContractSign };
