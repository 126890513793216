import statusType from '../constants/statusType';
import {
  SIGN_IN_DETAILS,
  SIGN_IN_DETAILS_ERROR,
  SIGN_IN_DETAILS_LOADING,
  SIGN_IN_DETAILS_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const signInReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SIGN_IN_DETAILS:
      return { ...state };

    case SIGN_IN_DETAILS_LOADING:
      return { ...state, details: { status: statusType.LOADING } };

    case SIGN_IN_DETAILS_SUCCESS:
      return { ...state, details: { data: payload, status: statusType.SUCCESSED } };

    case SIGN_IN_DETAILS_ERROR:
      return { ...state, details: { error: payload, status: statusType.FAILED } };

    case SIGN_IN_LOADING:
      return { ...state, status: statusType.LOADING };

    case SIGN_IN_SUCCESS:
      return { ...state, status: statusType.SUCCESSED };

    case SIGN_IN_ERROR:
      return { ...state, error: payload, status: statusType.FAILED };

    case SIGN_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default signInReducer;
