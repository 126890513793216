import statusType from '../constants/statusType';
import {
  FIND_EMPLOYEES_ERROR,
  FIND_EMPLOYEES_LOADING,
  FIND_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_EMPLOYEES_LOADING,
  GET_EMPLOYEES_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  find: {},
  status: statusType.IDLE,
};

const employeesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES_ERROR: {
      return { ...state, status: statusType.FAILED };
    }

    case GET_EMPLOYEES_LOADING: {
      return { ...state, status: statusType.LOADING };
    }

    case GET_EMPLOYEES_SUCCESS: {
      return { ...state, data: payload, status: statusType.SUCCESSED };
    }

    case FIND_EMPLOYEES_ERROR: {
      return { ...state, find: { status: statusType.FAILED } };
    }

    case FIND_EMPLOYEES_LOADING: {
      return { ...state, find: { status: statusType.LOADING } };
    }

    case FIND_EMPLOYEES_SUCCESS: {
      return { ...state, find: { data: payload, status: statusType.SUCCESSED } };
    }
  }

  return state;
};

export default employeesReducer;
