import React, { memo, useCallback } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';

import { editTechnoCompany } from '@/store/actions/companyActions';
import { MODALS } from '@/views/modals';
import { Section } from '@/components';
import { showModal } from '@/store/actions/modalsActions';

import classes from './SectionDomains.module.scss';

const DomainRow = props => {
  const { domain, onDelete } = props;
  const { t } = useTranslation();

  const handleClickDelete = useCallback(() => {
    if (onDelete) {
      onDelete(domain);
    }
  }, [domain, onDelete]);

  return (
    <Button danger shape="round" data-domain={domain} onClick={handleClickDelete}>
      {t('common.delete')}
    </Button>
  );
};

const SectionDomains = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: { general: { id }, email_domains: domains } = {} } = props;

  const handleAddDomain = useCallback(
    domain => {
      const newDomains = domains?.corporateEmailDomains?.filter(item => item !== domain);
      dispatch(
        editTechnoCompany({
          data: {
            email_domains: {
              email_domains: {
                ...domains,
                corporateEmailDomains: [...newDomains, domain],
              },
            },
          },
          id,
        })
      );
    },
    [dispatch, domains, id]
  );

  const handleDeleteDomain = useCallback(
    domain => {
      const newDomains = domains?.corporateEmailDomains?.filter(item => item !== domain);
      dispatch(
        editTechnoCompany({
          data: {
            email_domains: {
              email_domains: {
                ...domains,
                corporateEmailDomains: newDomains,
              },
            },
          },
          id,
        })
      );
    },
    [dispatch, domains, id]
  );

  const handleClickAdd = useCallback(() => {
    dispatch(showModal({ modal: MODALS.AddDomain, onOk: handleAddDomain }));
  }, [dispatch, handleAddDomain]);

  const handleClickDelete = useCallback(
    domain => {
      dispatch(showModal({ domain, modal: MODALS.ConfirmDeleteDomain, onOk: handleDeleteDomain }));
    },
    [dispatch, handleDeleteDomain]
  );

  return (
    <Section
      title={t('pages.company.mail-domain')}
      action={
        <Col>
          <Button icon={<PlusOutlined />} shape="round" onClick={handleClickAdd}>
            {t('common.add')}
          </Button>
        </Col>
      }
      className={classes.detailsList}
    >
      <Col span={24}>
        <Row gutter={[0, 24]}>
          {domains?.corporateEmailDomains?.map(domain => {
            return (
              <Col span={24} key={domain}>
                <Row justify="space-between">
                  <Col>{domain}</Col>
                  {domain !== domains?.defaultEmailDomain && (
                    <Col>
                      <DomainRow domain={domain} onDelete={handleClickDelete} />
                    </Col>
                  )}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Section>
  );
};

PropTypes.propTypes = {
  data: PropTypes.shape({}),
  NoData: PropTypes.node,
};

export default memo(SectionDomains);
