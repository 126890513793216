import React, { memo, useCallback, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Typography } from 'antd';

// import { removeFile } from '@/store/actions/resourceActions';
import { Upload } from '@/components';
// import { useDispatch } from 'react-redux';

import classes from './UploadImage.module.scss';

const MAX_IMAGE_SIZE = 1 * 1024 * 1024;

const beforeUpload = ({ size }) => {
  if (size > MAX_IMAGE_SIZE) {
    return false;
  }
  return true;
};

const UploadImage = props => {
  const { t } = useTranslation();
  const { src, name = 'image', onChange, onRemove } = props;
  const [imageUrl, setImageUrl] = useState();

  const handleDeleteImage = useCallback(() => {
    if (onRemove) {
      onRemove(imageUrl);
    }
    if (onChange) {
      onChange();
    }
    setImageUrl(null);
  }, [imageUrl, onChange, onRemove]);

  const handleUpload = useCallback(
    ({ url }) => {
      if (onChange) {
        onChange(url);
      }
      setImageUrl(url);
    },
    [onChange]
  );

  useEffect(() => {
    if (src) {
      setImageUrl(src);
    }
  }, [src]);

  return imageUrl ? (
    <Row justify="start" align="middle">
      <Col className={classes.wrapper}>
        <Image src={imageUrl} preview={false} />
      </Col>
      <Col>
        <Button type="default" shape="round" onClick={handleDeleteImage}>
          {t('common.delete')}
        </Button>
      </Col>
    </Row>
  ) : (
    <Upload onUpload={handleUpload} accept="image/*" name={name} listType="picture-card" beforeUpload={beforeUpload}>
      <Row justify="center">
        <Col span={24}>
          <PlusOutlined />
        </Col>
        <Col span={24}>
          <Typography>{t('common.upload')}</Typography>
        </Col>
      </Row>
    </Upload>
  );
};

UploadImage.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  src: PropTypes.string,
};

export default memo(UploadImage);
