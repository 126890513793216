import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';

import imageError from '@/assets/images/error.png';

import Modal from '../Modal';
import { MODALS } from '..';

const Oops = props => {
  const { onOk } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickOk = useCallback(() => {
    dispatch(hideModal(MODALS.Oops));
    if (onOk) {
      onOk();
    }
  }, [dispatch, onOk]);

  return (
    <Modal name={MODALS.Oops} width={428}>
      <Row gutter={[0, 24]} justify="center">
        <Col>
          <Image src={imageError} preview={false} />
        </Col>
        <Col>
          <Row justify="center">
            <Typography.Title level={5}>{t('modal.oops.title')}</Typography.Title>
            <Typography.Text>{t('modal.oops.description')}</Typography.Text>
          </Row>
        </Col>
        <Row justify="center">
          <Button shape="round" type="primary" onClick={handleClickOk}>
            {t('modal.oops.button.yes')}
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default memo(Oops);
