/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sort-keys */
import React, { memo } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';

import TendersWidget from '@/components/TendersWidget';
import TenderVerticalRoute from '@/pages/Tenders/components/TenderVerticalRoute';
import { TENDER_DATE_FORMAT, TENDER_DATETIME_FORMAT } from '@/constants/tender';

import colors from '@/styles/colors.module.scss';

const columns = [
  {
    dataIndex: 'route',
    key: 'route',
    title: i18n.t('common.route'),
    render: route => (
      <Row align="middle">
        <TenderVerticalRoute route={route} />
      </Row>
    ),
  },
  {
    dataIndex: 'bid',
    key: 'trading-dates',
    title: i18n.t('common.tender.trading-dates'),
    render: bid =>
      bid?.dealsStarts &&
      `${moment(bid?.dealsStarts).format(TENDER_DATE_FORMAT)} - ${moment(bid?.dealsEnds).format(TENDER_DATE_FORMAT)}`,
  },
  {
    dataIndex: 'bid',
    key: 'trading-stages',
    title: i18n.t('common.tender.current-trading-state'),
    render: bid =>
      bid?.currentPhase && (
        <Col>
          <Col>{i18n.t('common.tender.x-trading-stage', { stage: bid?.currentPhase })}</Col>
          <Col>
            <Typography.Text type="secondary" style={{ whiteSpace: 'nowrap' }}>
              {moment(bid?.currentPhaseStartDate).format(TENDER_DATETIME_FORMAT)}
            </Typography.Text>
          </Col>
        </Col>
      ),
  },
];

const TradingTenders = props => {
  const { t } = useTranslation();

  return (
    <TendersWidget
      title={t('common.trading-tenders')}
      titleIcon={<SyncOutlined style={{ color: colors.main6 }} />}
      columns={columns}
      {...props}
    />
  );
};

export default memo(TradingTenders);
