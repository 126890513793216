import React, { memo } from 'react';

import { Col, Divider, Row } from 'antd';

import { useTranslation } from 'react-i18next';

import TenderRoute from '@/pages/Tenders/components/TenderRoute';

import classes from './TenderId.module.scss';

const TenderId = props => {
  const { tender } = props;

  const { t } = useTranslation();

  return (
    <Col span={24} className={classes.root}>
      <Col span={22}>
        <Row justify="center">
          {t('pages.tender-add.id-title', { id: tender?.conditions?.id })}
          <span>
            {', '}
            <TenderRoute route={tender?.route} withArrow={false} />
          </span>
        </Row>
      </Col>
      <Divider style={{ marginTop: 8 }} />
    </Col>
  );
};

export default memo(TenderId);
