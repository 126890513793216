import React, { memo } from 'react';

import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Select = props => {
  const { t } = useTranslation();
  const { placeholder = t('common.select-option'), ...restProps } = props;
  return <AntSelect placeholder={placeholder} {...restProps} />;
};

Select.propTypes = {
  placeholder: PropTypes.string,
};

export default memo(Select);
