import React, { memo, useCallback, useEffect, useState } from 'react';

import { changeEmail } from '@/store/actions/accountActions';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { BaseContent } from '@/containers/content';
import { DashboardLayout } from '@/containers/layout';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { showModal } from '@/store/actions/modalsActions';
import statusType from '@/store/constants/statusType';
import { Link, Text } from '@/components';
import storageService, { KEY_ACCOUNT } from '@/services/storageService';

import classes from './Account.module.scss';

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: account } = useSelector(state => state.account);
  const emailChangeState = useSelector(state => state.changeEmail);
  const [newEmail, setNewEmail] = useState();

  const onChangeEmail = useCallback(
    email => {
      setNewEmail(email);
      dispatch(changeEmail({ email }));
    },
    [dispatch]
  );

  const handleClickChangeEmail = useCallback(() => {
    dispatch(showModal({ modal: MODALS.ChangeEmail, onChange: onChangeEmail }));
  }, [dispatch, onChangeEmail]);

  const handleClickResetPassword = useCallback(() => {
    dispatch(showModal({ email: account?.email, modal: MODALS.ConfirmPasswordChange }));
  }, [dispatch, account]);

  useEffect(() => {
    if (emailChangeState?.status === statusType.SUCCESSED) {
      storageService.clearValue(KEY_ACCOUNT);
      history.push(`${pageUrls.CHANGE_EMAIL_SUCCESS}?id=${account?.id}&email=${encodeURIComponent(newEmail)}`);
    }
  }, [dispatch, history, account, emailChangeState, newEmail]);

  return (
    <DashboardLayout>
      <BaseContent title={t('pages.account.account-settings')}>
        <Form layout="horizontal" name="account">
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row align="middle" gutter={[30, 0]}>
                <Col flex="auto">
                  <Divider className={classes.divider} orientation="left">
                    {t('common.personal-data')}
                  </Divider>
                </Col>
                <Col>
                  {!account?.isTechnoUser && (
                    <Link to={pageUrls.ACCOUNT.EDIT}>
                      <Button icon={<EditOutlined />} shape="round">
                        {t('common.edit')}
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Row>
                    <Col span={12}>{t('common.first-name')}:</Col>
                    <Col span={12} className={classes.name}>
                      {account?.firstName} {account?.lastName} {account?.middleName}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={12}>{t('common.position')}:</Col>
                    <Col span={12}>{account?.position}</Col>
                  </Row>
                </Col>

                {account?.isTechnoUser && (
                  <Col span={24}>
                    <Row>
                      <Col span={12}>{t('common.role')}:</Col>
                      <Col span={12}>{t(`common.role.${account?.role?.toLocaleLowerCase()}`)}</Col>
                    </Row>
                  </Col>
                )}
                {account?.isTechnoUser && (
                  <Col span={24}>
                    <Row>
                      <Col span={12}>{t('common.admin-rights')}:</Col>
                      <Col span={12}>{account?.admin ? t('common.yes') : '-'}</Col>
                    </Row>
                  </Col>
                )}
                {account?.isTechnoUser && (
                  <Col span={24}>
                    <Row>
                      <Col span={12}>{t('common.phone')}:</Col>
                      <Col span={12}>{account?.phone}</Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Divider className={classes.divider} orientation="left">
                {t('common.email-and-password')}
              </Divider>
            </Col>

            <Col span={24}>
              <Row align="middle">
                <Col span={18}>
                  <Row>
                    <Col span={16}>{t('common.current-email')}:</Col>
                    <Col span={8}>{account?.email}</Col>
                  </Row>
                </Col>
                {!account?.isTechnoUser && (
                  <Col span={6}>
                    <Row justify="end">
                      <Button shape="round" onClick={handleClickChangeEmail}>
                        {t('common.change')}
                      </Button>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Row align="middle">
                <Col span={18}>
                  <Row>
                    <Col span={16}>{t('common.current-password')}:</Col>
                    <Col span={8}>******</Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row justify="end">
                    <Button shape="round" onClick={handleClickResetPassword}>
                      {t('common.change')}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(Account);
