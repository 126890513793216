export const parsePagination = data => {
  return {
    current: data?.number + 1,
    pageSize: data?.size,
    total: data?.totalElements,
  };
};


export const parseFileName = file => {
  try {
    const chains = file.split('/');
    const dirty = chains[chains.length - 1];
    const name = dirty.split('?')[0];
    return name;
  } catch (err) {
    //
  }

  return file;
};