import React, { memo } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Space } from 'antd';

import colors from '@/styles/colors.module.scss';

const TenderRoute = props => {
  const { route = [], withIntermediate = false, withArrow = true } = props;
  const pointA = route[0];
  const pointB = route[route.length - 1];
  return (
    route.length > 0 && (
      <Space>
        {withIntermediate ? (
          route.map(({ city, country }, pointIndex) => (
            <React.Fragment key={`key-point-${pointIndex}`}>
              <Space>{`${city}, ${country}`}</Space>
              {withArrow
                ? pointIndex !== route.length - 1 && <ArrowRightOutlined style={{ color: colors.blueGray05 }} />
                : ' - '}
            </React.Fragment>
          ))
        ) : (
          <Space>
            {`${pointA.city}, ${pointA.country}`}
            {withArrow ? <ArrowRightOutlined style={{ color: colors.blueGray05 }} /> : ' - '}
            {`${pointB.city}, ${pointB.country}`}
          </Space>
        )}
      </Space>
    )
  );
};

TenderRoute.propTypes = {
  route: PropTypes.arrayOf(PropTypes.shape({ city: PropTypes.string, country: PropTypes.string })),
  withArrow: PropTypes.bool,
  withIntermediate: PropTypes.bool,
};

export default memo(TenderRoute);
