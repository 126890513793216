import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { ADD_COMPANY } from '@/store/constants/actionTypes';
import { createCompany } from '@/store/actions/companyActions';
import env from '@/constants/env';
import Modal from '@/views/modals/Modal';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';

import { MODALS } from '..';

const { Title, Text } = Typography;
const FORM_ITEM_PROPS = {
  labelAlign: 'left',
  labelCol: { span: 10 },
};

const AddCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, status } = useSelector(({ company }) => company.add);

  const binFieldProps = useMemo(() => {
    if (error) {
      return {
        help: (
          <Trans i18nKey={`pages.company-add.error.code.${error.code}`}>
            [<Typography.Link underline href={`mailto:${env.SUPPORT_EMAIL}`} target="_blank" />]
          </Trans>
        ),
        validateStatus: 'error',
      };
    }
    return null;
  }, [error]);

  const handleFinishForm = useCallback(
    ({ company }) => {
      dispatch(createCompany(company));
    },
    [dispatch]
  );

  const handleChangeBin = useCallback(() => {
    if (error) {
      dispatch({
        type: ADD_COMPANY,
      });
    }
  }, [dispatch, error]);

  const handleClose = useCallback(() => {
    dispatch({
      type: ADD_COMPANY,
    });
  }, [dispatch]);

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      history.push(pageUrls.COMPANY.FILL);
    }
  }, [dispatch, history, status]);

  return (
    <Modal name={MODALS.AddCompany} onClose={handleClose}>
      <Form name="company-add" layout="horizontal" requiredMark={false} onFinish={handleFinishForm}>
        <Row gutter={[0, 22]}>
          <Col span={24}>
            <Title level={2}>{t('common.create-company')}</Title>
            <Text>{t('pages.company.create-company-to-access-tenders')}</Text>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('common.company-name')}
              name={['company', 'name']}
              rules={fieldRules.required}
              {...FORM_ITEM_PROPS}
            >
              <Input placeholder={t('placeholder.name')} />
            </Form.Item>
            <Form.Item
              label={t('pages.company-fill.company-bin')}
              name={['company', 'bin']}
              rules={fieldRules.bin}
              {...binFieldProps}
              {...FORM_ITEM_PROPS}
            >
              <Input placeholder={t('placeholder.numbers')} onChange={handleChangeBin} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Button type="primary" shape="round" htmlType="submit" loading={status === statusType.LOADING}>
                {t('pages.company.add-company')}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(AddCompany);
