import React, { memo } from 'react';

import i18 from 'i18next';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';

import * as fieldRules from '@/constants/fieldRules';
import { getTenderType } from '@/utils/tenderUtils';
import { DatePicker, PlaceAutoComplete } from '@/components';
import { LOADING_UNLOADING, normalizePlace, TENDER_TYPE } from '@/constants/tender';

import PeriodSelector from '../PeriodSelector';
import RouteSelector from '../RouteSelector';

const itemProps = {
  labelAlign: 'left',
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const itemPropsFilled = {
  labelAlign: 'left',
  labelCol: { span: 8 },
};

const DELIVERY_TERMS = [
  { label: 'FCA', value: 'FCA' },
  { label: 'EXW', value: 'EXW' },
];

export const TRANSPORT_NUMBER_TYPE = {
  multiple: 'multiple',
  once: 'once',
};

const TRANSPORT_NUMBERS = [
  { label: i18.t('pages.tender.transport-number.once'), value: TRANSPORT_NUMBER_TYPE.once },
  { label: i18.t('pages.tender.transport-number.multiple'), value: TRANSPORT_NUMBER_TYPE.multiple },
];

const transfromDate = date => {
  if (typeof date === 'number') {
    return moment(date);
  }
  return date;
};

const normalizeFinishPlace = place => normalizePlace(place, { loadingType: LOADING_UNLOADING.UNLOADING, type: 'end' });

const ConditionFields = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const numberOfTransfers = getFieldValue(['conditions', 'numberOfTransfers']);
            return (
              <Form.Item
                label={t('pages.tender.transport-number')}
                name="typeOfTransfers"
                initialValue={numberOfTransfers > 1 ? TRANSPORT_NUMBER_TYPE.multiple : TRANSPORT_NUMBER_TYPE.once}
                rules={fieldRules.required}
                {...itemPropsFilled}
              >
                <Radio.Group size="large" buttonStyle="solid" options={TRANSPORT_NUMBERS} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const typeOfTransfers = getFieldValue('typeOfTransfers');
            const transferStartDate = getFieldValue(['conditions', 'transferStartDate']);
            if (typeOfTransfers !== TRANSPORT_NUMBER_TYPE.once) {
              return (
                <Form.Item label={t('pages.tender.carriage-period')} rules={fieldRules.required} {...itemPropsFilled}>
                  <PeriodSelector
                    FromPickerProps={{
                      placeholder: t('pages.tender.carriage-period-from'),
                      transformValue: transfromDate,
                    }}
                    fromDate={
                      typeof transferStartDate === 'number' ? transfromDate(transferStartDate) : transferStartDate
                    }
                    ToPickerProps={{ placeholder: t('pages.tender.carriage-period-to'), transformValue: transfromDate }}
                  />
                </Form.Item>
              );
            }
            return (
              <Form.Item
                label={t('pages.tender.carriage-period')}
                name={['conditions', 'transferStartDate']}
                rules={fieldRules.required}
                {...itemProps}
              >
                <DatePicker transformValue={transfromDate} />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isMultiple = getFieldValue('typeOfTransfers') === TRANSPORT_NUMBER_TYPE.multiple;
            return (
              <Form.Item
                label={t('pages.tender.transfers-amount')}
                name={['conditions', 'numberOfTransfers']}
                initialValue={2}
                hidden={!isMultiple}
                {...itemPropsFilled}
              >
                <Input.Number min={2} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label={t('pages.tender.carriage-route')} {...itemPropsFilled}>
          <Row gutter={[24, 0]}>
            <Col span={12} style={{ paddingRight: 0 }}>
              <Form.Item
                name={['route', 0]}
                rules={fieldRules.required}
                normalize={normalizePlace}
                style={{ marginBottom: 0 }}
              >
                <PlaceAutoComplete placeholder={t('pages.tender.route-start')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['route', 1]}
                rules={fieldRules.required}
                normalize={normalizeFinishPlace}
                style={{ marginBottom: 0 }}
              >
                <PlaceAutoComplete placeholder={t('pages.tender.route-finish')} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label colon={false} shouldUpdate {...itemPropsFilled}>
          <Form.List name="route">
            {(fields, { add, remove }) => {
              const list = fields.slice(2);
              return (
                <Row gutter={[0, 0]}>
                  {list.map(({ key, ...restField }) => (
                    <Col span={24} key={key}>
                      <RouteSelector FormItemProps={restField} onRemove={remove} />
                    </Col>
                  ))}
                  <Col span={24}>
                    <Form.Item>
                      <Button type="link" icon={<PlusOutlined />} style={{ paddingLeft: 0 }} onClick={() => add()}>
                        {t('pages.tender-add.add-route')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item noStyle shouldUpdate {...itemPropsFilled}>
          {({ getFieldValue }) => {
            const route = getFieldValue('route');
            const routeFilled = route?.length >= 1;
            const tenderInternal = getTenderType(route) === TENDER_TYPE.INLAND;
            return (
              routeFilled && (
                <Form.Item
                  label={t('pages.tender.tender-type')}
                  name={['conditions', 'tenderInternal']}
                  initialValue={tenderInternal}
                  {...itemProps}
                >
                  {t(`pages.tender.tender-type.${tenderInternal ? TENDER_TYPE.INLAND : TENDER_TYPE.FOREIGN}`)}
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const route = getFieldValue('route');
            const routeFilled = route?.length >= 1;
            const tenderInternal = getTenderType(route) === TENDER_TYPE.INLAND;
            return (
              routeFilled &&
              !tenderInternal && (
                <Form.Item
                  label={t('pages.tender.carriage-delivery-terms')}
                  name={['conditions', 'incotermsType']}
                  rules={fieldRules.required}
                  {...itemProps}
                >
                  <Select placeholder={t('common.select-option')} size="large" options={DELIVERY_TERMS} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(ConditionFields);
