import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import * as fieldRules from '@/constants/fieldRules';

const Password = props => {
  const { t } = useTranslation();
  const { autoComplete, label = t('common.password'), name = 'password', placeholder = '' } = props;

  return (
    <Form.Item label={label} name={name} rules={fieldRules.password}>
      <Input.Password autoComplete={autoComplete} name={name} placeholder={placeholder} />
    </Form.Item>
  );
};

Password.propTypes = {
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default memo(Password);
