import { HIDE_MODAL, SHOW_MODAL } from '../constants/actionTypes';

const showModal = modal => async dispatch => {
  dispatch({
    payload: { ...modal },
    type: SHOW_MODAL,
  });
};

const hideModal = modal => async dispatch => {
  dispatch({
    payload: { modal },
    type: HIDE_MODAL,
  });
};

export { showModal, hideModal };
