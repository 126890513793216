import React, { useEffect } from 'react';

import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAccount } from '@/store/actions/accountActions';
import Modals from '@/views/modals/Modals';
import NotFound from '@/pages/NotFound';
import pageUrls from '@/constants/pageUrls';
import useCompanyAlerts from '@/hooks/useCompanyAlerts';
import { privateRoutes, routes } from '@/routes';

import { ViewerContext } from './context/viewer-context';

function App() {
  useCompanyAlerts();
  const dispatch = useDispatch();
  const { authorized, data: account } = useSelector(state => state.account);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  return (
    <Layout style={{ minHeight: '100%' }}>
      <ViewerContext.Provider value={account}>
        <Switch>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}
          {!authorized && <Redirect to={pageUrls.SIGN_IN} />}

          <Redirect exact from={pageUrls.MAIN} to={pageUrls.DASHBOARD.MAIN} />

          {privateRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}

          <Route component={NotFound} />
        </Switch>
        <Modals />
      </ViewerContext.Provider>
    </Layout>
  );
}

export default App;
