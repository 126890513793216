import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Row, Spin } from 'antd';

import { BaseContent } from '@/containers/content';

const CompanyDetails = props => {
  const { t } = useTranslation();
  const { children, header, loading, title = t('pages.company.my-company'), visible } = props;

  return (
    <BaseContent title={title} header={header} contentSpan={24}>
      <Spin spinning={loading}>
        {visible && (
          <Form>
            <Row gutter={[0, 32]}>{children}</Row>
          </Form>
        )}
      </Spin>
    </BaseContent>
  );
};

export default memo(CompanyDetails);
