import React, { memo, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_ACTION } from '@/constants/company';
import pageUrls from '@/constants/pageUrls';
import { reworkCompany } from '@/store/actions/companyActions';
import statusType from '@/store/constants/statusType';
import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const CompanyReworkAvailable = props => {
  const { action = true, id } = props;
  const reworkState = useSelector(state => state.company[COMPANY_ACTION.TAKE_IN_REWORK]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickButton = useCallback(() => {
    dispatch(reworkCompany(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (reworkState?.status === statusType.SUCCESSED) {
      history.push(pageUrls.COMPANY.MY);
    }
  }, [history, reworkState]);

  return (
    <Alert
      type={ALERT_TYPE.WARNING}
      message={t('alert.company-rework-available.title')}
      description={t('alert.company-rework-available.description')}
      action={
        action && (
          <Button
            shape="round"
            type="primary"
            onClick={handleClickButton}
            loading={reworkState?.status === statusType.LOADING}
          >
            {t('alert.company-rework-available.button')}
          </Button>
        )
      }
    />
  );
};

export default memo(CompanyReworkAvailable);
