import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { Button, Col, Image, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import imagePasswordChangeFail from '@/assets/images/error.png';

import { BaseLayout } from '@/containers/layout';
import env from '@/constants/env';
import pageUrls from '@/constants/pageUrls';
import { Card, Text } from '@/components';

import classes from './PasswordChangeFail.module.scss';

const PasswordChangeFail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickEnter = useCallback(() => {
    history.push(pageUrls.MAIN);
  }, [history]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imagePasswordChangeFail} preview={false} />
            </Row>
          </Col>
          <Row gutter={[0, 8]} className={classes.description}>
            <Col span={24}>
              <Text block size={24} height={32} align="center" weight="semibold">
                {t('common.server-error')}
              </Text>
            </Col>
            <Col span={24}>
              <Text block size={16} height={24} align="center">
                <Trans i18nKey="pages.password-change-fail.description" values={{ email: env.SUPPORT_EMAIL }}>
                  [<Text color="main-6" />]
                </Trans>
              </Text>
            </Col>
          </Row>
          <Col span={24}>
            <Button htmlType="submit" type="primary" size="large" block onClick={handleClickEnter}>
              <Text align="center">{t('common.enter-application')}</Text>
            </Button>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(PasswordChangeFail);
