import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';

import reportImage from '@/assets/images/report.png';

import Modal from '../Modal';
import { MODALS } from '..';

const { Title, Text } = Typography;

const CreateReportComplete = props => {
  const { onOk } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickNo = useCallback(() => {
    dispatch(hideModal(MODALS.CreateReportComplete));
  }, [dispatch]);

  const handleClickOk = useCallback(() => {
    if (onOk) {
      dispatch(hideModal(MODALS.CreateReportComplete));
      onOk();
    }
  }, [dispatch, onOk]);

  return (
    <Modal name={MODALS.CreateReportComplete} width={428}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row justify="center">
            <Image src={reportImage} preview={false} />
          </Row>
        </Col>
        <Col span={24}>
          <Col>
            <Row justify="center">
              <Title level={4}>{t('modal.create-report-complete.title')}</Title>
            </Row>
          </Col>
          <Col>
            <Row justify="center">
              <Text>{t('modal.create-report-complete.description')}</Text>
            </Row>
          </Col>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col flex>
              <Button shape="round" block onClick={handleClickOk}>
                {t('modal.create-report-complete.button.yes')}
              </Button>
            </Col>
            <Col span={11}>
              <Button type="primary" block shape="round" onClick={handleClickNo}>
                {t('modal.create-report-complete.button.no')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(CreateReportComplete);
