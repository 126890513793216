import React from 'react';

import { CheckCircleOutlined, ClockCircleOutlined, WarningOutlined } from '@ant-design/icons';

import colors from '@/styles/colors.module.scss';
import { findProperties } from '@/utils/objectUtils';

import { CONTRACT_STATUS } from './contract';

export const contractStatusIcons = {
  [CONTRACT_STATUS.FAKE_NOT_SIGNED]: <WarningOutlined style={{ color: colors.red6 }} />,
  [CONTRACT_STATUS.CANCELED]: <WarningOutlined style={{ color: colors.red6 }} />,
  [CONTRACT_STATUS.EXPIRED]: <ClockCircleOutlined style={{ color: colors.gold6 }} />,
  [CONTRACT_STATUS.GENERATED]: <ClockCircleOutlined style={{ color: colors.gold6 }} />,
  [CONTRACT_STATUS.INITIATED]: <ClockCircleOutlined style={{ color: colors.gold6 }} />,
  [CONTRACT_STATUS.SIGNED]: <CheckCircleOutlined style={{ color: colors.gold6 }} />,
  [CONTRACT_STATUS.SIGNING_CANCELED]: <WarningOutlined style={{ color: colors.red6 }} />,
  [CONTRACT_STATUS.TERMINATED]: <WarningOutlined style={{ color: colors.red6 }} />,
};

export const COMPANY_ACTION = {
  ACTIVATE_AFTER_RULES_VIOLATION: 'ACTIVATE_AFTER_RULES_VIOLATION', // активировать после нарушения правил, сделать активной
  AGREE_TO_TERMS: 'AGREE_TO_TERMS', // согласен с условиями
  BLOCK: 'BLOCK', // заблокировать
  CONFIRM_VERIFICATION: 'CONFIRM_VERIFICATION', // верифицировать
  ENABLE_EDIT: 'ENABLE_EDIT', // разрешить редактирование?
  REJECT_VERIFICATION: 'REJECT_VERIFICATION', // отлонить верификацию
  RESTORE_FROM_ARCHIVE: 'RESTORE_FROM_ARCHIVE', // восстановить после архивирования
  SEND_FOR_REWORK: 'SEND_FOR_REWORK', // отправить на доработку
  SEND_TO_ARCHIVE: 'SEND_TO_ARCHIVE', // архивировать
  SEND_TO_VERIFICATION_AFTER_EDIT: 'SEND_TO_VERIFICATION_AFTER_EDIT', // отправить на верификацию после редактировния
  SUBMIT_FOR_RE_VERIFICATION: 'SUBMIT_FOR_RE_VERIFICATION', // отправить на повторную верификацию
  SUBMIT_FOR_VERIFICATION: 'SUBMIT_FOR_VERIFICATION', // отправить на верификацию
  TAKE_IN_REWORK: 'TAKE_IN_REWORK', // взять на доработку
  UN_BLOCK: 'UN_BLOCK', // разблокировать
};

export const COMPANY_STATUS = {
  ACTIVE: 'ACTIVE', // Активная
  ARCHIVED: 'ARCHIVED', // Архивная
  BLOCKED: 'BLOCKED', // Заблокирована
  IN_REWORK: 'IN_REWORK', // На доработке
  INACTIVE_AFTER_EDIT: 'INACTIVE_AFTER_EDIT', // Неактивна после редактирования
  INACTIVE_ON_RULES_VIOLATION: 'INACTIVE_ON_RULES_VIOLATION', // Неактивна из-за нарушения
  NEW: 'NEW', // Новая
  VERIFICATION_REJECTED: 'VERIFICATION_REJECTED', // Верификация отклонена
  WAITING_FOR_RE_VERIFICATION: 'WAITING_FOR_RE_VERIFICATION', // Ожидает повторной верификации
  WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION', // Ожидает верификации
  WAITING_FOR_VERIFICATION_AFTER_EDIT: 'WAITING_FOR_VERIFICATION_AFTER_EDIT', // Верификация после редактирования
  WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT: 'WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT', // Ожидает согласия с условиями
};

export const COMPANY_GROUP = {
  ALL: 'ALL',
  NOT_ACTIVE: 'NOT_ACTIVE',
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
};

export const companyTransportationType = {
  BOTH: 'both',
  INNER: 'inner',
  OUTER: 'outer',
};

const requiredGeneralFields = [
  'general.name',
  'general.bin',
  'general.propertyType',
  'general.vatCertificateNumber',
  'general.transportationType',
];

const requiredDocumentsFields = [
  'documents.report.fileName',
  'documents.sgr.fileName',
  'documents.sgr-egov.fileName',
  'documents.statute.fileName',
  'documents.vat.fileName',
];

const requiredContactsFields = [
  'contacts.ceo.firstName',
  'contacts.ceo.lastName',
  'contacts.ceo.middleName',
  'contacts.ceo.email',
  'contacts.ceo.phone',
];

const requiredAddressFields = [
  'addresses.registration.zipCode',
  'addresses.registration.country',
  'addresses.registration.city',
  'addresses.registration.address',
];

const requiredMailAddressFields = [
  'addresses.mail.zipCode',
  'addresses.mail.country',
  'addresses.mail.city',
  'addresses.mail.address',
];

export const validForVerify = company => {
  const isGeneralValid = findProperties(requiredGeneralFields, company);
  const isDocumentsValid = findProperties(requiredDocumentsFields, company);
  const isContactsValid = findProperties(requiredContactsFields, company);
  const isAddressesValid = findProperties(requiredAddressFields, company);

  const sameMailAddress =
    company?.general?.sameMailAddress === false ? findProperties(requiredMailAddressFields, company) : true;

  return isGeneralValid && isDocumentsValid && isContactsValid && isAddressesValid && sameMailAddress;
};

export const tagsColors = {
  [COMPANY_STATUS.ACTIVE]: 'green',
  [COMPANY_STATUS.ARCHIVED]: 'geekblue',
  [COMPANY_STATUS.NEW]: 'magenta',
  [COMPANY_STATUS.BLOCKED]: 'red',
  [COMPANY_STATUS.IN_REWORK]: 'purple',
  [COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT]: 'main',
  [COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION]: 'calendulagold',
  [COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT]: 'green',
  [COMPANY_STATUS.INACTIVE_AFTER_EDIT]: 'gray',
  [COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION]: 'red',
  [COMPANY_STATUS.WAITING_FOR_VERIFICATION]: 'calendulagold',
  [COMPANY_STATUS.VERIFICATION_REJECTED]: 'red',
};
