export const formatPhone = value => {
  const phone = value.split(' ').join('');
  const code = phone.substr(0, 3);
  const p1 = phone.substring(3, 6);
  const p2 = phone.substring(6, 8);
  const p3 = phone.substring(8, 10);

  let normalized = code;
  if (p1) {
    normalized += ` ${p1}`;
  }
  if (p2) {
    normalized += ` ${p2}`;
  }
  if (p3) {
    normalized += ` ${p3}`;
  }

  return normalized;
};

const formatter = new Intl.NumberFormat('ru-RU');

export const compactNumber = value => {
  if (value < 1e9) {
    return formatter.format(value);
  }

  if (value >= 1e9 && value < 1e12) {
    return `${+(value / 1e9).toFixed(1)}b`;
  }

  if (value >= 1e12 && value < 1e15) {
    return `${+(value / 1e12).toFixed(1)}t`;
  }

  if (value >= 1e15 && value < 1e18) {
    return `${+(value / 1e15).toFixed(1)}aa`;
  }

  return value;
};

export const formatMoney = (value, currency = '') => {
  return `${compactNumber(value)} ${currency}`;
};
