import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { DashboardLayout } from '@/containers/layout';

import TechnoCompany from './TechnoCompany';
import TransportCompany from './TransportCompany';

const MyCompany = () => {
  const { data: account } = useSelector(state => state.account);

  return <DashboardLayout>{account?.isTechnoUser ? <TechnoCompany /> : <TransportCompany />}</DashboardLayout>;
};

export default memo(MyCompany);
