import React, { memo, useCallback, useState } from 'react';

import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import { COMPANY_STATUS } from '@/constants/company';
import { TENDER_GROUP, TENDER_STATUS } from '@/constants/tender';

import TenderFilters from '../components/TenderFilters';
import TendersTable from '../components/TendersTable';
import {
  colRoute,
  colTradingDates,
  colTradingInitialBid,
  colTradingStage,
  colTradingStatus,
  colTransportationDates,
} from '../components/TendersTable/columns';

const tabs = [TENDER_GROUP.TRADING, TENDER_GROUP.NEW, TENDER_GROUP.PENDING, TENDER_GROUP.COMPLETED];

const columns = {
  [TENDER_GROUP.TRADING]: [colRoute, colTransportationDates, colTradingDates, colTradingStage],
  [TENDER_GROUP.NEW]: [colRoute, colTransportationDates, colTradingInitialBid, colTradingDates],
  [TENDER_GROUP.PENDING]: [colRoute, colTransportationDates, colTradingInitialBid, colTradingDates],
  [TENDER_GROUP.COMPLETED]: [colRoute, colTradingStatus, colTradingDates, colTransportationDates],
};

const tabDefaultFilters = tab => {
  switch (tab) {
    case TENDER_GROUP.NEW:
      return [TENDER_STATUS.ACCEPTING];

    case TENDER_GROUP.TRADING:
      return [TENDER_STATUS.TRADING];

    case TENDER_GROUP.PENDING:
      return [TENDER_STATUS.ACCEPTING];

    case TENDER_GROUP.COMPLETED:
      return [
        TENDER_STATUS.CLOSED,
        TENDER_STATUS.READY,
        TENDER_STATUS.COMPLETED,
        TENDER_STATUS.CANCELED,
        TENDER_STATUS.FAILED,
      ];
  }
  return [tab];
};

const Tenders = () => {
  const [tab, setTab] = useState();
  const { data: account } = useSelector(state => state.account);
  const { [account?.companyId]: { data: company } = {} } = useSelector(state => state.company.get);

  const normalizeTabBaseFilters = useCallback(
    tab => {
      switch (tab) {
        case TENDER_GROUP.NEW:
          return {
            companyId: company?.general?.id,
            participant: false,
            transportationType: company?.general?.transportationType,
          };

        case TENDER_GROUP.TRADING:
          return {
            companyId: company?.general?.id,
            participant: true,
            transportationType: company?.general?.transportationType,
          };

        case TENDER_GROUP.PENDING:
          return {
            companyId: company?.general?.id,
            participant: true,
            transportationType: company?.general?.transportationType,
          };

        case TENDER_GROUP.COMPLETED:
          return {
            companyId: company?.general?.id,
            participant: true,
            transportationType: company?.general?.transportationType,
          };

        default:
          return {};
      }
    },
    [company]
  );

  const transformTabSort = useCallback(tab => {
    switch (tab) {
      case TENDER_GROUP.NEW:
        return {
          sort: 'dealsStart,asc',
        };

      case TENDER_GROUP.TRADING:
        return {
          sort: 'dealsEnds,asc',
        };

      case TENDER_GROUP.PENDING:
        return {
          sort: 'dealsStart,asc',
        };

      case TENDER_GROUP.COMPLETED:
        return {
          sort: 'dealsEnds,desc',
        };

      default:
        return {};
    }
  }, []);

  const renderFilters = useCallback(
    props => {
      return <TenderFilters {...props} withSubStatus={tab === TENDER_GROUP.COMPLETED} />;
    },
    [tab]
  );

  const onChangeTab = useCallback(value => {
    setTab(value);
  }, []);

  const onChangeFilter = useCallback((filter, value) => {
    if (filter === 'subStatuses' && value?.length === 1) {
      switch (value?.[0]) {
        case TENDER_STATUS.CLOSED:
          return { tenderStatuses: [TENDER_STATUS.CLOSED, TENDER_STATUS.READY, TENDER_STATUS.COMPLETED] };
        case TENDER_STATUS.CANCELED:
          return { tenderStatuses: [TENDER_STATUS.CANCELED, TENDER_STATUS.FAILED] };
      }
    }
    return { tenderStatuses: null };
  }, []);

  if (!company?.general?.id) {
    return <Skeleton />;
  }
  const status = company?.general?.status;
  const tendersAvailable =
    status === COMPANY_STATUS.ACTIVE ||
    status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT ||
    status === COMPANY_STATUS.BLOCKED;

  return (
    <TendersTable
      normalizeTabBaseFilters={normalizeTabBaseFilters}
      tabs={tabs}
      tendersAvailable={tendersAvailable}
      transformTabFilter={tabDefaultFilters}
      transformTabSort={transformTabSort}
      columns={columns}
      FilterComponent={renderFilters}
      onChangeTab={onChangeTab}
      onChangeFilter={onChangeFilter}
    />
  );
};

export default memo(Tenders);
