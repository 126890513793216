import React, { memo, useCallback } from 'react';

import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MODALS } from '@/views/modals';
import { showModal } from '@/store/actions/modalsActions';
import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const ContractSignNeeded = props => {
  const { action = true, date } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickButton = useCallback(() => {
    dispatch(showModal({ modal: MODALS.ContractSignInstructions }));
  }, [dispatch]);

  return (
    <Alert
      type={ALERT_TYPE.SUCCESS}
      message={t('alert.contract-sign-needed.title')}
      description={t('alert.contract-sign-needed.description', { date })}
      action={
        action && (
          <Button shape="round" type="primary" onClick={handleClickButton}>
            {t('alert.contract-sign-needed.button')}
          </Button>
        )
      }
    />
  );
};

export default memo(ContractSignNeeded);
