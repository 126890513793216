import statusType from '../constants/statusType';
import { UPLOAD_FILE, UPLOAD_FILE_ERROR, UPLOAD_FILE_LOADING, UPLOAD_FILE_SUCCESS } from '../constants/actionTypes';

const initialState = {};

const uploadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_FILE:
      return initialState;

    case UPLOAD_FILE_LOADING: {
      const { name, progress } = payload;
      return { ...state, [name]: { progress, status: statusType.LOADING } };
    }

    case UPLOAD_FILE_SUCCESS: {
      const { name } = payload;
      return { ...state, [name]: { progress: 100, status: statusType.SUCCESSED } };
    }

    case UPLOAD_FILE_ERROR: {
      const { name, error } = payload;
      return { ...state, [name]: { error: { ...error }, status: statusType.FAILED } };
    }

    default:
      return state;
  }
};

export default uploadReducer;
