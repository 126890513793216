/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, useCallback, useContext, useEffect } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Col, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_STATUS } from '@/constants/company';
import { formatMoney } from '@/utils/format';
import pageUrls from '@/constants/pageUrls';
import { sendContract } from '@/store/actions/companyActions';
import statusType from '@/store/constants/statusType';
import { CompanyStatusTag, ContractStatusTag, Link, Table } from '@/components';
import { CONTRACT_ACTION, CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import {ViewerContext} from '@/context/viewer-context';
import {ACCOUNT_ROLE} from '@/constants/roles';

const columns = [
  {
    key: 'name',
    dataIndex: ['company', 'general'],
    title: i18next.t('common.company-name'),
    render: ({ logo, name, id }) => (
      <Link to={pageUrls.COMPANY.ITEM(id)}>
        <Space>
          <Avatar size={40} src={logo} />
          {name}
        </Space>
      </Link>
    ),
  },
  {
    key: 'status',
    dataIndex: ['company', 'general', 'status'],
    title: i18next.t('common.company-status'),
    render: status => <CompanyStatusTag status={status} />,
  },
  {
    key: 'contract-status',
    dataIndex: ['company', 'contracts'],
    title: i18next.t('common.contract-status'),
    render: contracts => <ContractStatusTag status={contracts?.[CONTRACT_TYPE.AGREEMENT]?.status} />,
  },
  {
    key: 'min-bid',
    title: i18next.t('common.tender.min-bid'),
    render: result => <Row align="middle">{formatMoney(result?.minimalBid, result?.currencyCode)}</Row>,
  },
  {
    key: 'action',
    dataIndex: ['company'],
    render: company => {
      const dispatch = useDispatch();
      const contractState = useSelector(state => state.contract);

      const companyStatus = company?.general?.status;
      const companyContract = company?.contracts?.[CONTRACT_TYPE.AGREEMENT];
      const contractStatus = companyContract?.status;

      const sendAvailable =
        companyStatus === COMPANY_STATUS.ACTIVE &&
        contractStatus !== CONTRACT_STATUS.INITIATED &&
        contractStatus !== CONTRACT_STATUS.GENERATED &&
        contractStatus !== CONTRACT_STATUS.SIGNED;

      const sendDisabled =
        contractState?.[CONTRACT_ACTION.SEND_CONTRACT]?.status === statusType.SUCCESSED &&
        contractState?.[CONTRACT_ACTION.SEND_CONTRACT]?.data?.id === company?.general?.id;

      const sendLoading =
        contractState?.[CONTRACT_ACTION.SEND_CONTRACT]?.status === statusType.LOADING &&
        contractState?.[CONTRACT_ACTION.SEND_CONTRACT]?.data?.id === company?.general?.id;

      const handleSendContract = useCallback(() => {
        dispatch(sendContract({ id: company?.general?.id }));
      }, [dispatch, company]);

      return (
        <Button
          shape="round"
          disabled={sendDisabled || !sendAvailable}
          loading={sendLoading}
          onClick={handleSendContract}
        >
          {i18next.t('common.send-contract')}
        </Button>
      );
    },
  },
];

const auColumns = columns.slice(0, columns.length-1);

const TenderResult = props => {
  const { results } = props;
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext);
  const protocol = results?.protocol;
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Row justify="end">
          <Typography.Link href={protocol} disabled={!protocol} target="_blank">
            <Button shape="round" disabled={!protocol}>
              {t(protocol ? 'common.download-protocol' : 'common.protocol-pending')}
            </Button>
          </Typography.Link>
        </Row>
      </Col>
      <Col span={24}>
        <Table data={results?.companies} rowKey="companyId" columns={viewer?.role === ACCOUNT_ROLE.AU? auColumns:columns} />
      </Col>
    </Row>
  );
};

export default memo(TenderResult);
