import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';

import * as fieldRules from '@/constants/fieldRules';
import { DatePicker } from '@/components';

const PeriodSelector = props => {
  const { label, transformValue, StartFormProps, StartPickerProps, EndFormProps, EndPickerProps } = props;

  return (
    <Row gutter={[24, 0]} style={{ marginBottom: 16 }}>
      <Col span={24}>
        <Form.Item label={label} style={{ height: 30, marginBottom: 0 }} />
      </Col>
      <Col span={12}>
        <Form.Item name="start" noStyle rules={fieldRules.required} {...StartFormProps}>
          <DatePicker disabledDate={false} transformValue={transformValue} {...StartPickerProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="end" noStyle rules={fieldRules.required} {...EndFormProps}>
          <DatePicker disabledDate={false} transformValue={transformValue} {...EndPickerProps} />
        </Form.Item>
      </Col>
    </Row>
  );
};

PeriodSelector.propTypes = {
  EndFormProps: PropTypes.shape({}),
  EndPickerProps: PropTypes.shape({}),
  StartFormProps: PropTypes.shape({}),
  StartPickerProps: PropTypes.shape({}),
};

export default memo(PeriodSelector);
