import React, { memo } from 'react';

import { Col, Image } from 'antd';

import logo from './images/logo-tender.svg';
import truck from './images/truck.png';

import classes from './styles/Header.module.scss';

const Header = () => {
  return (
    <>
      <Col span={24} className={classes.header}>
        <Image src={logo} className={classes.logo} preview={false} />
      </Col>
      <Image src={truck} wrapperClassName={classes.truck} preview={false} />
    </>
  );
};

export default memo(Header);
