import React, { memo, useContext } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Spin, Tag, Typography } from 'antd';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { formatMoney } from '@/utils/format';
import TenderIntermediateRoute from '@/pages/Tenders/components/TenderIntermediateRoute';
import TenderRoute from '@/pages/Tenders/components/TenderRoute';
import { ViewerContext } from '@/context/viewer-context';
import {
  CARGO_TYPE,
  TENDER_DATE_FORMAT,
  TENDER_DATETIME_FORMAT,
  TENDER_SECTION,
  TENDER_TYPE,
} from '@/constants/tender';
import { DetailsItem, Section } from '@/components';

const { Title } = Typography;

const RequiredValue = props => {
  const { value, replaceByEmpty = false, children } = props;
  const { t } = useTranslation();

  return value ? (
    children
  ) : replaceByEmpty ? (
    ''
  ) : (
    <Tag color="orange" shape="round">
      {t('common.required-for-tender-publish')}
    </Tag>
  );
};

const parseDate = (ts, format = TENDER_DATE_FORMAT) => moment(ts).format(format);
const parseCompanies = companies =>
  companies?.[0]?.id ? companies?.map(company => company?.name)?.join(', ') : companies?.join(', ');

const SummaryFields = props => {
  const { onEdit, withRequiredTags = false } = props;
  const viewer = useContext(ViewerContext);
  const { t } = useTranslation();

  const {
    get: { data: tender },
  } = useSelector(state => state.tender);

  if (!tender) {
    return <Spin />;
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Section name={TENDER_SECTION.CONDITIONS} title={t('pages.tender.transport-conditions')} onEdit={onEdit}>
          <DetailsItem
            label={t('pages.tender.transport-number')}
            value={
              tender?.conditions?.numberOfTransfers > 1
                ? t('pages.tender.transport-number.multiple')
                : t('pages.tender.transport-number.once')
            }
          />
          <DetailsItem
            label={t('pages.tender.transport-period')}
            value={`${parseDate(tender?.conditions?.transferStartDate)} ${
              tender?.conditions?.transferEndDate > 0 ? ` - ${parseDate(tender?.conditions?.transferEndDate)}` : ''
            }`}
          />
          <DetailsItem label={t('pages.tender.transfers-amount')} value={tender?.conditions?.numberOfTransfers} />
          <DetailsItem label={t('pages.tender.tender-route')} value={<TenderRoute route={tender?.route} />} />
          {tender?.route?.length > 2 && (
            <DetailsItem
              label={t('pages.tender.tender-intermediate-points')}
              value={<TenderIntermediateRoute route={tender?.route} />}
            />
          )}
          <DetailsItem
            label={t('pages.tender.tender-type')}
            value={t(
              `pages.tender.tender-type.${
                tender?.conditions?.tenderInternal ? TENDER_TYPE.INLAND : TENDER_TYPE.FOREIGN
              }`
            )}
          />
          {!tender?.conditions?.tenderInternal && tender?.conditions?.incotermsType && (
            <DetailsItem label={t('pages.tender.carriage-delivery-terms')} value={tender?.conditions?.incotermsType} />
          )}
        </Section>
      </Col>
      <Col span={24}>
        <Section name={TENDER_SECTION.CARGO} title={t('pages.tender.cargo-and-transport')} onEdit={onEdit}>
          <Title level={5}>{t('pages.tender.cargo')}</Title>
          <DetailsItem
            label={t('pages.tender.cargo-type')}
            value={
              <RequiredValue replaceByEmpty={!withRequiredTags} value={tender?.cargo?.type}>
                {t(`pages.tender.cargo-type.${tender?.cargo?.type}`)}
                {tender?.cargo?.type === CARGO_TYPE.OTHER && `: ${tender?.cargo?.typeDescription}`}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.load-type')}
            value={
              <RequiredValue value={tender?.cargo?.loadType}>
                {t(`pages.tender.load-type.${tender?.cargo?.loadType}`)}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.cargo-capacity')}
            value={
              <RequiredValue value={tender?.cargo?.volume}>{`${tender?.cargo?.volume} ${t(
                'common.m3'
              )}`}</RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.cargo-weight')}
            value={
              <RequiredValue value={tender?.cargo?.weight}>{`${tender?.cargo?.weight} ${t('common.t')}`}</RequiredValue>
            }
          />
          <DetailsItem label={t('pages.tender.cargo-properties')} value={tender?.cargo?.properties} />
          <Divider />
          <Title level={5}>{t('pages.tender.transport')}</Title>
          <DetailsItem
            label={t('pages.tender.transport-type')}
            value={
              <RequiredValue value={tender?.transport?.type}>
                {t(`pages.tender.transport-type.${tender?.transport?.type}`)}
              </RequiredValue>
            }
          />

          <DetailsItem
            label={t('pages.tender.volume')}
            value={
              <RequiredValue value={tender?.transport?.cargoVolume}>
                {`${tender?.transport?.cargoVolume} ${t('common.m3')}`}
              </RequiredValue>
            }
          />

          <DetailsItem
            label={t('pages.tender.transport-load-capacity')}
            value={
              <RequiredValue value={tender?.transport?.tonnage}>
                {`${tender?.transport?.tonnage} ${t('common.t')}`}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.transport-amount')}
            value={
              <RequiredValue value={tender?.transport?.numberRequired}>
                {tender?.transport?.numberRequired}
              </RequiredValue>
            }
          />
          <DetailsItem label={t('pages.tender.transport-properties')} value={tender?.transport?.comments} />
        </Section>
      </Col>
      <Col span={24}>
        <Section name={TENDER_SECTION.BID} title={t('pages.tender.tender-details')} onEdit={onEdit}>
          <DetailsItem
            label={t('common.tender-start-datetime')}
            value={
              <RequiredValue value={tender?.bid?.dealsStarts}>
                {parseDate(tender?.bid?.dealsStarts, TENDER_DATETIME_FORMAT)}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('common.tender-finish-datetime')}
            value={
              <RequiredValue value={tender?.bid?.dealsEnds}>
                {tender?.bid?.dealsEnds && parseDate(tender?.bid?.dealsEnds, TENDER_DATETIME_FORMAT)}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.tender-currency')}
            value={
              <RequiredValue value={tender?.bid?.currency}>
                {t(`common.labeled-currency.${tender?.bid?.currency?.toLowerCase()}`)}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.average-price')}
            value={
              <RequiredValue value={tender?.bid?.averageCargoPrice}>
                {formatMoney(tender?.bid?.averageCargoPrice)}{' '}
                {tender?.bid?.currency && t(`common.currency.${tender?.bid?.currency?.toLowerCase()}`, '')}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.initial-bid')}
            value={
              tender?.bid?.initialBidChecked ? (
                <RequiredValue value={tender?.bid?.initialBid}>
                  {formatMoney(tender?.bid?.initialBid)}{' '}
                  {tender?.bid?.currency && t(`common.currency.${tender?.bid?.currency?.toLowerCase()}`, '')}
                </RequiredValue>
              ) : (
                ''
              )
            }
          />
          <DetailsItem label={t('pages.tender.initial-bid-comment')} value={tender?.bid?.initialBidComment} />
          <DetailsItem
            label={t('common.tender.bid-step')}
            value={
              <RequiredValue value={tender?.bid?.dealBidStep}>
                {formatMoney(tender?.bid?.dealBidStep)}{' '}
                {tender?.bid?.currency && t(`common.currency.${tender?.bid?.currency?.toLowerCase()}`)}
              </RequiredValue>
            }
          />
          <DetailsItem
            label={t('pages.tender.tender-min-bid-participants')}
            value={
              <RequiredValue value={tender?.bid?.minimalNumberOfParticipants}>
                {tender?.bid?.minimalNumberOfParticipants}
              </RequiredValue>
            }
          />
          {viewer?.role !== ACCOUNT_ROLE.TC && (
            <DetailsItem
              label={t('pages.tender.excluded-companies')}
              value={parseCompanies(tender?.bid?.excludeCompanies)}
            />
          )}
          <DetailsItem
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: 0 }}
            label={t('pages.tender.person-in-charge')}
            value={
              <RequiredValue value={tender?.bid?.responsibleAssignee}>
                {tender?.bid?.responsibleAssignee?.fullName ?? tender?.bid?.responsibleAssignee}
              </RequiredValue>
            }
          />
          <DetailsItem label={t('pages.tender.tender-comment')} value={tender?.bid?.comments} />
        </Section>
      </Col>
    </Row>
  );
};

SummaryFields.propTypes = {
  onEdit: PropTypes.func,
};

export default memo(SummaryFields);
