import React, { memo, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Typography } from 'antd';

import { hideModal } from '@/store/actions/modalsActions';

import Modal from '../Modal';
import { MODALS } from '..';

const ContractSignInstructions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickOk = useCallback(() => {
    dispatch(hideModal(MODALS.ContractSignInstructions));
  }, [dispatch]);

  return (
    <Modal name={MODALS.ContractSignInstructions} width={452}>
      <Row gutter={[0, 24]}>
        <Row>
          <Typography.Title level={3}>{t('modal.contract-sign-instructions.title')}</Typography.Title>
        </Row>
        <Row>
          <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
            <ul>
              <li>{t('modal.contract-sign-instructions.step1')}</li>
              <li>{t('modal.contract-sign-instructions.step2')}</li>
              <li>{t('modal.contract-sign-instructions.step3')}</li>
              <li>{t('modal.contract-sign-instructions.step4')}</li>
            </ul>
          </Typography.Text>
        </Row>
        <Col span={24}>
          <Row justify="end">
            <Button type="primary" shape="round" size="large" onClick={handleClickOk}>
              {t('modal.contract-sign-instructions.button.yes')}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(ContractSignInstructions);
