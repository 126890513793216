import React, { memo, useCallback, useEffect, useMemo } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Dropdown, Form, Input, Menu, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { COMPANY_STATUS } from '@/constants/company';
import { formatMoney } from '@/utils/format';
import { MODALS } from '@/views/modals';
import pageUrls from '@/constants/pageUrls';
import { showModal } from '@/store/actions/modalsActions';
import statusType from '@/store/constants/statusType';
import TenderRoute from '@/pages/Tenders/components/TenderRoute';
import {
  cancelTender,
  completeTender,
  duplicateTender,
  makeBid,
  makeInitialBid,
  readyTender,
} from '@/store/actions/tenderActions';
import { CONTRACT_STATUS, CONTRACT_TYPE } from '@/constants/contract';
import { Link, TenderStatusTag } from '@/components';
import { TENDER_ACTION, TENDER_DATE_FORMAT, TENDER_DATETIME_FORMAT, TENDER_STATUS } from '@/constants/tender';

import colors from '@/styles/colors.module.scss';

import { ReactComponent as IconCheck } from '@/assets/icons/icon-check.svg';
import { ReactComponent as IconClock } from '@/assets/icons/icon-clock.svg';
import { ReactComponent as IconLock } from '@/assets/icons/icon-lock.svg';

import classes from './TenderHeader.module.scss';

const { Title } = Typography;

const getTenderActions = (role, status, signedAgreement) => {
  switch (role) {
    case ACCOUNT_ROLE.LG:
    case ACCOUNT_ROLE.LG_CHIEF: {
      switch (status) {
        case TENDER_STATUS.ACCEPTING:
          return [
            { key: TENDER_ACTION.CANCEL, label: i18n.t('pages.tender.action.cancel') },
            { key: TENDER_ACTION.DUPLICATE, label: i18n.t('pages.tender.action.duplicate') },
          ];
        case TENDER_STATUS.TRADING:
          return [
            { key: TENDER_ACTION.CANCEL, label: i18n.t('pages.tender.action.cancel') },
            { key: TENDER_ACTION.DUPLICATE, label: i18n.t('pages.tender.action.duplicate') },
            { key: TENDER_ACTION.COMPLETE_DEALS, label: i18n.t('pages.tender.action.complete') },
          ];
        case TENDER_STATUS.CLOSED:
          return [
            { key: TENDER_ACTION.DUPLICATE, label: i18n.t('pages.tender.action.duplicate') },
            signedAgreement && { key: TENDER_ACTION.MOVE_TO_READY, label: i18n.t('pages.tender.action.ready') },
          ].filter(i => !!i);
        case TENDER_STATUS.READY:
        case TENDER_STATUS.COMPLETED:
        case TENDER_STATUS.CANCELED:
        case TENDER_STATUS.FAILED:
          return [{ key: TENDER_ACTION.DUPLICATE, label: i18n.t('pages.tender.action.duplicate') }];
      }
    }
  }
  return [];
};

const validateBid = (value, initial) => {
  const bid = parseFloat(value);
  if (bid > initial) {
    return Promise.reject(i18n.t('pages.tender.error.bid-must-equal-less'));
  }
  if (bid <= 0) {
    return Promise.reject(i18n.t('pages.tender.error.bid-must-be-more-zero'));
  }
  return Promise.resolve();
};

const validateBidStep = (value, max, step, currency) => {
  const bid = parseFloat(value);
  if (bid > max) {
    return Promise.reject(i18n.t('pages.tender.error.min-bid-step', { currency, step }));
  }
  return Promise.resolve();
};

const TenderActionsDropdown = props => {
  const { onClick, options } = props;
  const { t } = useTranslation();
  return (
    <Dropdown
      trigger="click"
      overlay={
        <Menu onClick={onClick}>
          {options.map(({ key, label }) => (
            <Menu.Item key={key}>{label}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button shape="round">
        {t('pages.company-details.actions')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const Stage = props => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { active, completed, loading, currency, date, bid, bidStep, myBid, stage, onBid, ButtonProps } = props;

  const handleClickBid = useCallback(() => {
    form.validateFields().then(fields => {
      if (onBid) {
        onBid(fields.bid);
      }
    });
  }, [form, onBid]);

  useEffect(() => {
    if (!completed && !active) {
      form.setFieldsValue({ bid: 0 });
    } else {
      form.setFieldsValue({ bid });
    }
  }, [form, active, completed, bid]);

  return (
    <Col span={24} className={classes.stage}>
      {stage && (
        <Row align="middle" gutter={[8, 0]}>
          <Col>
            <Typography>{t('common.tender.x-trading-stage', { stage })}:</Typography>
          </Col>
          <Col flex={1}>
            <Divider />
          </Col>
          <Col>
            {completed && (
              <Space>
                <IconCheck />
                {t('common.tender.stage.completed')}
              </Space>
            )}
            {active && (
              <Space>
                <IconClock />
                {t('common.tender.stage.in-process')}
              </Space>
            )}
            {!active && !completed && (
              <Space>
                <IconLock />
                {t('common.tender.stage.not-started')}
              </Space>
            )}
          </Col>
        </Row>
      )}
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Typography.Text type="secondary">{t('common.tender.start-datetime')}:</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text type="secondary">{t('common.tender.initial-bid')}:</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text type="secondary">{t('common.tender.your-bid')}:</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle">
            <Col span={6}>{moment(date).format(TENDER_DATETIME_FORMAT)}</Col>
            <Col span={6}>
              {!completed && !active ? '-' : <Typography>{bid ? formatMoney(bid, currency) : '-'}</Typography>}
            </Col>
            <Col span={8}>
              {completed || myBid ? (
                <Typography>{myBid ? formatMoney(myBid, currency) : '-'}</Typography>
              ) : (
                <Form form={form} onFinish={handleClickBid}>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={16}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name="bid"
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          () => ({
                            validator: (_, value) => validateBid(value, bid),
                          }),
                          () => ({
                            validator: (_, value) => validateBidStep(value, bid - bidStep, bidStep, currency),
                          }),
                        ]}
                      >
                        <Input className="ant-input-with-suffix" suffix={currency} disabled={!active} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Button
                        shape="round"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!active}
                        loading={loading}
                      >
                        {ButtonProps?.label || t('common.make-bid')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const StagesSection = props => {
  const { bidStep, currency, stages, onBid } = props;

  return (
    <Row gutter={[0, 16]} className={classes.stages}>
      {stages?.map(item => {
        return (
          <Stage
            key={item?.number}
            active={item?.active}
            bid={item?.startBid}
            bidStep={bidStep}
            date={item?.phaseStart}
            completed={item?.completed}
            currency={currency}
            myBid={item?.myBid}
            stage={item?.number}
            onBid={onBid}
          />
        );
      })}
    </Row>
  );
};

const BidSection = props => {
  const { tender, onBid } = props;
  const { t } = useTranslation();
  const { bid: { bidStatus } = {} } = useSelector(state => state.tender);

  const handleBid = useCallback(
    value => {
      onBid(value);
    },
    [onBid]
  );

  return (
    <Stage
      active
      loading={bidStatus === statusType.LOADING}
      bid={tender?.bid?.initialBid}
      currency={tender?.bid?.currency}
      date={tender?.bid?.dealsStarts}
      onBid={handleBid}
      ButtonProps={{ label: t('common.apply') }}
    />
  );
};

const MyBidSection = props => {
  const { bid, bidStep, currency } = props;
  const { t } = useTranslation();

  return (
    <Row className={classes['my-bid']}>
      <Col span={24}>
        <Row justify="space-between">
          <Col span={16}>
            <Typography.Text type="secondary">{t('pages.tender.you-bid-on-start')}:</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text type="secondary">{t('common.tender.bid-step')}:</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col span={16}>
            <Typography>{formatMoney(bid, currency)}</Typography>
          </Col>
          <Col span={8}>
            <Typography>{formatMoney(bidStep, currency)}</Typography>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const TenderHeader = props => {
  const { tender, members } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: account } = useSelector(state => state.account);
  const { data: company } = useSelector(state => state.myCompany);
  const role = account?.role;
  const tenderId = tender?.id;
  const tenderStatus = tender?.conditions?.status;

  const tenderActions = useMemo(() => {
    return getTenderActions(
      account?.role,
      tenderStatus,
      !!members?.find(({ company }) => company?.contracts?.[CONTRACT_TYPE.AGREEMENT]?.status === CONTRACT_STATUS.SIGNED)
    );
  }, [account?.role, tenderStatus, members]);

  const handleMakeBid = useCallback(
    bid => {
      if (tenderStatus === TENDER_STATUS.ACCEPTING) {
        dispatch(makeInitialBid({ bid, id: tenderId }));
      } else if (tenderStatus === TENDER_STATUS.TRADING) {
        dispatch(makeBid({ bid, id: tenderId }));
      }
    },
    [dispatch, tenderId, tenderStatus]
  );

  const handleInitialBid = useCallback(
    value => {
      dispatch(
        showModal({
          bid: formatMoney(value, tender?.bid?.currency),
          date: moment(tender?.bid?.dealsStarts).format(TENDER_DATETIME_FORMAT),
          modal: MODALS.ConfirmInitialBid,
          onOk: () => handleMakeBid(value),
        })
      );
    },
    [dispatch, handleMakeBid, tender?.bid]
  );

  const handleBid = useCallback(
    value => {
      dispatch(
        showModal({
          bid: formatMoney(value, tender?.bid?.currency),
          modal: MODALS.ConfirmBid,
          onOk: () => handleMakeBid(value),
        })
      );
    },
    [dispatch, handleMakeBid, tender?.bid]
  );

  const handleCancelTender = useCallback(
    comment => {
      dispatch(cancelTender({ comment, id: tenderId }));
    },
    [dispatch, tenderId]
  );

  const handleDuplicateTender = useCallback(() => {
    dispatch(duplicateTender(tenderId));
  }, [dispatch, tenderId]);

  const handleReadyTender = useCallback(() => {
    dispatch(readyTender({ id: tenderId }));
  }, [dispatch, tenderId]);

  const handleCloseTender = useCallback(
    comment => {
      dispatch(completeTender({ comment, id: tenderId }));
    },
    [dispatch, tenderId]
  );

  const handleClickAction = useCallback(
    ({ key }) => {
      switch (key) {
        case TENDER_ACTION.CANCEL:
          dispatch(showModal({ modal: MODALS.ConfirmCancelTender, onOk: handleCancelTender }));
          break;
        case TENDER_ACTION.DUPLICATE:
          dispatch(showModal({ modal: MODALS.DuplicateTender, onOk: handleDuplicateTender }));
          break;

        case TENDER_ACTION.MOVE_TO_READY:
          dispatch(showModal({ modal: MODALS.ConfirmTenderReady, onOk: handleReadyTender }));
          break;

        case TENDER_ACTION.COMPLETE_DEALS:
          dispatch(showModal({ modal: MODALS.ConfirmTenderClose, onOk: handleCloseTender }));
          break;
      }
    },
    [dispatch, handleCancelTender, handleDuplicateTender, handleReadyTender, handleCloseTender]
  );

  const bidAvailable =
    company?.general?.status === COMPANY_STATUS.ACTIVE &&
    tenderStatus === TENDER_STATUS.ACCEPTING &&
    !tender?.bid?.myInitialBid;

  const phaseAvailable =
    account?.role === ACCOUNT_ROLE.TC &&
    tender?.bid?.phases?.length > 0 &&
    ((tenderStatus === TENDER_STATUS.ACCEPTING && tender?.bid?.myInitialBid) ||
      tenderStatus === TENDER_STATUS.TRADING ||
      tenderStatus === TENDER_STATUS.COMPLETED ||
      tenderStatus === TENDER_STATUS.CLOSED);

  const isLGRole = role === ACCOUNT_ROLE.LG || role === ACCOUNT_ROLE.LG_CHIEF;
  const isEmployeeRole = isLGRole || role === ACCOUNT_ROLE.AU;

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col span={14}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Link to={pageUrls.TENDERS.LIST} style={{ color: colors.blueGray5 }}>
                  <ArrowLeftOutlined /> {t('common.tender.all-tenders')}
                </Link>
              </Col>
              <Col span={24}>
                <Space direction="horizontal" align="start">
                  {tenderId && (
                    <Title level={4} style={{ margin: 0 }}>
                      {tenderId},{' '}
                      {tender?.conditions?.createdAt &&
                        moment(tender?.conditions?.createdAt).format(TENDER_DATE_FORMAT)}
                    </Title>
                  )}
                  {tenderStatus && <TenderStatusTag status={tenderStatus} />}
                  {isEmployeeRole &&
                    tender?.conditions?.status === TENDER_STATUS.CANCELED &&
                    tender?.conditions?.previousStatus && (
                      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                        {t('common.tender.previous-status')}:{' '}
                        {t(`common.tender.status.${tender?.conditions?.previousStatus}`)}
                      </Typography.Text>
                    )}
                  {tender?.conditions?.status === TENDER_STATUS.TRADING && tender?.bid?.currentPhase && (
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      {t('common.tender.x-trading-stage', { stage: tender?.bid?.currentPhase })}
                    </Typography.Text>
                  )}
                </Space>
              </Col>
              <Col span={24}>
                <Typography.Text type="secondary">
                  <TenderRoute route={tender?.route} withArrow={false} />
                </Typography.Text>
              </Col>
              {isEmployeeRole && tenderStatus === TENDER_STATUS.CANCELED && tender?.conditions?.commentOnStateChange ? (
                <Col span={24}>
                  <Typography.Text type="secondary">{t('common.tender.reason')}</Typography.Text>{' '}
                  <Typography.Text type="secondary">{tender?.conditions?.commentOnStateChange}</Typography.Text>
                </Col>
              ) : (
                tenderStatus === TENDER_STATUS.FAILED && (
                  <Col span={24}>
                    <Col>
                      <Typography.Text type="secondary">{t('common.tender.reason')}</Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text type="secondary">
                        {t('common.tender.cancel-reason.insufficient-amount', {
                          amount: tender?.bid?.minimalNumberOfParticipants,
                        })}
                      </Typography.Text>
                    </Col>
                  </Col>
                )
              )}
            </Row>
          </Col>
          {isLGRole && tenderActions?.length > 0 && (
            <Col>
              <TenderActionsDropdown onClick={handleClickAction} options={tenderActions} />
            </Col>
          )}
          {tender?.bid?.myInitialBid && (
            <Col span={10}>
              <MyBidSection
                bid={tender?.bid?.myInitialBid}
                bidStep={tender?.bid?.dealBidStep}
                currency={tender?.bid?.currency}
              />
            </Col>
          )}
        </Row>
      </Col>

      {bidAvailable && (
        <Col span={24}>
          <BidSection tender={tender} onBid={handleInitialBid} />
        </Col>
      )}

      {phaseAvailable && (
        <Col span={24}>
          <StagesSection
            bidStep={tender?.bid?.dealBidStep}
            currency={tender?.bid?.currency}
            stages={tender?.bid?.phases}
            onBid={handleBid}
          />
        </Col>
      )}

      {role === ACCOUNT_ROLE.TC && (
        <Col span={24}>
          <Link to={pageUrls.RULES.TENDER_BID} style={{ color: colors.main6 }}>
            <InfoCircleOutlined style={{ color: colors.main6 }} /> {t('common.tender.bid-rules')}
          </Link>
        </Col>
      )}
    </Row>
  );
};

export default memo(TenderHeader);
