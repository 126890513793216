import React, { memo } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const Text = props => {
  const { align, block, children, className, color, size, height, wrap, weight } = props;
  return (
    <Typography.Text
      className={clsx(
        {
          [`text-size-${size}`]: size,
          [`text-height-${height}`]: height,
          [`text-align-${align}`]: align,
          [`text-weight-${weight}`]: weight,
          [`text-color-${color}`]: color,
          'display-block': !!block,
          [`text-wrap-${wrap}`]: !!wrap,
        },
        className
      )}
    >
      {children}
    </Typography.Text>
  );
};

Text.propTypes = {
  align: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  size: PropTypes.number,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrap: PropTypes.bool,
};

export default memo(Text);
