import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import {
  CHANGE_EMAIL_ERROR,
  CHANGE_EMAIL_LOADING,
  CHANGE_EMAIL_SUCCESS,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_LOADING,
  GET_ACCOUNT_SUCCESS,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_SUCCESS,
  SIGN_IN_DETAILS_ERROR,
  SIGN_IN_DETAILS_LOADING,
  SIGN_IN_DETAILS_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_OUT_LOADING,
  SIGN_OUT_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
} from '@/store/constants/actionTypes';
import storage, { KEY_ACCOUNT, KEY_SETTINGS } from '@/services/storageService';

const TECHNODOM_COMPANY_ID = 'TECHNODOM_COMPANY_ID';

const normalizeUser = user => {
  const normalized = {
    ...user,
    fullName: `${user?.lastName ? user?.lastName : ''} ${user?.firstName ? user.firstName : ''}${
      user?.middleName ? ` ${user?.middleName}` : ''
    }`,
    isTechnoUser: user?.companyId === TECHNODOM_COMPANY_ID,
  };
  return normalized;
};

const getSignInDetails = email => async dispatch => {
  dispatch({
    type: SIGN_IN_DETAILS_LOADING,
  });

  try {
    const { status, error, response } = await apiService.get(apiUrls.ACCOUNT.SIGN_IN_DETAILS, { email });

    if (error) {
      dispatch({
        payload: { status, ...error },
        type: SIGN_IN_DETAILS_ERROR,
      });
    } else {
      dispatch({
        payload: response.data,
        type: SIGN_IN_DETAILS_SUCCESS,
      });
    }
  } catch (e) {
    dispatch({
      payload: e,
      type: SIGN_IN_DETAILS_ERROR,
    });
  }
};

const signIn = (email, password, remember) => async dispatch => {
  dispatch({
    type: SIGN_IN_LOADING,
  });

  const request = { email, password: btoa(password), remember };
  const { error, response, status } = await apiService.post(apiUrls.ACCOUNT.SIGN_IN, request);

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: SIGN_IN_ERROR,
    });
  } else {
    try {
      const account = normalizeUser(response?.data);
      storage.setValue(KEY_ACCOUNT, account);

      dispatch({
        payload: account,
        type: SIGN_IN_SUCCESS,
      });
    } catch (e) {
      dispatch({
        payload: { status, ...e },
        type: SIGN_IN_ERROR,
      });
    }
  }
};

const signOut = () => async dispatch => {
  storage.clearValue(KEY_ACCOUNT);
  storage.clearValue(KEY_SETTINGS);
  dispatch({
    type: SIGN_OUT_LOADING,
  });
  await apiService.post(apiUrls.ACCOUNT.SIGN_OUT);
  dispatch({
    type: SIGN_OUT_SUCCESS,
  });
};

const signUp = fields => async dispatch => {
  dispatch({
    type: SIGN_UP_LOADING,
  });

  const request = {
    ...fields,
    password: btoa(fields.password),
  };

  const { error, response, status } = await apiService.post(apiUrls.ACCOUNT.SIGN_UP, request);
  if (error) {
    dispatch({
      payload: { status, ...error },
      type: SIGN_UP_ERROR,
    });
  } else {
    const { data } = response;
    dispatch({
      payload: data,
      type: SIGN_UP_SUCCESS,
    });
  }
};

const getAccount = payload => async dispatch => {
  const localAccount = await storage.getValue(KEY_ACCOUNT);

  const account = {
    ...localAccount,
    ...payload,
  };

  if (account?.id) {
    dispatch({
      type: GET_ACCOUNT_LOADING,
    });

    const { error, response, status } = account?.isTechnoUser
      ? await apiService.get(apiUrls.TECHNO.USER.GET(account.id), {
          employeeId: account.id,
        })
      : await apiService.get(apiUrls.ACCOUNT.GET(account.id));

    if (error) {
      dispatch({
        payload: { status, ...error },
        type: GET_ACCOUNT_ERROR,
      });
    } else {
      const data = normalizeUser(response?.data);
      storage.setValue(KEY_ACCOUNT, data);
      dispatch({
        payload: data,
        type: GET_ACCOUNT_SUCCESS,
      });
    }
  }
};

const setAccount = account => async dispatch => {
  dispatch({
    type: SET_USER_LOADING,
  });

  const { id } = await storage.getValue(KEY_ACCOUNT);

  const { error, status } = await apiService.put(apiUrls.ACCOUNT.GET(id), { ...account });

  if (error) {
    dispatch({
      payload: { error, status },
      type: SET_USER_ERROR,
    });
  } else {
    dispatch({
      type: SET_USER_SUCCESS,
    });
  }
};

const changeEmail = payload => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_EMAIL_LOADING,
  });

  const account = {
    ...getState().account?.data,
    ...payload,
  };

  const { id, email, otps } = account;
  const emailOtp = otps?.email;
  const { error, status } = await apiService.put(apiUrls.ACCOUNT.CHANGE_EMAIL(id), { email, otp: emailOtp });

  if (error) {
    dispatch({
      payload: { status, ...error },
      type: CHANGE_EMAIL_ERROR,
    });
  } else {
    dispatch({
      type: CHANGE_EMAIL_SUCCESS,
    });
  }
};

export { changeEmail, getAccount, getSignInDetails, setAccount, signIn, signOut, signUp };
