import statusType from '../constants/statusType';
import {
  FIND_COMPANIES_ERROR,
  FIND_COMPANIES_LOADING,
  FIND_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_LOADING,
  GET_COMPANIES_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  error: null,
  find: {},
  status: statusType.IDLE,
};

const companiesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANIES_LOADING:
      return { status: statusType.LOADING };

    case GET_COMPANIES_SUCCESS:
      return { data: payload, status: statusType.SUCCESSED };

    case GET_COMPANIES_ERROR:
      return { error: payload, status: statusType.FAILED };

    case FIND_COMPANIES_LOADING:
      return { find: { status: statusType.LOADING } };

    case FIND_COMPANIES_SUCCESS:
      return { find: { data: payload, status: statusType.SUCCESSED } };

    case FIND_COMPANIES_ERROR:
      return { find: { error: payload, status: statusType.FAILED } };

    default:
      return state;
  }
};

export default companiesReducer;
