import React, { memo } from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Col, Row, Space } from 'antd';

const TenderIntermediateRoute = props => {
  const { route = [] } = props;
  const { t } = useTranslation();

  const points = route.slice(1, route.length - 1);
  return (
    <Row gutter={[0, 16]}>
      {points.map(({ city, country, loadingType }) => (
        <Col span={24} key={`key-point-${country}-${city}`}>
          <Space>
            <EnvironmentOutlined />
            {`${city}, ${country}`} | {t(`common.${loadingType?.toLowerCase()}`)}
          </Space>
        </Col>
      ))}
    </Row>
  );
};

export default memo(TenderIntermediateRoute);
