import statusType from '../constants/statusType';
import { CHANGE_EMAIL, CHANGE_EMAIL_ERROR, CHANGE_EMAIL_LOADING, CHANGE_EMAIL_SUCCESS } from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const changeEmailReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_EMAIL:
      return { status: statusType.IDLE };

    case CHANGE_EMAIL_LOADING:
      return { status: statusType.LOADING };

    case CHANGE_EMAIL_SUCCESS: {
      return { status: statusType.SUCCESSED };
    }

    case CHANGE_EMAIL_ERROR:
      return { error: payload, status: statusType.FAILED };

    default:
      return state;
  }
};

export default changeEmailReducer;
