import React, { memo, useContext, useEffect } from 'react';

import { Col } from 'antd';
import { DashboardLayout } from '@/containers/layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { BaseContent } from '@/containers/content';
import { getTender, getTenderResults } from '@/store/actions/tenderActions';
import pageUrls from '@/constants/pageUrls';
import statusType from '@/store/constants/statusType';
import { TENDER_STATUS } from '@/constants/tender';
import { ViewerContext } from '@/context/viewer-context';

import SummaryFields from '../TenderAdd/components/SummaryFields';
import TenderDetails from './components/TenderDetails';

import classes from './Tender.module.scss';
import TenderHeader from './components/TenderHeader';

const Tender = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const viewer = useContext(ViewerContext);

  const {
    cancel: { status: cancelStatus },
    complete: { status: completeStatus } = {},
    bid: { status: bidStatus } = {},
    duplicate: { data: duplicateData, status: duplicateStatus },
    get: { data: tender },
    ready: { status: readyStatus } = {},
    results: { data: restuls } = {},
  } = useSelector(state => state.tender);

  useEffect(() => {
    if (id) {
      dispatch(getTender(id, { excludeCompanies: viewer?.role === ACCOUNT_ROLE.TC }));
      dispatch(getTenderResults(id));
    }
  }, [dispatch, id, viewer]);

  useEffect(() => {
    if (bidStatus === statusType.SUCCESSED) {
      history.replace(pageUrls.TENDERS.ITEM(id));
    }
    if (cancelStatus === statusType.SUCCESSED) {
      history.push(pageUrls.TENDERS.LIST);
    }
    if (completeStatus === statusType.SUCCESSED) {
      history.replace(pageUrls.TENDERS.ITEM(id));
    }
   
    if (duplicateStatus === statusType.SUCCESSED) {
      if (duplicateData?.conditions?.id) {
        history.push(pageUrls.TENDERS.EDIT(duplicateData?.conditions?.id));
      } else {
        history.push(pageUrls.TENDERS.LIST);
      }
    }
     if (readyStatus === statusType.SUCCESSED) {
       history.replace(pageUrls.TENDERS.ITEM(id));
     }
  }, [dispatch, history, id, bidStatus, cancelStatus, completeStatus, duplicateData, duplicateStatus, readyStatus]);

  return (
    <DashboardLayout>
      <Col span={24} className={classes.header}>
        <TenderHeader tender={{ id, ...tender }} members={restuls?.companies} />
      </Col>
      <BaseContent contentSpan={24}>
        <SummaryFields withRequiredTags={false} />
        {(viewer?.role === ACCOUNT_ROLE.LG ||
          viewer?.role === ACCOUNT_ROLE.LG_CHIEF ||
          viewer?.role === ACCOUNT_ROLE.AU) &&
          tender &&
          tender?.conditions?.status !== TENDER_STATUS.DRAFT && (
            <Col span={24} className={classes.details}>
              <TenderDetails tender={{ id, ...tender }} />
            </Col>
          )}
      </BaseContent>
    </DashboardLayout>
  );
};

export default memo(Tender);
