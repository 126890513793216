import React, { memo, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldRules from '@/constants/fieldRules';
import { EDIT_TECHNO_COMPANY } from '@/store/constants/actionTypes';
import { hideModal } from '@/store/actions/modalsActions';
import Modal from '@/views/modals/Modal';
import { MODALS } from '@/views/modals';
import statusType from '@/store/constants/statusType';

const AddDomain = props => {
  const { onOk } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    edit: { status },
  } = useSelector(state => state.technoCompany);

  const handleClickClose = useCallback(() => {
    dispatch(hideModal(MODALS.AddDomain));
  }, [dispatch]);

  const handleFinishForm = useCallback(
    ({ domain }) => {
      if (onOk) {
        onOk(domain);
      }
    },
    [onOk]
  );

  useEffect(() => {
    if (status === statusType.SUCCESSED) {
      dispatch({
        type: EDIT_TECHNO_COMPANY,
      });
      dispatch(hideModal(MODALS.AddDomain));
    }
  }, [dispatch, status]);

  return (
    <Modal name={MODALS.AddDomain} width={468}>
      <Form name="add-domain" onFinish={handleFinishForm}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>{t('modal.add-domain.title')}</Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="domain" rules={[...fieldRules.required, ...fieldRules.domain]}>
              <Input placeholder={t('placeholder.domain-name')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Space size="middle">
                <Button shape="round" size="large" onClick={handleClickClose}>
                  {t('common.cancel')}
                </Button>
                <Button
                  shape="round"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={status === statusType.LOADING}
                >
                  {t('common.add')}
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(AddDomain);
