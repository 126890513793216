/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sort-keys */
import React, { memo } from 'react';

import i18n from 'i18next';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { TENDER_DATE_FORMAT } from '@/constants/tender';
import TendersWidget from '@/components/TendersWidget';
import TenderVerticalRoute from '@/pages/Tenders/components/TenderVerticalRoute';

import colors from '@/styles/colors.module.scss';
import { formatMoney } from '@/utils/format';

const columns = [
  {
    dataIndex: 'route',
    key: 'route',
    title: i18n.t('common.route'),
    render: route => (
      <Row align="middle">
        <TenderVerticalRoute route={route} />
      </Row>
    ),
  },
  {
    dataIndex: 'bid',
    key: 'trading-dates',
    title: i18n.t('common.tender.trading-dates'),
    render: bid =>
      bid?.dealsStarts &&
      `${moment(bid?.dealsStarts).format(TENDER_DATE_FORMAT)} - ${moment(bid?.dealsEnds).format(TENDER_DATE_FORMAT)}`,
  },
  {
    key: 'my-bid',
    dataIndex: 'bid',
    title: i18n.t('common.tender.my-bid'),
    render: bid => bid?.myInitialBid && `${formatMoney(bid.myInitialBid)} ${bid?.currency}`,
  },
];

const MyTenders = props => {
  const { t } = useTranslation();
  return (
    <TendersWidget
      title={t('common.my-tenders')}
      titleIcon={<InboxOutlined style={{ color: colors.gold6 }} />}
      columns={columns}
      {...props}
    />
  );
};

export default memo(MyTenders);
