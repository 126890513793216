import apiService from '@/services/apiService';
import apiUrls from '@/constants/apiUrls';
import env from '@/constants/env';
import {
  REMOVE_FILE_ERROR,
  REMOVE_FILE_LOADING,
  REMOVE_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_LOADING,
  UPLOAD_FILE_SUCCESS,
} from '../constants/actionTypes';

const uploadFile =
  (name = 'file', file, cb) =>
  async (dispatch, getState) => {
    dispatch({
      payload: { name },
      type: UPLOAD_FILE_LOADING,
    });
    const {
      account: {
        data: { companyId: id },
      },
      myCompany
    } = getState();

    const form = new FormData();
    form.set(name, file);
    const { error, response, status } = await apiService.post(
      apiUrls.RESOURCE.UPLOAD(id, myCompany?.data?.general?.bucketName, name),
      form,
      {
        baseURL: env.APP_RESOURCE_URL,
        onUploadProgress: ({ loaded, total }) => {
          const progress = parseInt((loaded / total) * 100);
          dispatch({
            payload: { name, progress },
            type: UPLOAD_FILE_LOADING,
          });
        },
        withCredentials: false,
      }
    );

    if (error) {
      dispatch({
        payload: { name, [name]: { error, status } },
        type: UPLOAD_FILE_ERROR,
      });
    } else {
      const { data } = response;
      if (cb) {
        cb(data);
      }
      dispatch({
        payload: { name, [name]: data },
        type: UPLOAD_FILE_SUCCESS,
      });
    }
  };

const removeFile = (url, cb) => async (dispatch, getState) => {
  dispatch({
    payload: { url },
    type: REMOVE_FILE_LOADING,
  });

  const {
    account: {
      data: { companyId: id },
    },
    myCompany,
  } = getState();

  const { status, error, response } = await apiService.delete(
    apiUrls.RESOURCE.REMOVE(id, myCompany?.data?.general?.bucketName),
    { resource: url },
    { baseURL: env.APP_RESOURCE_URL, withCredentials: false }
  );

  if (error) {
    dispatch({
      payload: { error, status, url },
      type: REMOVE_FILE_ERROR,
    });
  } else {
    if (cb) {
      cb(response?.data);
    }
    dispatch({
      payload: { url },
      type: REMOVE_FILE_SUCCESS,
    });
  }
};
export { removeFile, uploadFile };
