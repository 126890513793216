import React, { memo } from 'react';

import { Col, Row, Typography } from 'antd';

import classes from './styles/Feature2.module.scss';

const Progress = props => {
  const { title, suffix, description } = props;
  return (
    <Col>
      <Row align="middle">
        <Typography.Title className={classes.title}>{title}</Typography.Title>
        <Typography.Text className={classes.suffix}>{suffix}</Typography.Text>
      </Row>
      <Typography className={classes.description}>{description}</Typography>
    </Col>
  );
};

const Feature = () => {
  return (
    <Col span={24} className={classes.root}>
      <Row gutter={[60, 0]}>
        <Col span={6}>
          <Progress title="19" suffix="лет" description="Грузоотправитель с огромным опытом перевозок" />
        </Col>
        <Col span={6}>
          <Progress title="82" suffix="магазина" description="Включает в себя сеть магазинов Technodom" />
        </Col>
        <Col span={6}>
          <Progress title="9432" suffix="м2" description="Составляет общая торговая площадь" />
        </Col>
        <Col span={6}>
          <Progress title="800" suffix="тендеров" description="Успешно проведенных за последний год" />
        </Col>
      </Row>
    </Col>
  );
};

export default memo(Feature);
