import React, { memo } from 'react';

import { Tag as AntTag } from 'antd';

const Tag = props => {
  const { background, color, borderColor, ...rest } = props;
  return <AntTag style={{ background, color, borderColor }} {...rest} />;
};

export default memo(Tag);
