import statusType from '../constants/statusType';
import { SET_USER_ERROR, SET_USER_LOADING, SET_USER_SUCCESS } from '../constants/actionTypes';

const initialState = {
  error: null,
  status: statusType.IDLE,
};

const setAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_LOADING:
      return { ...state, status: statusType.LOADING };

    case SET_USER_SUCCESS:
      return { ...state, status: statusType.SUCCESSED };

    case SET_USER_ERROR:
      return { ...state, error: payload, status: statusType.FAILED };

    default:
      return state;
  }
};

export default setAccountReducer;
