import React, { memo, useCallback, useEffect } from 'react';

import { useParams } from 'react-router';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import ContactFields from '@/pages/CompanyFill/components/ContactFields';
import env from '@/constants/env';
import errorCodes from '@/constants/errorCodes';
import GeneralFields from '@/pages/CompanyFill/components/GeneralFields';

import classes from './CompanyEditForm.module.scss';

const { Title } = Typography;

const SECTION_TYPE = {
  CONTACTS: 'contacts',
  GENERAL: 'general',
};

const CompanyEditForm = props => {
  const { company, loading, error, onCancel, onSave, excludeFields, contactFields = <ContactFields /> } = props;
  const params = useParams();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { section = SECTION_TYPE.GENERAL } = params;

  const handleClickCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleClickSave = useCallback(() => {
    form
      .validateFields()
      .then(fields => {
        if (onSave) {
          onSave(fields);
        }
      })
      .catch(() => {});
  }, [form, onSave]);

  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...company,
      });
    }
  }, [company, form]);

  useEffect(() => {
    if (error) {
      const errorCode = error?.code;
      switch (errorCode) {
        case errorCodes.COMPANY_BIN_CONFLICT: {
          form.setFields([
            {
              errors: [
                <Trans key="t" i18nKey={`pages.company-add.error.code.${errorCodes.COMPANY_BIN_CONFLICT}`}>
                  [<Typography.Link underline href={`mailto:${env.SUPPORT_EMAIL}`} target="_blank" />]
                </Trans>,
              ],
              name: ['general', 'bin'],
            },
          ]);
          break;
        }
      }
    }
  }, [error, form]);

  return (
    <Form name="edit-company" layout="horizontal" form={form} encType="multipart/form-data">
      <Row gutter={[0, 24]}>
        <Col span={24} className={classes.title}>
          <Divider orientation="left" className={classes.divider}>
            <Title level={4}>{t(`pages.company-edit.edit-${section}-info`)}</Title>
          </Divider>
        </Col>
        <Col span={24}>
          {section === SECTION_TYPE.GENERAL && <GeneralFields excludeFields={excludeFields} />}
          {section === SECTION_TYPE.CONTACTS && contactFields}
        </Col>
        <Col span={24}>
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button type="default" shape="round" onClick={handleClickCancel}>
                {t('common.cancel')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" shape="round" onClick={handleClickSave} loading={loading}>
                {t('common.save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(CompanyEditForm);
