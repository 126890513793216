import { useEffect } from 'react';

import moment from 'moment';
import { matchPath, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { ACCOUNT_ROLE } from '@/constants/roles';
import { ALERTS } from '@/views/alerts';
import { CONTRACT_STATUS } from '@/constants/contract';
import pageUrls from '@/constants/pageUrls';
import storageService from '@/services/storageService';
import { COMPANY_ACTION, COMPANY_STATUS } from '@/constants/company';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/constants/common';
import { getCompany, getCompanyAvailableActions } from '@/store/actions/companyActions';
import { hideAlert, showAlert } from '@/store/actions/alertsActions';

/**
 * do not use this hook for common cases
 */
const useCompanyAlerts = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: account } = useSelector(state => state.account);
  const { [account?.companyId]: { data: company } = {} } = useSelector(state => state.company?.get);
  const { data: availableActions } = useSelector(state => state.companyActions);

  useEffect(() => {
    const status = company?.general?.status;
    const [contract] = company?.agreements || [];

    if (status === COMPANY_STATUS.NEW || status === COMPANY_STATUS.INACTIVE_AFTER_EDIT) {
      // const isValid = validForVerify(company);
      dispatch(
        showAlert(ALERTS.CompanyVerifyNeeded, {
          action: !matchPath(location.pathname, {
            path: [pageUrls.COMPANY.MY, pageUrls.COMPANY.EDIT.PATH, pageUrls.COMPANY.FILL],
          }),
        })
      );
    }

    if (
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION ||
      status === COMPANY_STATUS.WAITING_FOR_RE_VERIFICATION ||
      status === COMPANY_STATUS.WAITING_FOR_VERIFICATION_AFTER_EDIT
    ) {
      const contactAccountKey = Object.keys(company?.contacts)?.find(
        contactKey => company?.contacts?.[contactKey]?.contactPerson === true
      );
      const contactAccount = company?.contacts?.[contactAccountKey];
      dispatch(showAlert(ALERTS.WaitForVerification, { email: contactAccount?.email }));
    }

    if (status === COMPANY_STATUS.WAITING_TO_AGREE_WITH_TERMS_OF_THE_CONTRACT) {
      if (matchPath(location.pathname, { path: pageUrls.CONTRACT })) {
        dispatch(showAlert(ALERTS.ContractAgreeNeeded));
      } else {
        dispatch(showAlert(ALERTS.CompanyVerifySuccessed));
      }
    }

    if (status === COMPANY_STATUS.ACTIVE && !matchPath(location.pathname, { path: pageUrls.COMPANY.MY })) {
      if (!storageService.getSettings()?.alert?.CompanyAddSuccessed) {
        storageService.setSettings({ alert: { CompanyAddSuccessed: true } });
        dispatch(showAlert(ALERTS.CompanyAddSuccessed));
      }
    }

    if (status === COMPANY_STATUS.ACTIVE) {
      if (contract?.status === CONTRACT_STATUS.INITIATED) {
        dispatch(
          showAlert(ALERTS.ContractSignNeeded, { date: moment(contract?.createdOn).add(8, 'd').format(DATE_FORMAT) })
        );
      }
    }

    if (status === COMPANY_STATUS.IN_REWORK) {
      dispatch(
        showAlert(ALERTS.CompanyUpdateNeeded, { action: !matchPath(location.pathname, { path: pageUrls.COMPANY.MY }) })
      );
    }

    if (status === COMPANY_STATUS.VERIFICATION_REJECTED) {
      if (!availableActions) {
        dispatch(getCompanyAvailableActions(company?.general?.id, [COMPANY_ACTION.TAKE_IN_REWORK]));
      } else {
        const reworkEnabled = availableActions?.[COMPANY_ACTION.TAKE_IN_REWORK] === true;
        if (reworkEnabled) {
          dispatch(showAlert(ALERTS.CompanyReworkAvailable, { id: company?.general?.id }));
        } else if (company?.general?.activationTime) {
          dispatch(
            showAlert(ALERTS.CompanyVerifyRejected, {
              date: moment(company?.general?.activationTime).format(DATE_TIME_FORMAT),
            })
          );
        }
      }
    }

    if (status === COMPANY_STATUS.INACTIVE_ON_RULES_VIOLATION) {
      dispatch(showAlert(ALERTS.CompanyBlockedDueContract));
    }

    if (status === COMPANY_STATUS.ARCHIVED) {
      dispatch(
        showAlert(ALERTS.CompanyArchived, { action: !matchPath(location.pathname, { path: pageUrls.COMPANY.MY }) })
      );
    }
  }, [dispatch, availableActions, account?.email, company, location.pathname]);

  useEffect(() => {
    if (account?.id && account?.companyId) {
      if (account?.isTechnoUser !== true) {
        dispatch(getCompany(account.companyId, true));
      }
    }

    if (account?.role === ACCOUNT_ROLE.TC) {
      if (account?.id) {
        if (!account.companyId) {
          dispatch(showAlert(ALERTS.CompanyAddNeeded));
        } else {
          dispatch(hideAlert(ALERTS.CompanyAddNeeded));
        }
      }
    }
  }, [account?.id, account?.companyId, account?.role, account?.isTechnoUser, dispatch]);

  return null;
};

export default useCompanyAlerts;
