import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import Alert, { ALERT_TYPE } from '@/views/alerts/Alert';

const ContractAgreeNeeded = () => {
  const { t } = useTranslation();

  return (
    <Alert
      type={ALERT_TYPE.SUCCESS}
      message={t('alert.contract-agree-needed.title')}
      description={t('alert.contract-agree-needed.description')}
    />
  );
};

export default memo(ContractAgreeNeeded);
