import i18n from 'i18next';

import { findProperties } from '@/utils/objectUtils';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const LOADING_UNLOADING = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
};

export const TENDER_ACTIVITY_TYPE = {
  TENDER_PUBLISHED: 'ACCEPT_SIGNINGS',
  TENDER_FAILED: 'FAILED',
  TENDER_CANCELED: 'CANCELED',
  TENDER_TRADING_FINISHED: 'BARGAINING_COMPLETED',
  TENDER_COMPLETED: 'COMPLETED',
};

export const TENDER_ACTION = {
  CANCEL: 'CANCEL',
  COMPLETE: 'COMPLETE',
  COMPLETE_DEALS: 'COMPLETE_DEALS',
  DELETE: 'DELETE',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT',
  FAIL: 'FAIL',
  MAKE_BID: 'MAKE_BID',
  MOVE_TO_READY: 'MOVE_TO_READY',
  PUBLISH: 'PUBLISH',
  SIGN_UP: 'SIGN_UP',
  START_DEALS: 'START_DEALS',
};

export const TENDER_DETAILS = {
  ACTIVITY: 'ACTIVITY',
  MEMBERS: 'MEMBERS',
  RESULT: 'RESULT',
};

export const TENDER_TYPE = {
  BOTH: 'BOTH',
  FOREIGN: 'FOREIGN',
  INLAND: 'INLAND',
};

export const reorderPlaces = places => {
  const [start, finish, ...rest] = places;
  const ordered = [start, ...rest, finish].map((place, index) => ({ ...place, orderNumber: index + 1 }));
  return ordered;
};

export const normalizePlace = (place, extra = { loadingType: LOADING_UNLOADING.LOADING, type: 'start' }) =>
  place && {
    ...extra,
    city: place.city,
    country: place.country,
    id: place.id,
    latitude: place.location.lat,
    longitude: place.location.lng,
  };

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TENDER_DATE_FORMAT = DATE_FORMAT;
export const TENDER_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const TENDER_STATUS = {
  ACCEPTING: 'ACCEPT_SIGNINGS', // Прием заявок
  CANCELED: 'CANCELED', // Отменен
  CLOSED: 'BARGAINING_COMPLETED', // Проведен
  COMPLETED: 'COMPLETED', // Завершен
  DRAFT: 'DRAFT', // Черновик
  FAILED: 'FAILED', // Несостоявшийся
  READY: 'READY_FOR_USE', // Готов к работе
  TRADING: 'BARGAINING', // Идут торги
};

export const TENDER_GROUP = {
  ALL: 'ALL', // Все
  COMPLETED: 'COMPLETED', // Завершенные
  FAILED: 'FAILED', //
  NEW: 'NEW', // Новые
  PENDING: 'PENDING', // Ожидаются
  TRADING: 'TRADING', // Идут торги
};

export const TENDER_SECTION = {
  BID: 'bid',
  CARGO: 'cargo',
  CONDITIONS: 'conditions',
  ROUTE: 'route',
  TRANSPORT: 'transport',
};

export const tenderStatusOptions = [
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.DRAFT}`), value: TENDER_STATUS.DRAFT },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.ACCEPTING}`), value: TENDER_STATUS.ACCEPTING },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.TRADING}`), value: TENDER_STATUS.TRADING },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.CLOSED}`), value: TENDER_STATUS.CLOSED },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.READY}`), value: TENDER_STATUS.READY },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.COMPLETED}`), value: TENDER_STATUS.COMPLETED },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.CANCELED}`), value: TENDER_STATUS.CANCELED },
  { label: i18n.t(`common.tender.status.${TENDER_STATUS.FAILED}`), value: TENDER_STATUS.FAILED },
];

export const tagsColors = {
  [TENDER_STATUS.ACCEPTING]: 'gold',
  [TENDER_STATUS.CANCELED]: 'red',
  [TENDER_STATUS.CLOSED]: 'green',
  [TENDER_STATUS.DRAFT]: 'magenta',
  [TENDER_STATUS.FAILED]: 'gray',
  [TENDER_STATUS.COMPLETED]: 'cyan',
  [TENDER_STATUS.READY]: 'geekblue',
  [TENDER_STATUS.TRADING]: 'main',
};

export const tenderStatusIcon = {
  [TENDER_STATUS.ACCEPTING]: <ExclamationCircleOutlined />,
  [TENDER_STATUS.CANCELED]: <CloseCircleOutlined />,
  [TENDER_STATUS.CLOSED]: <CheckCircleOutlined />,
  [TENDER_STATUS.DRAFT]: <EditOutlined />,
  [TENDER_STATUS.FAILED]: <StopOutlined />,
  [TENDER_STATUS.COMPLETED]: <CheckCircleOutlined />,
  [TENDER_STATUS.READY]: <PlayCircleOutlined />,
  [TENDER_STATUS.TRADING]: <SyncOutlined />,
};

const requiredCargoFields = ['cargo.type', 'cargo.loadType', 'cargo.volume', 'cargo.weight'];
const requiredTransportFields = [
  'transport.type',
  'transport.cargoVolume',
  'transport.tonnage',
  'transport.numberRequired',
];
const requiredBidFields = [
  'bid.averageCargoPrice',
  'bid.currency',
  'bid.dealBidStep',
  'bid.dealsStarts',
  'bid.dealsEnds',
  'bid.minimalNumberOfParticipants',
  'bid.responsibleAssignee',
];

export const TRANSPORT_TYPE = {
  auto: 'auto',
  container: 'container',
  train: 'train',
};

export const CARGO_TYPE = {
  BUILD_IN: 'build-in',
  DIGITAL: 'digital',
  LARGE_HOUSEHOLD: 'large-household',
  OTHER: 'other',
  SMALL_HOUSEHOLD: 'small-household',
};

export const containerTypeOptions = [
  { label: i18n.t('common.capacity-ft', { capacity: 20 }), value: '20' },
  { label: i18n.t('common.capacity-ft', { capacity: 40 }), value: '40' },
];

export const transportVolumeOptions = {
  [TRANSPORT_TYPE.auto]: [
    { label: i18n.t('common.capacity-m3', { capacity: 86 }), value: '86' },
    { label: i18n.t('common.capacity-m3', { capacity: 120 }), value: '120' },
  ],
  [TRANSPORT_TYPE.train]: [
    { label: i18n.t('common.capacity-m3', { capacity: 175 }), value: '175' },
    { label: i18n.t('common.capacity-m3', { capacity: 250 }), value: '250' },
  ],
};

export const transportTonnageOptions = {
  [TRANSPORT_TYPE.auto]: [
    { label: i18n.t('common.weight-tons', { tons: 3 }), value: '3' },
    { label: i18n.t('common.weight-tons', { tons: 5 }), value: '5' },
    { label: i18n.t('common.weight-tons', { tons: 10 }), value: '10' },
    { label: i18n.t('common.weight-tons', { tons: 20 }), value: '20' },
    { label: i18n.t('common.weight-tons', { tons: 30 }), value: '30' },
    { label: i18n.t('common.weight-tons', { tons: 40 }), value: '40' },
    { label: i18n.t('common.weight-tons', { tons: 50 }), value: '50' },
    { label: i18n.t('common.weight-tons', { tons: 60 }), value: '60' },
  ],
  [TRANSPORT_TYPE.container]: [
    { label: i18n.t('common.weight-tons', { tons: 3 }), value: '3' },
    { label: i18n.t('common.weight-tons', { tons: 5 }), value: '5' },
    { label: i18n.t('common.weight-tons', { tons: 10 }), value: '10' },
    { label: i18n.t('common.weight-tons', { tons: 20 }), value: '20' },
    { label: i18n.t('common.weight-tons', { tons: 30 }), value: '30' },
    { label: i18n.t('common.weight-tons', { tons: 40 }), value: '40' },
    { label: i18n.t('common.weight-tons', { tons: 50 }), value: '50' },
    { label: i18n.t('common.weight-tons', { tons: 60 }), value: '60' },
  ],
  [TRANSPORT_TYPE.train]: [
    { label: i18n.t('common.weight-tons', { tons: 3 }), value: '3' },
    { label: i18n.t('common.weight-tons', { tons: 5 }), value: '5' },
    { label: i18n.t('common.weight-tons', { tons: 10 }), value: '10' },
    { label: i18n.t('common.weight-tons', { tons: 20 }), value: '20' },
    { label: i18n.t('common.weight-tons', { tons: 30 }), value: '30' },
    { label: i18n.t('common.weight-tons', { tons: 40 }), value: '40' },
    { label: i18n.t('common.weight-tons', { tons: 50 }), value: '50' },
    { label: i18n.t('common.weight-tons', { tons: 60 }), value: '60' },
  ],
};

export const validForPublish = tender => {
  const isCargoValid = findProperties(requiredCargoFields, tender);
  const isTransportValid = findProperties(requiredTransportFields, tender);
  const isBidValid = findProperties(requiredBidFields, tender);
  const isInitialBidValid = tender?.bid?.initialBidChecked ? !!tender?.bid?.initialBid : true;

  return isCargoValid && isTransportValid && isBidValid && isInitialBidValid;
};
