import React, { memo } from 'react';

import { Button, Col, Image, Row, Space, Typography } from 'antd';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import classes from './styles/Feature4.module.scss';

import email from './images/email.svg';
import message from './images/message.svg';

const Feature = () => {
  return (
    <Col span={24} className={classes.root}>
      <Col>
        <Row justify="center">
          <Image src={message} preview={false} />
        </Row>
      </Col>
      <Row justify="center">
        <Col span={8}>
          <Typography.Title level={2} className={classes.title}>
            Остались вопросы по работе тендерной платформы?
          </Typography.Title>
        </Col>
      </Row>

      <Col>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row justify="center">
              <Typography>Вы можете обратиться в нашу службу поддержки:</Typography>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Space>
                <MailOutlined />
                <Typography>customersupport@technodom.kz</Typography>
              </Space>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Space>
                <PhoneOutlined />
                <Typography>333-44-7733</Typography>
              </Space>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default memo(Feature);
