import React, { memo, useCallback } from 'react';

import { useHistory } from 'react-router';
import { Button, Col, Image, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import imageError from '@/assets/images/error.png';

import { BaseLayout } from '@/containers/layout';
import env from '@/constants/env';
import pageUrls from '@/constants/pageUrls';
import { Card, Text } from '@/components';

import classes from './LinkError.module.scss';

const { Title } = Typography;

const LinkError = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickEnter = useCallback(() => {
    history.push(pageUrls.MAIN);
  }, [history]);

  return (
    <BaseLayout>
      <Card className={classes.card}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Image src={imageError} preview={false} />
            </Row>
          </Col>
          <Row gutter={[0, 8]} className={classes.description}>
            <Col span={24}>
              <Title level={3} align="center">
                {t('pages.link-error.title')}
              </Title>
            </Col>
            <Col span={24}>
              <Text block size={16} height={24} align="center" wrap>
                <Trans i18nKey="pages.link-error.description" values={{ email: env.SUPPORT_EMAIL }}>
                  [<Text color="main-6" />]
                </Trans>
              </Text>
            </Col>
          </Row>
          <Col span={24}>
            <Row justify="center">
              <Button type="primary" shape="round" size="large" onClick={handleClickEnter}>
                <Text align="center">{t('common.enter-application')}</Text>
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  );
};

export default memo(LinkError);
