import React, { memo, useCallback, useEffect, useMemo } from 'react';

import i18n from 'i18next';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Typography } from 'antd';
import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { findTendersByStatus } from '@/store/actions/tendersActions';
import pageUrls from '@/constants/pageUrls';
import TendersWidget from '@/components/TendersWidget';
import TenderVerticalRoute from '@/pages/Tenders/components/TenderVerticalRoute';
import { TENDER_DATE_FORMAT, TENDER_DATETIME_FORMAT, TENDER_STATUS } from '@/constants/tender';

import colors from '@/styles/colors.module.scss';

const columns = [
  {
    dataIndex: 'route',
    key: 'route',
    title: i18n.t('common.route'),
    render: route => {
      return (
        <Row align="middle">
          <TenderVerticalRoute route={route} />
        </Row>
      );
    },
  },
  {
    dataIndex: 'bid',
    key: 'trading-dates',
    title: i18n.t('common.tender.trading-dates'),
    render: bid =>
      bid?.dealsStarts &&
      `${moment(bid?.dealsStarts).format(TENDER_DATE_FORMAT)} - ${moment(bid?.dealsEnds).format(TENDER_DATE_FORMAT)}`,
  },
  {
    dataIndex: 'conditions',
    key: 'transportation-dates',
    title: i18n.t('common.tender.transportation-dates'),
    render: conditions =>
      `${moment(conditions?.transferStartDate).format(TENDER_DATE_FORMAT)}${
        conditions?.transferEndDate ? ` - ${moment(conditions?.transferEndDate).format(TENDER_DATE_FORMAT)}` : ''
      }`,
  },
];

const columnsTrading = [
  ...columns.slice(0, 2),
  {
    dataIndex: 'bid',
    key: 'trading-stages',
    title: i18n.t('common.tender.current-trading-state'),
    render: bid =>
      bid?.currentPhase && (
        <Col>
          <Col>{i18n.t('common.tender.x-trading-stage', { stage: bid?.currentPhase })}</Col>
          <Col>
            <Typography.Text type="secondary" style={{ whiteSpace: 'nowrap' }}>
              {moment(bid?.currentPhaseStartDate).format(TENDER_DATETIME_FORMAT)}
            </Typography.Text>
          </Col>
        </Col>
      ),
  },
];

const columnsDraft = [
  ...columns.slice(0, 1),
  {
    key: 'transportation-dates',
    dataIndex: ['conditions'],
    title: i18n.t('common.tender.transportation-dates'),
    render: conditions =>
      `${conditions?.transferStartDate ? moment(conditions?.transferStartDate).format(TENDER_DATE_FORMAT) : ''} ${
        conditions?.transferEndDate ? ` - ${moment(conditions?.transferEndDate).format(TENDER_DATE_FORMAT)}` : ''
      }`,
  },
  {
    key: 'creation-date',
    dataIndex: ['conditions'],
    title: i18n.t('common.tender.creation-date'),
    render: ({ createdAt }) => createdAt && moment(createdAt).format(TENDER_DATE_FORMAT),
  },
];

const DashboardLG = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const tenders = useSelector(state => state.specificTenders);

  const listAccepting = useMemo(() => tenders?.[TENDER_STATUS.ACCEPTING]?.data || [], [tenders]);
  const listDraft = useMemo(() => tenders?.[TENDER_STATUS.DRAFT]?.data || [], [tenders]);
  const listTrading = useMemo(() => tenders?.[TENDER_STATUS.TRADING]?.data || [], [tenders]);
  const listClosed = useMemo(() => tenders?.[TENDER_STATUS.CLOSED]?.data || [], [tenders]);

  const handleClickAdd = useCallback(() => {
    history.push(pageUrls.TENDERS.ADD);
  }, [history]);

  const handleClickViewTenders = useCallback(
    tab => {
      history.push(`${pageUrls.TENDERS.LIST}?tab=${tab}`);
    },
    [history]
  );

  useEffect(() => {
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.ACCEPTING] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'dealsStart,asc' },
        status: TENDER_STATUS.ACCEPTING,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.DRAFT] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'createdAt,desc' },
        status: TENDER_STATUS.DRAFT,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.TRADING] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'dealsEnds,asc' },
        status: TENDER_STATUS.TRADING,
      })
    );
    dispatch(
      findTendersByStatus({
        data: { tenderStatuses: [TENDER_STATUS.CLOSED] },
        pagination: { page: 0, size: 4 },
        sort: { sort: 'dealsEnds,desc' },
        status: TENDER_STATUS.CLOSED,
      })
    );
  }, [dispatch]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Row justify="end">
          <Button type="primary" shape="round" onClick={handleClickAdd}>
            {t('pages.tenders.create-new-tender')}
          </Button>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <TendersWidget
              title={t('common.draft-tenders')}
              titleIcon={<EditOutlined style={{ color: colors.magenta6 }} />}
              columns={columnsDraft}
              data={listDraft}
              viewAllValue={TENDER_STATUS.DRAFT}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.accepting-tenders')}
              titleIcon={<ExclamationCircleOutlined style={{ color: colors.gold6 }} />}
              columns={columns}
              data={listAccepting}
              viewAllValue={TENDER_STATUS.ACCEPTING}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.trading-tenders')}
              titleIcon={<SyncOutlined style={{ color: colors.main6 }} />}
              columns={columnsTrading}
              data={listTrading}
              viewAllValue={TENDER_STATUS.TRADING}
              onViewAll={handleClickViewTenders}
            />
          </Col>
          <Col span={12}>
            <TendersWidget
              title={t('common.closed-tenders')}
              titleIcon={<CheckCircleOutlined style={{ color: colors.green6 }} />}
              columns={columns}
              data={listClosed}
              viewAllValue={TENDER_STATUS.CLOSED}
              onViewAll={handleClickViewTenders}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(DashboardLG);
