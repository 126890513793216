import React, { memo } from 'react';

import i18n from 'i18next';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Typography } from 'antd';

import { PlaceAutoComplete } from '@/components';
import { required } from '@/constants/fieldRules';
import { LOADING_UNLOADING, normalizePlace } from '@/constants/tender';

const options = [
  { label: i18n.t('common.loading'), value: LOADING_UNLOADING.LOADING },
  { label: i18n.t('common.unloading'), value: LOADING_UNLOADING.UNLOADING },
];

const normalizeUnloadingPlace = place =>
  place && normalizePlace(place, { loadingType: LOADING_UNLOADING.UNLOADING, type: 'intermediate' });

const RouteSelector = props => {
  const { FormItemProps, onRemove } = props;

  const { name } = FormItemProps;
  const { t } = useTranslation();

  return (
    <Row gutter={[24, 0]}>
      <Col span={12} style={{ paddingRight: 0 }}>
        <Form.Item name={[name]} rules={required} normalize={normalizeUnloadingPlace}>
          <PlaceAutoComplete placeholder={t('pages.tender.route-start')} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Row justify="space-between">
          <Typography style={{ fontSize: 16, lineHeight: '24px', padding: 6 }}>{options[1].label}</Typography>
          <Button style={{ padding: 0 }} type="link" icon={<MinusCircleOutlined />} onClick={() => onRemove(name)} />
        </Row>
      </Col>
    </Row>
  );
};

export default memo(RouteSelector);
