export const rus = `
ДОГОВОР
транспортной экспедиции груза
 
№ ______________
 
город Алматы                «___» _____ 20___ года
 
 именуемым в дальнейшем "Экспедитор", в лице директора {{fullName}}, действующего на основании {{directorBasis}} с одной стороны, и АО «Technodom Operator» (Технодом Оператор), именуемым в дальнейшем "Клиент”, в лице Президента Исмаилов Рената Рафиковича, действующего на основании Устава, с другой стороны, заключили настоящий договор о нижеследующем.

1. ПРЕДМЕТ ДОГОВОРА
 
1.1. Клиент поручает, а Экспедитор принимает на себя обязательства по доставке груза Клиента, согласно условиям, оговоренным в настоящем договоре и транспортной заявке, являющейся неотъемлемой частью настоящего Договора в адрес Клиента либо грузополучателя. Маршруты перевозки, срок доставки, род груза, объем груза, страховая стоимость груза, стоимость транспортировки, и иные необходимые условия транспортировки, оговариваются в заявке, являющейся неотъемлемой частью настоящего Договора.
1.2. Обязанности Экспедитора заканчиваются после прибытия груза в место назначения, указанное в транспортной заявке и принятия груза Клиентом либо Грузополучателем, согласно инвойсу.
1.3. Переписка между Сторонами относительно перевозки, подача заявки, решение текущих вопросов ведется менеджерами обеих сторон по электронной почте, факсимильной связи или посредством использования других официальных средств связи и действительна для третьих лиц.       Все остальные документы, полномочия на подписание которых предоставлены только первому руководителю должны быть подписаны и переданы в оригинале на бумажном носителе через курьерскую службу, иное почтовое учреждение или непосредственно через представителей Сторон.
Переписка между сторонами осуществляется следующими уполномоченными лицами Сторон по нижеуказанным реквизитам:
• Представитель Клиента {{fullName}},
электронный адрес Клиента: _________________,
• Представитель Экспедитора _____________,
электронный адрес Экспедитора: ____________________________________.
1.4. Стороны подтверждают, что юридические адреса, указанные в настоящем Договоре действительны и соглашаются, что вся корреспонденция, направленная по указанным адресам, считается полученной по истечении 12 дней после отправки через почтовые учреждения.
 
2.  ОБЯЗАННОСТИ СТОРОН
 
2.1. Экспедитор по настоящему Договору принимает на себя следующие обязанности:
2.1.1 . После получения от Клиента надлежащим образом оформленной Заявки и ее согласования организовать доставку грузов в указанный пункт назначения с использованием при необходимости различных видов транспорта. В случае невозможности исполнения Заявки, Экспедитор в течение 13 часов с момента получения Заявки должен направить отказ, и получить подтверждение от Клиента о получении. В случае если отказ не поступил, заявка считается принятой Экспедитором к исполнению.
2.1.2 Разработать оптимальную схему перевозки и согласовать ее с Клиентом.
2.1.3. В зависимости от условия поставки груза (ИНКОТЕРМС 2010), указанного в заявке, обеспечить проверку загрузки и выгрузки груза в соответствии с инвойсами.
2.1.4. Принять груз от Поставщика согласно отгрузочным документам (инвойс, упаковочный лист) без признаков нарушения целостности упаковки.
2.1.5 При отгрузке груза в адрес Грузополучателя или Клиента осуществлять погрузку на основании инвойса, представленного Клиентом.
2.1.6 Экспедитор вправе привлекать третьих лиц для выполнения работ по перевозке и связанных с перевозкой услуг, а также заключать с ними соответствующие договоры от своего имени, при этом ответственность перед Клиентом за действия/бездействия третьих лиц несет Экспедитор.
2.1.7 Надлежащим образом организовать экспедирование груза Клиента по маршруту, согласованному с Клиентом в соответствии с Заявкой.
2.1.8 Ежедневно информировать Клиента о местонахождении груза в пути следования и прибытии груза в место назначения. Незамедлительно информировать о ставших известными задержках, которые возникают в пути следования груза, во время погрузки/разгрузки, технических неисправностях и других непредвиденных обстоятельствах.
2.1.9 По запросу Клиента и не позднее 5 (пять) рабочих дней со дня получения такого запроса предоставить Клиенту документы, необходимые для коммерческих, административных, налоговых и иных целей.
2.1.10 По требованию Клиента за дополнительную плату Экспедитор должен изменять маршрут в целях прохождения груза через определенные пункты. Если груз находится в процессе перевозки, то изменение маршрута допускается в том случае, если у Экспедитора есть возможность изменения маршрута.
2.1.11 Представитель Экспедитора обязан принимать участие в приемке груза в пункте доставки в Республике Казахстан, при этом Клиент либо Грузополучатель предварительно уведомляет Экспедитора о времени и месте осуществления приемки груза. Уведомление в адрес Экспедитора направляется по электронной почте или по телефону.
2.1.12 Представитель Экспедитора участвует в приемке груза в пункте доставки в Республике Казахстан на основании доверенности. В случае если представитель Экспедитора не явился на приемку груза в указанный срок, то Клиент либо Грузополучатель осуществляет приемку груза без участия представителя Экспедитора и при обнаружении недостачи груза составляется соответствующий акт.   Данный акт имеет юридическую силу и при обнаружении недостачи либо порчи груза является доказательством в суде, при рассмотрении спора о возмещение стоимости недостачи либо порчи груза.
2.1.13 При перевозке груза автомобильным транспортом, водитель транспортного средства считается представителем Экспедитора и обязан принимать участие в приемке груза в месте погрузки и разгрузки груза и подписывать все необходимые документы, связанные с приемкой груза.
2.1.14 По Заявке клиента в особых случаях предоставлять услуги охраны на сопровождение дорогостоящего груза.
2.1.15 Оказывать содействие в получении необходимой транспортной и коммерческой документации.
2.1.16 Обеспечить присутствие своего представителя при перегрузе груза из одного транспортного средства в другое на станции перегруза или ином месте перегруза, с предоставлением Клиенту отчета и фотографий   в течение суток.
2.1.17 Застраховать груз в страховой компании согласованной с Клиентом. При этом выгодоприобретателем указать Клиента. 
2.1.18. В случае изменения регистрационных данных Экспедитор обязан повторно представить документ, подтверждающий резидентство с учетом измененных данных.
2.1.19. При выявлении порчи, недостачи груза либо его утери возместить стоимость груза согласно пунктам 4.2.; 4.3. настоящего Договора.
2.1.20. Нести полную материальную ответственность за сохранность и целостность груза с момента принятия его к перевозке от Грузоотправителя до его передачи Клиенту либо Грузополучателю.
2.1.21. Признать груз утерянным Экспедитором по истечении 5 (пять) календарных дней при перевозках по Казахстану и по истечении 20 (двадцати) календарных дней при международных перевозках с момента истечения срока доставки указанного в заявке, а при доставке груза транспортным средством, оснащенным системой слежения – по истечению 5 (пяти) суток при перевозках по Казахстану и по истечении 20 (двадцати) календарных дней при международных перевозках от срока доставки указанного в заявке при отсутствии информации о месте нахождения, и обязан возместить Клиенту стоимость утраченного груза и штраф в размере 20% от стоимости утраченного груза, в порядке и сроки, предусмотренные пунктом 4.3. Договора. В случае последующего обнаружения груза, его судьба будет решаться по договоренности сторон.
2.1.22. В срок не более двух календарных дней с момента принятия груза к перевозке предоставить по электронной почте следующие документы:
• Инвойс;
• Сертификат происхождения;
• Сертификат соответствия;
• Упаковочный лист;
• Транспортную накладную.
2.2 Клиент по настоящему договору принимает на себя следующие обязанности:
2.2.1 Предоставить Экспедитору Заявку на организацию перевозки груза Клиента за 2 (два) рабочих дня до дня погрузки груза и согласовать ее Экспедитором.
2.2.2 Предоставлять к перевозке грузы в надлежащей таре и упаковке, предохраняющей груз от порчи и повреждения в пути следования и во время перевалки, соответствующей стандартам, правилам перевозок грузов задействованными видами транспорта.  
2.2.3 Своевременно и в полном объеме оплачивать счета за услуги, оказанные Экспедитором.
2.2.4 Предоставить Экспедитору все данные о характере, размере, весе, упаковке груза, количестве мест, месте отправления и назначения, дате готовности груза к перевозке, условиях доставки, объявленной стоимости груза.
2.2.5 Предоставить Экспедитору инструкции, дополнительную информацию и необходимые документы для составления им транспортных и товаросопроводительных документов.
 
3. ПОРЯДОК РАСЧЕТОВ
 
3.1. Оплата по настоящему Договору производится в течение 25 банковских дней после принятия груза Клиентом либо Грузополучателем в месте доставки, в количестве согласно инвойсу без недостатков.
3.2. В случае обнаружения ущерба (недостача, порча, брак) при получении груза либо наличия предположения об утере груза Экспедитором в пути следования Клиент вправе приостановить оплату Экспедитору в размере предполагаемого ущерба, в том числе и за ранее осуществленные, но не оплаченные услуги Экспедитора.
3.3. В случае выявления недостачи груза по вине Экспедитора, стоимость оказанной услуги по доставке груза   уменьшается пропорционально стоимости недостающего груза.
3.4 Экспедитор не вправе в качестве обеспечения оплаты Клиентом услуг, оказанных по настоящему Договору, удерживать предмет транспортировки либо иное имущество Клиента до получения соответствующей оплаты по счетам.
 
4.   ОТВЕТСТВЕННОСТЬ СТОРОН
 
4.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязанностей согласно настоящему Договору. При не достижении согласия по спорным вопросам стороны руководствуются законодательством Республики Казахстан.
4.2. Экспедитор несет материальную ответственность за утерю, повреждение или недостачу принятого к перевозке груза с момента получения груза от Поставщика до передачи груза Клиенту либо Грузополучателю и возмещает его стоимость согласно пункту 4.3. настоящего Договора, в срок не более 7 (семи) календарных дней с момента получения претензии.
4.3. Ущерб, причиненный при перевозке груза, возмещается Экспедитором в следующем размере:
• в случае утраты, утери или недостачи груза в размере 100% от стоимости утраченного/уторенного груза указанной в инвойсе, сопровождающем груз и стоимости его транспортировки. Груз считается утерянным если он не доставлен Клиенту либо Грузополучателю в сроки, установленные п.п. 2.1.21. Договора;
• в случае повреждения груза, в размере суммы, на которую понизилась его стоимость, при условии получения согласия Клиента по сумме возмещения и стоимости его транспортировки, рассчитанной пропорционально размеру уменьшения стоимости;
• при невозможности восстановления поврежденного груза или если на его восстановление требуются затраты в размере 60% и более его закупочной стоимости - в размере 100% его стоимости указанной в инвойсе, сопровождающем груз и стоимости его транспортировки.
В этом случае Клиент вправе в одностороннем порядке уменьшить свою задолженность перед Экспедитором за оказанные услуги на сумму утерянного/утраченного/поврежденного груза, направив об этом извещение в течение 5 (пяти) календарных дней. Если сумма задолженности Клиента перед Экспедитором недостаточно для погашения ущерба, то Экспедитор перечисляет разницу в срок не более 10 (десяти) календарных дней со дня получения извещения от Клиента.
4.4. За просрочку доставки   груза Клиенту либо Грузополучателю   Экспедитор уплачивает штраф в   размере 0,1% от стоимости перевозки за каждые сутки просрочки, начиная с даты доставки, указанной в Заявке.
4.5. Сторона, нарушившая свои обязательства по настоящему Договору, должна без промедления устранить эти нарушения.
4.6. В случае просрочки оплаты, согласно п. 3.1. настоящего договора, Клиент обязан уплатить Экспедитору пеню в размере 0,1 % от неоплаченной суммы за каждые сутки просрочки оплаты, но не более 5 % от суммы задолженности, кроме просрочки оплаты по основаниям, указанным в п. 3.2. Договора.
4.7. В случае немотивированного отказа Экспедитора от оказания услуг по согласованной Заявке за 2 (два) календарных дня до начала ее осуществления, Экспедитор выплачивает штраф в размере 10 % от стоимости услуг по Заявке.
4.8. Экспедитор уплачивает Клиенту полную стоимость утерянного груза. Груз считается утерянным если он не доставлен в пункт назначения по истечении 5 (пяти) календарных дней с момента истечения срока доставки. В этом случае Клиент вправе в одностороннем порядке уменьшить свою задолженность перед Экспедитором за оказанные услуги на сумму утерянного груза, направив об этом извещение в течение 3 (трех) рабочих дней.
 
5. ПРЕТЕНЗИИ, РАЗРЕШЕНИЕ СПОРОВ
 
5.1. В случае нарушения своих прав каждая из сторон может предъявить другой стороне соответствующие претензии.
5.2. Претензия должна быть рассмотрена стороной, которой она адресована, не позднее 5 (пяти) рабочих дней с момента получения. В течение указанного срока в адрес другой стороны должен быть направлен мотивированный отказ от удовлетворения претензии, либо, в течение последующих двух рабочих дней должно быть произведено возмещение.
5.3. В случае неполучения ответа в срок, указанный в пункте 5.2. претензия считается принятой и подлежит удовлетворению. Не предоставление ответа на претензию означает согласие с ней и дает основание другой стороне на удержание суммы претензии из причитающихся к оплате сумм, в том числе и за другие перевозки. В случае недостаточности сумм для возмещения ущерба, разница перечисляется виновной стороной в срок не более 10 (десяти) календарных дней.
5.4. Все претензии и споры в случае невозможности разрешения разногласий путем переговоров подлежат рассмотрению Межрайонным экономическим судом г. Алматы в порядке, предусмотренном действующим законодательством Республики Казахстан.
5.5 Документами, подтверждающими требования Клиента, является любой из нижеперечисленных документов:
• Акты, составленные с участием Клиента и Экспедитора;
• акт составленный экспертом Национальной Палаты предпринимателей РК;
• акт сюрвейера;
• коммерческий акт железной дороги;
• акт, составленный Клиентом либо грузополучателем в случае неприбытия представителя Экспедитора на приемку груза;
• другие документы, фотографии, видеосъемка и прочие доказательства, допустимые в соответствии с законодательством Республики Казахстан.
 
6. АНТИКОРРУПЦИОННЫЕ ТРЕБОВАНИЯ. СОБЛЮДЕНИЕ ЗАКОНОДАТЕЛЬСТВА О ПЕРСОНАЛЬНЫХ ДАННЫХ. КОНФИДЕНЦИАЛЬНОСТЬ
 
6.1. Экспедитор подтверждает, что осведомлен о следующих антикоррупционных требованиях Клиента:
6.1.1. Недопустимым является предлагать, давать или получать вознаграждение в любом виде, в том числе денежном и материальном в отношении любого работника, поставщика, продавца, исполнителя, подрядчика, консультанта, партнера, агента или посредника Клиента.
6.1.2. Клиент содействует распространению антикоррупционных требований среди всех лиц, представляющих интересы Клиента. Каждый, кто действует в интересах Клиента или от его имени, несет ответственность за доведение до третьих лиц и соблюдение ими антикоррупционных требований Клиента.
6.2. Экспедитор признает и подтверждает, что он должен всегда и в полной мере соблюдать антикоррупционные требования Клиента, изложенные в данной статье Договора и обеспечивать их соблюдение всеми своими работниками, а также агентами и подрядчиками при заключении сделок с Клиентом или от его имени.
6.3. Стороны обязуются не участвовать в любых коррупционных делах или взяточничестве и обязуются действовать добросовестно в ходе выполнения своих обязательств по настоящему договору.
6.4. Стороны безотлагательно уведомляют друг друга о любых фактах, материалах или обстоятельствах, которые предполагают или подтверждают, что имело место любое нарушение Стороной настоящей статьи Договора.
6.5. Стороны обязуются обеспечить конфиденциальность персональных данных, ставших известными при исполнении обязательств по настоящему Договору, а также безопасность персональных данных при их обработке в соответствии с Законом Республики Казахстан «О персональных данных и их защите». Под персональными данными понимается любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу, в том числе его фамилия, имя, отчество, год, месяц, дата и место рождения, адрес, образование, профессия, имущественное положение, доходы, другая информация.
Любая информация, ставшая известной Сторонам о хозяйственной деятельности любой Стороны в период действия настоящего Договора, является конфиденциальной и не подлежит разглашению третьим лицам, кроме случаев, когда это может стать необходимым в соответствии с требованиями действующего законодательства Республики Казахстан.
 
7. ФОРС-МАЖОР
 
7.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему договору, в случае возникновения чрезвычайных обстоятельств, а именно: военных действий, стихийных бедствий, забастовок, массовых беспорядков, запретительных или ограничительных законодательных решений и иных решений государственных органов, естественных монополистов, иных организаций и предприятий, на решения которых стороны не могут повлиять, наступивших после подписания настоящего договора и препятствующих полному или частичному исполнению каких-либо обязательств по настоящему договору. Срок исполнения обязательств продлевается на время действия таких обстоятельств согласно законодательству Республики Казахстан.
7.2. Не уведомление или несвоевременное извещение о наступивших чрезвычайных обстоятельствах лишает соответствующую сторону права ссылаться на какое-нибудь из них в качестве основания, освобождающего ее от ответственности за неисполнение договорных обязательств.
7.3. Если чрезвычайное обстоятельство продолжает действовать в течение 30 и более суток, любая из сторон имеет право аннулировать договор полностью или частично, сообщив о принятом решении другой стороне. В этом случае ни одна из сторон не обязана возмещать возможные убытки.
 
8. ПРОЧИЕ УСЛОВИЯ

8.1. Настоящий Договор вступает в силу с момента его подписания сторонами и действует до   __________________________ года.
8.2. Корреспонденция любой из Сторон настоящего Договора, направляется по адресам, указанным в Договоре если отсутствуют уведомления о направлении писем по иным адресам.
8.3. Договор составлен в двух экземплярах на русском и казахском языках, при этом текст договора на русском языке имеет приоритет над текстом на казахском языке.
8.4. Все изменения и дополнения к настоящему договору должны быть оформлены в письменном виде и подписаны сторонами.
8.5. Во всех случаях, не предусмотренных настоящим договором, стороны руководствуются действующим     законодательством Республики Казахстан. Применимое право – законодательство Республики Казахстан.



9. Юридические адреса, реквизиты и подписи Сторон

Клиент:
{{fullName}}

Заңды мекенжайы: / Юридический адрес:
{{companyAddress}}

БСН / БИН {{companyBIN}}
ЖСК / ИИК {{bankIIK}}
банк {{bankLabel}}
БСК / БИК {{bankBIK}}

Президенті  / Президент 
_______________________ Исмаилов Р.Р. 
`;

export const kz = `
Жүкті көлікпен тасымалдау
Ш А Р Т Ы
№ ______________
 
 
Алматы қаласы             «___» _____ 20___ жыл
 
 
Бұдан әрі «Экспедитор» деп аталатын    «________________________________» атынан __________________ негізінде әрекет ететін _______________________________________ _________________________________________ бір жағынан және
бұдан  әрі  «Клиент» деп аталатын  «Technodom Operator» (Технодом Оператор) АҚ  атынан Жарғы негізінде әрекет            ететін  Президент  Исмаилов  Ренат  Рафикович екінші жағынан, төмендегілер туралы  осы Шартты  (бұдан әрі мәтін бойынша - Шарт) жасасты:
1. ШАРТТЫҢ МӘНІ
 
1.1. Клиент жүкті көлікпен тасымалдауды тапсырады, ал Экспедитор Клиенттің жүгін осы Шартта қарастырылған талаптарға және осы Шарттың ажырамас бөлігі болып табылатын көліктік өтінімге сәйкес жүкті  Клиенттің немесе Жүк қабылдаушының мекенжайына жеткізу міндеттемелерін қабылдайды. Тасымалдау маршруты, жеткізу мерзімі, жүктің түрі, жүктің көлемі, жүкті сақтандыру құны, жүкті тасымалдау құны және жүкті тасымалдауға байланысты басқа да талаптар осы Шарттың ажырамас бөлігі болып табылатын Өтінімде келісілген.
1.2. Жүк тасымалдау өтінімінде көрсетілген межелі орнына жеткізілгеннен кейін және Клиент немесе Жүк қабылдаушы оны инвойсқа сәйкес қабылдап алғаннан кейін Экспедитордың міндеттері аяқталады.
1.3. Тараптар арасындағы жүкті тасымалдауға, өтінім беруге, ағымдағы мәселелерді шешуге қатысты хат-хабарлар алмасу екі тараптың менеджерлері арқылы электронды пошта, факсимильді байланыс немесе өзге де ресми байланыс құралдарын пайдалану арқылы жүзеге асырылады және үшінші тұлғалар үшін жарамды. Тараптардың бірінші басшылары қол қоюға уәкілетті барлық құжаттарға қол қойылуы тиіс курьерлік қызмет, басқа да пошталық мекеме немесе Тараптардың өкілдері арқылы тұпнұсқада қағаз тасымалдауышта тапсырылуы тиіс.
Тараптар арасында хат-хабар алмасу төмендегі реквизиттер бойынша Тараптардың уәкілетті тұлғаларымен жүзеге асырылады:
• Клиенттің өкілі: {{fullName}}, клиенттің электрондық мекенжайы:  _____________________;
• Экспедитордың өкілі: _______________________________________,              Экспедитордың электрондық мекенжайы: ______________________.
1.4. Тараптар осы Шартта көрсетілген заңды мекенжайының дұрыстығын растайды және осы мекенжайға пошталық мекемелер арқылы жіберілген барлық хат-хабарлар жіберілген күннен бастап 12 күн ішінде алынған хат-хабар болып саналатындығына келіседі.
 
2. ТАРАПТАРДЫҢ МІНДЕТТЕРІ
 
2.1. Экспедитор осы Шарт бойынша төмендегі міндеттемелерді қабылдайды:
2.1.1. Клиенттен тиісті түрде ресімделген Өтінімді қабылдаған және ол келісілгеннен кейін қажет болған жағдайда түрлі көлік түрлерін пайдалана отырып, жүкті межелі орнына жеткізуді ұйымдастырады. Өтінімді орындау мүмкін болмаған жағдайда, Экспедитор Өтінім қабылдаған сәттен бастап 13 сағаттың ішінде жүкті тасымалдаудан бас тарту жөнінде хабарлама жіберуі тиіс және Клиенттен хабарламаны алғандығын растайтын жауап алуы тиіс. Егер жүкті тасымалдаудан бас тарту жөніндегі хабарлама түспеген жағдайда, Экспедитор өтінімді қабылдаған болып саналады.
2.1.2. Оңтайлы тасымалдау сызбасын әзірлейді және оны Клиентпен келіседі.
2.1.3. Өтінімде көрсетілген жүкті жеткізу талаптарына (ИНКОТЕРМС 2010) байланысты жүкті тиеуді және түсіруді ийвойсқа сәйкес бақылау жасайды.
2.1.4. Жеткізушіден орамасы бүтін жүкті жөнелтім құжаттарына (инвойс, орама құжаты) сәйкес қабылдайды.
2.1.5. Жүк Клиент ұсынған инвойс негізінде Жүк қабылдаушының немесе Клиенттің мекенжайына тиеледі.
2.1.6. Экспедитор жүкті тасымалдауға және тасымалдау қызметіне байланысты жұмыстарды орындау үшін үшінші тұлғаларды тартуға, сондай-ақ олармен өзінің атынан тиісті келісімшарт жасауға құқылы, бұл ретте үшінші тұлғалардың әрекеттері/әрекетсіздіктері үшін Клиент алдында Экспедитор жауапты болады.
2.1.7. Клиенттің жүгін Клиент Өтінімге сәйкес келіскен маршрут бойынша тасымалдауды тиісті түрде ұйымдастыру.
2.1.8. Клиентке күн сайын жүктің жүріс жолындағы жерін және жүктің межелі орнына жеткендігі туралы хабарлап отырады. Жүктің жүріс жолында немесе жүкті тиеу/түсіру кезінде жүктің тұрып қалғандығы, техникалық ақаулықтар мен өзге де болжанбаған  жағдайлардың орын алғандығы туралы тез арада хабарлайды.
2.1.9. Клиенттің сұранымы бойынша        және сұраным түскен күннен бастап                 5 (бес) жұмыс күні ішінде Клиентке коммерциялық, әкімшілік, салықтық және басқа мақсаттар үшін қажетті құжаттарды ұсынады.
2.1.10. Клиенттің талабы бойынша жүктің белгіленген пунктер арқылы өтуі үшін Экспедитор қосымша ақы төлей отырып, жүк маршрутын өзгертуі тиіс. Егер жүк тасымалдау барысында болса, онда тек Экспедитор жүктің маршрутын өзгертуге мүмкіншілігі болған жағдайда ғана, маршрутты өзгертуге жол беріледі.  
2.1.11. Экспедитордың өкілі Қазақстан Республикасында жүкті жеткізу орнында қабылдауға қатысуға міндетті, бұл ретте Клиент немесе Жүк қабылдаушы Экспедиторға жүкті қабылдау орны мен уақыты туралы ақпаратты алдын ала хабарлайды. Жүкті қабылдау орны мен уақыты туралы ақпарат Эскпедитордың атына электронды пошта арқылы жіберіледі немесе оны телефон арқылы хабарлайды.  
2.1.12. Экспедитордың өкілі Қазақстан Республикасында жүкті жеткізу орнында қабылдауға сенімхат негізінде қатысады. Егер Экспедитордың өкілі белгіленген мерзімде жүк қабылдауға келмеген жағдайда, Клиент немесе Жүк қабылдаушы жүкті Экспедитор өкілінің қатысуынсыз қабылдайды және  жүктің кем шығуы анықталған жағдайда тиісті акт жасайды. Аталған акт заң күшіне ие және жүктің кем шығуы немесе бүлінуі анықталған  жағдайда, кем шыққан немесе бүлінген  жүктің құнын төлеу жөнінде даулар қаралған жағдайда сотта дәлел ретінде жүреді. 
2.1.13. Жүк автокөлікпен тасымалданған кезде көлік құралының жүргізушісі Экспедитордың өкілі болып саналады және жүкті тиеу немесе түсіру орнында жүкті қабылдауға қатысуға және жүкті қабылдауға байланысты барлық қажетті құжаттарға қол қоюға міндетті. 
2.1.14. Ерекше жағдайларда Клиенттің Өтінімі бойынша қымбат бағалы жүкті алып жүру кезінде күзету қызметін ұсынады. 
2.1.15. Қажетті көліктік және коммерциялық құжаттамаларды алуға көмек көрсетеді. 
2.1.16. Жүкті ауыстыру станциясында немесе жүкті ауыстыратын басқа жерде жүкті бір көлік құралынан екінші көлік құралына ауыстырған кезде өзінің өкілінің қатысуын қамтамасыз етеді, Клиентке есептер мен фотосуреттерді бір тәулік ішінде ұсынады. 
2.1.17. Жүкті Клиентпен келіскен сақтандыру компаниясында сақтандырады. Бұл ретте пайда алушы ретінде Клиент көрсетіледі. 
2.1.18. Тіркеу деректері өзгерген жағдайда Экспедитор өзгерген деректерді есепке ала отырып, резиденттілігін растайтын құжатты қайта ұсынуы қажет. 
2.1.19. Жүктің кем шығуы, бүлінуі анықталған жағдайда немесе жүк жоғалған кезде осы Шарттың 4.2.; 4.3.-тармақтарына сәйкес жүк құнын төлейді. 
2.1.20. Жүк жөнелтушіден жүкті тасымалдауға қабылдаған сәттен бастап оны Клиентке немесе Жүк қабылдаушыға тапсырғанға дейін жүктің түгелділігі мен  бүтіндігі үшін толық материалдық жауапкершілікте болады. 
2.1.21. Өтінімде көрсетілген жеткізу мерзімі Қазақстан бойынша тасымалдау кезінде күнтізбелік 5 (бес) күннен өткеннен кейін және халықаралық тасымалдау кезінде күнтізбелік 20 (жиырма) күннен өткеннен кейін Экспедитор жүкті жоғалтқан болып есептеледі, жүк қадағалау жүйесімен жабдықталған көлік құралымен жеткізілген кезде – жүктің орналасқан орны туралы ақпарат болмаған кезде жүк Қазақстан бойынша тасымалданған кезде өтінімде көрсетілген жеткізу мерзімінен 5 (бес) тәулік өткеннен кейін, ал халықаралық тасымалдау кезінде өтінімде көрсетілген жеткізу мерзімінен 20 (жиырма) тәулік өткеннен кейін  Экспедитор Клиентке осы Шарттың 4.3.-бабында қарастырылған тәртіпте және мерзімде жоғалған жүктің құнын және жоғалған жүктің құнынан 20% мөлшерінде айыппұл төлеуге міндетті. Жүк табылған жағдайда тараптар өзара келісілген шешім қабылдайды.
2.1.22. Жүкті тасымалдауға қабылдаған сәттен бастап екі күнтізбелік күннен аспайтын мерзімде электронды пошта арқылы төмендегі құжаттарды ұсынады: 
 
• Инвойс;
• Өндірілген жері туралы сертификат;
• Сәйкестік сертификаты;
• Орам парағы;
• Көліктік жүкқұжаты.
 
2.2. Клиент осы Шарт бойынша төмендегі міндеттерді қабылдайды:
2.2.1. Экспедиторға Клиенттің жүгін тасымалдауды ұйымдастыру өтінімін жүкті тиеу күнінен  2 (екі) жұмыс күні бұрын ұсынады және оны Экспедитормен келіседі.
2.2.2. Тасымалдауға пайдаланатын көлік түріне қарай жүкті тасымалдау стандарттарына, ережелеріне сәйкес жүктің жүріс жолында және ауыстырып тиеу кезінде жүкті бүлінуден және зақымдардан қорғайтын ыдыста және орамда тапсырады.
2.2.3. Экспедитордың қызметтері үшін төленетін ақыны уақтылы және толық көлемде төлейді.
2.2.4. Экспедиторға жүктің түрі, көлемі, салмағы, орамы, орындар саны, жүкті жіберу орны мен  мақсаты, жүкті тасымалдауға дайын болатын дата, жеткізу талаптары, жүктің жарияланған құны жөніндегі ақпаратты ұсынады.
2.2.5. Экспедиторға нұсқауларды, қосымша ақпаратты, көліктік және жөнелтпе құжаттарды дайындауға қажетті құжаттарды ұсынады.
 
3. ЕСЕПТЕСУ ТӘРТІБІ
 
3.1. Осы Шарт бойынша ақы төлеу Клиент немесе Жүк қабылдаушы жүкті жеткізу орнында инвойстағы санына сәйкес кемшіліктерсіз қабылдағаннан кейін  25   банктік күн ішінде  жүзеге асырылады.
3.2. Жүкті қабылдаған кезде жүкке зиян келгендігі (кем шығу, зақым келу, жарамсыздық) анықталған немесе жол жүру кезінде Экспедитор жүкті жоғалтқанына сезіктенген жағдайда, Клиент Экспедиторға келтірілген зиян көлемінде, соның ішінде бұрын келтірілген зияндары үшін қызметке ақы төлемеуге құқылы.
3.3. Экспедитордың кінәсі бойынша жүк кем шыққаны анықталған жағдайда жүкті жеткізу бойынша көрсетілген қызмет құнын жетіспейтін жүк құнына тең кемітеді.
3.4. Экспедитор осы Шарт бойынша Клиентке көрсетілген қызметтер үшін төлем ретінде есепшот бойынша тиісті төлем жасалғанға дейін тасымалданатын затты немесе Клиенттің өзге де затын ұстап тұруға құқығы жоқ.
 
4. ТАРАПТАРДЫҢ ЖАУАПКЕРШІЛІГІ

4.1. Тараптар осы Шарт бойынша өз міндеттемелерін орындамағаны  немесе тиісінше орындамағаны үшін жауапты болады. Даулы жағдайлар бойынша келісімге келе алмаған жағдайда, Тараптар Қазақстан Республикасының заңнамасын басшылыққа алады.
4.2. Экспедитор Жеткізушіден жүкті қабылдап алған сәттен бастап оны Клиентке немесе Жүк қабылдаушыға тапсырғанға дейін тасымалдауға қабылдаған жүктің жоғалуына, бүлінуіне және жетіспеуіне материалдық жауапкершілікте болады және осы Шарттың 4.3.-тармағына сәйкес жүк құнын арыз қабылданған сәттен бастап күнтізбелік 7 (жеті) күннен  аспайтын мерзімде төлейді.
4.3. Экспедитор жүкті тасымалдау кезінде келтірілген шығындарды төмендегі көлемде өтейді:
• жоғалған жүктің инвойста көрсетілген құнынан 100% көлемінде жүк жоғалған немесе кем шыққан жағдайда – жоғалған немесе кем шыққан жүктің құны мен оны тасымалдау құны көлемінде өтейді. Жүк Шарттың 2.1.21.-тармақшасында белгіленген мерзімде Клиентке немесе Жүк қабылдаушыға жеткізілмеген жағдайда жоғалған болып саналады.
• жоғалған жүктің құны төмендеген  жағдайда – Клиенттен жүк бүлінгеннен кейін жүк құнының төмендеген көлеміне және тасымалдау құнына келісім алған кезде, жүк құнының төмендеген сомасы көлемінде өтейді.
• бүлінген жүкті қалпына келтіру мүмкін болмаған жағдайда немесе жүкті қалпына келтіру үшін жүк құнының 60%-ы көлеміндегі сома жұмсалатын болса – жүкті алып жүру инвойсында көрсетілген жүкті сатып алу құны мен оны тасымалдау құнының 100% көлемінде өтейді.
Клиент мұндай жағдайда күнтізбелік 5 (бес) күн ішінде Экспедиторға хабарлама жіберіп, Экспедиторға көрсетілген қызметтер үшін төленетін сомадан жоғалған/жойылған/бүлінген жүк құнын бір жақты шегеруге құқылы. Клиенттің Экспедитор алдындағы берешегі шығынды өтеуге жеткіліксіз болған жағдайда, Экспедитор күнтізбелік 10 (он) күннен аспайтын мерзім ішінде осы айырмашылықты төлейді.
4.4. Экспедитор  Клиентке немесе Жүк қабылдаушыға  жүкті кешіктіргені үшін Өтінімде  көрсетілген  жеткізу күнінен бастап жүкті кешіктірген әрбір күн үшін тасымалдау құнынан 0,1% көлемінде айыппұл төлейді.
4.5. Осы  Шарт  бойынша өз міндеттемелерін бұзған Тарап оларды тез арада жою керек.
4.6. Осы Шарттың 3.1.-тармағына сәйкес ақы төлеу мерзімі кешіктірілген жағдайда Клиент Экспедиторға осы Шарттың 3.2-тармағында көрсетілген  негіздер бойынша  ақы төлеу мерзімі кешіктірілген жағдайды қоспағанда, әрбір кешіктірілген күн үшін берешек сомасынан 0,1% көлемінде өсімақы төлейді, бірақ ол берешек сомасының 5%-нан  аспауы тиіс.
4.7. Экспедитор Өтінімде келісілген қызметтерді жүзеге асыру мерзімінен күнтізбелік 2 (екі) күн бұрын оларды орындаудан дәлелсіз түрде бас тартса, Экспедитор Өтінімде көрсетілген қызметтердің құнынан 10 % көлемінде айыппұл төлейді.
4.8. Экспедитор Клиентке жоғалған жүктің толық құнын төлейді. Жүкті межелі орнына жеткізу мерзімі өткен сәттен бастап күнтізбелік 5 (бес) күн өткенге дейін жүк межелі орнына жеткізілмеген жағдайда, жүк жоғалған болып есептеледі. Мұндай жағдайда Клиент 3 (үш) жұмыс күні ішінде Экспедиторға хабарлама жіберіп, бір жақты тәртіпке Экспедиторға көрсеткен қызметтері үшін берешек сомасын жоғалған жүктің сомасына төмендете алады.
5. КІНӘРАТТАР, ДАУЛАРДЫ ШЕШУ
5.1. Тараптардың құқықтары бұзылған жағдайда әрбір тарап екінші тарапқа тиісті түрде шағым түсіре алады.
5.2. Шағым жолданған тарап, шағымды          қабылдаған  сәттен  бастап  5  (бес)  жұмыс күнінен кешіктірмей шағымды қарауы тиіс. Аталған мерзім ішінде  екінші   тараптың мекенжайына шағымды    қанағаттандырудан бас тарту    жөнінде  дәлелді  хабарлама  жіберуі тиіс, немесе екі жұмыс күні ішінде шығындардың орнын толтырады.
5.3. Шарттың 5.2.-тармағында көрсетілген мерзімде жауап қайтарылмаған жағдайда, шағым мойындалған деп есептеледі және қанағаттандырылуға жатады
Шағымға жауап қайтарылмау, онымен келіскендігін білдіреді және екінші тарапқа төленетін сомадан, соның ішінде басқа да тасымалдаулар үшін де,  тиісті соманы бірінші тараптың ұстап қалуына негіз болады. Келтірілген шығынның орнын толтыру үшін төленетін сома жеткіліксіз болған жағдайда, кінәлі тарап, есебі қоса берілген хабарламаны алғаннан кейін күнтізбелік 10 (он) күн ішінде аударады.
5.4. Осы Шарт бойынша барлық шағымдар мен даулар келіссөздер арқылы реттелмесе Қазақстан республикасының қолданыстағы заңнамасында қарастырылған тәртіпте Алматы қаласының Мамандандырылған ауданаралық экономикалық соты арқылы шешілуге жатады.
5.5. Төмендегі құжаттардың кез-келгені Клиенттің талабын растайтын құжат болып табылады:
• Клиент пен Экспедитордың қатысуымен жасалған акт;
• ҚР Ұлттық кәсіпкерлер палатасының сарапшылары жасаған акт;
• сюрвейер актісі;
• темір жолмен жасалған коммерциялық акт;
• Экспедитордың өкілі жүкті қабылдауға келмеген жағдайда Клиент жасаған акт;
• Қазақстан Республикасының заңнамасына сәйкес рұқсат етілген басқа да құжаттар, фотосуреттер, бейнетүсірімдер мен басқа да дәлел ретінде жүретін құжаттар.
 
6. СЫБАЙЛАС ЖЕМҚОРЛЫҚҚА ҚАРСЫ ТАЛАПТАР. ДЕРБЕС ДЕРЕКТЕР ТУРАЛЫ ЗАҢНАМАНЫ САҚТАУ. ҚҰПИЯЛЫЛЫҚ
 
6.1. Экспедитор Клиенттің мынадай сыбайлас жемқорлыққа қарсы талаптары жайлы хабардар екенін растайды:
6.1.1. Сыйақыны кез келген түрде, соның ішінде Сатып алушының кез келген жұмыскеріне, жеткізушісіне, орындаушысына, мердігеріне, кеңесшісіне, серіктесіне, агентіне немесе делдалына ақшалай және материалдық түрде ұсынуға, беруге немесе олардан алуға жол берілмейді.
6.1.2. Клиент өзінің мүддесін көздейтін барлық тұлғаларға жемқорлыққа қарсы талаптарды таратуға ықпал етеді. Клиенттің мүддесіне немесе оның атынан әрекет ететін әрбір тұлға Клиенттің жемқорлыққа қарсы талаптарын үшінші тұлғаларға жеткізуге және олардың сол талаптарды орындауына жауапты болады.
6.2. Экспедитор Шарттың осы бабында баяндалған Клиенттің жемқорлыққа қарсы талаптарын әрдайым және толық көлемде сақтайтынын және оларды өзінің барлық жұмыскерлерінің, сондай-ақ Клиентпен мәміле жасаған кезде немесе оның атынан немесе оған қандай да бір қатысы бар агенттер мен мердігерлердің сақтауын қамтамасыз ететінін қабылдайды және растайды.
6.3. Тараптар кез келген сыбайлас жемқорлық немесе парақорлық іс-әрекеттеріне қатыспауға және осы Шарт бойынша өз міндеттемелерін орындау барысында адал болуға міндеттеме алады.
6.4. Тараптар Шарттың осы тарауын Тараптардың бірінің бұзуы орын алғанын болжайтын немесе растайтын кез келген фактілер, материалдар немесе жағдайлар туралы бір-біріне дереу хабарлайды.
6.5. Тараптар осы Шарт бойынша міндеттемелерін орындау барысында оларға белгілі болған дербес деректердің құпиялылығын, сондай-ақ Қазақстан Республикасының  «Дербес деректер және оларды қорғау туралы» Заңына сәйкес дербес деректерді өңдеу кезінде олардың қауіпсіздігін қамтамасыз етуге міндеттенеді. Дербес деректерге  ақпараттар негізінде айқындалған немесе айқындалатын жеке тұлғаға қатысты берілген кез келген ақпарат, атап айтқанда, оның тегі, аты, әкесінің аты, туған жылы, айы, күні мен туылған жері, мекенжайы, білімі, мамандығы, мүліктік жағдайы, табысы мен басқа да ақпараттар жатады.
Тараптарға осы Шарт әрекет ететін мерзім ішінде кез келген Тараптың шаруашылық қызметі жөнінде белгілі болған кез келген ақпарат құпия ақпарат болып табылады және Қазақстан Республикасының қолданыстағы заңнама талаптарына сәйкес қажет болуы мүмкін жағдайларды қоспағанда, үшінші тұлғаларға жариялауға болмайды.
 
7. ФОРС-МАЖОР
 
7.1. Егер соғыс әрекеттері, табиғи сипаттағы апаттар, ереуілдер, жаппай тәртіпсіздіктер, тыйым салынған немесе шек қойылған заңнамалық шешімдер, мемлекеттік және табиғи монополияларды реттеу органдарының, өзге ұйымдар мен өнеркәсіптердің шешімдері сияқты еңсерілмес күш жағдайларының салдарынан және осы Шартты жасасқаннан кейін ол шешімдерге ықпал ете алмайтын және осы Шарт бойынша қандай да бір міндеттемелердің толық немесе ішінара орындалуына кедергі келтіретін жағдайлар орын алған кезде Тараптар осы Шарт бойынша өз міндеттемелерін ішінара немесе толық орындамағаны  үшін жауапкершіліктен босатылады. Міндеттемелерді орындау мерзімі Қазақстан Республикасының заңнамасына сәйкес сондай жағдайлар орын алған уақытқа созылады.
7.2. Осы Шарт бойынша өз міндеттемелерін орындау  мүмкін  болмаған Тарап,  оны  жауапкершіліктен  босататын еңсерілмес  күш  жағдайдарының  туындағаны жөнінде  екінші  Тарапты  хабарламау  немесе уақтылы  хабарламау  сол  Тарапты  өз міндеттемелерін орындамауға еңсерілмес күш   жағдайларының  негіз болатынына сілтеме  жасау  құқығынан  айырады.
7.3. Егер еңсерілмес күш жағдайлары 30 және одан артық тәулік ішінде әрекет етсе, кез келген тарап екінші тарапты қабылдаған шешімі туралы хабарлап, шарттың күшін толық немесе ішінара жоюға құқылы. Мұндай жағдайда бір де бір тарап келтірілген шығындардың орнын толтыруға міндетті емес.
 
8. ӨЗГЕ ТАЛАПТАР
 
8.1. Осы шарт оған Тараптар қол қойған сәттен бастап күшіне енеді және __________________________ әрекет етеді.
8.2. ТарапТАР Хат-хабарды өзге мекенжайға жіберу туралы жазбаша хабарлама алмаған жағдайда,  кез келген хат-хабарды осы Шартта көрсетілген мекенжайға жібереді.
  1.1. Осы Шарт қазақ және орыс тілдерінде бірдей заң күші бар екі данада жасалды.  Шарттың қазақ тіліндегі мәтіні мен орыс тіліндегі мәтіні арасында қайшылықтар туындаған жағдайда, Шарттың орыс тіліндегі мәтініне артықшылық беріледі.
  1.2. Осы Шартқа енгізілген барлық өзгерістер мен толықтырулар жазбаша түрде ресімделуі және оған Тараптар қол қоюы тиіс.
Тараптар осы Шартпен көзделмеген барлық мәселелерді шешуде Қазақстан Республикасының қолданыстағы заңнамасын басшылыққа алады. Қолданылатын құқық – Қазақстан Республикасының заңнамасы.









9. Заңды мекенжайы, реквизиттері және тараптардың қолы

Экспедитор

Заңды мекенжайы: / Юридический адрес:
______________________________________
БСН / БИН _____________________________
ЖСК / ИИК_______________________
банк _______________________
БСК / БИК ________________________

_________ ____________ _________________
`;
